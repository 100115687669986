import axios from "axios";
import { getConfigLoading, getConfigSuccess, getConfigError, setCountryName } from '../slice/config.slice'

function isColorDark(color) {
    // Remove leading '#' if present
    color = color.replace('#', '');

    // Convert the color to RGB
    const red = parseInt(color.substring(0, 2), 16);
    const green = parseInt(color.substring(2, 4), 16);
    const blue = parseInt(color.substring(4, 6), 16);

    // Calculate the relative luminance using the formula
    // Y = 0.2126 * R + 0.7152 * G + 0.0722 * B
    const luminance = (0.2126 * red + 0.7152 * green + 0.0722 * blue) / 255;

    // Check if the luminance is below a threshold (0.5) to determine if the color is dark
    return luminance < 0.5;
}

export function getConfig(payload) {
    return (dispatch) => {
        dispatch(getConfigLoading())
        axios.post(`https://thediamondport.com/api/wh_get_config`, payload)
            .then((resp) => {
                const { success, data } = resp.data
                if (success) {
                    let darkColor = data.colorcode
                    let lightColor = isColorDark(data.colorcode) ? '#ffffff' : '#000000'
                    let labWidth = `${data?.lab && (100 / data.lab.length) - 1}%`
                    if (lightColor !== "") {
                        data['lightColor'] = lightColor
                        document.documentElement.style.setProperty('--color', darkColor);
                        document.documentElement.style.setProperty('--light-color', lightColor);
                        document.documentElement.style.setProperty('--lab-width', labWidth);
                        dispatch(getConfigSuccess(data))
                    }
                }
                else {
                    dispatch(getConfigError())
                }
            })
            .catch((err) => {
                dispatch(getConfigError())
            })
    }
}

export function getCurrentCountry() {
    return (dispatch) => {
        axios.get('https://ipapi.co/json/')
          .then(data => {
            const country = data.data;
            dispatch(setCountryName(country))
          })
          .catch(error => {
            console.error("Error fetching data:", error);
          });
    }
  }