import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from './Card';
import { getProduct } from '../middleware-redux/Product';
import { useState } from 'react';
import Pro_Table from './Pro_Table';
import DiamondView from './DiamondView';
import '../assets/sass/style.scss'
import { setProductObj } from '../slice/product.slice';

const Product = ({ listType }) => {
  const dispatch = useDispatch()
  const { proLoading, product, productArr, productObj } = useSelector((state) => state.product);
  const { config, productFilter } = useSelector((state) => state.config);

  const [detailModal, setDetailModal] = useState(false)

  let timeoutId;

  const handleScroll = (e) => {
    const scrollableHeight = e.scrollHeight - window.innerHeight;
    const currentScroll = window.pageYOffset;
    const scrollPercent = (currentScroll / scrollableHeight) * 100;

    if (productArr.length > 0) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (
          product?.next_page_url && scrollPercent > 90
        ) {
          dispatch(getProduct({ ...productFilter, host: window?.Shopify?.shop }, product?.current_page))
        }
      }, 300)
    }
  }

  useEffect(() => {
    let elements = document.querySelectorAll('.tdp-product-section');
    if (product?.next_page_url) {
      elements?.forEach(element => {
        window.addEventListener(
          "scroll",
          () => handleScroll(element),
          { passive: true }
        );
      });
    }

    return () => {
      elements?.forEach(element => {
        window.removeEventListener(
          "scroll",
          () => handleScroll(element),
          { passive: true }
        );
      });
    };
  }, [product, productFilter]);

  const openDetailModal = () => {
    setDetailModal(!detailModal)
    if (detailModal) {
      dispatch(setProductObj({}))
    }
  }

  return (
    <>
      {Object.keys(productObj).length === 0 && <div className='tdp-w-full tdp-flex tdp-justify-center'>
        <div className={`tdp-product-section main-div laptop-md-sm:tdp-w-[85%] laptop-s-lg:tdp-w-[66%] laptop-s-lg-tab:tdp-w-[94%] tablet-lg-lg2:tdp-w-[84%] tablet-lg2-lg3:tdp-w-[85%] tablet-lg3-lg4:tdp-w-[85%] lg-sm-mobile:tdp-w-[77%] tdp-flex tdp-flex-wrap tdp-h-full tdp-justify-around ${listType !== 'Grid' && 'lg-sm-mobile:tdp-w-[88%] tdp-overflow-x-auto tdp-overflow-y-hidden'}`}>
          {listType === 'Grid' ?
            (productArr.length !== 0 ? productArr?.map((pro, i) => (
              <Card data={pro} key={i} filter={config} openDetailModal={openDetailModal} />
            ))
              : (!proLoading && Object.keys(product).length > 0 && productArr.length === 0) ? <h1 className='h1'>No data found using your search criteria.</h1> :
                <div className='tdp-flex tdp-justify-center tdp-w-full tdp-mt-3'>
                  <span className='spinner' style={{ background: config.colorcode }}></span>
                </div>)
            :
            ((productArr.length !== 0) ?
              <Pro_Table data={productArr} filter={config} openDetailModal={openDetailModal} />

              : (!proLoading && Object.keys(product).length > 0 && productArr.length === 0) ? <h1 className='h1'>No data found using your search criteria.</h1> :
                <div className='tdp-flex tdp-justify-center tdp-w-full tdp-mt-3'>
                  <span className='spinner' style={{ background: config.colorcode }}></span>
                </div>)
          }


          {productArr.length !== 0 && Object.keys(product).length === 0 && <div className='tdp-flex tdp-justify-center tdp-w-full tdp-mt-3'>
            <span className='spinner scroll' style={{ background: config.colorcode }}></span>
          </div>}
        </div>
      </div>}
      {Object.keys(productObj).length > 0 && <DiamondView data={productObj} />}
    </>

  )
}

export default Product