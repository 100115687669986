import { useDispatch, useSelector } from 'react-redux';
import { setProductObj } from '../slice/product.slice';


const Pro_Table = ({ data, openDetailModal }) => {

  let dispatch = useDispatch()

  const { config, productFilter } = useSelector((state) => state.config);

  const columns = [{
    "label": "Diamond",
    "key": "image"
  }, {
    "label": "Shape",
    "key": "shape"
  }, {
    "label": "Carat",
    "key": "carat"
  }, {
    "label": "Color",
    "key": "color"
  }, {
    "label": "Clarity",
    "key": "clarity"
  }, {
    "label": "Cut",
    "key": "cut"
  }, {
    "label": "Polish",
    "key": "polish"
  }, {
    "label": "Symmetry",
    "key": "symmetry"
  }, {
    "label": "Lab",
    "key": "lab"
  }, {
    "label": "Price",
    "key": "net_price"
  }]

  const view_clicked = (obj) => {
    dispatch(setProductObj(obj))
    openDetailModal()
  }

  return (
    <>
      <div className='tdp-flex tdp-justify-start tdp-w-full tdp-my-5'>
        {(config?.diamond_type?.includes("both") || config?.diamond_type?.includes("natural")) && <div className='tdp-natural tdp-mx-2 tdp-flex tdp-justify-start tdp-items-center'>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="18" fill="#02BEE8" />
          </svg>
          <span className='tdp-mx-3 tdp-text-lg'>Natural</span>
        </div>}
        {(config?.diamond_type?.includes("both") || config?.diamond_type?.includes("labgrown")) && <div className='tdp-natural tdp-mx-2 tdp-flex tdp-justify-start tdp-items-center'>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="18" fill="#2CAA00" />
          </svg>
          <span className='tdp-mx-3 tdp-text-lg'>Lab Grown</span>
        </div>}
      </div>
      <div className='tdp-w-full tdp-product'>
        <div style={{ overflowX: 'auto' }}>
          <table className='tdp-product-tbl tdp-border-collapse'>
            <tr className='tdp-bg-gray-300 tdp-border-l-[5px] tdp-border-gray-300'>
              {columns.map((col, i) => (
                config?.display_price !== 0 ? <th key={i} className='tdp-p-4 tdp-text-start'>{col.label}</th> : col.label !== "Price" && <th key={i} className='tdp-p-4 tdp-text-start'>{col.label}</th>
              ))}
              <th className='tdp-p-4 tdp-text-start'>Action</th>
            </tr>
            {data.length > 0 && data.map((d, i) => (
              <tr key={i} className='tdp-py-1'>
                <td className='tdp-w-[7%] tdp-border-right-0 tdp-p-4 tdp-text-left' style={{ borderLeft: `5px solid ${productFilter.diamond_type.toLocaleLowerCase() === "natural" ? '#02BEE8' : '#2CAA00'}`, borderTopWidth: '2px', borderBottomWidth: '2px' }}><img src={d.image ? d.image : require(`../assets/images/${d.shape?.slice(0, 1) + d.shape?.slice(1)?.toLocaleLowerCase()}.png`)} className='tdp-select-none tdp-w-2/4' /></td>
                <td className='tdp-p-4 tdp-text-left' style={{ borderTopWidth: '2px', borderBottomWidth: '2px' }}>{d.shape}</td>
                <td className='tdp-p-4 tdp-text-left' style={{ borderTopWidth: '2px', borderBottomWidth: '2px' }}>{d.carat}</td>
                <td className='tdp-p-4 tdp-text-left' style={{ borderTopWidth: '2px', borderBottomWidth: '2px' }}>{d.color}</td>
                <td className='tdp-p-4 tdp-text-left' style={{ borderTopWidth: '2px', borderBottomWidth: '2px' }}>{d.clarity}</td>
                <td className='tdp-p-4 tdp-text-left' style={{ borderTopWidth: '2px', borderBottomWidth: '2px' }}>{d.cut === "" ? <span>&nbsp;</span> : d.cut}</td>
                <td className='tdp-p-4 tdp-text-left' style={{ borderTopWidth: '2px', borderBottomWidth: '2px' }}>{d.polish}</td>
                <td className='tdp-p-4 tdp-text-left' style={{ borderTopWidth: '2px', borderBottomWidth: '2px' }}>{d.symmetry}</td>
                <td className='tdp-p-4 tdp-text-left' style={{ borderTopWidth: '2px', borderBottomWidth: '2px' }}>{d.lab}</td>
                {config?.display_price !== 0 && <td className='tdp-p-4 tdp-text-left' style={{ borderTopWidth: '2px', borderBottomWidth: '2px' }}>{config?.currency_symbol}{Number(parseFloat(d.net_price).toFixed(2)).toLocaleString()}</td>}
                <td className='tdp-border-left-0 tdp-p-4 tdp-border-left-0' style={{ borderRight: `0.1rem solid #cdcdcd9c`, borderTopWidth: '2px', borderBottomWidth: '2px' }}>
                  <button onClick={() => view_clicked(d)} className="tdp-text-blue-800 tdp-decoration-solid">Details</button>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div >
    </>
  )
};
export default Pro_Table;