import { configureStore } from "@reduxjs/toolkit";
import configSlice from "../slice/config.slice";
import productSlice from "../slice/product.slice";

function configStore() {
    const store = configureStore({
        reducer: {
            config: configSlice,
            product: productSlice
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ serializableCheck: false }),
    });
    return store;
}

export default configStore;
