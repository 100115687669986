import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Main from "../pages/Main";
import "../App.css"
import '../assets/sass/app.scss';

function App() {
  useEffect(() => {
    handleGoogle()
  }, [])

  function getFaviconEl() {
    return document.getElementById("favicon");
  }
  
  const handleGoogle = () => {
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href = "https://www.google.com/favicon.ico";
  };

  return <React.Fragment>
    <ToastContainer />
    <Routes>
      <Route path="/:token" element={<Main />} />
    </Routes>
  </React.Fragment>
}

export default App;
