import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setProductObj } from '../slice/product.slice';


const Card = ({ data, filter, openDetailModal }) => {
  const dispatch = useDispatch();

  const { config } = useSelector((state) => state.config);
  let shape = data.shape.slice(0, 1) + data.shape.slice(1).toLocaleLowerCase()

  const view_clicked = (obj) => {
    dispatch(setProductObj(obj))
    openDetailModal()
  }

  return (
    <div onClick={() => view_clicked(data)} className='tdp-product-card tablet-lg3-lg4:tdp-w-[45%] lg-sm-mobile:tdp-w-full tdp-cursor-pointer laptop-s-lg-tab:tdp-w-[24%] tdp-card laptop-xl:tdp-w-[18%] laptop-lg-md:tdp-w-[23.5%] laptop-md-sm:tdp-w-[24%] laptop-s-lg:tdp-w-[30%] tablet-lg2-lg3:tdp-w-[45%] tablet-lg-lg2:tdp-w-[28%] tdp-flex tdp-flex-col tdp-my-4 tdp-rounded-xl tdp-border-gray-200 tdp-border-[1px]'>
      <div className={`${data.image ? "tdp-img" : "tdp-shape"} tdp-rounded-t-xl tdp-justify-center tdp-items-center tdp-flex`}>
        <img src={data.image ? data.image : require(`../assets/images/${shape}.png`)} className={`tdp-object-cover tdp-select-none ${data.image ? 'tdp-rounded-t-xl tdp-w-full tdp-h-full-image' : 'tdp-w-3/5 tdp-h-small-image'}`} style={{ border: 'none', borderTopLeftRadius: data.image ? '12px' : '0', borderTopRightRadius: data.image ? '12px' : '0' }} />
      </div>
      <div className="tdp-info tdp-w-full tdp-bg-[#fafafa] tdp-p-4 tdp-rounded-b-xl">
        <div className='laptop-md-sm:tdp-h-[40px] tdp-w-full tablet-lg-lg2:tdp-h-[30px] tablet-lg2-lg3:tdp-h-[40px] laptop-s-lg-tab:tdp-h-[35px]'>
          <p className="card-tdp-h4 laptop-sm2-sm:tdp-h5 laptop-s-lg-tab:tdp-h5 card-tdp-h4 tdp-card-leading-h4 tdp-text-left tdp-wt-medium laptop-s-lg-tab:tdp-h5" style={{ color: filter?.colorcode }}>{parseFloat(data.carat).toFixed(2)} ct • {data.itemHead.join(' • ')}</p>
          <p className="card-tdp-h4 laptop-sm2-sm:tdp-h5 laptop-s-lg-tab:tdp-h5 card-tdp-h4 tdp-card-leading-h4 tdp-text-left tdp-wt-medium laptop-s-lg-tab:tdp-h5" style={{ color: filter?.colorcode }}>{data.secondItemHead.join(' ')}</p>
        </div>
        <div className={config?.display_price !== 0 ? 'tdp-flex tdp-justify-between tdp-mt-3' : 'tdp-flex tdp-justify-end tdp-mt-3'}>
          {config?.display_price !== 0 && <p className="price-tdp-h4 tdp-leading-h2 tdp-text-left tdp-p-0 tdp-m-0" style={{ color: filter?.colorcode }}>{filter?.currency_symbol}{parseFloat(data.net_price).toFixed(2).toLocaleString()}</p>}
          <p className="card-tdp-h4 tdp-leading-h2 tdp-text-left tdp-p-0 tdp-m-0" style={{ color: filter?.colorcode }}>{data.flourLab.join('-')}</p>
        </div>
      </div>
    </div>
  )
}

export default Card