import { createSlice } from "@reduxjs/toolkit";


export const productSlice = createSlice({
    name: "Product",
    initialState: {
        proLoading: false,
        product: {},
        productArr: "",
        proError: "",

        moreproLoading: false,
        productObj: {},

        InquiryLoading: false,
        InquirySuccess: {},
        InquiryError: {},

        ChooseDiamondLoading: false,
        ChooseDiamondSuccess: {},
        ChooseDiamondError: {},

    },
    reducers: {
        getProductLoading: (state) => {
            state.proLoading = true
            state.product = {}
            state.productArr = []
            state.proError = ""
        },
        getProductSuccess: (state, action) => {
            state.moreproLoading = false
            state.proLoading = false
            state.product = action.payload
            state.productArr = [...state.productArr, ...action.payload.data]
        },
        getProductError: (state) => {
            state.moreproLoading = false
            state.proLoading = false
            state.proError = "Can't find any product"
        },

        setProductArrEmpty: (state) => {
            state.product = {}
            state.productArr = []
        },

        getMoreProductLoading: (state) => {
            state.moreproLoading = true
            state.product = {}
        },

        setProductObj: (state, action) => {
            state.productObj = action.payload
        },

        GetInquiryLoading: (state) => {
            state.InquiryLoading = true
        },
        GetInquirySuccess: (state, action) => {
            state.InquiryLoading = false;
            state.InquirySuccess = action.payload
        },
        GetInquiryError: (state, action) => {
            state.InquiryLoading = false;
            state.InquiryError = action.payload.data
        },

        GetChooseDiamondLoading: (state) => {
            state.ChooseDiamondLoading = true
        },
        GetChooseDiamondSuccess: (state, action) => {
            state.ChooseDiamondLoading = false;
            state.ChooseDiamondSuccess = action.payload
        },
        GetChooseDiamondError: (state, action) => {
            state.ChooseDiamondLoading = false;
            state.ChooseDiamondError = action.payload.data
        },

        DiamondViewClosed: (state) => {
            state.InquiryLoading = false;
            state.InquirySuccess = {};
            state.InquiryError = {};

            state.ChooseDiamondLoading = false;
            state.ChooseDiamondSuccess = {};
            state.ChooseDiamondError = {};
        }
    }
});

export const {
    getProductLoading, getProductSuccess, getProductError, setProductArrEmpty, getMoreProductLoading, setProductObj, GetInquiryLoading, GetInquirySuccess, GetInquiryError, GetChooseDiamondLoading, GetChooseDiamondSuccess, GetChooseDiamondError, DiamondViewClosed
} = productSlice.actions;

export default productSlice.reducer