import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setProductFilter } from '../slice/config.slice';
import { setProductArrEmpty } from '../slice/product.slice';
import { getProduct } from '../middleware-redux/Product';

const FancyColor = ({ arr, isReset, setResetFalse, color }) => {
    const dispatch = useDispatch()
    const { productFilter } = useSelector((state) => state.config);

    const [selected, setSelected] = useState([])
    const [width, setWidth] = useState(0);
    const [initialLoading, setInitialLoading] = useState(true)
    useEffect(() => {
        let fancyColorArr = arr.filter((a) => a !== "champange" && a !== "other")
        setWidth((100 / fancyColorArr.length))
    }, [])

    const colorComp = (color) => {
        if (color === "argyle") {
            return (
                <>
                    <svg width="28" height="28" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="#BCD8F4" />
                        <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="url(#paint0_linear_196_10365)" />
                        <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint1_linear_196_10365)" />
                        <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint2_linear_196_10365)" />
                        <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="#C4DAF2" />
                        <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint3_linear_196_10365)" />
                        <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="url(#paint4_linear_196_10365)" />
                        <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint5_linear_196_10365)" />
                        <path d="M17.5535 10.8596V6.12616H15.5921L14.2061 9.47216L17.5535 10.8596Z" fill="url(#paint6_linear_196_10365)" />
                        <path d="M14.2063 9.47215L10.8589 8.08685L9.47217 9.47215L12.8196 12.8203L14.2063 9.47215Z" fill="url(#paint7_linear_196_10365)" />
                        <path d="M20.9011 9.47216L19.5144 6.12616H17.5537V10.8596L20.9011 9.47216Z" fill="url(#paint8_linear_196_10365)" />
                        <path d="M24.2476 8.08686L22.2869 6.12616H19.5142L20.9009 9.47216L24.2476 8.08686Z" fill="url(#paint9_linear_196_10365)" />
                        <path d="M15.5923 6.12616H12.8196L10.8589 8.08686L14.2063 9.47216L15.5923 6.12616Z" fill="url(#paint10_linear_196_10365)" />
                        <path d="M10.8589 27.0205L12.8196 28.9812H15.5923L14.2063 25.6352L10.8589 27.0205Z" fill="url(#paint11_linear_196_10365)" />
                        <path d="M20.9009 25.6352L24.2476 27.0204L25.6343 25.6352L22.2869 22.2878L20.9009 25.6352Z" fill="url(#paint12_linear_196_10365)" />
                        <path d="M19.5142 28.9812H22.2869L24.2476 27.0205L20.9009 25.6352L19.5142 28.9812Z" fill="url(#paint13_linear_196_10365)" />
                        <path d="M6.12549 19.5144V22.2878L8.08549 24.2485L9.47219 20.9011L6.12549 19.5144Z" fill="url(#paint14_linear_196_10365)" />
                        <path d="M17.5537 24.2485V28.9812H19.5144L20.9011 25.6352L17.5537 24.2485Z" fill="url(#paint15_linear_196_10365)" />
                        <path d="M22.2871 22.2878L25.6345 25.6352L27.0212 24.2485L25.6345 20.9011L22.2871 22.2878Z" fill="url(#paint16_linear_196_10365)" />
                        <path d="M9.47215 20.9011L8.08545 24.2485L9.47215 25.6352L12.8195 22.2878L9.47215 20.9011Z" fill="url(#paint17_linear_196_10365)" />
                        <path d="M14.2061 25.6352L15.5921 28.9812H17.5535V24.2485L14.2061 25.6352Z" fill="url(#paint18_linear_196_10365)" />
                        <path d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint19_linear_196_10365)" />
                        <path d="M22.2869 12.8203L25.6343 9.47215L24.2476 8.08685L20.9009 9.47215L22.2869 12.8203Z" fill="url(#paint20_linear_196_10365)" />
                        <path d="M10.8589 17.5536L12.8196 22.2878L17.5537 17.5536H10.8589Z" fill="url(#paint21_linear_196_10365)" />
                        <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="#C5DDF8" />
                        <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="url(#paint22_linear_196_10365)" />
                        <path d="M25.6345 14.2063L22.2871 12.8203L24.2478 17.5537L25.6345 14.2063Z" fill="url(#paint23_linear_196_10365)" />
                        <path d="M22.2871 12.8203L17.5537 10.8596V17.5537L22.2871 12.8203Z" fill="url(#paint24_linear_196_10365)" />
                        <path d="M24.2478 17.5537L22.2871 12.8203L17.5537 17.5537H24.2478Z" fill="url(#paint25_linear_196_10365)" />
                        <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint26_linear_196_10365)" />
                        <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint27_linear_196_10365)" />
                        <path d="M25.6345 14.2063L27.0212 10.8596L25.6345 9.47217L22.2871 12.8203L25.6345 14.2063Z" fill="url(#paint28_linear_196_10365)" />
                        <path opacity="0.9" d="M14.2065 25.6352L17.5539 24.2485L12.8198 22.2878L14.2065 25.6352Z" fill="url(#paint29_linear_196_10365)" />
                        <path d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint30_linear_196_10365)" />
                        <path opacity="0.5" d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint31_linear_196_10365)" />
                        <path d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint32_linear_196_10365)" />
                        <path opacity="0.5" d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint33_linear_196_10365)" />
                        <path d="M20.9011 25.6352L22.2871 22.2878L17.5537 24.2485L20.9011 25.6352Z" fill="url(#paint34_linear_196_10365)" />
                        <path d="M25.6343 20.9011L28.981 19.5144V17.5536H24.2476L25.6343 20.9011Z" fill="url(#paint35_linear_196_10365)" />
                        <path d="M24.2476 17.5537H28.981V15.593L25.6343 14.2063L24.2476 17.5537Z" fill="url(#paint36_linear_196_10365)" />
                        <path d="M22.2871 22.2878L25.6345 20.9011L24.2478 17.5536L22.2871 22.2878Z" fill="url(#paint37_linear_196_10365)" />
                        <path d="M28.981 15.593V12.8203L27.021 10.8596L25.6343 14.2063L28.981 15.593Z" fill="url(#paint38_linear_196_10365)" />
                        <path opacity="0.9" d="M17.5537 24.2485L22.2871 22.2878L17.5537 17.5536V24.2485Z" fill="url(#paint39_linear_196_10365)" />
                        <path d="M25.6343 20.9011L27.021 24.2485L28.981 22.2878V19.5144L25.6343 20.9011Z" fill="url(#paint40_linear_196_10365)" />
                        <path d="M12.8198 22.2878L17.5539 24.2485V17.5536L12.8198 22.2878Z" fill="url(#paint41_linear_196_10365)" />
                        <path opacity="0.9" d="M12.8196 12.8203L10.8589 17.5537H17.5537L12.8196 12.8203Z" fill="url(#paint42_linear_196_10365)" />
                        <path d="M17.5537 17.5536L22.2871 22.2878L24.2478 17.5536H17.5537Z" fill="url(#paint43_linear_196_10365)" />
                        <path d="M17.5539 10.8596L12.8198 12.8203L17.5539 17.5537V10.8596Z" fill="url(#paint44_linear_196_10365)" />
                        <path d="M6.12549 22.2878V28.9812L8.08549 24.2485L6.12549 22.2878Z" fill="url(#paint45_linear_196_10365)" />
                        <path d="M9.47219 25.6352L6.12549 28.9812L10.8589 27.0205L9.47219 25.6352Z" fill="url(#paint46_linear_196_10365)" />
                        <path d="M8.08549 24.2485L6.12549 28.9812L9.47219 25.6352L8.08549 24.2485Z" fill="url(#paint47_linear_196_10365)" />
                        <path d="M10.8589 27.0204L6.12549 28.9811H12.8196L10.8589 27.0204Z" fill="url(#paint48_linear_196_10365)" />
                        <path d="M6.12549 28.9812L17.5537 33.7153L12.8196 28.9812H6.12549Z" fill="url(#paint49_linear_196_10365)" />
                        <path d="M1.39209 17.5536L6.12549 28.9811V22.2878L1.39209 17.5536Z" fill="url(#paint50_linear_196_10365)" />
                        <path d="M12.8198 28.9812L17.5539 33.7153L15.5925 28.9812H12.8198Z" fill="url(#paint51_linear_196_10365)" />
                        <path d="M6.12549 15.593L1.39209 17.5537H6.12549V15.593Z" fill="url(#paint52_linear_196_10365)" />
                        <path d="M6.12549 17.5536H1.39209L6.12549 19.5144V17.5536Z" fill="url(#paint53_linear_196_10365)" />
                        <path d="M6.12549 19.5144L1.39209 17.5536L6.12549 22.2878V19.5144Z" fill="url(#paint54_linear_196_10365)" />
                        <path d="M19.5144 28.9812L17.5537 33.7153L22.2871 28.9812H19.5144Z" fill="url(#paint55_linear_196_10365)" />
                        <path d="M25.6343 25.6352L28.981 28.9812L27.021 24.2485L25.6343 25.6352Z" fill="url(#paint56_linear_196_10365)" />
                        <path d="M27.021 24.2485L28.981 28.9812V22.2878L27.021 24.2485Z" fill="url(#paint57_linear_196_10365)" />
                        <path d="M24.2476 27.0205L28.981 28.9812L25.6343 25.6352L24.2476 27.0205Z" fill="url(#paint58_linear_196_10365)" />
                        <path d="M28.981 22.2878V28.9811L33.7151 17.5536L28.981 22.2878Z" fill="url(#paint59_linear_196_10365)" />
                        <path d="M17.5537 33.7153L28.9812 28.9812H22.2871L17.5537 33.7153Z" fill="url(#paint60_linear_196_10365)" />
                        <path d="M22.2871 28.9811H28.9812L24.2478 27.0204L22.2871 28.9811Z" fill="url(#paint61_linear_196_10365)" />
                        <path opacity="0.9" d="M6.12549 12.8203L1.39209 17.5537L6.12549 15.593V12.8203Z" fill="url(#paint62_linear_196_10365)" />
                        <path d="M17.5537 28.9812V33.7153L19.5144 28.9812H17.5537Z" fill="url(#paint63_linear_196_10365)" />
                        <path d="M15.5923 28.9812L17.5537 33.7153V28.9812H15.5923Z" fill="url(#paint64_linear_196_10365)" />
                        <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="#ABC8E0" />
                        <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="url(#paint65_linear_196_10365)" />
                        <path d="M28.981 12.8203V6.12616L27.021 10.8596L28.981 12.8203Z" fill="url(#paint66_linear_196_10365)" />
                        <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint67_linear_196_10365)" />
                        <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint68_linear_196_10365)" />
                        <path d="M27.021 10.8596L28.981 6.12616L25.6343 9.47216L27.021 10.8596Z" fill="url(#paint69_linear_196_10365)" />
                        <path opacity="0.9" d="M24.2478 8.08686L28.9812 6.12616H22.2871L24.2478 8.08686Z" fill="url(#paint70_linear_196_10365)" />
                        <path d="M28.981 22.2878L33.7151 17.5536L28.981 19.5144V22.2878Z" fill="url(#paint71_linear_196_10365)" />
                        <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint72_linear_196_10365)" />
                        <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint73_linear_196_10365)" />
                        <path d="M28.981 17.5537H33.7151L28.981 15.593V17.5537Z" fill="url(#paint74_linear_196_10365)" />
                        <path d="M28.981 15.593L33.7151 17.5537L28.981 12.8203V15.593Z" fill="url(#paint75_linear_196_10365)" />
                        <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint76_linear_196_10365)" />
                        <path d="M28.981 19.5144L33.7151 17.5536H28.981V19.5144Z" fill="url(#paint77_linear_196_10365)" />
                        <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint78_linear_196_10365)" />
                        <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint79_linear_196_10365)" />
                        <path d="M10.8589 8.08686L6.12549 6.12616L9.47219 9.47216L10.8589 8.08686Z" fill="url(#paint80_linear_196_10365)" />
                        <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint81_linear_196_10365)" />
                        <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint82_linear_196_10365)" />
                        <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="#C4DAF2" />
                        <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="url(#paint83_linear_196_10365)" />
                        <path d="M8.08549 10.8596L6.12549 6.12616V12.8203L8.08549 10.8596Z" fill="url(#paint84_linear_196_10365)" />
                        <path d="M17.5537 6.12616V1.39206L15.5923 6.12616H17.5537Z" fill="url(#paint85_linear_196_10365)" />
                        <path d="M19.5144 6.12616L17.5537 1.39206V6.12616H19.5144Z" fill="url(#paint86_linear_196_10365)" />
                        <path d="M15.5925 6.12616L17.5539 1.39206L12.8198 6.12616H15.5925Z" fill="url(#paint87_linear_196_10365)" />
                        <path d="M17.5537 1.39206L6.12549 6.12616H12.8196L17.5537 1.39206Z" fill="url(#paint88_linear_196_10365)" />
                        <g opacity="0.5">
                            <path d="M17.5537 17.5536L19.9211 23.2678L22.2871 22.2878L17.5537 17.5536Z" fill="url(#paint89_linear_196_10365)" />
                            <path d="M17.5537 17.5536L23.2678 19.9211L24.2478 17.5536H17.5537Z" fill="url(#paint90_linear_196_10365)" />
                            <path d="M17.5537 17.5537L23.2678 15.187L22.2871 12.8203L17.5537 17.5537Z" fill="url(#paint91_linear_196_10365)" />
                            <path d="M17.5537 17.5537L19.9211 11.8396L17.5537 10.8596V17.5537Z" fill="url(#paint92_linear_196_10365)" />
                            <path d="M17.5539 17.5537L15.1872 11.8396L12.8198 12.8203L17.5539 17.5537Z" fill="url(#paint93_linear_196_10365)" />
                            <path d="M17.5537 17.5537L11.8396 15.187L10.8589 17.5537H17.5537Z" fill="url(#paint94_linear_196_10365)" />
                            <path d="M17.5535 17.5536L11.8394 19.9211L12.8194 22.2878L17.5535 17.5536Z" fill="url(#paint95_linear_196_10365)" />
                            <path d="M17.5537 17.5536L15.187 23.2678L17.5537 24.2485V17.5536Z" fill="url(#paint96_linear_196_10365)" />
                        </g>
                        <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint97_linear_196_10365)" />
                        <g opacity="0.3">
                            <path d="M17.554 8.07285L14.7764 10.8497H20.3309L17.554 8.07285Z" fill="url(#paint98_linear_196_10365)" />
                            <path d="M24.2583 10.8497H20.3306L24.2583 14.7774V10.8497Z" fill="url(#paint99_linear_196_10365)" />
                            <path d="M10.8491 10.8497V14.7774L14.7761 10.8497H10.8491Z" fill="url(#paint100_linear_196_10365)" />
                            <path d="M17.554 27.0344L20.3309 24.2582H14.7764L17.554 27.0344Z" fill="url(#paint101_linear_196_10365)" />
                            <path d="M8.07227 17.5536L10.8492 20.3312V14.7774L8.07227 17.5536Z" fill="url(#paint102_linear_196_10365)" />
                            <path d="M10.8491 24.2582H14.7761L10.8491 20.3312V24.2582Z" fill="url(#paint103_linear_196_10365)" />
                            <path d="M24.2583 24.2582V20.3312L20.3306 24.2582H24.2583Z" fill="url(#paint104_linear_196_10365)" />
                            <path d="M24.2583 20.3312L27.0345 17.5536L24.2583 14.7774V20.3312Z" fill="url(#paint105_linear_196_10365)" />
                        </g>
                        <g opacity="0.3">
                            <path d="M10.8525 10.8526V14.7782L14.7781 10.8526H10.8525Z" fill="url(#paint106_linear_196_10365)" />
                            <path d="M17.5538 8.07706L14.7783 10.8526H20.3293L17.5538 8.07706Z" fill="url(#paint107_linear_196_10365)" />
                            <path d="M8.07715 17.5537L10.8526 20.3292V14.7782L8.07715 17.5537Z" fill="url(#paint108_linear_196_10365)" />
                            <path d="M24.2554 24.2548V20.3292L20.3291 24.2548H24.2554Z" fill="url(#paint109_linear_196_10365)" />
                            <path d="M10.8525 24.2548H14.7781L10.8525 20.3292V24.2548Z" fill="url(#paint110_linear_196_10365)" />
                            <path d="M17.5538 27.0303L20.3293 24.2548H14.7783L17.5538 27.0303Z" fill="url(#paint111_linear_196_10365)" />
                            <path d="M27.0302 17.5537L24.2554 14.7782V20.3292L27.0302 17.5537Z" fill="url(#paint112_linear_196_10365)" />
                            <path d="M24.2554 14.7782V10.8526H20.3291L24.2554 14.7782Z" fill="url(#paint113_linear_196_10365)" />
                        </g>
                        <g opacity="0.3">
                            <path d="M3.76318 11.8417L6.12918 17.5537L9.47518 9.47498L3.76318 11.8417Z" fill="url(#paint114_linear_196_10365)" />
                            <path d="M11.8418 3.76297L9.4751 9.47497L17.5538 6.12897L11.8418 3.76297Z" fill="url(#paint115_linear_196_10365)" />
                            <path d="M3.76318 23.2657L9.47518 25.6324L6.12918 17.5537L3.76318 23.2657Z" fill="url(#paint116_linear_196_10365)" />
                            <path d="M31.3448 23.2657L28.9781 17.5537L25.6328 25.6324L31.3448 23.2657Z" fill="url(#paint117_linear_196_10365)" />
                            <path d="M11.8418 31.3444L17.5538 28.9784L9.4751 25.6324L11.8418 31.3444Z" fill="url(#paint118_linear_196_10365)" />
                            <path d="M23.2664 31.3444L25.6324 25.6324L17.5537 28.9784L23.2664 31.3444Z" fill="url(#paint119_linear_196_10365)" />
                            <path d="M31.3448 11.8417L25.6328 9.47498L28.9781 17.5537L31.3448 11.8417Z" fill="url(#paint120_linear_196_10365)" />
                            <path d="M25.6324 9.47497L23.2664 3.76297L17.5537 6.12897L25.6324 9.47497Z" fill="url(#paint121_linear_196_10365)" />
                        </g>
                        <path opacity="0.3" d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint122_linear_196_10365)" />
                        <path opacity="0.1" d="M33.674 17.4543L28.9812 28.7838L17.553 33.5179L6.12479 28.7838L1.43269 17.4557L1.39209 17.5537L6.12549 28.9812L17.5537 33.7153L28.9812 28.9812L33.7153 17.5537L33.674 17.4543Z" fill="white" />
                        <path opacity="0.5" d="M1.43339 17.6531L6.12619 6.32356L17.5544 1.58946L28.9826 6.32356L33.6747 17.6517L33.7153 17.5537L28.9819 6.12616L17.5537 1.39206L6.12619 6.12616L1.39209 17.5537L1.43339 17.6531Z" fill="#A6C8ED" />
                        <g style={{ mixBlendMode: "multiply" }}>
                            <path d="M28.9816 6.12659L17.5537 1.39206L6.12514 6.12659L1.39209 17.5537L6.12514 28.9816L17.5537 33.7154L28.9816 28.9816L33.7154 17.5537L28.9816 6.12659Z" fill="url(#paint123_linear_196_10365)" />
                        </g>
                        <defs>
                            <linearGradient id="paint0_linear_196_10365" x1="6.1252" y1="13.2262" x2="9.47241" y2="13.2262" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CCE0FA" />
                                <stop offset="0.6681" stopColor="#C9DCF9" />
                                <stop offset="0.724" stopColor="#B9C9E9" />
                                <stop offset="0.8121" stopColor="#A6B0D5" />
                                <stop offset="0.8931" stopColor="#9AA1C9" />
                                <stop offset="0.9596" stopColor="#969CC5" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_196_10365" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E0EBFF" />
                                <stop offset="0.4828" stopColor="#EBF2FF" />
                                <stop offset="0.5393" stopColor="#F0F5FF" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_196_10365" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E0EBFF" />
                                <stop offset="0.4828" stopColor="#EBF2FF" />
                                <stop offset="0.5393" stopColor="#F0F5FF" />
                            </linearGradient>
                            <linearGradient id="paint3_linear_196_10365" x1="6.27745" y1="19.873" x2="10.9939" y2="17.871" gradientUnits="userSpaceOnUse">
                                <stop offset="0.194" stopColor="#AFCED9" />
                                <stop offset="0.2378" stopColor="#BAD6E1" />
                                <stop offset="0.3456" stopColor="#D1E5F2" />
                                <stop offset="0.4483" stopColor="#DEEEFC" />
                                <stop offset="0.5393" stopColor="#E3F1FF" />
                                <stop offset="0.8221" stopColor="#FEFEFD" />
                            </linearGradient>
                            <linearGradient id="paint4_linear_196_10365" x1="6.1252" y1="15.88" x2="10.8592" y2="15.88" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E0EBFF" />
                                <stop offset="0.4828" stopColor="#EBF2FF" />
                                <stop offset="0.5393" stopColor="#F0F5FF" />
                            </linearGradient>
                            <linearGradient id="paint5_linear_196_10365" x1="6.1252" y1="19.2273" x2="10.8592" y2="19.2273" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E0EBFF" />
                                <stop offset="0.4828" stopColor="#EBF2FF" />
                                <stop offset="0.5393" stopColor="#F0F5FF" />
                            </linearGradient>
                            <linearGradient id="paint6_linear_196_10365" x1="14.7016" y1="10.0627" x2="18.3135" y2="7.032" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#B1B9E2" />
                                <stop offset="0.0638" stopColor="#B6BFE5" />
                                <stop offset="0.1464" stopColor="#C4CFEE" />
                                <stop offset="0.2391" stopColor="#DCE9FD" />
                                <stop offset="0.2526" stopColor="#E0EEFF" />
                                <stop offset="0.4048" stopColor="#E4F0FF" />
                                <stop offset="0.4823" stopColor="#E9F3FF" />
                                <stop offset="0.5831" stopColor="#E6F0FD" />
                                <stop offset="0.6609" stopColor="#DCE7F8" />
                                <stop offset="0.7311" stopColor="#CCD9EE" />
                                <stop offset="0.7964" stopColor="#B5C4E0" />
                                <stop offset="0.8141" stopColor="#ADBDDC" />
                            </linearGradient>
                            <linearGradient id="paint7_linear_196_10365" x1="11.5729" y1="7.96082" x2="12.4416" y2="12.8872" gradientUnits="userSpaceOnUse">
                                <stop offset="0.0745" stopColor="#C6E0FF" />
                                <stop offset="0.1248" stopColor="#B6CAEC" />
                                <stop offset="0.2357" stopColor="#969CC5" />
                                <stop offset="0.3834" stopColor="#B4C3E3" />
                                <stop offset="0.5303" stopColor="#CFE5FD" />
                                <stop offset="0.7108" stopColor="#DAEBFF" />
                                <stop offset="1" stopColor="#B9D8F4" />
                            </linearGradient>
                            <linearGradient id="paint8_linear_196_10365" x1="17.9886" y1="11.0262" x2="19.824" y2="6.24497" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#DDE7FE" />
                                <stop offset="0.4345" stopColor="#D4DEF7" />
                                <stop offset="0.463" stopColor="#B6BFDD" />
                                <stop offset="0.4908" stopColor="#A0A8CB" />
                                <stop offset="0.5171" stopColor="#939BC0" />
                                <stop offset="0.5404" stopColor="#8E96BC" />
                            </linearGradient>
                            <linearGradient id="paint9_linear_196_10365" x1="19.5141" y1="7.79929" x2="24.2472" y2="7.79929" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E7F0FF" />
                                <stop offset="0.4617" stopColor="#D6E6FB" />
                                <stop offset="0.5034" stopColor="#C9DEF7" />
                                <stop offset="0.5404" stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint10_linear_196_10365" x1="11.161" y1="8.79771" x2="15.8767" y2="6.79603" gradientUnits="userSpaceOnUse">
                                <stop offset="0.194" stopColor="#AFCED9" />
                                <stop offset="0.2378" stopColor="#BAD6E1" />
                                <stop offset="0.3456" stopColor="#D1E5F2" />
                                <stop offset="0.4483" stopColor="#DEEEFC" />
                                <stop offset="0.5393" stopColor="#E3F1FF" />
                                <stop offset="0.8221" stopColor="#FEFEFD" />
                            </linearGradient>
                            <linearGradient id="paint11_linear_196_10365" x1="10.8592" y1="27.3082" x2="15.5923" y2="27.3082" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E7F0FF" />
                                <stop offset="0.4617" stopColor="#D6E6FB" />
                                <stop offset="0.5034" stopColor="#C9DEF7" />
                                <stop offset="0.5404" stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint12_linear_196_10365" x1="20.9008" y1="24.6542" x2="25.634" y2="24.6542" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint13_linear_196_10365" x1="19.2298" y1="28.3116" x2="23.9455" y2="26.3099" gradientUnits="userSpaceOnUse">
                                <stop offset="0.194" stopColor="#AFCED9" />
                                <stop offset="0.2378" stopColor="#BAD6E1" />
                                <stop offset="0.3456" stopColor="#D1E5F2" />
                                <stop offset="0.4483" stopColor="#DEEEFC" />
                                <stop offset="0.5393" stopColor="#E3F1FF" />
                                <stop offset="0.8221" stopColor="#FEFEFD" />
                            </linearGradient>
                            <linearGradient id="paint14_linear_196_10365" x1="6.1252" y1="21.8813" x2="9.47241" y2="21.8813" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CCE0FA" />
                                <stop offset="0.6681" stopColor="#C9DCF9" />
                                <stop offset="0.724" stopColor="#B9C9E9" />
                                <stop offset="0.8121" stopColor="#A6B0D5" />
                                <stop offset="0.8931" stopColor="#9AA1C9" />
                                <stop offset="0.9596" stopColor="#969CC5" />
                            </linearGradient>
                            <linearGradient id="paint15_linear_196_10365" x1="16.7631" y1="27.8093" x2="20.615" y2="25.2111" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E7F0FF" />
                                <stop offset="0.4617" stopColor="#D6E6FB" />
                                <stop offset="0.5034" stopColor="#C9DEF7" />
                                <stop offset="0.5404" stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint16_linear_196_10365" x1="22.2869" y1="23.2679" x2="27.0209" y2="23.2679" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E7F0FF" />
                                <stop offset="0.4617" stopColor="#D6E6FB" />
                                <stop offset="0.5034" stopColor="#C9DEF7" />
                                <stop offset="0.5404" stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint17_linear_196_10365" x1="8.08557" y1="23.2679" x2="12.8197" y2="23.2679" gradientUnits="userSpaceOnUse">
                                <stop offset="0.3389" stopColor="#F3FCFF" />
                                <stop offset="0.352" stopColor="#F0FAFE" />
                                <stop offset="0.4365" stopColor="#E3EEFB" />
                                <stop offset="0.5115" stopColor="#DEEAFA" />
                                <stop offset="0.7158" stopColor="#DAE6F8" />
                                <stop offset="0.8158" stopColor="#CAD3F2" />
                            </linearGradient>
                            <linearGradient id="paint18_linear_196_10365" x1="14.2063" y1="26.6148" x2="17.5534" y2="26.6148" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CCE0FA" />
                                <stop offset="0.6681" stopColor="#C9DCF9" />
                                <stop offset="0.724" stopColor="#B9C9E9" />
                                <stop offset="0.8121" stopColor="#A6B0D5" />
                                <stop offset="0.8931" stopColor="#9AA1C9" />
                                <stop offset="0.9596" stopColor="#969CC5" />
                            </linearGradient>
                            <linearGradient id="paint19_linear_196_10365" x1="11.2499" y1="27.1708" x2="13.0853" y2="22.3896" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#DDE7FE" />
                                <stop offset="0.4345" stopColor="#D4DEF7" />
                                <stop offset="0.463" stopColor="#B6BFDD" />
                                <stop offset="0.4908" stopColor="#A0A8CB" />
                                <stop offset="0.5171" stopColor="#939BC0" />
                                <stop offset="0.5404" stopColor="#8E96BC" />
                            </linearGradient>
                            <linearGradient id="paint20_linear_196_10365" x1="20.9008" y1="10.4536" x2="25.634" y2="10.4536" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E0EBFF" />
                                <stop offset="0.4828" stopColor="#EBF2FF" />
                                <stop offset="0.5393" stopColor="#F0F5FF" />
                            </linearGradient>
                            <linearGradient id="paint21_linear_196_10365" x1="11.6988" y1="19.9893" x2="17.3881" y2="17.2144" gradientUnits="userSpaceOnUse">
                                <stop offset="0.3389" stopColor="#F3FCFF" />
                                <stop offset="0.352" stopColor="#F0FAFE" />
                                <stop offset="0.4365" stopColor="#E3EEFB" />
                                <stop offset="0.5115" stopColor="#DEEAFA" />
                                <stop offset="0.7158" stopColor="#DAE6F8" />
                                <stop offset="0.8158" stopColor="#CAD3F2" />
                            </linearGradient>
                            <linearGradient id="paint22_linear_196_10365" x1="21.2137" y1="9.42334" x2="19.7324" y2="12.1909" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CCE0FA" />
                                <stop offset="0.6681" stopColor="#C9DCF9" />
                                <stop offset="0.724" stopColor="#B9C9E9" />
                                <stop offset="0.8121" stopColor="#A6B0D5" />
                                <stop offset="0.8931" stopColor="#9AA1C9" />
                                <stop offset="0.9596" stopColor="#969CC5" />
                            </linearGradient>
                            <linearGradient id="paint23_linear_196_10365" x1="22.2869" y1="15.1871" x2="25.6343" y2="15.1871" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CCE0FA" />
                                <stop offset="0.6681" stopColor="#C9DCF9" />
                                <stop offset="0.724" stopColor="#B9C9E9" />
                                <stop offset="0.8121" stopColor="#A6B0D5" />
                                <stop offset="0.8931" stopColor="#9AA1C9" />
                                <stop offset="0.9596" stopColor="#969CC5" />
                            </linearGradient>
                            <linearGradient id="paint24_linear_196_10365" x1="17.5536" y1="14.2065" x2="22.2869" y2="14.2065" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#B1B9E2" />
                                <stop offset="0.0638" stopColor="#B6BFE5" />
                                <stop offset="0.1464" stopColor="#C4CFEE" />
                                <stop offset="0.2391" stopColor="#DCE9FD" />
                                <stop offset="0.2526" stopColor="#E0EEFF" />
                                <stop offset="0.4048" stopColor="#E4F0FF" />
                                <stop offset="0.4823" stopColor="#E9F3FF" />
                                <stop offset="0.5831" stopColor="#E6F0FD" />
                                <stop offset="0.6609" stopColor="#DCE7F8" />
                                <stop offset="0.7311" stopColor="#CCD9EE" />
                                <stop offset="0.7964" stopColor="#B5C4E0" />
                                <stop offset="0.8141" stopColor="#ADBDDC" />
                            </linearGradient>
                            <linearGradient id="paint25_linear_196_10365" x1="17.5536" y1="15.1871" x2="24.2475" y2="15.1871" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E0EBFF" />
                                <stop offset="0.4828" stopColor="#EBF2FF" />
                                <stop offset="0.5393" stopColor="#F0F5FF" />
                            </linearGradient>
                            <linearGradient id="paint26_linear_196_10365" x1="14.5549" y1="13.4865" x2="15.8531" y2="10.1044" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#DDE7FE" />
                                <stop offset="0.4345" stopColor="#D4DEF7" />
                                <stop offset="0.463" stopColor="#B6BFDD" />
                                <stop offset="0.4908" stopColor="#A0A8CB" />
                                <stop offset="0.5171" stopColor="#939BC0" />
                                <stop offset="0.5404" stopColor="#8E96BC" />
                            </linearGradient>
                            <linearGradient id="paint27_linear_196_10365" x1="15.6248" y1="9.65069" x2="15.0458" y2="12.5457" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E7F0FF" />
                                <stop offset="0.4617" stopColor="#D6E6FB" />
                                <stop offset="0.5034" stopColor="#C9DEF7" />
                                <stop offset="0.5404" stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint28_linear_196_10365" x1="23.9881" y1="13.5744" x2="25.5719" y2="9.44851" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#DDE7FE" />
                                <stop offset="0.4345" stopColor="#D4DEF7" />
                                <stop offset="0.463" stopColor="#B6BFDD" />
                                <stop offset="0.4908" stopColor="#A0A8CB" />
                                <stop offset="0.5171" stopColor="#939BC0" />
                                <stop offset="0.5404" stopColor="#8E96BC" />
                            </linearGradient>
                            <linearGradient id="paint29_linear_196_10365" x1="12.82" y1="23.9613" x2="17.5539" y2="23.9613" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CDE7F8" />
                                <stop offset="0.4833" stopColor="#E3F2FB" />
                                <stop offset="1" stopColor="white" />
                            </linearGradient>
                            <linearGradient id="paint30_linear_196_10365" x1="9.47238" y1="15.1871" x2="12.8197" y2="15.1871" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFCED9" />
                                <stop offset="0.1268" stopColor="#BAD6E1" />
                                <stop offset="0.439" stopColor="#D1E5F2" />
                                <stop offset="0.7363" stopColor="#DEEEFC" />
                                <stop offset="1" stopColor="#E3F1FF" />
                            </linearGradient>
                            <linearGradient id="paint31_linear_196_10365" x1="11.1499" y1="13.5571" x2="11.4714" y2="17.2228" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1898" stopColor="#E5EEFD" />
                                <stop offset="0.3903" stopColor="#D2E2FC" />
                                <stop offset="0.5937" stopColor="#C6DAFB" />
                                <stop offset="0.8022" stopColor="#C2D8FB" />
                                <stop offset="0.9293" stopColor="#B6D0EE" />
                                <stop offset="1" stopColor="#ADCBE5" />
                            </linearGradient>
                            <linearGradient id="paint32_linear_196_10365" x1="10.4464" y1="17.6264" x2="11.3151" y2="22.553" gradientUnits="userSpaceOnUse">
                                <stop offset="0.0745" stopColor="#C6E0FF" />
                                <stop offset="0.1248" stopColor="#B6CAEC" />
                                <stop offset="0.2357" stopColor="#969CC5" />
                                <stop offset="0.3834" stopColor="#B4C3E3" />
                                <stop offset="0.5303" stopColor="#CFE5FD" />
                                <stop offset="0.7108" stopColor="#DAEBFF" />
                                <stop offset="1" stopColor="#B9D8F4" />
                            </linearGradient>
                            <linearGradient id="paint33_linear_196_10365" x1="12.0043" y1="19.8213" x2="9.52433" y2="21.3161" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1898" stopColor="#E5EEFD" />
                                <stop offset="0.3903" stopColor="#D2E2FC" />
                                <stop offset="0.5937" stopColor="#C6DAFB" />
                                <stop offset="0.8022" stopColor="#C2D8FB" />
                                <stop offset="0.9293" stopColor="#B6D0EE" />
                                <stop offset="1" stopColor="#ADCBE5" />
                            </linearGradient>
                            <linearGradient id="paint34_linear_196_10365" x1="18.0586" y1="25.4377" x2="22.7744" y2="23.436" gradientUnits="userSpaceOnUse">
                                <stop offset="0.194" stopColor="#AFCED9" />
                                <stop offset="0.2378" stopColor="#BAD6E1" />
                                <stop offset="0.3456" stopColor="#D1E5F2" />
                                <stop offset="0.4483" stopColor="#DEEEFC" />
                                <stop offset="0.5393" stopColor="#E3F1FF" />
                                <stop offset="0.8221" stopColor="#FEFEFD" />
                            </linearGradient>
                            <linearGradient id="paint35_linear_196_10365" x1="24.2472" y1="19.2273" x2="28.9811" y2="19.2273" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFCED9" />
                                <stop offset="0.1268" stopColor="#BAD6E1" />
                                <stop offset="0.439" stopColor="#D1E5F2" />
                                <stop offset="0.7363" stopColor="#DEEEFC" />
                                <stop offset="1" stopColor="#E3F1FF" />
                            </linearGradient>
                            <linearGradient id="paint36_linear_196_10365" x1="25.3801" y1="14.3471" x2="27.5861" y2="18.3269" gradientUnits="userSpaceOnUse">
                                <stop offset="0.194" stopColor="#AFCED9" />
                                <stop offset="0.3016" stopColor="#BBD6E2" />
                                <stop offset="0.4956" stopColor="#DBECF9" />
                                <stop offset="0.5393" stopColor="#E3F1FF" />
                                <stop offset="1" stopColor="#C3DAF4" />
                            </linearGradient>
                            <linearGradient id="paint37_linear_196_10365" x1="22.4939" y1="18.5256" x2="24.2392" y2="21.6742" gradientUnits="userSpaceOnUse">
                                <stop offset="0.194" stopColor="#AFCED9" />
                                <stop offset="0.3016" stopColor="#BBD6E2" />
                                <stop offset="0.4956" stopColor="#DBECF9" />
                                <stop offset="0.5393" stopColor="#E3F1FF" />
                                <stop offset="1" stopColor="#C3DAF4" />
                            </linearGradient>
                            <linearGradient id="paint38_linear_196_10365" x1="25.634" y1="13.2262" x2="28.9811" y2="13.2262" gradientUnits="userSpaceOnUse">
                                <stop offset="0.3389" stopColor="#F3FCFF" />
                                <stop offset="0.352" stopColor="#F0FAFE" />
                                <stop offset="0.4365" stopColor="#E3EEFB" />
                                <stop offset="0.5115" stopColor="#DEEAFA" />
                                <stop offset="0.7158" stopColor="#DAE6F8" />
                                <stop offset="0.8158" stopColor="#CAD3F2" />
                            </linearGradient>
                            <linearGradient id="paint39_linear_196_10365" x1="17.5536" y1="20.9009" x2="22.2869" y2="20.9009" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CDE7F8" />
                                <stop offset="0.4833" stopColor="#E3F2FB" />
                                <stop offset="1" stopColor="white" />
                            </linearGradient>
                            <linearGradient id="paint40_linear_196_10365" x1="26.3129" y1="22.5807" x2="29.6766" y2="21.1529" gradientUnits="userSpaceOnUse">
                                <stop offset="0.194" stopColor="#AFCED9" />
                                <stop offset="0.2378" stopColor="#BAD6E1" />
                                <stop offset="0.3456" stopColor="#D1E5F2" />
                                <stop offset="0.4483" stopColor="#DEEEFC" />
                                <stop offset="0.5393" stopColor="#E3F1FF" />
                                <stop offset="0.8221" stopColor="#FEFEFD" />
                            </linearGradient>
                            <linearGradient id="paint41_linear_196_10365" x1="12.82" y1="20.9009" x2="17.5539" y2="20.9009" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CCE0FA" />
                                <stop offset="0.6681" stopColor="#C9DCF9" />
                                <stop offset="0.724" stopColor="#B9C9E9" />
                                <stop offset="0.8121" stopColor="#A6B0D5" />
                                <stop offset="0.8931" stopColor="#9AA1C9" />
                                <stop offset="0.9596" stopColor="#969CC5" />
                            </linearGradient>
                            <linearGradient id="paint42_linear_196_10365" x1="10.8592" y1="15.1871" x2="17.5536" y2="15.1871" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CDE7F8" />
                                <stop offset="0.4833" stopColor="#E3F2FB" />
                                <stop offset="1" stopColor="white" />
                            </linearGradient>
                            <linearGradient id="paint43_linear_196_10365" x1="17.5536" y1="19.9207" x2="24.2475" y2="19.9207" gradientUnits="userSpaceOnUse">
                                <stop offset="0.2198" stopColor="#7D85A4" />
                                <stop offset="0.4596" stopColor="#C4DBF6" />
                                <stop offset="0.4966" stopColor="#C9DEF7" />
                                <stop offset="0.5383" stopColor="#D6E6FB" />
                                <stop offset="0.5726" stopColor="#E7F0FF" />
                            </linearGradient>
                            <linearGradient id="paint44_linear_196_10365" x1="15.0906" y1="11.2936" x2="16.2354" y2="17.7861" gradientUnits="userSpaceOnUse">
                                <stop offset="0.0745" stopColor="#C6E0FF" />
                                <stop offset="0.1248" stopColor="#B6CAEC" />
                                <stop offset="0.2357" stopColor="#969CC5" />
                                <stop offset="0.3834" stopColor="#B4C3E3" />
                                <stop offset="0.5303" stopColor="#CFE5FD" />
                                <stop offset="0.7108" stopColor="#DAEBFF" />
                                <stop offset="1" stopColor="#B9D8F4" />
                            </linearGradient>
                            <linearGradient id="paint45_linear_196_10365" x1="4.57363" y1="26.681" x2="8.11527" y2="24.2922" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E7F0FF" />
                                <stop offset="0.4617" stopColor="#D6E6FB" />
                                <stop offset="0.5034" stopColor="#C9DEF7" />
                                <stop offset="0.5404" stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint46_linear_196_10365" x1="6.1252" y1="27.3082" x2="10.8592" y2="27.3082" gradientUnits="userSpaceOnUse">
                                <stop offset="0.2198" stopColor="#7D85A4" />
                                <stop offset="0.4596" stopColor="#C4DBF6" />
                                <stop offset="0.4966" stopColor="#C9DEF7" />
                                <stop offset="0.5383" stopColor="#D6E6FB" />
                                <stop offset="0.5726" stopColor="#E7F0FF" />
                            </linearGradient>
                            <linearGradient id="paint47_linear_196_10365" x1="6.1252" y1="26.6148" x2="9.47241" y2="26.6148" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E0EBFF" />
                                <stop offset="0.4828" stopColor="#EBF2FF" />
                                <stop offset="0.5393" stopColor="#F0F5FF" />
                            </linearGradient>
                            <linearGradient id="paint48_linear_196_10365" x1="6.63623" y1="30.1853" x2="12.3087" y2="27.7774" gradientUnits="userSpaceOnUse">
                                <stop offset="0.194" stopColor="#AFCED9" />
                                <stop offset="0.2378" stopColor="#BAD6E1" />
                                <stop offset="0.3456" stopColor="#D1E5F2" />
                                <stop offset="0.4483" stopColor="#DEEEFC" />
                                <stop offset="0.5393" stopColor="#E3F1FF" />
                                <stop offset="0.8221" stopColor="#FEFEFD" />
                            </linearGradient>
                            <linearGradient id="paint49_linear_196_10365" x1="6.1252" y1="31.3484" x2="17.5536" y2="31.3484" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E0EBFF" />
                                <stop offset="0.4828" stopColor="#EBF2FF" />
                                <stop offset="0.5393" stopColor="#F0F5FF" />
                            </linearGradient>
                            <linearGradient id="paint50_linear_196_10365" x1="1.39207" y1="23.2675" x2="6.1252" y2="23.2675" gradientUnits="userSpaceOnUse">
                                <stop offset="0.1929" stopColor="#C9E0EB" />
                                <stop offset="0.5344" stopColor="#CADEEF" />
                                <stop offset="0.8146" stopColor="#D7E8FA" />
                                <stop offset="1" stopColor="#D7E8FA" />
                            </linearGradient>
                            <linearGradient id="paint51_linear_196_10365" x1="14.7108" y1="28.648" x2="15.6631" y2="34.0488" gradientUnits="userSpaceOnUse">
                                <stop offset="0.0745" stopColor="#C6E0FF" />
                                <stop offset="0.1248" stopColor="#B6CAEC" />
                                <stop offset="0.2357" stopColor="#969CC5" />
                                <stop offset="0.3834" stopColor="#B4C3E3" />
                                <stop offset="0.5303" stopColor="#CFE5FD" />
                                <stop offset="0.7108" stopColor="#DAEBFF" />
                                <stop offset="1" stopColor="#B9D8F4" />
                            </linearGradient>
                            <linearGradient id="paint52_linear_196_10365" x1="1.84685" y1="18.4861" x2="6.44289" y2="16.2445" gradientUnits="userSpaceOnUse">
                                <stop offset="0.3389" stopColor="#F3FCFF" />
                                <stop offset="0.352" stopColor="#F0FAFE" />
                                <stop offset="0.4365" stopColor="#E3EEFB" />
                                <stop offset="0.5115" stopColor="#DEEAFA" />
                                <stop offset="0.7158" stopColor="#DAE6F8" />
                                <stop offset="0.8158" stopColor="#CAD3F2" />
                            </linearGradient>
                            <linearGradient id="paint53_linear_196_10365" x1="2.58657" y1="19.3246" x2="5.83967" y2="17.1303" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E7F0FF" />
                                <stop offset="0.4617" stopColor="#D6E6FB" />
                                <stop offset="0.5034" stopColor="#C9DEF7" />
                                <stop offset="0.5404" stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint54_linear_196_10365" x1="2.78671" y1="16.7806" x2="5.90652" y2="22.4089" gradientUnits="userSpaceOnUse">
                                <stop offset="0.194" stopColor="#AFCED9" />
                                <stop offset="0.3016" stopColor="#BBD6E2" />
                                <stop offset="0.4956" stopColor="#DBECF9" />
                                <stop offset="0.5393" stopColor="#E3F1FF" />
                                <stop offset="1" stopColor="#C3DAF4" />
                            </linearGradient>
                            <linearGradient id="paint55_linear_196_10365" x1="16.8094" y1="32.1895" x2="22.4983" y2="29.4148" gradientUnits="userSpaceOnUse">
                                <stop offset="0.3389" stopColor="#F3FCFF" />
                                <stop offset="0.352" stopColor="#F0FAFE" />
                                <stop offset="0.4365" stopColor="#E3EEFB" />
                                <stop offset="0.5115" stopColor="#DEEAFA" />
                                <stop offset="0.7158" stopColor="#DAE6F8" />
                                <stop offset="0.8158" stopColor="#CAD3F2" />
                            </linearGradient>
                            <linearGradient id="paint56_linear_196_10365" x1="27.0944" y1="27.3754" x2="28.591" y2="26.1196" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#B1B9E2" />
                                <stop offset="0.0638" stopColor="#B6BFE5" />
                                <stop offset="0.1464" stopColor="#C4CFEE" />
                                <stop offset="0.2391" stopColor="#DCE9FD" />
                                <stop offset="0.2526" stopColor="#E0EEFF" />
                                <stop offset="0.4048" stopColor="#E4F0FF" />
                                <stop offset="0.4823" stopColor="#E9F3FF" />
                                <stop offset="0.5831" stopColor="#E6F0FD" />
                                <stop offset="0.6609" stopColor="#DCE7F8" />
                                <stop offset="0.7311" stopColor="#CCD9EE" />
                                <stop offset="0.7964" stopColor="#B5C4E0" />
                                <stop offset="0.8141" stopColor="#ADBDDC" />
                            </linearGradient>
                            <linearGradient id="paint57_linear_196_10365" x1="27.8628" y1="22.4848" x2="29.0075" y2="28.9767" gradientUnits="userSpaceOnUse">
                                <stop offset="0.0745" stopColor="#C6E0FF" />
                                <stop offset="0.1248" stopColor="#B6CAEC" />
                                <stop offset="0.2357" stopColor="#969CC5" />
                                <stop offset="0.3834" stopColor="#B4C3E3" />
                                <stop offset="0.5303" stopColor="#CFE5FD" />
                                <stop offset="0.7108" stopColor="#DAEBFF" />
                                <stop offset="1" stopColor="#B9D8F4" />
                            </linearGradient>
                            <linearGradient id="paint58_linear_196_10365" x1="24.2472" y1="27.3082" x2="28.9811" y2="27.3082" gradientUnits="userSpaceOnUse">
                                <stop offset="0.0080863" stopColor="#D9E8F9" />
                                <stop offset="0.113" stopColor="#E3EEFB" />
                                <stop offset="0.3197" stopColor="#F0F6FE" />
                                <stop offset="0.5034" stopColor="#F5F9FF" />
                                <stop offset="0.7479" stopColor="#F2F8FF" />
                                <stop offset="0.9366" stopColor="#E8F3FF" />
                                <stop offset="1" stopColor="#E3F1FF" />
                            </linearGradient>
                            <linearGradient id="paint59_linear_196_10365" x1="28.9811" y1="23.2675" x2="33.7152" y2="23.2675" gradientUnits="userSpaceOnUse">
                                <stop offset="0.1929" stopColor="#C9E0EB" />
                                <stop offset="0.5344" stopColor="#CADEEF" />
                                <stop offset="0.8146" stopColor="#D7E8FA" />
                                <stop offset="1" stopColor="#D7E8FA" />
                            </linearGradient>
                            <linearGradient id="paint60_linear_196_10365" x1="18.5613" y1="34.9162" x2="27.5983" y2="27.3332" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#B1B9E2" />
                                <stop offset="0.0638" stopColor="#B6BFE5" />
                                <stop offset="0.1464" stopColor="#C4CFEE" />
                                <stop offset="0.2391" stopColor="#DCE9FD" />
                                <stop offset="0.2526" stopColor="#E0EEFF" />
                                <stop offset="0.4048" stopColor="#E4F0FF" />
                                <stop offset="0.4823" stopColor="#E9F3FF" />
                                <stop offset="0.5831" stopColor="#E6F0FD" />
                                <stop offset="0.6609" stopColor="#DCE7F8" />
                                <stop offset="0.7311" stopColor="#CCD9EE" />
                                <stop offset="0.7964" stopColor="#B5C4E0" />
                                <stop offset="0.8141" stopColor="#ADBDDC" />
                            </linearGradient>
                            <linearGradient id="paint61_linear_196_10365" x1="22.2869" y1="28.0011" x2="28.9813" y2="28.0011" gradientUnits="userSpaceOnUse">
                                <stop offset="0.2198" stopColor="#7D85A4" />
                                <stop offset="0.4596" stopColor="#C4DBF6" />
                                <stop offset="0.4966" stopColor="#C9DEF7" />
                                <stop offset="0.5383" stopColor="#D6E6FB" />
                                <stop offset="0.5726" stopColor="#E7F0FF" />
                            </linearGradient>
                            <linearGradient id="paint62_linear_196_10365" x1="1.39207" y1="15.1871" x2="6.1252" y2="15.1871" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CDE7F8" />
                                <stop offset="0.4833" stopColor="#E3F2FB" />
                                <stop offset="1" stopColor="white" />
                            </linearGradient>
                            <linearGradient id="paint63_linear_196_10365" x1="17.5536" y1="31.3484" x2="19.5143" y2="31.3484" gradientUnits="userSpaceOnUse">
                                <stop offset="0.0080863" stopColor="#D9E8F9" />
                                <stop offset="0.113" stopColor="#E3EEFB" />
                                <stop offset="0.3197" stopColor="#F0F6FE" />
                                <stop offset="0.5034" stopColor="#F5F9FF" />
                                <stop offset="0.7479" stopColor="#F2F8FF" />
                                <stop offset="0.9366" stopColor="#E8F3FF" />
                                <stop offset="1" stopColor="#E3F1FF" />
                            </linearGradient>
                            <linearGradient id="paint64_linear_196_10365" x1="15.9829" y1="31.8435" x2="18.314" y2="29.8875" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#B1B9E2" />
                                <stop offset="0.0638" stopColor="#B6BFE5" />
                                <stop offset="0.1464" stopColor="#C4CFEE" />
                                <stop offset="0.2391" stopColor="#DCE9FD" />
                                <stop offset="0.2526" stopColor="#E0EEFF" />
                                <stop offset="0.4048" stopColor="#E4F0FF" />
                                <stop offset="0.4823" stopColor="#E9F3FF" />
                                <stop offset="0.5831" stopColor="#E6F0FD" />
                                <stop offset="0.6609" stopColor="#DCE7F8" />
                                <stop offset="0.7311" stopColor="#CCD9EE" />
                                <stop offset="0.7964" stopColor="#B5C4E0" />
                                <stop offset="0.8141" stopColor="#ADBDDC" />
                            </linearGradient>
                            <linearGradient id="paint65_linear_196_10365" x1="24.6535" y1="8.91961" x2="29.2501" y2="6.67769" gradientUnits="userSpaceOnUse">
                                <stop offset="0.3389" stopColor="#F3FCFF" />
                                <stop offset="0.352" stopColor="#F0FAFE" />
                                <stop offset="0.4365" stopColor="#E3EEFB" />
                                <stop offset="0.5115" stopColor="#DEEAFA" />
                                <stop offset="0.7158" stopColor="#DAE6F8" />
                                <stop offset="0.8158" stopColor="#CAD3F2" />
                            </linearGradient>
                            <linearGradient id="paint66_linear_196_10365" x1="26.9912" y1="10.8155" x2="30.5328" y2="8.42666" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E7F0FF" />
                                <stop offset="0.4617" stopColor="#D6E6FB" />
                                <stop offset="0.5034" stopColor="#C9DEF7" />
                                <stop offset="0.5404" stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint67_linear_196_10365" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                                <stop offset="0.0080863" stopColor="#D9E8F9" />
                                <stop offset="0.113" stopColor="#E3EEFB" />
                                <stop offset="0.3197" stopColor="#F0F6FE" />
                                <stop offset="0.5034" stopColor="#F5F9FF" />
                                <stop offset="0.7479" stopColor="#F2F8FF" />
                                <stop offset="0.9366" stopColor="#E8F3FF" />
                                <stop offset="1" stopColor="#E3F1FF" />
                            </linearGradient>
                            <linearGradient id="paint68_linear_196_10365" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CCE0FA" />
                                <stop offset="0.6681" stopColor="#C9DCF9" />
                                <stop offset="0.724" stopColor="#B9C9E9" />
                                <stop offset="0.8121" stopColor="#A6B0D5" />
                                <stop offset="0.8931" stopColor="#9AA1C9" />
                                <stop offset="0.9596" stopColor="#969CC5" />
                            </linearGradient>
                            <linearGradient id="paint69_linear_196_10365" x1="25.634" y1="8.49268" x2="28.9811" y2="8.49268" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFCED9" />
                                <stop offset="0.1268" stopColor="#BAD6E1" />
                                <stop offset="0.439" stopColor="#D1E5F2" />
                                <stop offset="0.7363" stopColor="#DEEEFC" />
                                <stop offset="1" stopColor="#E3F1FF" />
                            </linearGradient>
                            <linearGradient id="paint70_linear_196_10365" x1="22.2869" y1="7.1064" x2="28.9813" y2="7.1064" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CDE7F8" />
                                <stop offset="0.4833" stopColor="#E3F2FB" />
                                <stop offset="1" stopColor="white" />
                            </linearGradient>
                            <linearGradient id="paint71_linear_196_10365" x1="28.2983" y1="21.4739" x2="33.4074" y2="17.1868" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#B1B9E2" />
                                <stop offset="0.0638" stopColor="#B6BFE5" />
                                <stop offset="0.1464" stopColor="#C4CFEE" />
                                <stop offset="0.2391" stopColor="#DCE9FD" />
                                <stop offset="0.2526" stopColor="#E0EEFF" />
                                <stop offset="0.4048" stopColor="#E4F0FF" />
                                <stop offset="0.4823" stopColor="#E9F3FF" />
                                <stop offset="0.5831" stopColor="#E6F0FD" />
                                <stop offset="0.6609" stopColor="#DCE7F8" />
                                <stop offset="0.7311" stopColor="#CCD9EE" />
                                <stop offset="0.7964" stopColor="#B5C4E0" />
                                <stop offset="0.8141" stopColor="#ADBDDC" />
                            </linearGradient>
                            <linearGradient id="paint72_linear_196_10365" x1="-0.301693" y1="13.5634" x2="7.81896" y2="10.1164" gradientUnits="userSpaceOnUse">
                                <stop offset="0.194" stopColor="#AFCED9" />
                                <stop offset="0.2378" stopColor="#BAD6E1" />
                                <stop offset="0.3456" stopColor="#D1E5F2" />
                                <stop offset="0.4483" stopColor="#DEEEFC" />
                                <stop offset="0.5393" stopColor="#E3F1FF" />
                                <stop offset="0.8221" stopColor="#FEFEFD" />
                            </linearGradient>
                            <linearGradient id="paint73_linear_196_10365" x1="1.39207" y1="11.8399" x2="6.1252" y2="11.8399" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CCE0FA" />
                                <stop offset="0.6681" stopColor="#C9DCF9" />
                                <stop offset="0.724" stopColor="#B9C9E9" />
                                <stop offset="0.8121" stopColor="#A6B0D5" />
                                <stop offset="0.8931" stopColor="#9AA1C9" />
                                <stop offset="0.9596" stopColor="#969CC5" />
                            </linearGradient>
                            <linearGradient id="paint74_linear_196_10365" x1="29.4764" y1="18.144" x2="32.2545" y2="15.8128" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#B1B9E2" />
                                <stop offset="0.0638" stopColor="#B6BFE5" />
                                <stop offset="0.1464" stopColor="#C4CFEE" />
                                <stop offset="0.2391" stopColor="#DCE9FD" />
                                <stop offset="0.2526" stopColor="#E0EEFF" />
                                <stop offset="0.4048" stopColor="#E4F0FF" />
                                <stop offset="0.4823" stopColor="#E9F3FF" />
                                <stop offset="0.5831" stopColor="#E6F0FD" />
                                <stop offset="0.6609" stopColor="#DCE7F8" />
                                <stop offset="0.7311" stopColor="#CCD9EE" />
                                <stop offset="0.7964" stopColor="#B5C4E0" />
                                <stop offset="0.8141" stopColor="#ADBDDC" />
                            </linearGradient>
                            <linearGradient id="paint75_linear_196_10365" x1="29.533" y1="16.4114" x2="31.8779" y2="14.8297" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E7F0FF" />
                                <stop offset="0.4617" stopColor="#D6E6FB" />
                                <stop offset="0.5034" stopColor="#C9DEF7" />
                                <stop offset="0.5404" stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint76_linear_196_10365" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFCED9" />
                                <stop offset="0.1268" stopColor="#BAD6E1" />
                                <stop offset="0.439" stopColor="#D1E5F2" />
                                <stop offset="0.7363" stopColor="#DEEEFC" />
                                <stop offset="1" stopColor="#E3F1FF" />
                            </linearGradient>
                            <linearGradient id="paint77_linear_196_10365" x1="28.9811" y1="18.534" x2="33.7152" y2="18.534" gradientUnits="userSpaceOnUse">
                                <stop offset="0.2198" stopColor="#7D85A4" />
                                <stop offset="0.4596" stopColor="#C4DBF6" />
                                <stop offset="0.4966" stopColor="#C9DEF7" />
                                <stop offset="0.5383" stopColor="#D6E6FB" />
                                <stop offset="0.5726" stopColor="#E7F0FF" />
                            </linearGradient>
                            <linearGradient id="paint78_linear_196_10365" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFCED9" />
                                <stop offset="0.1268" stopColor="#BAD6E1" />
                                <stop offset="0.439" stopColor="#D1E5F2" />
                                <stop offset="0.7363" stopColor="#DEEEFC" />
                                <stop offset="1" stopColor="#E3F1FF" />
                            </linearGradient>
                            <linearGradient id="paint79_linear_196_10365" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CCE0FA" />
                                <stop offset="0.6681" stopColor="#C9DCF9" />
                                <stop offset="0.724" stopColor="#B9C9E9" />
                                <stop offset="0.8121" stopColor="#A6B0D5" />
                                <stop offset="0.8931" stopColor="#9AA1C9" />
                                <stop offset="0.9596" stopColor="#969CC5" />
                            </linearGradient>
                            <linearGradient id="paint80_linear_196_10365" x1="6.1252" y1="7.79929" x2="10.8592" y2="7.79929" gradientUnits="userSpaceOnUse">
                                <stop offset="0.0080863" stopColor="#D9E8F9" />
                                <stop offset="0.113" stopColor="#E3EEFB" />
                                <stop offset="0.3197" stopColor="#F0F6FE" />
                                <stop offset="0.5034" stopColor="#F5F9FF" />
                                <stop offset="0.7479" stopColor="#F2F8FF" />
                                <stop offset="0.9366" stopColor="#E8F3FF" />
                                <stop offset="1" stopColor="#E3F1FF" />
                            </linearGradient>
                            <linearGradient id="paint81_linear_196_10365" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                                <stop offset="0.2198" stopColor="#7D85A4" />
                                <stop offset="0.4596" stopColor="#C4DBF6" />
                                <stop offset="0.4966" stopColor="#C9DEF7" />
                                <stop offset="0.5383" stopColor="#D6E6FB" />
                                <stop offset="0.5726" stopColor="#E7F0FF" />
                            </linearGradient>
                            <linearGradient id="paint82_linear_196_10365" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CCE0FA" />
                                <stop offset="0.6681" stopColor="#C9DCF9" />
                                <stop offset="0.724" stopColor="#B9C9E9" />
                                <stop offset="0.8121" stopColor="#A6B0D5" />
                                <stop offset="0.8931" stopColor="#9AA1C9" />
                                <stop offset="0.9596" stopColor="#969CC5" />
                            </linearGradient>
                            <linearGradient id="paint83_linear_196_10365" x1="19.7778" y1="2.95104" x2="20.3705" y2="6.31258" gradientUnits="userSpaceOnUse">
                                <stop offset="0.0745" stopColor="#C6E0FF" />
                                <stop offset="0.1248" stopColor="#B6CAEC" />
                                <stop offset="0.2357" stopColor="#969CC5" />
                                <stop offset="0.3834" stopColor="#B4C3E3" />
                                <stop offset="0.5303" stopColor="#CFE5FD" />
                                <stop offset="0.7108" stopColor="#DAEBFF" />
                                <stop offset="1" stopColor="#B9D8F4" />
                            </linearGradient>
                            <linearGradient id="paint84_linear_196_10365" x1="4.57346" y1="10.52" x2="7.67693" y2="8.42666" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E7F0FF" />
                                <stop offset="0.4617" stopColor="#D6E6FB" />
                                <stop offset="0.5034" stopColor="#C9DEF7" />
                                <stop offset="0.5404" stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint85_linear_196_10365" x1="15.0366" y1="4.98668" x2="18.4863" y2="3.30416" gradientUnits="userSpaceOnUse">
                                <stop offset="0.3389" stopColor="#F3FCFF" />
                                <stop offset="0.352" stopColor="#F0FAFE" />
                                <stop offset="0.4365" stopColor="#E3EEFB" />
                                <stop offset="0.5115" stopColor="#DEEAFA" />
                                <stop offset="0.7158" stopColor="#DAE6F8" />
                                <stop offset="0.8158" stopColor="#CAD3F2" />
                            </linearGradient>
                            <linearGradient id="paint86_linear_196_10365" x1="17.5536" y1="3.75904" x2="19.5143" y2="3.75904" gradientUnits="userSpaceOnUse">
                                <stop offset="0.4274" stopColor="#E7F0FF" />
                                <stop offset="0.4617" stopColor="#D6E6FB" />
                                <stop offset="0.5034" stopColor="#C9DEF7" />
                                <stop offset="0.5404" stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint87_linear_196_10365" x1="12.82" y1="3.75904" x2="17.5539" y2="3.75904" gradientUnits="userSpaceOnUse">
                                <stop offset="0.3389" stopColor="#F3FCFF" />
                                <stop offset="0.352" stopColor="#F0FAFE" />
                                <stop offset="0.4365" stopColor="#E3EEFB" />
                                <stop offset="0.5115" stopColor="#DEEAFA" />
                                <stop offset="0.7158" stopColor="#DAE6F8" />
                                <stop offset="0.8158" stopColor="#CAD3F2" />
                            </linearGradient>
                            <linearGradient id="paint88_linear_196_10365" x1="11.6069" y1="2.44053" x2="12.2738" y2="6.22239" gradientUnits="userSpaceOnUse">
                                <stop offset="0.0745" stopColor="#C6E0FF" />
                                <stop offset="0.1248" stopColor="#B6CAEC" />
                                <stop offset="0.2357" stopColor="#969CC5" />
                                <stop offset="0.3834" stopColor="#B4C3E3" />
                                <stop offset="0.5303" stopColor="#CFE5FD" />
                                <stop offset="0.7108" stopColor="#DAEBFF" />
                                <stop offset="1" stopColor="#B9D8F4" />
                            </linearGradient>
                            <linearGradient id="paint89_linear_196_10365" x1="17.5536" y1="20.4108" x2="22.2869" y2="20.4108" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint90_linear_196_10365" x1="19.5741" y1="19.5741" x2="22.921" y2="16.2271" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint91_linear_196_10365" x1="20.4109" y1="17.5537" x2="20.4109" y2="12.8205" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint92_linear_196_10365" x1="19.574" y1="15.5333" x2="16.2268" y2="12.1861" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint93_linear_196_10365" x1="17.5539" y1="14.6965" x2="12.82" y2="14.6965" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint94_linear_196_10365" x1="15.5333" y1="15.5333" x2="12.1861" y2="18.8806" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint95_linear_196_10365" x1="14.6963" y1="17.5536" x2="14.6963" y2="22.2877" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint96_linear_196_10365" x1="15.5333" y1="19.5739" x2="18.8806" y2="22.9212" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C4DBF6" />
                                <stop offset="0.7802" stopColor="#7D85A4" />
                            </linearGradient>
                            <linearGradient id="paint97_linear_196_10365" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#CCE0FA" />
                                <stop offset="0.6681" stopColor="#C9DCF9" />
                                <stop offset="0.724" stopColor="#B9C9E9" />
                                <stop offset="0.8121" stopColor="#A6B0D5" />
                                <stop offset="0.8931" stopColor="#9AA1C9" />
                                <stop offset="0.9596" stopColor="#969CC5" />
                            </linearGradient>
                            <linearGradient id="paint98_linear_196_10365" x1="14.8959" y1="11.1373" x2="19.6364" y2="9.17365" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint99_linear_196_10365" x1="21.3122" y1="13.22" x2="24.6647" y2="11.8313" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint100_linear_196_10365" x1="10.1551" y1="13.1009" x2="14.8956" y2="11.1373" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint101_linear_196_10365" x1="15.4708" y1="25.9338" x2="20.2113" y2="23.9702" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint102_linear_196_10365" x1="8.47902" y1="18.536" x2="11.8313" y2="17.1474" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint103_linear_196_10365" x1="10.4428" y1="23.2763" x2="13.7946" y2="21.8879" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint104_linear_196_10365" x1="20.2113" y1="23.9707" x2="24.9521" y2="22.007" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint105_linear_196_10365" x1="23.2762" y1="17.9608" x2="26.628" y2="16.5724" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint106_linear_196_10365" x1="11.1397" y1="14.8972" x2="13.1027" y2="10.1582" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint107_linear_196_10365" x1="17.1474" y1="11.8335" x2="18.5351" y2="8.48339" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint108_linear_196_10365" x1="9.17724" y1="19.6353" x2="11.1398" y2="14.8972" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint109_linear_196_10365" x1="22.0047" y1="24.9491" x2="23.9677" y2="20.2101" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint110_linear_196_10365" x1="11.8337" y1="24.6616" x2="13.2218" y2="21.3107" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint111_linear_196_10365" x1="16.5726" y1="26.6242" x2="17.9604" y2="23.2738" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint112_linear_196_10365" x1="23.9676" y1="20.2101" x2="25.9302" y2="15.472" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint113_linear_196_10365" x1="21.8856" y1="13.7967" x2="23.2736" y2="10.4457" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint114_linear_196_10365" x1="6.61929" y1="17.5537" x2="6.61929" y2="9.47524" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint115_linear_196_10365" x1="13.5146" y1="9.47523" x2="13.5146" y2="3.76307" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint116_linear_196_10365" x1="6.61929" y1="25.6321" x2="6.61929" y2="17.5537" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint117_linear_196_10365" x1="28.4887" y1="25.6321" x2="28.4887" y2="17.5537" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint118_linear_196_10365" x1="13.5146" y1="31.3444" x2="13.5146" y2="25.6321" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint119_linear_196_10365" x1="21.5929" y1="31.3444" x2="21.5929" y2="25.6321" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint120_linear_196_10365" x1="28.4887" y1="17.5537" x2="28.4887" y2="9.47524" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint121_linear_196_10365" x1="21.5929" y1="9.47523" x2="21.5929" y2="3.76307" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#AFDFE5" />
                                <stop offset="0.7912" stopColor="#A4D5D8" />
                                <stop offset="1" stopColor="#A0D2D4" />
                            </linearGradient>
                            <linearGradient id="paint122_linear_196_10365" x1="9.47238" y1="24.6542" x2="14.2065" y2="24.6542" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#ADD3E5" />
                                <stop offset="0.0707" stopColor="#B6D9EE" />
                                <stop offset="0.1978" stopColor="#C2E1FB" />
                                <stop offset="0.4063" stopColor="#C6E3FB" />
                                <stop offset="0.6097" stopColor="#D2E9FC" />
                                <stop offset="0.8102" stopColor="#E5F2FD" />
                                <stop offset="1" stopColor="white" />
                            </linearGradient>
                            <linearGradient id="paint123_linear_196_10365" x1="7.45743" y1="4.37501" x2="27.9404" y2="30.1279" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C12A45" />
                                <stop offset="1" stopColor="#5B0716" />
                            </linearGradient>
                        </defs>
                    </svg>
                </>
            )
        } else if (color === "black") {
            return (
                <>
                    <svg width="28" height="28" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.58418 11.1965L6.73828 13.0431V15.6541L9.89038 14.3479L8.58418 11.1965Z" fill="#0D2ED5" />
                        <path opacity="0.3" d="M8.58418 11.1965L6.73828 13.0431V15.6541L9.89038 14.3479L8.58418 11.1965Z" fill="url(#paint0_linear_195_9457)" />
                        <path d="M13.0423 13.0431L9.89018 9.89029L8.58398 11.1965L9.89018 14.3479L13.0423 13.0431Z" fill="url(#paint1_linear_195_9457)" />
                        <path d="M13.0423 13.0431L9.89018 9.89029L8.58398 11.1965L9.89018 14.3479L13.0423 13.0431Z" fill="url(#paint2_linear_195_9457)" />
                        <path d="M9.89038 14.3479L6.73828 15.6541V17.5H11.1966L9.89038 14.3479Z" fill="#2D42CC" />
                        <path d="M11.1966 17.5H6.73828V19.3466L9.89038 20.6521L11.1966 17.5Z" fill="url(#paint3_linear_195_9457)" />
                        <path opacity="0.3" d="M9.89038 14.3479L6.73828 15.6541V17.5H11.1966L9.89038 14.3479Z" fill="url(#paint4_linear_195_9457)" />
                        <path opacity="0.3" d="M11.1966 17.5H6.73828V19.3466L9.89038 20.6521L11.1966 17.5Z" fill="url(#paint5_linear_195_9457)" />
                        <path d="M17.4998 11.1965V6.73959H15.6532L14.3477 9.89029L17.4998 11.1965Z" fill="url(#paint6_linear_195_9457)" />
                        <path d="M14.3477 9.89028L11.1963 8.58548L9.89014 9.89028L13.0422 13.0431L14.3477 9.89028Z" fill="#2E40B6" />
                        <path d="M20.6521 9.89029L19.3459 6.73959H17.5V11.1965L20.6521 9.89029Z" fill="url(#paint7_linear_195_9457)" />
                        <path d="M23.8026 8.58549L21.9567 6.73959H19.3457L20.6519 9.89029L23.8026 8.58549Z" fill="url(#paint8_linear_195_9457)" />
                        <path d="M15.6532 6.73959H13.0422L11.1963 8.58549L14.3477 9.89029L15.6532 6.73959Z" fill="url(#paint9_linear_195_9457)" />
                        <path d="M11.1963 26.4145L13.0422 28.2611H15.6532L14.3477 25.1097L11.1963 26.4145Z" fill="url(#paint10_linear_195_9457)" />
                        <path d="M20.6519 25.1097L23.8026 26.4145L25.1088 25.1097L21.9567 21.9576L20.6519 25.1097Z" fill="#1F2C6D" />
                        <path d="M19.3457 28.2611H21.9567L23.8026 26.4145L20.6519 25.1097L19.3457 28.2611Z" fill="url(#paint11_linear_195_9457)" />
                        <path d="M6.73828 19.3466V21.9576L8.58418 23.8042L9.89038 20.6521L6.73828 19.3466Z" fill="url(#paint12_linear_195_9457)" />
                        <path d="M17.5 23.8042V28.2611H19.3459L20.6521 25.1097L17.5 23.8042Z" fill="#0419A9" />
                        <path d="M21.957 21.9576L25.1091 25.1097L26.4146 23.8042L25.1091 20.6521L21.957 21.9576Z" fill="url(#paint13_linear_195_9457)" />
                        <path d="M9.89018 20.6521L8.58398 23.8042L9.89018 25.1097L13.0423 21.9576L9.89018 20.6521Z" fill="#051791" />
                        <path d="M14.3477 25.1097L15.6532 28.2611H17.4998V23.8042L14.3477 25.1097Z" fill="url(#paint14_linear_195_9457)" />
                        <path d="M13.0422 21.9576L9.89014 25.1097L11.1963 26.4145L14.3477 25.1097L13.0422 21.9576Z" fill="url(#paint15_linear_195_9457)" />
                        <path d="M21.9567 13.0431L25.1088 9.89028L23.8026 8.58548L20.6519 9.89028L21.9567 13.0431Z" fill="url(#paint16_linear_195_9457)" />
                        <path d="M11.1963 17.5L13.0422 21.9576L17.4998 17.5H11.1963Z" fill="#18256A" />
                        <path d="M20.6521 9.89029L17.5 11.1965L21.9569 13.0431L20.6521 9.89029Z" fill="#2241E3" />
                        <path opacity="0.6" d="M20.6521 9.89029L17.5 11.1965L21.9569 13.0431L20.6521 9.89029Z" fill="url(#paint17_linear_195_9457)" />
                        <path d="M25.1091 14.3479L21.957 13.0431L23.8029 17.5L25.1091 14.3479Z" fill="url(#paint18_linear_195_9457)" />
                        <path d="M21.9569 13.0431L17.5 11.1965V17.5L21.9569 13.0431Z" fill="#101E67" />
                        <path d="M23.8028 17.5L21.9569 13.0431L17.5 17.5H23.8028Z" fill="url(#paint19_linear_195_9457)" />
                        <path d="M14.348 9.89029L13.0425 13.0431L17.5001 11.1965L14.348 9.89029Z" fill="url(#paint20_linear_195_9457)" />
                        <path opacity="0.5" d="M14.348 9.89029L13.0425 13.0431L17.5001 11.1965L14.348 9.89029Z" fill="url(#paint21_linear_195_9457)" />
                        <path d="M25.1091 14.3479L26.4146 11.1965L25.1091 9.89029L21.957 13.0431L25.1091 14.3479Z" fill="url(#paint22_linear_195_9457)" />
                        <path d="M14.348 25.1097L17.5001 23.8042L13.0425 21.9576L14.348 25.1097Z" fill="#172B96" />
                        <path d="M9.89014 14.3479L11.1963 17.5L13.0422 13.0431L9.89014 14.3479Z" fill="#304EE3" />
                        <path opacity="0.5" d="M9.89014 14.3479L11.1963 17.5L13.0422 13.0431L9.89014 14.3479Z" fill="url(#paint23_linear_195_9457)" />
                        <path d="M9.89014 20.6521L13.0422 21.9576L11.1963 17.5L9.89014 20.6521Z" fill="#2033B2" />
                        <path opacity="0.5" d="M9.89014 20.6521L13.0422 21.9576L11.1963 17.5L9.89014 20.6521Z" fill="url(#paint24_linear_195_9457)" />
                        <path d="M20.6521 25.1097L21.9569 21.9576L17.5 23.8042L20.6521 25.1097Z" fill="url(#paint25_linear_195_9457)" />
                        <path d="M25.1089 20.6521L28.261 19.3466V17.5H23.8027L25.1089 20.6521Z" fill="#304EE3" />
                        <path d="M23.8027 17.5H28.261V15.6541L25.1089 14.3479L23.8027 17.5Z" fill="#152EE1" />
                        <path d="M21.957 21.9576L25.1091 20.6521L23.8029 17.5L21.957 21.9576Z" fill="#152EE1" />
                        <path d="M28.261 15.6541V13.0431L26.4144 11.1965L25.1089 14.3479L28.261 15.6541Z" fill="#0D21AB" />
                        <path d="M17.5 23.8042L21.9569 21.9576L17.5 17.5V23.8042Z" fill="url(#paint26_linear_195_9457)" />
                        <path d="M25.1089 20.6521L26.4144 23.8042L28.261 21.9576V19.3466L25.1089 20.6521Z" fill="url(#paint27_linear_195_9457)" />
                        <path d="M13.0425 21.9576L17.5001 23.8042V17.5L13.0425 21.9576Z" fill="url(#paint28_linear_195_9457)" />
                        <path d="M13.0422 13.0431L11.1963 17.5H17.4998L13.0422 13.0431Z" fill="#172B96" />
                        <path d="M17.5 17.5L21.9569 21.9576L23.8028 17.5H17.5Z" fill="url(#paint29_linear_195_9457)" />
                        <path d="M17.5001 11.1965L13.0425 13.0431L17.5001 17.5V11.1965Z" fill="#1E31B1" />
                        <path d="M6.73828 21.9576V28.2611L8.58418 23.8042L6.73828 21.9576Z" fill="#0419A9" />
                        <path d="M9.89038 25.1097L6.73828 28.2611L11.1966 26.4145L9.89038 25.1097Z" fill="url(#paint30_linear_195_9457)" />
                        <path d="M8.58418 23.8042L6.73828 28.2611L9.89038 25.1097L8.58418 23.8042Z" fill="url(#paint31_linear_195_9457)" />
                        <path d="M11.1966 26.4145L6.73828 28.2611H13.0425L11.1966 26.4145Z" fill="url(#paint32_linear_195_9457)" />
                        <path d="M6.73828 28.2611L17.5001 32.7187L13.0425 28.2611H6.73828Z" fill="url(#paint33_linear_195_9457)" />
                        <path d="M2.28125 17.5L6.73815 28.2611V21.9576L2.28125 17.5Z" fill="url(#paint34_linear_195_9457)" />
                        <path d="M13.0425 28.2611L17.5001 32.7187L15.6535 28.2611H13.0425Z" fill="#2033B2" />
                        <path d="M6.73815 15.6541L2.28125 17.5H6.73815V15.6541Z" fill="#2336B2" />
                        <path d="M6.73815 17.5H2.28125L6.73815 19.3466V17.5Z" fill="#0B1E7E" />
                        <path d="M6.73815 19.3466L2.28125 17.5L6.73815 21.9576V19.3466Z" fill="#2638B3" />
                        <path d="M19.3459 28.2611L17.5 32.7187L21.9569 28.2611H19.3459Z" fill="#0520DE" />
                        <path d="M25.1089 25.1097L28.261 28.2611L26.4144 23.8042L25.1089 25.1097Z" fill="url(#paint35_linear_195_9457)" />
                        <path d="M26.4146 23.8042L28.2612 28.2611V21.9576L26.4146 23.8042Z" fill="#2E40B6" />
                        <path d="M23.8027 26.4145L28.261 28.2611L25.1089 25.1097L23.8027 26.4145Z" fill="#206BCC" />
                        <path d="M28.2612 21.9576V28.2611L32.7188 17.5L28.2612 21.9576Z" fill="#00199D" />
                        <path d="M17.5 32.7187L28.2611 28.2611H21.9569L17.5 32.7187Z" fill="url(#paint36_linear_195_9457)" />
                        <path d="M21.957 28.2611H28.2612L23.8029 26.4145L21.957 28.2611Z" fill="#1529AE" />
                        <path d="M6.73815 13.0431L2.28125 17.5L6.73815 15.6541V13.0431Z" fill="#0C218F" />
                        <path d="M17.5 28.2611V32.7187L19.3459 28.2611H17.5Z" fill="#3E5CF3" />
                        <path d="M15.6533 28.2611L17.4999 32.7187V28.2611H15.6533Z" fill="url(#paint37_linear_195_9457)" />
                        <path d="M25.1089 9.89029L28.261 6.73959L23.8027 8.58549L25.1089 9.89029Z" fill="#041584" />
                        <path opacity="0.3" d="M25.1089 9.89029L28.261 6.73959L23.8027 8.58549L25.1089 9.89029Z" fill="url(#paint38_linear_195_9457)" />
                        <path d="M28.2612 13.0431V6.73959L26.4146 11.1965L28.2612 13.0431Z" fill="#0B1E7E" />
                        <path d="M28.2611 6.73958L17.5 2.28128L21.9569 6.73958H28.2611Z" fill="#206BCC" />
                        <path opacity="0.3" d="M28.2611 6.73958L17.5 2.28128L21.9569 6.73958H28.2611Z" fill="url(#paint39_linear_195_9457)" />
                        <path d="M26.4144 11.1965L28.261 6.73959L25.1089 9.89029L26.4144 11.1965Z" fill="#182CAF" />
                        <path d="M23.8029 8.58549L28.2612 6.73959H21.957L23.8029 8.58549Z" fill="#172B96" />
                        <path d="M28.2612 21.9576L32.7188 17.5L28.2612 19.3466V21.9576Z" fill="url(#paint40_linear_195_9457)" />
                        <path d="M6.73815 6.73959L2.28125 17.5L6.73815 13.0431V6.73959Z" fill="url(#paint41_linear_195_9457)" />
                        <path opacity="0.3" d="M6.73815 6.73959L2.28125 17.5L6.73815 13.0431V6.73959Z" fill="url(#paint42_linear_195_9457)" />
                        <path d="M28.2612 17.5H32.7188L28.2612 15.6541V17.5Z" fill="url(#paint43_linear_195_9457)" />
                        <path d="M28.2612 15.6541L32.7188 17.5L28.2612 13.0431V15.6541Z" fill="#0B1E7E" />
                        <path d="M32.7188 17.5L28.2612 6.73959V13.0431L32.7188 17.5Z" fill="#182CAF" />
                        <path d="M28.2612 19.3466L32.7188 17.5H28.2612V19.3466Z" fill="#23306F" />
                        <path d="M13.0425 6.73959H6.73828L11.1966 8.58549L13.0425 6.73959Z" fill="#304EE3" />
                        <path opacity="0.3" d="M13.0425 6.73959H6.73828L11.1966 8.58549L13.0425 6.73959Z" fill="url(#paint44_linear_195_9457)" />
                        <path d="M11.1966 8.58549L6.73828 6.73959L9.89038 9.89029L11.1966 8.58549Z" fill="#152696" />
                        <path d="M9.89038 9.89029L6.73828 6.73959L8.58418 11.1965L9.89038 9.89029Z" fill="url(#paint45_linear_195_9457)" />
                        <path opacity="0.3" d="M9.89038 9.89029L6.73828 6.73959L8.58418 11.1965L9.89038 9.89029Z" fill="url(#paint46_linear_195_9457)" />
                        <path d="M21.9569 6.73958L17.5 2.28128L19.3459 6.73958H21.9569Z" fill="#2D42CC" />
                        <path opacity="0.2" d="M21.9569 6.73958L17.5 2.28128L19.3459 6.73958H21.9569Z" fill="url(#paint47_linear_195_9457)" />
                        <path d="M8.58418 11.1965L6.73828 6.73959V13.0431L8.58418 11.1965Z" fill="#0B1E7E" />
                        <path d="M17.4999 6.73958V2.28128L15.6533 6.73958H17.4999Z" fill="#0520DE" />
                        <path d="M19.3459 6.73958L17.5 2.28128V6.73958H19.3459Z" fill="url(#paint48_linear_195_9457)" />
                        <path d="M15.6535 6.73958L17.5001 2.28128L13.0425 6.73958H15.6535Z" fill="#051791" />
                        <path d="M17.5001 2.28128L6.73828 6.73958H13.0425L17.5001 2.28128Z" fill="#2033B2" />
                        <path opacity="0.3" d="M17.5 17.5L19.7288 22.8809L21.9569 21.9576L17.5 17.5Z" fill="url(#paint49_linear_195_9457)" />
                        <path opacity="0.3" d="M17.5 17.5L22.8809 19.7288L23.8028 17.5H17.5Z" fill="url(#paint50_linear_195_9457)" />
                        <path opacity="0.3" d="M17.5 17.5L22.8809 15.2712L21.9569 13.0431L17.5 17.5Z" fill="url(#paint51_linear_195_9457)" />
                        <path opacity="0.3" d="M17.5 17.5L19.7288 12.1191L17.5 11.1965V17.5Z" fill="url(#paint52_linear_195_9457)" />
                        <path opacity="0.3" d="M17.5001 17.5L15.2713 12.1191L13.0425 13.0431L17.5001 17.5Z" fill="url(#paint53_linear_195_9457)" />
                        <path opacity="0.3" d="M17.4998 17.5L12.1189 15.2712L11.1963 17.5H17.4998Z" fill="url(#paint54_linear_195_9457)" />
                        <path opacity="0.3" d="M17.5 17.5L12.1191 19.7288L13.0424 21.9576L17.5 17.5Z" fill="url(#paint55_linear_195_9457)" />
                        <path opacity="0.3" d="M17.4998 17.5L15.271 22.8809L17.4998 23.8042V17.5Z" fill="url(#paint56_linear_195_9457)" />
                        <path opacity="0.3" d="M32.7188 17.5L28.2612 6.73959V13.0431L32.7188 17.5Z" fill="url(#paint57_linear_195_9457)" />
                        <g opacity="0.3">
                            <mask id="mask0_195_9457" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="2" y="2" width="31" height="31">
                                <path d="M28.2611 6.73958L17.5 2.28128L6.73815 6.73958L2.28125 17.5L6.73815 28.2611L17.5 32.7187L28.2611 28.2611L32.7187 17.5L28.2611 6.73958Z" fill="white" />
                            </mask>
                            <g mask="url(#mask0_195_9457)">
                                <path d="M7.46631 23.7706L8.53941 26.3893L11.1483 27.4827L11.163 23.7853L7.46631 23.7706Z" fill="#0D2ED5" />
                                <path opacity="0.3" d="M7.46631 23.7706L8.53941 26.3893L11.1483 27.4827L11.163 23.7853L7.46631 23.7706Z" fill="url(#paint58_linear_195_9457)" />
                                <path d="M11.1794 20.0886L6.7085 21.9184L7.4666 23.7706L11.1633 23.7853L11.1794 20.0886Z" fill="url(#paint59_linear_195_9457)" />
                                <path d="M11.1794 20.0886L6.7085 21.9184L7.4666 23.7706L11.1633 23.7853L11.1794 20.0886Z" fill="url(#paint60_linear_195_9457)" />
                                <path d="M11.1631 23.7853L11.1484 27.4827L12.9936 28.2555L14.8605 23.8007L11.1631 23.7853Z" fill="#2D42CC" />
                                <path d="M14.8606 23.8007L12.9937 28.2555L14.8389 29.029L17.4639 26.4257L14.8606 23.8007Z" fill="url(#paint61_linear_195_9457)" />
                                <path opacity="0.3" d="M11.1631 23.7853L11.1484 27.4827L12.9936 28.2555L14.8605 23.8007L11.1631 23.7853Z" fill="url(#paint62_linear_195_9457)" />
                                <path opacity="0.3" d="M14.8606 23.8007L12.9937 28.2555L14.8389 29.029L17.4639 26.4257L14.8606 23.8007Z" fill="url(#paint63_linear_195_9457)" />
                                <path d="M11.2 14.8603L6.74518 12.9934L5.97168 14.8393L8.57498 17.4636L11.2 14.8603Z" fill="url(#paint64_linear_195_9457)" />
                                <path d="M8.57498 17.4636L5.95068 20.0669L6.70808 21.9184L11.179 20.0886L8.57498 17.4636Z" fill="#2E40B6" />
                                <path d="M11.2151 11.1629L7.51911 11.1489L6.74561 12.9934L11.2004 14.8603L11.2151 11.1629Z" fill="url(#paint65_linear_195_9457)" />
                                <path d="M11.2304 7.46759L8.61244 8.53929L7.51904 11.1489L11.215 11.1629L11.2304 7.46759Z" fill="url(#paint66_linear_195_9457)" />
                                <path d="M5.97209 14.8393L4.87939 17.4489L5.95109 20.0669L8.57539 17.4636L5.97209 14.8393Z" fill="url(#paint67_linear_195_9457)" />
                                <path d="M23.77 27.5338L26.388 26.4621L27.4814 23.8525L23.7854 23.8371L23.77 27.5338Z" fill="url(#paint68_linear_195_9457)" />
                                <path d="M26.4258 17.5364L29.0494 14.9338L28.292 13.0823L23.8218 14.9128L26.4258 17.5364Z" fill="#1F2C6D" />
                                <path d="M29.0284 20.1614L30.1211 17.5525L29.0494 14.9338L26.4258 17.5364L29.0284 20.1614Z" fill="url(#paint69_linear_195_9457)" />
                                <path d="M14.8389 29.029L17.4485 30.1224L20.0672 29.0507L17.4639 26.4257L14.8389 29.029Z" fill="url(#paint70_linear_195_9457)" />
                                <path d="M23.8008 20.1397L28.2549 22.0066L29.0284 20.1614L26.4258 17.5364L23.8008 20.1397Z" fill="#0419A9" />
                                <path d="M23.8218 14.9128L28.292 13.0823L27.5339 11.2301L23.8365 11.2154L23.8218 14.9128Z" fill="url(#paint71_linear_195_9457)" />
                                <path d="M17.4639 26.4257L20.0672 29.0507L21.9194 28.2926L20.0889 23.8224L17.4639 26.4257Z" fill="#051791" />
                                <path d="M23.7856 23.8371L27.4816 23.8525L28.2551 22.0066L23.801 20.1397L23.7856 23.8371Z" fill="url(#paint72_linear_195_9457)" />
                                <path d="M20.0889 23.8224L21.9194 28.2926L23.7702 27.5338L23.7856 23.8371L20.0889 23.8224Z" fill="url(#paint73_linear_195_9457)" />
                                <path d="M14.9122 11.179L13.081 6.7088L11.2302 7.4676L11.2148 11.1629L14.9122 11.179Z" fill="url(#paint74_linear_195_9457)" />
                                <path d="M14.8604 23.8007L20.0887 23.8224L17.5001 17.5L14.8604 23.8007Z" fill="#18256A" />
                                <path d="M11.2149 11.1629L11.2002 14.8603L14.9123 11.179L11.2149 11.1629Z" fill="#2241E3" />
                                <path opacity="0.6" d="M11.2149 11.1629L11.2002 14.8603L14.9123 11.179L11.2149 11.1629Z" fill="url(#paint75_linear_195_9457)" />
                                <path d="M17.5364 8.57568L14.9121 11.179L20.1397 11.2007L17.5364 8.57568Z" fill="url(#paint76_linear_195_9457)" />
                                <path d="M14.9123 11.179L11.2002 14.8603L17.5002 17.5L14.9123 11.179Z" fill="#101E67" />
                                <path d="M20.1397 11.2007L14.9121 11.179L17.5 17.5L20.1397 11.2007Z" fill="url(#paint77_linear_195_9457)" />
                                <path d="M8.5752 17.4636L11.1792 20.0886L11.2002 14.8603L8.5752 17.4636Z" fill="url(#paint78_linear_195_9457)" />
                                <path opacity="0.5" d="M8.5752 17.4636L11.1792 20.0886L11.2002 14.8603L8.5752 17.4636Z" fill="url(#paint79_linear_195_9457)" />
                                <path d="M17.5366 8.57568L14.9333 5.95068L13.0811 6.70878L14.9123 11.179L17.5366 8.57568Z" fill="url(#paint80_linear_195_9457)" />
                                <path d="M23.7856 23.8371L23.801 20.1397L20.0889 23.8224L23.7856 23.8371Z" fill="#172B96" />
                                <path d="M11.1631 23.7853L14.8605 23.8007L11.1792 20.0886L11.1631 23.7853Z" fill="#304EE3" />
                                <path opacity="0.5" d="M11.1631 23.7853L14.8605 23.8007L11.1792 20.0886L11.1631 23.7853Z" fill="url(#paint81_linear_195_9457)" />
                                <path d="M17.4637 26.4257L20.0887 23.8224L14.8604 23.8007L17.4637 26.4257Z" fill="#2033B2" />
                                <path opacity="0.5" d="M17.4637 26.4257L20.0887 23.8224L14.8604 23.8007L17.4637 26.4257Z" fill="url(#paint82_linear_195_9457)" />
                                <path d="M26.4258 17.5364L23.8218 14.9128L23.8008 20.1397L26.4258 17.5364Z" fill="url(#paint83_linear_195_9457)" />
                                <path d="M23.8363 11.2154L23.8517 7.51868L22.0065 6.74518L20.1396 11.2007L23.8363 11.2154Z" fill="#304EE3" />
                                <path d="M20.1399 11.2007L22.0068 6.74518L20.1616 5.97238L17.5366 8.57568L20.1399 11.2007Z" fill="#152EE1" />
                                <path d="M23.8216 14.9128L23.8363 11.2154L20.1396 11.2007L23.8216 14.9128Z" fill="#152EE1" />
                                <path d="M20.1614 5.9724L17.5525 4.879L14.9331 5.9507L17.5364 8.5757L20.1614 5.9724Z" fill="#0D21AB" />
                                <path d="M23.8007 20.1397L23.8217 14.9128L17.5 17.5L23.8007 20.1397Z" fill="url(#paint84_linear_195_9457)" />
                                <path d="M23.8364 11.2154L27.5338 11.2301L26.4621 8.61208L23.8518 7.51868L23.8364 11.2154Z" fill="url(#paint85_linear_195_9457)" />
                                <path d="M20.0886 23.8224L23.8007 20.1397L17.5 17.5L20.0886 23.8224Z" fill="url(#paint86_linear_195_9457)" />
                                <path d="M11.1792 20.0886L14.8605 23.8007L17.5002 17.5L11.1792 20.0886Z" fill="#172B96" />
                                <path d="M17.5 17.5L23.8217 14.9128L20.1397 11.2007L17.5 17.5Z" fill="url(#paint87_linear_195_9457)" />
                                <path d="M11.2002 14.8603L11.1792 20.0886L17.5002 17.5L11.2002 14.8603Z" fill="#1E31B1" />
                                <path d="M17.4482 30.1224L23.7482 32.7621L20.0669 29.0507L17.4482 30.1224Z" fill="#0419A9" />
                                <path d="M21.9194 28.2926L23.7485 32.7621L23.7702 27.5338L21.9194 28.2926Z" fill="url(#paint88_linear_195_9457)" />
                                <path d="M20.0669 29.0507L23.7482 32.7621L21.9191 28.2926L20.0669 29.0507Z" fill="url(#paint89_linear_195_9457)" />
                                <path d="M23.7702 27.5338L23.7485 32.7621L26.3882 26.4621L23.7702 27.5338Z" fill="url(#paint90_linear_195_9457)" />
                                <path d="M23.7485 32.7621L32.7106 23.8735L26.3882 26.4621L23.7485 32.7621Z" fill="url(#paint91_linear_195_9457)" />
                                <path d="M11.1274 32.7103L23.7484 32.7621L17.4484 30.1224L11.1274 32.7103Z" fill="url(#paint92_linear_195_9457)" />
                                <path d="M26.3882 26.4621L32.7106 23.8735L27.4816 23.8525L26.3882 26.4621Z" fill="#2033B2" />
                                <path d="M11.1484 27.4827L11.1274 32.7103L12.9936 28.2555L11.1484 27.4827Z" fill="#2336B2" />
                                <path d="M12.9936 28.2555L11.1274 32.7103L14.8388 29.029L12.9936 28.2555Z" fill="#0B1E7E" />
                                <path d="M14.8388 29.029L11.1274 32.7103L17.4484 30.1224L14.8388 29.029Z" fill="#2638B3" />
                                <path d="M29.0283 20.1614L32.7103 23.8735L30.121 17.5525L29.0283 20.1614Z" fill="#0520DE" />
                                <path d="M28.2923 13.0823L32.7618 11.2518L27.5342 11.2301L28.2923 13.0823Z" fill="url(#paint93_linear_195_9457)" />
                                <path d="M27.5341 11.2301L32.7617 11.2518L26.4624 8.61209L27.5341 11.2301Z" fill="#2E40B6" />
                                <path d="M29.0494 14.9338L32.7615 11.2518L28.292 13.0823L29.0494 14.9338Z" fill="#206BCC" />
                                <path d="M26.4621 8.61207L32.7614 11.2518L23.8735 2.29037L26.4621 8.61207Z" fill="#00199D" />
                                <path d="M32.7104 23.8735L32.7615 11.2518L30.1211 17.5525L32.7104 23.8735Z" fill="url(#paint94_linear_195_9457)" />
                                <path d="M30.121 17.5525L32.7614 11.2518L29.0493 14.9338L30.121 17.5525Z" fill="#1529AE" />
                                <path d="M8.53955 26.3893L11.1275 32.7103L11.1485 27.4827L8.53955 26.3893Z" fill="#0C218F" />
                                <path d="M28.2549 22.0066L32.7104 23.8735L29.0284 20.1614L28.2549 22.0066Z" fill="#3E5CF3" />
                                <path d="M27.4814 23.8525L32.7104 23.8735L28.2549 22.0066L27.4814 23.8525Z" fill="url(#paint95_linear_195_9457)" />
                                <path d="M13.0813 6.70879L11.2522 2.23929L11.2305 7.46759L13.0813 6.70879Z" fill="#041584" />
                                <path opacity="0.3" d="M13.0813 6.70879L11.2522 2.23929L11.2305 7.46759L13.0813 6.70879Z" fill="url(#paint96_linear_195_9457)" />
                                <path d="M17.5527 4.87899L11.252 2.23929L14.9333 5.95069L17.5527 4.87899Z" fill="#0B1E7E" />
                                <path d="M11.2519 2.23929L2.29053 11.1272L8.61223 8.53929L11.2519 2.23929Z" fill="#206BCC" />
                                <path opacity="0.3" d="M11.2519 2.23929L2.29053 11.1272L8.61223 8.53929L11.2519 2.23929Z" fill="url(#paint97_linear_195_9457)" />
                                <path d="M14.9333 5.95069L11.252 2.23929L13.0811 6.70879L14.9333 5.95069Z" fill="#182CAF" />
                                <path d="M11.2303 7.46759L11.252 2.23929L8.6123 8.53929L11.2303 7.46759Z" fill="#172B96" />
                                <path d="M26.4624 8.61207L23.8738 2.29037L23.8521 7.51867L26.4624 8.61207Z" fill="url(#paint98_linear_195_9457)" />
                                <path d="M2.23877 23.7489L11.1274 32.7103L8.53947 26.3893L2.23877 23.7489Z" fill="url(#paint99_linear_195_9457)" />
                                <path opacity="0.3" d="M2.23877 23.7489L11.1274 32.7103L8.53947 26.3893L2.23877 23.7489Z" fill="url(#paint100_linear_195_9457)" />
                                <path d="M22.0068 6.74517L23.8737 2.29037L20.1616 5.97237L22.0068 6.74517Z" fill="url(#paint101_linear_195_9457)" />
                                <path d="M20.1616 5.97238L23.8737 2.29037L17.5527 4.87897L20.1616 5.97238Z" fill="#0B1E7E" />
                                <path d="M23.8737 2.29039L11.252 2.23929L17.5527 4.87899L23.8737 2.29039Z" fill="#182CAF" />
                                <path d="M23.852 7.51867L23.8737 2.29037L22.0068 6.74517L23.852 7.51867Z" fill="#23306F" />
                                <path d="M4.87917 17.4489L2.23877 23.7489L5.95087 20.0669L4.87917 17.4489Z" fill="#304EE3" />
                                <path opacity="0.3" d="M4.87917 17.4489L2.23877 23.7489L5.95087 20.0669L4.87917 17.4489Z" fill="url(#paint102_linear_195_9457)" />
                                <path d="M5.95087 20.0669L2.23877 23.7489L6.70827 21.9184L5.95087 20.0669Z" fill="#152696" />
                                <path d="M6.70827 21.9184L2.23877 23.7489L7.46637 23.7706L6.70827 21.9184Z" fill="url(#paint103_linear_195_9457)" />
                                <path opacity="0.3" d="M6.70827 21.9184L2.23877 23.7489L7.46637 23.7706L6.70827 21.9184Z" fill="url(#paint104_linear_195_9457)" />
                                <path d="M8.61223 8.53928L2.29053 11.1272L7.51883 11.1489L8.61223 8.53928Z" fill="#2D42CC" />
                                <path opacity="0.2" d="M8.61223 8.53928L2.29053 11.1272L7.51883 11.1489L8.61223 8.53928Z" fill="url(#paint105_linear_195_9457)" />
                                <path d="M7.46637 23.7706L2.23877 23.7489L8.53947 26.3893L7.46637 23.7706Z" fill="#0B1E7E" />
                                <path d="M6.74533 12.9934L2.29053 11.1272L5.97183 14.8393L6.74533 12.9934Z" fill="#0520DE" />
                                <path d="M7.51883 11.1489L2.29053 11.1272L6.74533 12.9934L7.51883 11.1489Z" fill="url(#paint106_linear_195_9457)" />
                                <path d="M5.97183 14.8393L2.29053 11.1272L4.87913 17.4489L5.97183 14.8393Z" fill="#051791" />
                                <path d="M2.29057 11.1272L2.23877 23.7489L4.87917 17.4489L2.29057 11.1272Z" fill="#2033B2" />
                                <path opacity="0.3" d="M17.5 17.5L23.8112 17.5259L23.8217 14.9128L17.5 17.5Z" fill="url(#paint107_linear_195_9457)" />
                                <path opacity="0.3" d="M17.5 17.5L21.9814 13.0557L20.1397 11.2007L17.5 17.5Z" fill="url(#paint108_linear_195_9457)" />
                                <path opacity="0.3" d="M17.5 17.5L17.5259 11.1888L14.9121 11.179L17.5 17.5Z" fill="url(#paint109_linear_195_9457)" />
                                <path opacity="0.3" d="M17.5002 17.5L13.0559 13.0186L11.2002 14.8603L17.5002 17.5Z" fill="url(#paint110_linear_195_9457)" />
                                <path opacity="0.3" d="M17.5002 17.5L11.189 17.4741L11.1792 20.0886L17.5002 17.5Z" fill="url(#paint111_linear_195_9457)" />
                                <path opacity="0.3" d="M17.5002 17.5L13.0195 21.9443L14.8605 23.8007L17.5002 17.5Z" fill="url(#paint112_linear_195_9457)" />
                                <path opacity="0.3" d="M17.5003 17.5L17.4751 23.8112L20.0889 23.8224L17.5003 17.5Z" fill="url(#paint113_linear_195_9457)" />
                                <path opacity="0.3" d="M17.5 17.5L21.9443 21.9807L23.8007 20.1397L17.5 17.5Z" fill="url(#paint114_linear_195_9457)" />
                                <path opacity="0.3" d="M23.8737 2.29039L11.252 2.23929L17.5527 4.87899L23.8737 2.29039Z" fill="url(#paint115_linear_195_9457)" />
                            </g>
                        </g>
                        <g opacity="0.3">
                            <path d="M17.5 8.57288L14.8848 11.1874H20.1145L17.5 8.57288Z" fill="url(#paint116_linear_195_9457)" />
                            <path d="M23.8131 11.1874H20.1143L23.8131 14.8855V11.1874Z" fill="url(#paint117_linear_195_9457)" />
                            <path d="M11.1865 11.1874V14.8855L14.8846 11.1874H11.1865Z" fill="url(#paint118_linear_195_9457)" />
                            <path d="M17.5 26.4278L20.1145 23.8133H14.8848L17.5 26.4278Z" fill="url(#paint119_linear_195_9457)" />
                            <path d="M8.57129 17.5L11.1865 20.1152V14.8855L8.57129 17.5Z" fill="url(#paint120_linear_195_9457)" />
                            <path d="M11.1865 23.8133H14.8846L11.1865 20.1152V23.8133Z" fill="url(#paint121_linear_195_9457)" />
                            <path d="M23.8131 23.8133V20.1152L20.1143 23.8133H23.8131Z" fill="url(#paint122_linear_195_9457)" />
                            <path d="M23.813 20.1152L26.4275 17.5L23.813 14.8855V20.1152Z" fill="url(#paint123_linear_195_9457)" />
                        </g>
                        <g opacity="0.3">
                            <path d="M11.1895 11.1895V14.8862L14.8862 11.1895H11.1895Z" fill="url(#paint124_linear_195_9457)" />
                            <path d="M17.4995 8.57639L14.8857 11.1895H20.1133L17.4995 8.57639Z" fill="url(#paint125_linear_195_9457)" />
                            <path d="M8.57617 17.5L11.1893 20.1138V14.8862L8.57617 17.5Z" fill="url(#paint126_linear_195_9457)" />
                            <path d="M23.8105 23.8105V20.1138L20.1138 23.8105H23.8105Z" fill="url(#paint127_linear_195_9457)" />
                            <path d="M11.1895 23.8105H14.8862L11.1895 20.1138V23.8105Z" fill="url(#paint128_linear_195_9457)" />
                            <path d="M17.4995 26.4243L20.1133 23.8105H14.8857L17.4995 26.4243Z" fill="url(#paint129_linear_195_9457)" />
                            <path d="M26.4232 17.5L23.8101 14.8862V20.1138L26.4232 17.5Z" fill="url(#paint130_linear_195_9457)" />
                            <path d="M23.8105 14.8862V11.1895H20.1138L23.8105 14.8862Z" fill="url(#paint131_linear_195_9457)" />
                        </g>
                        <g opacity="0.3">
                            <path d="M4.51416 12.1212L6.74156 17.5L9.89296 9.8931L4.51416 12.1212Z" fill="url(#paint132_linear_195_9457)" />
                            <path d="M12.1212 4.51428L9.89307 9.89308L17.5 6.74238L12.1212 4.51428Z" fill="url(#paint133_linear_195_9457)" />
                            <path d="M4.51416 22.8788L9.89296 25.1069L6.74156 17.5L4.51416 22.8788Z" fill="url(#paint134_linear_195_9457)" />
                            <path d="M30.4857 22.8788L28.2569 17.5L25.1069 25.1069L30.4857 22.8788Z" fill="url(#paint135_linear_195_9457)" />
                            <path d="M12.1212 30.4864L17.5 28.2583L9.89307 25.1069L12.1212 30.4864Z" fill="url(#paint136_linear_195_9457)" />
                            <path d="M22.8788 30.4864L25.1069 25.1069L17.5 28.2583L22.8788 30.4864Z" fill="url(#paint137_linear_195_9457)" />
                            <path d="M30.4857 12.1212L25.1069 9.8931L28.2569 17.5L30.4857 12.1212Z" fill="url(#paint138_linear_195_9457)" />
                            <path d="M25.1069 9.89308L22.8788 4.51428L17.5 6.74238L25.1069 9.89308Z" fill="url(#paint139_linear_195_9457)" />
                        </g>
                        <path opacity="0.3" d="M13.0422 21.9576L9.89014 25.1097L11.1963 26.4145L14.3477 25.1097L13.0422 21.9576Z" fill="url(#paint140_linear_195_9457)" />
                        <path opacity="0.1" d="M32.6802 17.4069L28.2604 28.0749L17.4993 32.5325L6.73815 28.0749L2.31975 17.4076L2.28125 17.5L6.73815 28.2611L17.5 32.7187L28.2611 28.2611L32.7187 17.5L32.6802 17.4069Z" fill="white" />
                        <path opacity="0.5" d="M2.31975 17.5938L6.73955 6.92508L17.5007 2.46748L28.2618 6.92508L32.6802 17.5924L32.7187 17.5L28.2618 6.73958L17.5 2.28128L6.73955 6.73958L2.28125 17.5L2.31975 17.5938Z" fill="#0C46B7" />
                        <g style={{ mixBlendMode: "color" }}>
                            <path d="M28.2611 6.73958L17.5 2.28128L6.73815 6.73958L2.28125 17.5L6.73815 28.2611L17.5 32.7187L28.2611 28.2611L32.7187 17.5L28.2611 6.73958Z" fill="url(#paint141_diamond_195_9457)" />
                        </g>
                        <path opacity="0.5" d="M28.2611 6.73958L17.5 2.28128L6.73815 6.73958L2.28125 17.5L6.73815 28.2611L17.5 32.7187L28.2611 28.2611L32.7187 17.5L28.2611 6.73958Z" fill="black" />
                        <defs>
                            <linearGradient id="paint0_linear_195_9457" x1="6.73851" y1="13.4251" x2="9.89039" y2="13.4251" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1615" stopColor="#C5D8FB" />
                                <stop offset="0.393" stopColor="#78A3F7" />
                                <stop offset="0.5845" stopColor="#407DF3" />
                                <stop offset="0.7261" stopColor="#1D65F1" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_195_9457" x1="8.58432" y1="12.1192" x2="13.0422" y2="12.1192" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0F1F88" />
                                <stop offset="1" stopColor="#0D24C1" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_195_9457" x1="8.58432" y1="12.1192" x2="13.0422" y2="12.1192" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0F1F88" />
                                <stop offset="1" stopColor="#0D24C1" />
                            </linearGradient>
                            <linearGradient id="paint3_linear_195_9457" x1="6.73851" y1="19.076" x2="11.1963" y2="19.076" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#002DAE" />
                                <stop offset="1" stopColor="#3044D1" />
                            </linearGradient>
                            <linearGradient id="paint4_linear_195_9457" x1="6.73851" y1="15.924" x2="11.1963" y2="15.924" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#004A99" />
                                <stop offset="0.0785" stopColor="#0752C1" />
                                <stop offset="0.1558" stopColor="#0E59E3" />
                                <stop offset="0.1978" stopColor="#105CF0" />
                                <stop offset="0.3047" stopColor="#145FF0" />
                                <stop offset="0.4091" stopColor="#2067F1" />
                                <stop offset="0.5124" stopColor="#3374F2" />
                                <stop offset="0.6152" stopColor="#4F87F4" />
                                <stop offset="0.7176" stopColor="#729FF6" />
                                <stop offset="0.8197" stopColor="#9EBDF9" />
                                <stop offset="0.9195" stopColor="#D1DFFC" />
                                <stop offset="1" stopColor="white" />
                            </linearGradient>
                            <linearGradient id="paint5_linear_195_9457" x1="6.73851" y1="19.076" x2="11.1963" y2="19.076" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#004A99" />
                                <stop offset="0.0785" stopColor="#0752C1" />
                                <stop offset="0.1558" stopColor="#0E59E3" />
                                <stop offset="0.1978" stopColor="#105CF0" />
                                <stop offset="0.3047" stopColor="#145FF0" />
                                <stop offset="0.4091" stopColor="#2067F1" />
                                <stop offset="0.5124" stopColor="#3374F2" />
                                <stop offset="0.6152" stopColor="#4F87F4" />
                                <stop offset="0.7176" stopColor="#729FF6" />
                                <stop offset="0.8197" stopColor="#9EBDF9" />
                                <stop offset="0.9195" stopColor="#D1DFFC" />
                                <stop offset="1" stopColor="white" />
                            </linearGradient>
                            <linearGradient id="paint6_linear_195_9457" x1="14.348" y1="8.96784" x2="17.4997" y2="8.96784" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#4971E5" />
                                <stop offset="1" stopColor="#233DC3" />
                            </linearGradient>
                            <linearGradient id="paint7_linear_195_9457" x1="17.4999" y1="8.96784" x2="20.6519" y2="8.96784" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#5379E5" />
                                <stop offset="1" stopColor="#152EE1" />
                            </linearGradient>
                            <linearGradient id="paint8_linear_195_9457" x1="19.346" y1="8.31491" x2="23.8029" y2="8.31491" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0C20A8" />
                                <stop offset="1" stopColor="#0B196F" />
                            </linearGradient>
                            <linearGradient id="paint9_linear_195_9457" x1="11.196" y1="8.31491" x2="15.6529" y2="8.31491" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#002DAE" />
                                <stop offset="1" stopColor="#3044D1" />
                            </linearGradient>
                            <linearGradient id="paint10_linear_195_9457" x1="11.196" y1="26.6853" x2="15.6529" y2="26.6853" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0C20A8" />
                                <stop offset="1" stopColor="#0B196F" />
                            </linearGradient>
                            <linearGradient id="paint11_linear_195_9457" x1="19.346" y1="26.6853" x2="23.8029" y2="26.6853" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#002DAE" />
                                <stop offset="1" stopColor="#3044D1" />
                            </linearGradient>
                            <linearGradient id="paint12_linear_195_9457" x1="6.73851" y1="21.5751" x2="9.89039" y2="21.5751" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#1125B4" />
                                <stop offset="1" stopColor="#4971E5" />
                            </linearGradient>
                            <linearGradient id="paint13_linear_195_9457" x1="21.9571" y1="22.8809" x2="26.4149" y2="22.8809" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0C20A8" />
                                <stop offset="1" stopColor="#0B196F" />
                            </linearGradient>
                            <linearGradient id="paint14_linear_195_9457" x1="14.348" y1="26.0324" x2="17.4997" y2="26.0324" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#1125B4" />
                                <stop offset="1" stopColor="#4971E5" />
                            </linearGradient>
                            <linearGradient id="paint15_linear_195_9457" x1="9.89014" y1="24.1863" x2="14.348" y2="24.1863" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#5379E5" />
                                <stop offset="1" stopColor="#152EE1" />
                            </linearGradient>
                            <linearGradient id="paint16_linear_195_9457" x1="20.6517" y1="10.8143" x2="25.1088" y2="10.8143" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2F4DE3" />
                                <stop offset="0.1725" stopColor="#3351E3" />
                                <stop offset="0.3607" stopColor="#3D5FE4" />
                                <stop offset="0.5558" stopColor="#4F74E5" />
                                <stop offset="0.5907" stopColor="#5379E5" />
                            </linearGradient>
                            <linearGradient id="paint17_linear_195_9457" x1="20.9464" y1="9.84417" x2="19.5516" y2="12.4503" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1615" stopColor="#C5D8FB" />
                                <stop offset="0.393" stopColor="#78A3F7" />
                                <stop offset="0.5845" stopColor="#407DF3" />
                                <stop offset="0.7261" stopColor="#1D65F1" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint18_linear_195_9457" x1="21.9571" y1="15.2716" x2="25.1091" y2="15.2716" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#1125B4" />
                                <stop offset="1" stopColor="#4971E5" />
                            </linearGradient>
                            <linearGradient id="paint19_linear_195_9457" x1="17.4999" y1="15.2716" x2="23.8031" y2="15.2716" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0F1F88" />
                                <stop offset="1" stopColor="#0D24C1" />
                            </linearGradient>
                            <linearGradient id="paint20_linear_195_9457" x1="13.0424" y1="11.4668" x2="17.5" y2="11.4668" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#5379E5" />
                                <stop offset="1" stopColor="#152EE1" />
                            </linearGradient>
                            <linearGradient id="paint21_linear_195_9457" x1="15.6835" y1="10.0583" x2="15.1383" y2="12.7844" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.0805" stopColor="#D1DFFC" />
                                <stop offset="0.1803" stopColor="#9EBDF9" />
                                <stop offset="0.2824" stopColor="#729FF6" />
                                <stop offset="0.3848" stopColor="#4F87F4" />
                                <stop offset="0.4876" stopColor="#3374F2" />
                                <stop offset="0.5909" stopColor="#2067F1" />
                                <stop offset="0.6953" stopColor="#145FF0" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint22_linear_195_9457" x1="21.9571" y1="12.1192" x2="26.4149" y2="12.1192" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#5379E5" />
                                <stop offset="1" stopColor="#152EE1" />
                            </linearGradient>
                            <linearGradient id="paint23_linear_195_9457" x1="11.4698" y1="13.7367" x2="11.7725" y2="17.1885" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.0805" stopColor="#D1DFFC" />
                                <stop offset="0.1803" stopColor="#9EBDF9" />
                                <stop offset="0.2824" stopColor="#729FF6" />
                                <stop offset="0.3848" stopColor="#4F87F4" />
                                <stop offset="0.4876" stopColor="#3374F2" />
                                <stop offset="0.5909" stopColor="#2067F1" />
                                <stop offset="0.6953" stopColor="#145FF0" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint24_linear_195_9457" x1="12.2743" y1="19.6353" x2="9.93906" y2="21.0429" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.0805" stopColor="#D1DFFC" />
                                <stop offset="0.1803" stopColor="#9EBDF9" />
                                <stop offset="0.2824" stopColor="#729FF6" />
                                <stop offset="0.3848" stopColor="#4F87F4" />
                                <stop offset="0.4876" stopColor="#3374F2" />
                                <stop offset="0.5909" stopColor="#2067F1" />
                                <stop offset="0.6953" stopColor="#145FF0" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint25_linear_195_9457" x1="17.4999" y1="23.5338" x2="21.957" y2="23.5338" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#002DAE" />
                                <stop offset="1" stopColor="#3044D1" />
                            </linearGradient>
                            <linearGradient id="paint26_linear_195_9457" x1="17.4999" y1="20.652" x2="21.957" y2="20.652" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#4158F1" />
                                <stop offset="1" stopColor="#0D24C1" />
                            </linearGradient>
                            <linearGradient id="paint27_linear_195_9457" x1="25.1089" y1="21.5751" x2="28.2606" y2="21.5751" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#002DAE" />
                                <stop offset="1" stopColor="#3044D1" />
                            </linearGradient>
                            <linearGradient id="paint28_linear_195_9457" x1="13.0424" y1="20.652" x2="17.5" y2="20.652" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#1125B4" />
                                <stop offset="1" stopColor="#4971E5" />
                            </linearGradient>
                            <linearGradient id="paint29_linear_195_9457" x1="17.4999" y1="19.7289" x2="23.8031" y2="19.7289" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint30_linear_195_9457" x1="6.73851" y1="26.6853" x2="11.1963" y2="26.6853" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint31_linear_195_9457" x1="6.73851" y1="26.0324" x2="9.89039" y2="26.0324" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2F4DE3" />
                                <stop offset="0.1725" stopColor="#3351E3" />
                                <stop offset="0.3607" stopColor="#3D5FE4" />
                                <stop offset="0.5558" stopColor="#4F74E5" />
                                <stop offset="0.5907" stopColor="#5379E5" />
                            </linearGradient>
                            <linearGradient id="paint32_linear_195_9457" x1="6.73851" y1="27.3378" x2="13.0424" y2="27.3378" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#002DAE" />
                                <stop offset="1" stopColor="#3044D1" />
                            </linearGradient>
                            <linearGradient id="paint33_linear_195_9457" x1="6.73851" y1="30.4897" x2="17.5" y2="30.4897" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0F1F88" />
                                <stop offset="1" stopColor="#0D24C1" />
                            </linearGradient>
                            <linearGradient id="paint34_linear_195_9457" x1="2.28145" y1="22.8804" x2="6.73837" y2="22.8804" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0D24BE" />
                                <stop offset="1" stopColor="#0E1D85" />
                            </linearGradient>
                            <linearGradient id="paint35_linear_195_9457" x1="25.1089" y1="26.0324" x2="28.2606" y2="26.0324" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#4971E5" />
                                <stop offset="1" stopColor="#233DC3" />
                            </linearGradient>
                            <linearGradient id="paint36_linear_195_9457" x1="17.4999" y1="30.4897" x2="28.2607" y2="30.4897" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#4971E5" />
                                <stop offset="1" stopColor="#233DC3" />
                            </linearGradient>
                            <linearGradient id="paint37_linear_195_9457" x1="15.653" y1="30.4897" x2="17.4999" y2="30.4897" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#4971E5" />
                                <stop offset="1" stopColor="#233DC3" />
                            </linearGradient>
                            <linearGradient id="paint38_linear_195_9457" x1="23.8031" y1="8.31491" x2="28.2607" y2="8.31491" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#004A99" />
                                <stop offset="0.0785" stopColor="#0752C1" />
                                <stop offset="0.1558" stopColor="#0E59E3" />
                                <stop offset="0.1978" stopColor="#105CF0" />
                                <stop offset="0.2739" stopColor="#1D65F1" />
                                <stop offset="0.4155" stopColor="#407DF3" />
                                <stop offset="0.607" stopColor="#78A3F7" />
                                <stop offset="0.8385" stopColor="#C5D8FB" />
                                <stop offset="1" stopColor="white" />
                            </linearGradient>
                            <linearGradient id="paint39_linear_195_9457" x1="17.4999" y1="4.51042" x2="28.2607" y2="4.51042" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1615" stopColor="#C5D8FB" />
                                <stop offset="0.393" stopColor="#78A3F7" />
                                <stop offset="0.5845" stopColor="#407DF3" />
                                <stop offset="0.7261" stopColor="#1D65F1" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint40_linear_195_9457" x1="28.2609" y1="19.7289" x2="32.7188" y2="19.7289" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#4971E5" />
                                <stop offset="1" stopColor="#233DC3" />
                            </linearGradient>
                            <linearGradient id="paint41_linear_195_9457" x1="2.28145" y1="12.1197" x2="6.73837" y2="12.1197" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#002DAE" />
                                <stop offset="1" stopColor="#3044D1" />
                            </linearGradient>
                            <linearGradient id="paint42_linear_195_9457" x1="2.28145" y1="12.1197" x2="6.73837" y2="12.1197" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1615" stopColor="#C5D8FB" />
                                <stop offset="0.393" stopColor="#78A3F7" />
                                <stop offset="0.5845" stopColor="#407DF3" />
                                <stop offset="0.7261" stopColor="#1D65F1" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint43_linear_195_9457" x1="28.2609" y1="16.577" x2="32.7188" y2="16.577" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#4971E5" />
                                <stop offset="1" stopColor="#233DC3" />
                            </linearGradient>
                            <linearGradient id="paint44_linear_195_9457" x1="6.73851" y1="7.66245" x2="13.0424" y2="7.66245" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1615" stopColor="#C5D8FB" />
                                <stop offset="0.393" stopColor="#78A3F7" />
                                <stop offset="0.5845" stopColor="#407DF3" />
                                <stop offset="0.7261" stopColor="#1D65F1" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint45_linear_195_9457" x1="6.73851" y1="8.96784" x2="9.89039" y2="8.96784" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint46_linear_195_9457" x1="6.73851" y1="8.96784" x2="9.89039" y2="8.96784" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1615" stopColor="#C5D8FB" />
                                <stop offset="0.393" stopColor="#78A3F7" />
                                <stop offset="0.5845" stopColor="#407DF3" />
                                <stop offset="0.7261" stopColor="#1D65F1" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint47_linear_195_9457" x1="19.6471" y1="3.77844" x2="19.9855" y2="6.82372" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.0805" stopColor="#D1DFFC" />
                                <stop offset="0.1803" stopColor="#9EBDF9" />
                                <stop offset="0.2824" stopColor="#729FF6" />
                                <stop offset="0.3848" stopColor="#4F87F4" />
                                <stop offset="0.4876" stopColor="#3374F2" />
                                <stop offset="0.5909" stopColor="#2067F1" />
                                <stop offset="0.6953" stopColor="#145FF0" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint48_linear_195_9457" x1="17.4999" y1="4.51042" x2="19.3462" y2="4.51042" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0C20A8" />
                                <stop offset="1" stopColor="#0B196F" />
                            </linearGradient>
                            <linearGradient id="paint49_linear_195_9457" x1="17.4999" y1="20.1905" x2="21.957" y2="20.1905" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint50_linear_195_9457" x1="19.4024" y1="19.4025" x2="22.554" y2="16.2509" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint51_linear_195_9457" x1="20.1904" y1="17.5001" x2="20.1904" y2="13.0431" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint52_linear_195_9457" x1="19.4024" y1="15.5976" x2="16.2505" y2="12.4457" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint53_linear_195_9457" x1="17.5" y1="14.8096" x2="13.0424" y2="14.8096" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint54_linear_195_9457" x1="15.5973" y1="15.5976" x2="12.4454" y2="18.7495" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint55_linear_195_9457" x1="14.8096" y1="17.5" x2="14.8096" y2="21.9578" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint56_linear_195_9457" x1="15.5973" y1="19.4024" x2="18.7493" y2="22.5544" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint57_linear_195_9457" x1="28.2609" y1="12.1197" x2="32.7188" y2="12.1197" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1615" stopColor="#C5D8FB" />
                                <stop offset="0.393" stopColor="#78A3F7" />
                                <stop offset="0.5845" stopColor="#407DF3" />
                                <stop offset="0.7261" stopColor="#1D65F1" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint58_linear_195_9457" x1="8.92083" y1="26.549" x2="10.2409" y2="23.399" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1615" stopColor="#C5D8FB" />
                                <stop offset="0.393" stopColor="#78A3F7" />
                                <stop offset="0.5845" stopColor="#407DF3" />
                                <stop offset="0.7261" stopColor="#1D65F1" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint59_linear_195_9457" x1="8.38901" y1="24.1572" x2="10.2561" y2="19.7019" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0F1F88" />
                                <stop offset="1" stopColor="#0D24C1" />
                            </linearGradient>
                            <linearGradient id="paint60_linear_195_9457" x1="8.38901" y1="24.1572" x2="10.2561" y2="19.7019" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0F1F88" />
                                <stop offset="1" stopColor="#0D24C1" />
                            </linearGradient>
                            <linearGradient id="paint61_linear_195_9457" x1="14.5685" y1="28.9155" x2="16.4354" y2="24.4604" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#002DAE" />
                                <stop offset="1" stopColor="#3044D1" />
                            </linearGradient>
                            <linearGradient id="paint62_linear_195_9457" x1="11.4185" y1="27.5955" x2="13.2855" y2="23.1404" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#004A99" />
                                <stop offset="0.0785" stopColor="#0752C1" />
                                <stop offset="0.1558" stopColor="#0E59E3" />
                                <stop offset="0.1978" stopColor="#105CF0" />
                                <stop offset="0.3047" stopColor="#145FF0" />
                                <stop offset="0.4091" stopColor="#2067F1" />
                                <stop offset="0.5124" stopColor="#3374F2" />
                                <stop offset="0.6152" stopColor="#4F87F4" />
                                <stop offset="0.7176" stopColor="#729FF6" />
                                <stop offset="0.8197" stopColor="#9EBDF9" />
                                <stop offset="0.9195" stopColor="#D1DFFC" />
                                <stop offset="1" stopColor="white" />
                            </linearGradient>
                            <linearGradient id="paint63_linear_195_9457" x1="14.5685" y1="28.9155" x2="16.4354" y2="24.4604" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#004A99" />
                                <stop offset="0.0785" stopColor="#0752C1" />
                                <stop offset="0.1558" stopColor="#0E59E3" />
                                <stop offset="0.1978" stopColor="#105CF0" />
                                <stop offset="0.3047" stopColor="#145FF0" />
                                <stop offset="0.4091" stopColor="#2067F1" />
                                <stop offset="0.5124" stopColor="#3374F2" />
                                <stop offset="0.6152" stopColor="#4F87F4" />
                                <stop offset="0.7176" stopColor="#729FF6" />
                                <stop offset="0.8197" stopColor="#9EBDF9" />
                                <stop offset="0.9195" stopColor="#D1DFFC" />
                                <stop offset="1" stopColor="white" />
                            </linearGradient>
                            <linearGradient id="paint64_linear_195_9457" x1="7.6526" y1="17.0769" x2="8.97268" y2="13.9268" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#4971E5" />
                                <stop offset="1" stopColor="#233DC3" />
                            </linearGradient>
                            <linearGradient id="paint65_linear_195_9457" x1="8.97302" y1="13.927" x2="10.2932" y2="10.7767" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#5379E5" />
                                <stop offset="1" stopColor="#152EE1" />
                            </linearGradient>
                            <linearGradient id="paint66_linear_195_9457" x1="9.09347" y1="11.8084" x2="10.9602" y2="7.35397" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0C20A8" />
                                <stop offset="1" stopColor="#0B196F" />
                            </linearGradient>
                            <linearGradient id="paint67_linear_195_9457" x1="5.68042" y1="19.9537" x2="7.54705" y2="15.4994" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#002DAE" />
                                <stop offset="1" stopColor="#3044D1" />
                            </linearGradient>
                            <linearGradient id="paint68_linear_195_9457" x1="24.0403" y1="27.647" x2="25.907" y2="23.1925" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0C20A8" />
                                <stop offset="1" stopColor="#0B196F" />
                            </linearGradient>
                            <linearGradient id="paint69_linear_195_9457" x1="27.4534" y1="19.5015" x2="29.32" y2="15.0472" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#002DAE" />
                                <stop offset="1" stopColor="#3044D1" />
                            </linearGradient>
                            <linearGradient id="paint70_linear_195_9457" x1="17.0663" y1="29.9621" x2="18.3864" y2="26.8121" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#1125B4" />
                                <stop offset="1" stopColor="#4971E5" />
                            </linearGradient>
                            <linearGradient id="paint71_linear_195_9457" x1="24.7443" y1="15.299" x2="26.6114" y2="10.8437" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0C20A8" />
                                <stop offset="1" stopColor="#0B196F" />
                            </linearGradient>
                            <linearGradient id="paint72_linear_195_9457" x1="24.7079" y1="24.2233" x2="26.028" y2="21.0732" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#1125B4" />
                                <stop offset="1" stopColor="#4971E5" />
                            </linearGradient>
                            <linearGradient id="paint73_linear_195_9457" x1="20.9958" y1="27.9055" x2="22.8629" y2="23.4502" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#5379E5" />
                                <stop offset="1" stopColor="#152EE1" />
                            </linearGradient>
                            <linearGradient id="paint74_linear_195_9457" x1="12.1383" y1="11.55" x2="14.0049" y2="7.09574" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2F4DE3" />
                                <stop offset="0.1725" stopColor="#3351E3" />
                                <stop offset="0.3607" stopColor="#3D5FE4" />
                                <stop offset="0.5558" stopColor="#4F74E5" />
                                <stop offset="0.5907" stopColor="#5379E5" />
                            </linearGradient>
                            <linearGradient id="paint75_linear_195_9457" x1="11.292" y1="10.8494" x2="13.3125" y2="13.335" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1615" stopColor="#C5D8FB" />
                                <stop offset="0.393" stopColor="#78A3F7" />
                                <stop offset="0.5845" stopColor="#407DF3" />
                                <stop offset="0.7261" stopColor="#1D65F1" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint76_linear_195_9457" x1="17.1393" y1="12.1125" x2="18.4595" y2="8.96232" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#1125B4" />
                                <stop offset="1" stopColor="#4971E5" />
                            </linearGradient>
                            <linearGradient id="paint77_linear_195_9457" x1="15.273" y1="16.5667" x2="17.9128" y2="10.2673" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0F1F88" />
                                <stop offset="1" stopColor="#0D24C1" />
                            </linearGradient>
                            <linearGradient id="paint78_linear_195_9457" x1="9.60366" y1="19.4286" x2="11.4706" y2="14.9735" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#5379E5" />
                                <stop offset="1" stopColor="#152EE1" />
                            </linearGradient>
                            <linearGradient id="paint79_linear_195_9457" x1="9.30204" y1="16.1991" x2="11.7983" y2="17.8858" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.0805" stopColor="#D1DFFC" />
                                <stop offset="0.1803" stopColor="#9EBDF9" />
                                <stop offset="0.2824" stopColor="#729FF6" />
                                <stop offset="0.3848" stopColor="#4F87F4" />
                                <stop offset="0.4876" stopColor="#3374F2" />
                                <stop offset="0.5909" stopColor="#2067F1" />
                                <stop offset="0.6953" stopColor="#145FF0" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint80_linear_195_9457" x1="13.9889" y1="10.7922" x2="15.8559" y2="6.33696" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#5379E5" />
                                <stop offset="1" stopColor="#152EE1" />
                            </linearGradient>
                            <linearGradient id="paint81_linear_195_9457" x1="11.2138" y1="21.9508" x2="14.7904" y2="23.0938" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.0805" stopColor="#D1DFFC" />
                                <stop offset="0.1803" stopColor="#9EBDF9" />
                                <stop offset="0.2824" stopColor="#729FF6" />
                                <stop offset="0.3848" stopColor="#4F87F4" />
                                <stop offset="0.4876" stopColor="#3374F2" />
                                <stop offset="0.5909" stopColor="#2067F1" />
                                <stop offset="0.6953" stopColor="#145FF0" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint82_linear_195_9457" x1="17.4459" y1="23.617" x2="17.8745" y2="26.5403" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.0805" stopColor="#D1DFFC" />
                                <stop offset="0.1803" stopColor="#9EBDF9" />
                                <stop offset="0.2824" stopColor="#729FF6" />
                                <stop offset="0.3848" stopColor="#4F87F4" />
                                <stop offset="0.4876" stopColor="#3374F2" />
                                <stop offset="0.5909" stopColor="#2067F1" />
                                <stop offset="0.6953" stopColor="#145FF0" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint83_linear_195_9457" x1="23.5304" y1="20.0268" x2="25.397" y2="15.5725" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#002DAE" />
                                <stop offset="1" stopColor="#3044D1" />
                            </linearGradient>
                            <linearGradient id="paint84_linear_195_9457" x1="20.6503" y1="18.8201" x2="22.5169" y2="14.3657" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#4158F1" />
                                <stop offset="1" stopColor="#0D24C1" />
                            </linearGradient>
                            <linearGradient id="paint85_linear_195_9457" x1="24.7593" y1="11.602" x2="26.0793" y2="8.45191" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#002DAE" />
                                <stop offset="1" stopColor="#3044D1" />
                            </linearGradient>
                            <linearGradient id="paint86_linear_195_9457" x1="18.7833" y1="23.2752" x2="20.6503" y2="18.8199" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#1125B4" />
                                <stop offset="1" stopColor="#4971E5" />
                            </linearGradient>
                            <linearGradient id="paint87_linear_195_9457" x1="19.7275" y1="18.4334" x2="22.3673" y2="12.1341" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint88_linear_195_9457" x1="22.1738" y1="32.102" x2="24.0407" y2="27.6471" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint89_linear_195_9457" x1="21.5209" y1="31.8286" x2="22.8409" y2="28.6788" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2F4DE3" />
                                <stop offset="0.1725" stopColor="#3351E3" />
                                <stop offset="0.3607" stopColor="#3D5FE4" />
                                <stop offset="0.5558" stopColor="#4F74E5" />
                                <stop offset="0.5907" stopColor="#5379E5" />
                            </linearGradient>
                            <linearGradient id="paint90_linear_195_9457" x1="22.8258" y1="32.3753" x2="25.4659" y2="26.0752" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#002DAE" />
                                <stop offset="1" stopColor="#3044D1" />
                            </linearGradient>
                            <linearGradient id="paint91_linear_195_9457" x1="25.9757" y1="33.6953" x2="30.4829" y2="22.9399" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0F1F88" />
                                <stop offset="1" stopColor="#0D24C1" />
                            </linearGradient>
                            <linearGradient id="paint92_linear_195_9457" x1="16.5043" y1="34.9633" x2="18.3712" y2="30.5085" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0D24BE" />
                                <stop offset="1" stopColor="#0E1D85" />
                            </linearGradient>
                            <linearGradient id="paint93_linear_195_9457" x1="29.2144" y1="13.4687" x2="30.5345" y2="10.3185" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#4971E5" />
                                <stop offset="1" stopColor="#233DC3" />
                            </linearGradient>
                            <linearGradient id="paint94_linear_195_9457" x1="30.4823" y1="22.9398" x2="34.9891" y2="12.1854" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#4971E5" />
                                <stop offset="1" stopColor="#233DC3" />
                            </linearGradient>
                            <linearGradient id="paint95_linear_195_9457" x1="29.709" y1="24.7858" x2="30.4826" y2="22.9399" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#4971E5" />
                                <stop offset="1" stopColor="#233DC3" />
                            </linearGradient>
                            <linearGradient id="paint96_linear_195_9457" x1="10.96" y1="7.35389" x2="12.8269" y2="2.8989" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#004A99" />
                                <stop offset="0.0785" stopColor="#0752C1" />
                                <stop offset="0.1558" stopColor="#0E59E3" />
                                <stop offset="0.1978" stopColor="#105CF0" />
                                <stop offset="0.2739" stopColor="#1D65F1" />
                                <stop offset="0.4155" stopColor="#407DF3" />
                                <stop offset="0.607" stopColor="#78A3F7" />
                                <stop offset="0.8385" stopColor="#C5D8FB" />
                                <stop offset="1" stopColor="white" />
                            </linearGradient>
                            <linearGradient id="paint97_linear_195_9457" x1="4.51782" y1="12.0604" x2="9.0247" y2="1.30564" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1615" stopColor="#C5D8FB" />
                                <stop offset="0.393" stopColor="#78A3F7" />
                                <stop offset="0.5845" stopColor="#407DF3" />
                                <stop offset="0.7261" stopColor="#1D65F1" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint98_linear_195_9457" x1="24.2343" y1="7.67881" x2="26.1013" y2="3.22365" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#4971E5" />
                                <stop offset="1" stopColor="#233DC3" />
                            </linearGradient>
                            <linearGradient id="paint99_linear_195_9457" x1="5.74973" y1="30.4566" x2="7.61634" y2="26.0023" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#002DAE" />
                                <stop offset="1" stopColor="#3044D1" />
                            </linearGradient>
                            <linearGradient id="paint100_linear_195_9457" x1="5.74973" y1="30.4566" x2="7.61634" y2="26.0023" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1615" stopColor="#C5D8FB" />
                                <stop offset="0.393" stopColor="#78A3F7" />
                                <stop offset="0.5845" stopColor="#407DF3" />
                                <stop offset="0.7261" stopColor="#1D65F1" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint101_linear_195_9457" x1="21.0842" y1="6.35887" x2="22.9513" y2="1.90361" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#4971E5" />
                                <stop offset="1" stopColor="#233DC3" />
                            </linearGradient>
                            <linearGradient id="paint102_linear_195_9457" x1="3.1613" y1="24.1356" x2="5.80143" y2="17.8355" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1615" stopColor="#C5D8FB" />
                                <stop offset="0.393" stopColor="#78A3F7" />
                                <stop offset="0.5845" stopColor="#407DF3" />
                                <stop offset="0.7261" stopColor="#1D65F1" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint103_linear_195_9457" x1="4.46606" y1="24.6824" x2="5.78615" y2="21.5323" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint104_linear_195_9457" x1="4.46606" y1="24.6824" x2="5.78615" y2="21.5323" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1615" stopColor="#C5D8FB" />
                                <stop offset="0.393" stopColor="#78A3F7" />
                                <stop offset="0.5845" stopColor="#407DF3" />
                                <stop offset="0.7261" stopColor="#1D65F1" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint105_linear_195_9457" x1="4.68559" y1="9.60781" x2="7.87067" y2="10.545" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.0805" stopColor="#D1DFFC" />
                                <stop offset="0.1803" stopColor="#9EBDF9" />
                                <stop offset="0.2824" stopColor="#729FF6" />
                                <stop offset="0.3848" stopColor="#4F87F4" />
                                <stop offset="0.4876" stopColor="#3374F2" />
                                <stop offset="0.5909" stopColor="#2067F1" />
                                <stop offset="0.6953" stopColor="#145FF0" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint106_linear_195_9457" x1="4.51788" y1="12.0604" x2="5.29116" y2="10.2151" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0C20A8" />
                                <stop offset="1" stopColor="#0B196F" />
                            </linearGradient>
                            <linearGradient id="paint107_linear_195_9457" x1="20.1889" y1="18.6268" x2="22.0556" y2="14.1724" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint108_linear_195_9457" x1="20.1983" y1="16.3953" x2="18.3683" y2="11.9259" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint109_linear_195_9457" x1="18.627" y1="14.8112" x2="14.1725" y2="12.9445" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint110_linear_195_9457" x1="16.3957" y1="14.8019" x2="11.9256" y2="16.6322" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint111_linear_195_9457" x1="14.8115" y1="16.3733" x2="12.9445" y2="20.8286" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint112_linear_195_9457" x1="14.8024" y1="18.6048" x2="16.6326" y2="23.0748" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint113_linear_195_9457" x1="16.3737" y1="20.1889" x2="20.8289" y2="22.0559" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint114_linear_195_9457" x1="18.6049" y1="20.198" x2="23.0748" y2="18.3678" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2032A8" />
                                <stop offset="1" stopColor="#213BE2" />
                            </linearGradient>
                            <linearGradient id="paint115_linear_195_9457" x1="16.6292" y1="4.49238" x2="18.4964" y2="0.0367821" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.1615" stopColor="#C5D8FB" />
                                <stop offset="0.393" stopColor="#78A3F7" />
                                <stop offset="0.5845" stopColor="#407DF3" />
                                <stop offset="0.7261" stopColor="#1D65F1" />
                                <stop offset="0.8022" stopColor="#105CF0" />
                                <stop offset="0.8442" stopColor="#0E59E3" />
                                <stop offset="0.9215" stopColor="#0752C1" />
                                <stop offset="1" stopColor="#004A99" />
                            </linearGradient>
                            <linearGradient id="paint116_linear_195_9457" x1="14.997" y1="11.4581" x2="19.4609" y2="9.60907" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint117_linear_195_9457" x1="21.039" y1="13.4193" x2="24.1958" y2="12.1117" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint118_linear_195_9457" x1="10.533" y1="13.3071" x2="14.9968" y2="11.4581" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint119_linear_195_9457" x1="15.5384" y1="25.3912" x2="20.0023" y2="23.5421" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint120_linear_195_9457" x1="8.9546" y1="18.4251" x2="12.1113" y2="17.1175" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint121_linear_195_9457" x1="10.8039" y1="22.8887" x2="13.9601" y2="21.5813" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint122_linear_195_9457" x1="20.0023" y1="23.5427" x2="24.4665" y2="21.6935" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint123_linear_195_9457" x1="22.8882" y1="17.8834" x2="26.0445" y2="16.576" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint124_linear_195_9457" x1="11.4603" y1="14.9986" x2="13.3088" y2="10.5362" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint125_linear_195_9457" x1="17.1169" y1="12.1137" x2="18.4236" y2="8.95909" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint126_linear_195_9457" x1="9.6121" y1="19.4601" x2="11.4601" y2="14.9986" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint127_linear_195_9457" x1="21.6913" y1="24.4639" x2="23.5398" y2="20.0014" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint128_linear_195_9457" x1="12.1138" y1="24.1932" x2="13.4209" y2="21.0377" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint129_linear_195_9457" x1="16.5756" y1="26.0412" x2="17.8824" y2="22.8864" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint130_linear_195_9457" x1="23.5393" y1="20.0013" x2="25.3873" y2="15.5399" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint131_linear_195_9457" x1="21.5792" y1="13.9623" x2="22.8862" y2="10.8069" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint132_linear_195_9457" x1="7.20336" y1="17.5001" x2="7.20336" y2="9.89305" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint133_linear_195_9457" x1="13.6965" y1="9.89303" x2="13.6965" y2="4.51407" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint134_linear_195_9457" x1="7.20336" y1="25.1071" x2="7.20336" y2="17.5" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint135_linear_195_9457" x1="27.7965" y1="25.1071" x2="27.7965" y2="17.5" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint136_linear_195_9457" x1="13.6965" y1="30.486" x2="13.6965" y2="25.1071" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint137_linear_195_9457" x1="21.3035" y1="30.486" x2="21.3035" y2="25.1071" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint138_linear_195_9457" x1="27.7965" y1="17.5001" x2="27.7965" y2="9.89305" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint139_linear_195_9457" x1="21.3035" y1="9.89303" x2="21.3035" y2="4.51407" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#076999" />
                                <stop offset="0.25" stopColor="#06618C" />
                                <stop offset="0.7086" stopColor="#024E6B" />
                                <stop offset="1" stopColor="#003F52" />
                            </linearGradient>
                            <linearGradient id="paint140_linear_195_9457" x1="9.89014" y1="24.1863" x2="14.348" y2="24.1863" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#004A99" />
                                <stop offset="0.0785" stopColor="#0752C1" />
                                <stop offset="0.1558" stopColor="#0E59E3" />
                                <stop offset="0.1978" stopColor="#105CF0" />
                                <stop offset="0.3047" stopColor="#145FF0" />
                                <stop offset="0.4091" stopColor="#2067F1" />
                                <stop offset="0.5124" stopColor="#3374F2" />
                                <stop offset="0.6152" stopColor="#4F87F4" />
                                <stop offset="0.7176" stopColor="#729FF6" />
                                <stop offset="0.8197" stopColor="#9EBDF9" />
                                <stop offset="0.9195" stopColor="#D1DFFC" />
                                <stop offset="1" stopColor="white" />
                            </linearGradient>
                            <radialGradient id="paint141_diamond_195_9457" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.5 17.5) rotate(-133.409) scale(17.826 13.2402)">
                                <stop stopColor="white" />
                                <stop offset="1" stopColor="#040404" />
                            </radialGradient>
                        </defs>
                    </svg>
                </>
            )
        } else if (color === "blue") {
            return (
                <svg width="28" height="28" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="#BCD8F4" />
                    <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="url(#paint0_linear_196_10641)" />
                    <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint1_linear_196_10641)" />
                    <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint2_linear_196_10641)" />
                    <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="#C4DAF2" />
                    <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint3_linear_196_10641)" />
                    <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="url(#paint4_linear_196_10641)" />
                    <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint5_linear_196_10641)" />
                    <path d="M17.5535 10.8596V6.12616H15.5921L14.2061 9.47216L17.5535 10.8596Z" fill="url(#paint6_linear_196_10641)" />
                    <path d="M14.2063 9.47215L10.8589 8.08685L9.47217 9.47215L12.8196 12.8203L14.2063 9.47215Z" fill="url(#paint7_linear_196_10641)" />
                    <path d="M20.9011 9.47216L19.5144 6.12616H17.5537V10.8596L20.9011 9.47216Z" fill="url(#paint8_linear_196_10641)" />
                    <path d="M24.2476 8.08686L22.2869 6.12616H19.5142L20.9009 9.47216L24.2476 8.08686Z" fill="url(#paint9_linear_196_10641)" />
                    <path d="M15.5923 6.12616H12.8196L10.8589 8.08686L14.2063 9.47216L15.5923 6.12616Z" fill="url(#paint10_linear_196_10641)" />
                    <path d="M10.8589 27.0205L12.8196 28.9812H15.5923L14.2063 25.6352L10.8589 27.0205Z" fill="url(#paint11_linear_196_10641)" />
                    <path d="M20.9009 25.6352L24.2476 27.0204L25.6343 25.6352L22.2869 22.2878L20.9009 25.6352Z" fill="url(#paint12_linear_196_10641)" />
                    <path d="M19.5142 28.9812H22.2869L24.2476 27.0205L20.9009 25.6352L19.5142 28.9812Z" fill="url(#paint13_linear_196_10641)" />
                    <path d="M6.12549 19.5144V22.2878L8.08549 24.2485L9.47219 20.9011L6.12549 19.5144Z" fill="url(#paint14_linear_196_10641)" />
                    <path d="M17.5537 24.2485V28.9812H19.5144L20.9011 25.6352L17.5537 24.2485Z" fill="url(#paint15_linear_196_10641)" />
                    <path d="M22.2871 22.2878L25.6345 25.6352L27.0212 24.2485L25.6345 20.9011L22.2871 22.2878Z" fill="url(#paint16_linear_196_10641)" />
                    <path d="M9.47215 20.9011L8.08545 24.2485L9.47215 25.6352L12.8195 22.2878L9.47215 20.9011Z" fill="url(#paint17_linear_196_10641)" />
                    <path d="M14.2061 25.6352L15.5921 28.9812H17.5535V24.2485L14.2061 25.6352Z" fill="url(#paint18_linear_196_10641)" />
                    <path d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint19_linear_196_10641)" />
                    <path d="M22.2869 12.8203L25.6343 9.47215L24.2476 8.08685L20.9009 9.47215L22.2869 12.8203Z" fill="url(#paint20_linear_196_10641)" />
                    <path d="M10.8589 17.5536L12.8196 22.2878L17.5537 17.5536H10.8589Z" fill="url(#paint21_linear_196_10641)" />
                    <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="#C5DDF8" />
                    <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="url(#paint22_linear_196_10641)" />
                    <path d="M25.6345 14.2063L22.2871 12.8203L24.2478 17.5537L25.6345 14.2063Z" fill="url(#paint23_linear_196_10641)" />
                    <path d="M22.2871 12.8203L17.5537 10.8596V17.5537L22.2871 12.8203Z" fill="url(#paint24_linear_196_10641)" />
                    <path d="M24.2478 17.5537L22.2871 12.8203L17.5537 17.5537H24.2478Z" fill="url(#paint25_linear_196_10641)" />
                    <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint26_linear_196_10641)" />
                    <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint27_linear_196_10641)" />
                    <path d="M25.6345 14.2063L27.0212 10.8596L25.6345 9.47217L22.2871 12.8203L25.6345 14.2063Z" fill="url(#paint28_linear_196_10641)" />
                    <path opacity="0.9" d="M14.2065 25.6352L17.5539 24.2485L12.8198 22.2878L14.2065 25.6352Z" fill="url(#paint29_linear_196_10641)" />
                    <path d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint30_linear_196_10641)" />
                    <path opacity="0.5" d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint31_linear_196_10641)" />
                    <path d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint32_linear_196_10641)" />
                    <path opacity="0.5" d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint33_linear_196_10641)" />
                    <path d="M20.9011 25.6352L22.2871 22.2878L17.5537 24.2485L20.9011 25.6352Z" fill="url(#paint34_linear_196_10641)" />
                    <path d="M25.6343 20.9011L28.981 19.5144V17.5536H24.2476L25.6343 20.9011Z" fill="url(#paint35_linear_196_10641)" />
                    <path d="M24.2476 17.5537H28.981V15.593L25.6343 14.2063L24.2476 17.5537Z" fill="url(#paint36_linear_196_10641)" />
                    <path d="M22.2871 22.2878L25.6345 20.9011L24.2478 17.5536L22.2871 22.2878Z" fill="url(#paint37_linear_196_10641)" />
                    <path d="M28.981 15.593V12.8203L27.021 10.8596L25.6343 14.2063L28.981 15.593Z" fill="url(#paint38_linear_196_10641)" />
                    <path opacity="0.9" d="M17.5537 24.2485L22.2871 22.2878L17.5537 17.5536V24.2485Z" fill="url(#paint39_linear_196_10641)" />
                    <path d="M25.6343 20.9011L27.021 24.2485L28.981 22.2878V19.5144L25.6343 20.9011Z" fill="url(#paint40_linear_196_10641)" />
                    <path d="M12.8198 22.2878L17.5539 24.2485V17.5536L12.8198 22.2878Z" fill="url(#paint41_linear_196_10641)" />
                    <path opacity="0.9" d="M12.8196 12.8203L10.8589 17.5537H17.5537L12.8196 12.8203Z" fill="url(#paint42_linear_196_10641)" />
                    <path d="M17.5537 17.5536L22.2871 22.2878L24.2478 17.5536H17.5537Z" fill="url(#paint43_linear_196_10641)" />
                    <path d="M17.5539 10.8596L12.8198 12.8203L17.5539 17.5537V10.8596Z" fill="url(#paint44_linear_196_10641)" />
                    <path d="M6.12549 22.2878V28.9812L8.08549 24.2485L6.12549 22.2878Z" fill="url(#paint45_linear_196_10641)" />
                    <path d="M9.47219 25.6352L6.12549 28.9812L10.8589 27.0205L9.47219 25.6352Z" fill="url(#paint46_linear_196_10641)" />
                    <path d="M8.08549 24.2485L6.12549 28.9812L9.47219 25.6352L8.08549 24.2485Z" fill="url(#paint47_linear_196_10641)" />
                    <path d="M10.8589 27.0204L6.12549 28.9811H12.8196L10.8589 27.0204Z" fill="url(#paint48_linear_196_10641)" />
                    <path d="M6.12549 28.9812L17.5537 33.7153L12.8196 28.9812H6.12549Z" fill="url(#paint49_linear_196_10641)" />
                    <path d="M1.39209 17.5536L6.12549 28.9811V22.2878L1.39209 17.5536Z" fill="url(#paint50_linear_196_10641)" />
                    <path d="M12.8198 28.9812L17.5539 33.7153L15.5925 28.9812H12.8198Z" fill="url(#paint51_linear_196_10641)" />
                    <path d="M6.12549 15.593L1.39209 17.5537H6.12549V15.593Z" fill="url(#paint52_linear_196_10641)" />
                    <path d="M6.12549 17.5536H1.39209L6.12549 19.5144V17.5536Z" fill="url(#paint53_linear_196_10641)" />
                    <path d="M6.12549 19.5144L1.39209 17.5536L6.12549 22.2878V19.5144Z" fill="url(#paint54_linear_196_10641)" />
                    <path d="M19.5144 28.9812L17.5537 33.7153L22.2871 28.9812H19.5144Z" fill="url(#paint55_linear_196_10641)" />
                    <path d="M25.6343 25.6352L28.981 28.9812L27.021 24.2485L25.6343 25.6352Z" fill="url(#paint56_linear_196_10641)" />
                    <path d="M27.021 24.2485L28.981 28.9812V22.2878L27.021 24.2485Z" fill="url(#paint57_linear_196_10641)" />
                    <path d="M24.2476 27.0205L28.981 28.9812L25.6343 25.6352L24.2476 27.0205Z" fill="url(#paint58_linear_196_10641)" />
                    <path d="M28.981 22.2878V28.9811L33.7151 17.5536L28.981 22.2878Z" fill="url(#paint59_linear_196_10641)" />
                    <path d="M17.5537 33.7153L28.9812 28.9812H22.2871L17.5537 33.7153Z" fill="url(#paint60_linear_196_10641)" />
                    <path d="M22.2871 28.9811H28.9812L24.2478 27.0204L22.2871 28.9811Z" fill="url(#paint61_linear_196_10641)" />
                    <path opacity="0.9" d="M6.12549 12.8203L1.39209 17.5537L6.12549 15.593V12.8203Z" fill="url(#paint62_linear_196_10641)" />
                    <path d="M17.5537 28.9812V33.7153L19.5144 28.9812H17.5537Z" fill="url(#paint63_linear_196_10641)" />
                    <path d="M15.5923 28.9812L17.5537 33.7153V28.9812H15.5923Z" fill="url(#paint64_linear_196_10641)" />
                    <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="#ABC8E0" />
                    <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="url(#paint65_linear_196_10641)" />
                    <path d="M28.981 12.8203V6.12616L27.021 10.8596L28.981 12.8203Z" fill="url(#paint66_linear_196_10641)" />
                    <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint67_linear_196_10641)" />
                    <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint68_linear_196_10641)" />
                    <path d="M27.021 10.8596L28.981 6.12616L25.6343 9.47216L27.021 10.8596Z" fill="url(#paint69_linear_196_10641)" />
                    <path opacity="0.9" d="M24.2478 8.08686L28.9812 6.12616H22.2871L24.2478 8.08686Z" fill="url(#paint70_linear_196_10641)" />
                    <path d="M28.981 22.2878L33.7151 17.5536L28.981 19.5144V22.2878Z" fill="url(#paint71_linear_196_10641)" />
                    <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint72_linear_196_10641)" />
                    <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint73_linear_196_10641)" />
                    <path d="M28.981 17.5537H33.7151L28.981 15.593V17.5537Z" fill="url(#paint74_linear_196_10641)" />
                    <path d="M28.981 15.593L33.7151 17.5537L28.981 12.8203V15.593Z" fill="url(#paint75_linear_196_10641)" />
                    <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint76_linear_196_10641)" />
                    <path d="M28.981 19.5144L33.7151 17.5536H28.981V19.5144Z" fill="url(#paint77_linear_196_10641)" />
                    <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint78_linear_196_10641)" />
                    <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint79_linear_196_10641)" />
                    <path d="M10.8589 8.08686L6.12549 6.12616L9.47219 9.47216L10.8589 8.08686Z" fill="url(#paint80_linear_196_10641)" />
                    <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint81_linear_196_10641)" />
                    <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint82_linear_196_10641)" />
                    <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="#C4DAF2" />
                    <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="url(#paint83_linear_196_10641)" />
                    <path d="M8.08549 10.8596L6.12549 6.12616V12.8203L8.08549 10.8596Z" fill="url(#paint84_linear_196_10641)" />
                    <path d="M17.5537 6.12616V1.39206L15.5923 6.12616H17.5537Z" fill="url(#paint85_linear_196_10641)" />
                    <path d="M19.5144 6.12616L17.5537 1.39206V6.12616H19.5144Z" fill="url(#paint86_linear_196_10641)" />
                    <path d="M15.5925 6.12616L17.5539 1.39206L12.8198 6.12616H15.5925Z" fill="url(#paint87_linear_196_10641)" />
                    <path d="M17.5537 1.39206L6.12549 6.12616H12.8196L17.5537 1.39206Z" fill="url(#paint88_linear_196_10641)" />
                    <g opacity="0.5">
                        <path d="M17.5537 17.5536L19.9211 23.2678L22.2871 22.2878L17.5537 17.5536Z" fill="url(#paint89_linear_196_10641)" />
                        <path d="M17.5537 17.5536L23.2678 19.9211L24.2478 17.5536H17.5537Z" fill="url(#paint90_linear_196_10641)" />
                        <path d="M17.5537 17.5537L23.2678 15.187L22.2871 12.8203L17.5537 17.5537Z" fill="url(#paint91_linear_196_10641)" />
                        <path d="M17.5537 17.5537L19.9211 11.8396L17.5537 10.8596V17.5537Z" fill="url(#paint92_linear_196_10641)" />
                        <path d="M17.5539 17.5537L15.1872 11.8396L12.8198 12.8203L17.5539 17.5537Z" fill="url(#paint93_linear_196_10641)" />
                        <path d="M17.5537 17.5537L11.8396 15.187L10.8589 17.5537H17.5537Z" fill="url(#paint94_linear_196_10641)" />
                        <path d="M17.5535 17.5536L11.8394 19.9211L12.8194 22.2878L17.5535 17.5536Z" fill="url(#paint95_linear_196_10641)" />
                        <path d="M17.5537 17.5536L15.187 23.2678L17.5537 24.2485V17.5536Z" fill="url(#paint96_linear_196_10641)" />
                    </g>
                    <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint97_linear_196_10641)" />
                    <g opacity="0.3">
                        <path d="M17.554 8.07285L14.7764 10.8497H20.3309L17.554 8.07285Z" fill="url(#paint98_linear_196_10641)" />
                        <path d="M24.2583 10.8497H20.3306L24.2583 14.7774V10.8497Z" fill="url(#paint99_linear_196_10641)" />
                        <path d="M10.8491 10.8497V14.7774L14.7761 10.8497H10.8491Z" fill="url(#paint100_linear_196_10641)" />
                        <path d="M17.554 27.0344L20.3309 24.2582H14.7764L17.554 27.0344Z" fill="url(#paint101_linear_196_10641)" />
                        <path d="M8.07227 17.5536L10.8492 20.3312V14.7774L8.07227 17.5536Z" fill="url(#paint102_linear_196_10641)" />
                        <path d="M10.8491 24.2582H14.7761L10.8491 20.3312V24.2582Z" fill="url(#paint103_linear_196_10641)" />
                        <path d="M24.2583 24.2582V20.3312L20.3306 24.2582H24.2583Z" fill="url(#paint104_linear_196_10641)" />
                        <path d="M24.2583 20.3312L27.0345 17.5536L24.2583 14.7774V20.3312Z" fill="url(#paint105_linear_196_10641)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M10.8525 10.8526V14.7782L14.7781 10.8526H10.8525Z" fill="url(#paint106_linear_196_10641)" />
                        <path d="M17.5538 8.07706L14.7783 10.8526H20.3293L17.5538 8.07706Z" fill="url(#paint107_linear_196_10641)" />
                        <path d="M8.07715 17.5537L10.8526 20.3292V14.7782L8.07715 17.5537Z" fill="url(#paint108_linear_196_10641)" />
                        <path d="M24.2554 24.2548V20.3292L20.3291 24.2548H24.2554Z" fill="url(#paint109_linear_196_10641)" />
                        <path d="M10.8525 24.2548H14.7781L10.8525 20.3292V24.2548Z" fill="url(#paint110_linear_196_10641)" />
                        <path d="M17.5538 27.0303L20.3293 24.2548H14.7783L17.5538 27.0303Z" fill="url(#paint111_linear_196_10641)" />
                        <path d="M27.0302 17.5537L24.2554 14.7782V20.3292L27.0302 17.5537Z" fill="url(#paint112_linear_196_10641)" />
                        <path d="M24.2554 14.7782V10.8526H20.3291L24.2554 14.7782Z" fill="url(#paint113_linear_196_10641)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M3.76318 11.8417L6.12918 17.5537L9.47518 9.47498L3.76318 11.8417Z" fill="url(#paint114_linear_196_10641)" />
                        <path d="M11.8418 3.76297L9.4751 9.47497L17.5538 6.12897L11.8418 3.76297Z" fill="url(#paint115_linear_196_10641)" />
                        <path d="M3.76318 23.2657L9.47518 25.6324L6.12918 17.5537L3.76318 23.2657Z" fill="url(#paint116_linear_196_10641)" />
                        <path d="M31.3448 23.2657L28.9781 17.5537L25.6328 25.6324L31.3448 23.2657Z" fill="url(#paint117_linear_196_10641)" />
                        <path d="M11.8418 31.3444L17.5538 28.9784L9.4751 25.6324L11.8418 31.3444Z" fill="url(#paint118_linear_196_10641)" />
                        <path d="M23.2664 31.3444L25.6324 25.6324L17.5537 28.9784L23.2664 31.3444Z" fill="url(#paint119_linear_196_10641)" />
                        <path d="M31.3448 11.8417L25.6328 9.47498L28.9781 17.5537L31.3448 11.8417Z" fill="url(#paint120_linear_196_10641)" />
                        <path d="M25.6324 9.47497L23.2664 3.76297L17.5537 6.12897L25.6324 9.47497Z" fill="url(#paint121_linear_196_10641)" />
                    </g>
                    <path opacity="0.3" d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint122_linear_196_10641)" />
                    <path opacity="0.1" d="M33.674 17.4543L28.9812 28.7838L17.553 33.5179L6.12479 28.7838L1.43269 17.4557L1.39209 17.5537L6.12549 28.9812L17.5537 33.7153L28.9812 28.9812L33.7153 17.5537L33.674 17.4543Z" fill="white" />
                    <path opacity="0.5" d="M1.43339 17.6531L6.12619 6.32356L17.5544 1.58946L28.9826 6.32356L33.6747 17.6517L33.7153 17.5537L28.9819 6.12616L17.5537 1.39206L6.12619 6.12616L1.39209 17.5537L1.43339 17.6531Z" fill="#A6C8ED" />
                    <g style={{ mixBlendMode: "multiply" }} opacity="0.9">
                        <path d="M28.9816 6.12659L17.5537 1.39206L6.12514 6.12659L1.39209 17.5537L6.12514 28.9816L17.5537 33.7154L28.9816 28.9816L33.7154 17.5537L28.9816 6.12659Z" fill="#0A0731" />
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_196_10641" x1="6.1252" y1="13.2262" x2="9.47241" y2="13.2262" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_196_10641" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_196_10641" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_196_10641" x1="6.27745" y1="19.873" x2="10.9939" y2="17.871" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_196_10641" x1="6.1252" y1="15.88" x2="10.8592" y2="15.88" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_196_10641" x1="6.1252" y1="19.2273" x2="10.8592" y2="19.2273" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint6_linear_196_10641" x1="14.7016" y1="10.0627" x2="18.3135" y2="7.032" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint7_linear_196_10641" x1="11.5729" y1="7.96082" x2="12.4416" y2="12.8872" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint8_linear_196_10641" x1="17.9886" y1="11.0262" x2="19.824" y2="6.24497" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint9_linear_196_10641" x1="19.5141" y1="7.79929" x2="24.2472" y2="7.79929" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint10_linear_196_10641" x1="11.161" y1="8.79771" x2="15.8767" y2="6.79603" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint11_linear_196_10641" x1="10.8592" y1="27.3082" x2="15.5923" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint12_linear_196_10641" x1="20.9008" y1="24.6542" x2="25.634" y2="24.6542" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint13_linear_196_10641" x1="19.2298" y1="28.3116" x2="23.9455" y2="26.3099" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint14_linear_196_10641" x1="6.1252" y1="21.8813" x2="9.47241" y2="21.8813" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint15_linear_196_10641" x1="16.7631" y1="27.8093" x2="20.615" y2="25.2111" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint16_linear_196_10641" x1="22.2869" y1="23.2679" x2="27.0209" y2="23.2679" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint17_linear_196_10641" x1="8.08557" y1="23.2679" x2="12.8197" y2="23.2679" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint18_linear_196_10641" x1="14.2063" y1="26.6148" x2="17.5534" y2="26.6148" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint19_linear_196_10641" x1="11.2499" y1="27.1708" x2="13.0853" y2="22.3896" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint20_linear_196_10641" x1="20.9008" y1="10.4536" x2="25.634" y2="10.4536" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint21_linear_196_10641" x1="11.6988" y1="19.9893" x2="17.3881" y2="17.2144" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint22_linear_196_10641" x1="21.2137" y1="9.42334" x2="19.7324" y2="12.1909" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint23_linear_196_10641" x1="22.2869" y1="15.1871" x2="25.6343" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint24_linear_196_10641" x1="17.5536" y1="14.2065" x2="22.2869" y2="14.2065" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint25_linear_196_10641" x1="17.5536" y1="15.1871" x2="24.2475" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint26_linear_196_10641" x1="14.5549" y1="13.4865" x2="15.8531" y2="10.1044" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint27_linear_196_10641" x1="15.6248" y1="9.65069" x2="15.0458" y2="12.5457" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint28_linear_196_10641" x1="23.9881" y1="13.5744" x2="25.5719" y2="9.44851" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint29_linear_196_10641" x1="12.82" y1="23.9613" x2="17.5539" y2="23.9613" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint30_linear_196_10641" x1="9.47238" y1="15.1871" x2="12.8197" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint31_linear_196_10641" x1="11.1499" y1="13.5571" x2="11.4714" y2="17.2228" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1898" stopColor="#E5EEFD" />
                            <stop offset="0.3903" stopColor="#D2E2FC" />
                            <stop offset="0.5937" stopColor="#C6DAFB" />
                            <stop offset="0.8022" stopColor="#C2D8FB" />
                            <stop offset="0.9293" stopColor="#B6D0EE" />
                            <stop offset="1" stopColor="#ADCBE5" />
                        </linearGradient>
                        <linearGradient id="paint32_linear_196_10641" x1="10.4464" y1="17.6264" x2="11.3151" y2="22.553" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint33_linear_196_10641" x1="12.0043" y1="19.8213" x2="9.52433" y2="21.3161" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1898" stopColor="#E5EEFD" />
                            <stop offset="0.3903" stopColor="#D2E2FC" />
                            <stop offset="0.5937" stopColor="#C6DAFB" />
                            <stop offset="0.8022" stopColor="#C2D8FB" />
                            <stop offset="0.9293" stopColor="#B6D0EE" />
                            <stop offset="1" stopColor="#ADCBE5" />
                        </linearGradient>
                        <linearGradient id="paint34_linear_196_10641" x1="18.0586" y1="25.4377" x2="22.7744" y2="23.436" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint35_linear_196_10641" x1="24.2472" y1="19.2273" x2="28.9811" y2="19.2273" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint36_linear_196_10641" x1="25.3801" y1="14.3471" x2="27.5861" y2="18.3269" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint37_linear_196_10641" x1="22.4939" y1="18.5256" x2="24.2392" y2="21.6742" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint38_linear_196_10641" x1="25.634" y1="13.2262" x2="28.9811" y2="13.2262" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint39_linear_196_10641" x1="17.5536" y1="20.9009" x2="22.2869" y2="20.9009" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint40_linear_196_10641" x1="26.3129" y1="22.5807" x2="29.6766" y2="21.1529" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint41_linear_196_10641" x1="12.82" y1="20.9009" x2="17.5539" y2="20.9009" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint42_linear_196_10641" x1="10.8592" y1="15.1871" x2="17.5536" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint43_linear_196_10641" x1="17.5536" y1="19.9207" x2="24.2475" y2="19.9207" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint44_linear_196_10641" x1="15.0906" y1="11.2936" x2="16.2354" y2="17.7861" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint45_linear_196_10641" x1="4.57363" y1="26.681" x2="8.11527" y2="24.2922" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint46_linear_196_10641" x1="6.1252" y1="27.3082" x2="10.8592" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint47_linear_196_10641" x1="6.1252" y1="26.6148" x2="9.47241" y2="26.6148" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint48_linear_196_10641" x1="6.63623" y1="30.1853" x2="12.3087" y2="27.7774" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint49_linear_196_10641" x1="6.1252" y1="31.3484" x2="17.5536" y2="31.3484" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint50_linear_196_10641" x1="1.39207" y1="23.2675" x2="6.1252" y2="23.2675" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1929" stopColor="#C9E0EB" />
                            <stop offset="0.5344" stopColor="#CADEEF" />
                            <stop offset="0.8146" stopColor="#D7E8FA" />
                            <stop offset="1" stopColor="#D7E8FA" />
                        </linearGradient>
                        <linearGradient id="paint51_linear_196_10641" x1="14.7108" y1="28.648" x2="15.6631" y2="34.0488" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint52_linear_196_10641" x1="1.84685" y1="18.4861" x2="6.44289" y2="16.2445" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint53_linear_196_10641" x1="2.58657" y1="19.3246" x2="5.83967" y2="17.1303" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint54_linear_196_10641" x1="2.78671" y1="16.7806" x2="5.90652" y2="22.4089" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint55_linear_196_10641" x1="16.8094" y1="32.1895" x2="22.4983" y2="29.4148" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint56_linear_196_10641" x1="27.0944" y1="27.3754" x2="28.591" y2="26.1196" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint57_linear_196_10641" x1="27.8628" y1="22.4848" x2="29.0075" y2="28.9767" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint58_linear_196_10641" x1="24.2472" y1="27.3082" x2="28.9811" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint59_linear_196_10641" x1="28.9811" y1="23.2675" x2="33.7152" y2="23.2675" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1929" stopColor="#C9E0EB" />
                            <stop offset="0.5344" stopColor="#CADEEF" />
                            <stop offset="0.8146" stopColor="#D7E8FA" />
                            <stop offset="1" stopColor="#D7E8FA" />
                        </linearGradient>
                        <linearGradient id="paint60_linear_196_10641" x1="18.5613" y1="34.9162" x2="27.5983" y2="27.3332" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint61_linear_196_10641" x1="22.2869" y1="28.0011" x2="28.9813" y2="28.0011" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint62_linear_196_10641" x1="1.39207" y1="15.1871" x2="6.1252" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint63_linear_196_10641" x1="17.5536" y1="31.3484" x2="19.5143" y2="31.3484" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint64_linear_196_10641" x1="15.9829" y1="31.8435" x2="18.314" y2="29.8875" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint65_linear_196_10641" x1="24.6535" y1="8.91961" x2="29.2501" y2="6.67769" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint66_linear_196_10641" x1="26.9912" y1="10.8155" x2="30.5328" y2="8.42666" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint67_linear_196_10641" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint68_linear_196_10641" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint69_linear_196_10641" x1="25.634" y1="8.49268" x2="28.9811" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint70_linear_196_10641" x1="22.2869" y1="7.1064" x2="28.9813" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint71_linear_196_10641" x1="28.2983" y1="21.4739" x2="33.4074" y2="17.1868" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint72_linear_196_10641" x1="-0.301693" y1="13.5634" x2="7.81896" y2="10.1164" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint73_linear_196_10641" x1="1.39207" y1="11.8399" x2="6.1252" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint74_linear_196_10641" x1="29.4764" y1="18.144" x2="32.2545" y2="15.8128" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint75_linear_196_10641" x1="29.533" y1="16.4114" x2="31.8779" y2="14.8297" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint76_linear_196_10641" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint77_linear_196_10641" x1="28.9811" y1="18.534" x2="33.7152" y2="18.534" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint78_linear_196_10641" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint79_linear_196_10641" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint80_linear_196_10641" x1="6.1252" y1="7.79929" x2="10.8592" y2="7.79929" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint81_linear_196_10641" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint82_linear_196_10641" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint83_linear_196_10641" x1="19.7778" y1="2.95104" x2="20.3705" y2="6.31258" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint84_linear_196_10641" x1="4.57346" y1="10.52" x2="7.67693" y2="8.42666" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint85_linear_196_10641" x1="15.0366" y1="4.98668" x2="18.4863" y2="3.30416" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint86_linear_196_10641" x1="17.5536" y1="3.75904" x2="19.5143" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint87_linear_196_10641" x1="12.82" y1="3.75904" x2="17.5539" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint88_linear_196_10641" x1="11.6069" y1="2.44053" x2="12.2738" y2="6.22239" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint89_linear_196_10641" x1="17.5536" y1="20.4108" x2="22.2869" y2="20.4108" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint90_linear_196_10641" x1="19.5741" y1="19.5741" x2="22.921" y2="16.2271" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint91_linear_196_10641" x1="20.4109" y1="17.5537" x2="20.4109" y2="12.8205" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint92_linear_196_10641" x1="19.574" y1="15.5333" x2="16.2268" y2="12.1861" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint93_linear_196_10641" x1="17.5539" y1="14.6965" x2="12.82" y2="14.6965" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint94_linear_196_10641" x1="15.5333" y1="15.5333" x2="12.1861" y2="18.8806" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint95_linear_196_10641" x1="14.6963" y1="17.5536" x2="14.6963" y2="22.2877" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint96_linear_196_10641" x1="15.5333" y1="19.5739" x2="18.8806" y2="22.9212" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint97_linear_196_10641" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint98_linear_196_10641" x1="14.8959" y1="11.1373" x2="19.6364" y2="9.17365" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint99_linear_196_10641" x1="21.3122" y1="13.22" x2="24.6647" y2="11.8313" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint100_linear_196_10641" x1="10.1551" y1="13.1009" x2="14.8956" y2="11.1373" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint101_linear_196_10641" x1="15.4708" y1="25.9338" x2="20.2113" y2="23.9702" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint102_linear_196_10641" x1="8.47902" y1="18.536" x2="11.8313" y2="17.1474" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint103_linear_196_10641" x1="10.4428" y1="23.2763" x2="13.7946" y2="21.8879" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint104_linear_196_10641" x1="20.2113" y1="23.9707" x2="24.9521" y2="22.007" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint105_linear_196_10641" x1="23.2762" y1="17.9608" x2="26.628" y2="16.5724" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint106_linear_196_10641" x1="11.1397" y1="14.8972" x2="13.1027" y2="10.1582" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint107_linear_196_10641" x1="17.1474" y1="11.8335" x2="18.5351" y2="8.48339" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint108_linear_196_10641" x1="9.17724" y1="19.6353" x2="11.1398" y2="14.8972" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint109_linear_196_10641" x1="22.0047" y1="24.9491" x2="23.9677" y2="20.2101" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint110_linear_196_10641" x1="11.8337" y1="24.6616" x2="13.2218" y2="21.3107" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint111_linear_196_10641" x1="16.5726" y1="26.6242" x2="17.9604" y2="23.2738" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint112_linear_196_10641" x1="23.9676" y1="20.2101" x2="25.9302" y2="15.472" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint113_linear_196_10641" x1="21.8856" y1="13.7967" x2="23.2736" y2="10.4457" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint114_linear_196_10641" x1="6.61929" y1="17.5537" x2="6.61929" y2="9.47524" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint115_linear_196_10641" x1="13.5146" y1="9.47523" x2="13.5146" y2="3.76307" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint116_linear_196_10641" x1="6.61929" y1="25.6321" x2="6.61929" y2="17.5537" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint117_linear_196_10641" x1="28.4887" y1="25.6321" x2="28.4887" y2="17.5537" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint118_linear_196_10641" x1="13.5146" y1="31.3444" x2="13.5146" y2="25.6321" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint119_linear_196_10641" x1="21.5929" y1="31.3444" x2="21.5929" y2="25.6321" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint120_linear_196_10641" x1="28.4887" y1="17.5537" x2="28.4887" y2="9.47524" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint121_linear_196_10641" x1="21.5929" y1="9.47523" x2="21.5929" y2="3.76307" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint122_linear_196_10641" x1="9.47238" y1="24.6542" x2="14.2065" y2="24.6542" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#ADD3E5" />
                            <stop offset="0.0707" stopColor="#B6D9EE" />
                            <stop offset="0.1978" stopColor="#C2E1FB" />
                            <stop offset="0.4063" stopColor="#C6E3FB" />
                            <stop offset="0.6097" stopColor="#D2E9FC" />
                            <stop offset="0.8102" stopColor="#E5F2FD" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                    </defs>
                </svg>

            )
        } else if (color === "yellow") {
            return (
                <svg width="28" height="28" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="#BCD8F4" />
                    <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="url(#paint0_linear_196_10917)" />
                    <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint1_linear_196_10917)" />
                    <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint2_linear_196_10917)" />
                    <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="#C4DAF2" />
                    <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint3_linear_196_10917)" />
                    <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="url(#paint4_linear_196_10917)" />
                    <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint5_linear_196_10917)" />
                    <path d="M17.5535 10.8596V6.12616H15.5921L14.2061 9.47216L17.5535 10.8596Z" fill="url(#paint6_linear_196_10917)" />
                    <path d="M14.2063 9.47215L10.8589 8.08685L9.47217 9.47215L12.8196 12.8203L14.2063 9.47215Z" fill="url(#paint7_linear_196_10917)" />
                    <path d="M20.9011 9.47216L19.5144 6.12616H17.5537V10.8596L20.9011 9.47216Z" fill="url(#paint8_linear_196_10917)" />
                    <path d="M24.2476 8.08686L22.2869 6.12616H19.5142L20.9009 9.47216L24.2476 8.08686Z" fill="url(#paint9_linear_196_10917)" />
                    <path d="M15.5923 6.12616H12.8196L10.8589 8.08686L14.2063 9.47216L15.5923 6.12616Z" fill="url(#paint10_linear_196_10917)" />
                    <path d="M10.8589 27.0205L12.8196 28.9812H15.5923L14.2063 25.6352L10.8589 27.0205Z" fill="url(#paint11_linear_196_10917)" />
                    <path d="M20.9009 25.6352L24.2476 27.0204L25.6343 25.6352L22.2869 22.2878L20.9009 25.6352Z" fill="url(#paint12_linear_196_10917)" />
                    <path d="M19.5142 28.9812H22.2869L24.2476 27.0205L20.9009 25.6352L19.5142 28.9812Z" fill="url(#paint13_linear_196_10917)" />
                    <path d="M6.12549 19.5144V22.2878L8.08549 24.2485L9.47219 20.9011L6.12549 19.5144Z" fill="url(#paint14_linear_196_10917)" />
                    <path d="M17.5537 24.2485V28.9812H19.5144L20.9011 25.6352L17.5537 24.2485Z" fill="url(#paint15_linear_196_10917)" />
                    <path d="M22.2871 22.2878L25.6345 25.6352L27.0212 24.2485L25.6345 20.9011L22.2871 22.2878Z" fill="url(#paint16_linear_196_10917)" />
                    <path d="M9.47215 20.9011L8.08545 24.2485L9.47215 25.6352L12.8195 22.2878L9.47215 20.9011Z" fill="url(#paint17_linear_196_10917)" />
                    <path d="M14.2061 25.6352L15.5921 28.9812H17.5535V24.2485L14.2061 25.6352Z" fill="url(#paint18_linear_196_10917)" />
                    <path d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint19_linear_196_10917)" />
                    <path d="M22.2869 12.8203L25.6343 9.47215L24.2476 8.08685L20.9009 9.47215L22.2869 12.8203Z" fill="url(#paint20_linear_196_10917)" />
                    <path d="M10.8589 17.5536L12.8196 22.2878L17.5537 17.5536H10.8589Z" fill="url(#paint21_linear_196_10917)" />
                    <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="#C5DDF8" />
                    <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="url(#paint22_linear_196_10917)" />
                    <path d="M25.6345 14.2063L22.2871 12.8203L24.2478 17.5537L25.6345 14.2063Z" fill="url(#paint23_linear_196_10917)" />
                    <path d="M22.2871 12.8203L17.5537 10.8596V17.5537L22.2871 12.8203Z" fill="url(#paint24_linear_196_10917)" />
                    <path d="M24.2478 17.5537L22.2871 12.8203L17.5537 17.5537H24.2478Z" fill="url(#paint25_linear_196_10917)" />
                    <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint26_linear_196_10917)" />
                    <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint27_linear_196_10917)" />
                    <path d="M25.6345 14.2063L27.0212 10.8596L25.6345 9.47217L22.2871 12.8203L25.6345 14.2063Z" fill="url(#paint28_linear_196_10917)" />
                    <path opacity="0.9" d="M14.2065 25.6352L17.5539 24.2485L12.8198 22.2878L14.2065 25.6352Z" fill="url(#paint29_linear_196_10917)" />
                    <path d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint30_linear_196_10917)" />
                    <path opacity="0.5" d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint31_linear_196_10917)" />
                    <path d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint32_linear_196_10917)" />
                    <path opacity="0.5" d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint33_linear_196_10917)" />
                    <path d="M20.9011 25.6352L22.2871 22.2878L17.5537 24.2485L20.9011 25.6352Z" fill="url(#paint34_linear_196_10917)" />
                    <path d="M25.6343 20.9011L28.981 19.5144V17.5536H24.2476L25.6343 20.9011Z" fill="url(#paint35_linear_196_10917)" />
                    <path d="M24.2476 17.5537H28.981V15.593L25.6343 14.2063L24.2476 17.5537Z" fill="url(#paint36_linear_196_10917)" />
                    <path d="M22.2871 22.2878L25.6345 20.9011L24.2478 17.5536L22.2871 22.2878Z" fill="url(#paint37_linear_196_10917)" />
                    <path d="M28.981 15.593V12.8203L27.021 10.8596L25.6343 14.2063L28.981 15.593Z" fill="url(#paint38_linear_196_10917)" />
                    <path opacity="0.9" d="M17.5537 24.2485L22.2871 22.2878L17.5537 17.5536V24.2485Z" fill="url(#paint39_linear_196_10917)" />
                    <path d="M25.6343 20.9011L27.021 24.2485L28.981 22.2878V19.5144L25.6343 20.9011Z" fill="url(#paint40_linear_196_10917)" />
                    <path d="M12.8198 22.2878L17.5539 24.2485V17.5536L12.8198 22.2878Z" fill="url(#paint41_linear_196_10917)" />
                    <path opacity="0.9" d="M12.8196 12.8203L10.8589 17.5537H17.5537L12.8196 12.8203Z" fill="url(#paint42_linear_196_10917)" />
                    <path d="M17.5537 17.5536L22.2871 22.2878L24.2478 17.5536H17.5537Z" fill="url(#paint43_linear_196_10917)" />
                    <path d="M17.5539 10.8596L12.8198 12.8203L17.5539 17.5537V10.8596Z" fill="url(#paint44_linear_196_10917)" />
                    <path d="M6.12549 22.2878V28.9812L8.08549 24.2485L6.12549 22.2878Z" fill="url(#paint45_linear_196_10917)" />
                    <path d="M9.47219 25.6352L6.12549 28.9812L10.8589 27.0205L9.47219 25.6352Z" fill="url(#paint46_linear_196_10917)" />
                    <path d="M8.08549 24.2485L6.12549 28.9812L9.47219 25.6352L8.08549 24.2485Z" fill="url(#paint47_linear_196_10917)" />
                    <path d="M10.8589 27.0204L6.12549 28.9811H12.8196L10.8589 27.0204Z" fill="url(#paint48_linear_196_10917)" />
                    <path d="M6.12549 28.9812L17.5537 33.7153L12.8196 28.9812H6.12549Z" fill="url(#paint49_linear_196_10917)" />
                    <path d="M1.39209 17.5536L6.12549 28.9811V22.2878L1.39209 17.5536Z" fill="url(#paint50_linear_196_10917)" />
                    <path d="M12.8198 28.9812L17.5539 33.7153L15.5925 28.9812H12.8198Z" fill="url(#paint51_linear_196_10917)" />
                    <path d="M6.12549 15.593L1.39209 17.5537H6.12549V15.593Z" fill="url(#paint52_linear_196_10917)" />
                    <path d="M6.12549 17.5536H1.39209L6.12549 19.5144V17.5536Z" fill="url(#paint53_linear_196_10917)" />
                    <path d="M6.12549 19.5144L1.39209 17.5536L6.12549 22.2878V19.5144Z" fill="url(#paint54_linear_196_10917)" />
                    <path d="M19.5144 28.9812L17.5537 33.7153L22.2871 28.9812H19.5144Z" fill="url(#paint55_linear_196_10917)" />
                    <path d="M25.6343 25.6352L28.981 28.9812L27.021 24.2485L25.6343 25.6352Z" fill="url(#paint56_linear_196_10917)" />
                    <path d="M27.021 24.2485L28.981 28.9812V22.2878L27.021 24.2485Z" fill="url(#paint57_linear_196_10917)" />
                    <path d="M24.2476 27.0205L28.981 28.9812L25.6343 25.6352L24.2476 27.0205Z" fill="url(#paint58_linear_196_10917)" />
                    <path d="M28.981 22.2878V28.9811L33.7151 17.5536L28.981 22.2878Z" fill="url(#paint59_linear_196_10917)" />
                    <path d="M17.5537 33.7153L28.9812 28.9812H22.2871L17.5537 33.7153Z" fill="url(#paint60_linear_196_10917)" />
                    <path d="M22.2871 28.9811H28.9812L24.2478 27.0204L22.2871 28.9811Z" fill="url(#paint61_linear_196_10917)" />
                    <path opacity="0.9" d="M6.12549 12.8203L1.39209 17.5537L6.12549 15.593V12.8203Z" fill="url(#paint62_linear_196_10917)" />
                    <path d="M17.5537 28.9812V33.7153L19.5144 28.9812H17.5537Z" fill="url(#paint63_linear_196_10917)" />
                    <path d="M15.5923 28.9812L17.5537 33.7153V28.9812H15.5923Z" fill="url(#paint64_linear_196_10917)" />
                    <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="#ABC8E0" />
                    <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="url(#paint65_linear_196_10917)" />
                    <path d="M28.981 12.8203V6.12616L27.021 10.8596L28.981 12.8203Z" fill="url(#paint66_linear_196_10917)" />
                    <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint67_linear_196_10917)" />
                    <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint68_linear_196_10917)" />
                    <path d="M27.021 10.8596L28.981 6.12616L25.6343 9.47216L27.021 10.8596Z" fill="url(#paint69_linear_196_10917)" />
                    <path opacity="0.9" d="M24.2478 8.08686L28.9812 6.12616H22.2871L24.2478 8.08686Z" fill="url(#paint70_linear_196_10917)" />
                    <path d="M28.981 22.2878L33.7151 17.5536L28.981 19.5144V22.2878Z" fill="url(#paint71_linear_196_10917)" />
                    <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint72_linear_196_10917)" />
                    <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint73_linear_196_10917)" />
                    <path d="M28.981 17.5537H33.7151L28.981 15.593V17.5537Z" fill="url(#paint74_linear_196_10917)" />
                    <path d="M28.981 15.593L33.7151 17.5537L28.981 12.8203V15.593Z" fill="url(#paint75_linear_196_10917)" />
                    <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint76_linear_196_10917)" />
                    <path d="M28.981 19.5144L33.7151 17.5536H28.981V19.5144Z" fill="url(#paint77_linear_196_10917)" />
                    <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint78_linear_196_10917)" />
                    <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint79_linear_196_10917)" />
                    <path d="M10.8589 8.08686L6.12549 6.12616L9.47219 9.47216L10.8589 8.08686Z" fill="url(#paint80_linear_196_10917)" />
                    <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint81_linear_196_10917)" />
                    <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint82_linear_196_10917)" />
                    <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="#C4DAF2" />
                    <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="url(#paint83_linear_196_10917)" />
                    <path d="M8.08549 10.8596L6.12549 6.12616V12.8203L8.08549 10.8596Z" fill="url(#paint84_linear_196_10917)" />
                    <path d="M17.5537 6.12616V1.39206L15.5923 6.12616H17.5537Z" fill="url(#paint85_linear_196_10917)" />
                    <path d="M19.5144 6.12616L17.5537 1.39206V6.12616H19.5144Z" fill="url(#paint86_linear_196_10917)" />
                    <path d="M15.5925 6.12616L17.5539 1.39206L12.8198 6.12616H15.5925Z" fill="url(#paint87_linear_196_10917)" />
                    <path d="M17.5537 1.39206L6.12549 6.12616H12.8196L17.5537 1.39206Z" fill="url(#paint88_linear_196_10917)" />
                    <g opacity="0.5">
                        <path d="M17.5537 17.5536L19.9211 23.2678L22.2871 22.2878L17.5537 17.5536Z" fill="url(#paint89_linear_196_10917)" />
                        <path d="M17.5537 17.5536L23.2678 19.9211L24.2478 17.5536H17.5537Z" fill="url(#paint90_linear_196_10917)" />
                        <path d="M17.5537 17.5537L23.2678 15.187L22.2871 12.8203L17.5537 17.5537Z" fill="url(#paint91_linear_196_10917)" />
                        <path d="M17.5537 17.5537L19.9211 11.8396L17.5537 10.8596V17.5537Z" fill="url(#paint92_linear_196_10917)" />
                        <path d="M17.5539 17.5537L15.1872 11.8396L12.8198 12.8203L17.5539 17.5537Z" fill="url(#paint93_linear_196_10917)" />
                        <path d="M17.5537 17.5537L11.8396 15.187L10.8589 17.5537H17.5537Z" fill="url(#paint94_linear_196_10917)" />
                        <path d="M17.5535 17.5536L11.8394 19.9211L12.8194 22.2878L17.5535 17.5536Z" fill="url(#paint95_linear_196_10917)" />
                        <path d="M17.5537 17.5536L15.187 23.2678L17.5537 24.2485V17.5536Z" fill="url(#paint96_linear_196_10917)" />
                    </g>
                    <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint97_linear_196_10917)" />
                    <g opacity="0.3">
                        <path d="M17.554 8.07285L14.7764 10.8497H20.3309L17.554 8.07285Z" fill="url(#paint98_linear_196_10917)" />
                        <path d="M24.2583 10.8497H20.3306L24.2583 14.7774V10.8497Z" fill="url(#paint99_linear_196_10917)" />
                        <path d="M10.8491 10.8497V14.7774L14.7761 10.8497H10.8491Z" fill="url(#paint100_linear_196_10917)" />
                        <path d="M17.554 27.0344L20.3309 24.2582H14.7764L17.554 27.0344Z" fill="url(#paint101_linear_196_10917)" />
                        <path d="M8.07227 17.5536L10.8492 20.3312V14.7774L8.07227 17.5536Z" fill="url(#paint102_linear_196_10917)" />
                        <path d="M10.8491 24.2582H14.7761L10.8491 20.3312V24.2582Z" fill="url(#paint103_linear_196_10917)" />
                        <path d="M24.2583 24.2582V20.3312L20.3306 24.2582H24.2583Z" fill="url(#paint104_linear_196_10917)" />
                        <path d="M24.2583 20.3312L27.0345 17.5536L24.2583 14.7774V20.3312Z" fill="url(#paint105_linear_196_10917)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M10.8525 10.8526V14.7782L14.7781 10.8526H10.8525Z" fill="url(#paint106_linear_196_10917)" />
                        <path d="M17.5538 8.07706L14.7783 10.8526H20.3293L17.5538 8.07706Z" fill="url(#paint107_linear_196_10917)" />
                        <path d="M8.07715 17.5537L10.8526 20.3292V14.7782L8.07715 17.5537Z" fill="url(#paint108_linear_196_10917)" />
                        <path d="M24.2554 24.2548V20.3292L20.3291 24.2548H24.2554Z" fill="url(#paint109_linear_196_10917)" />
                        <path d="M10.8525 24.2548H14.7781L10.8525 20.3292V24.2548Z" fill="url(#paint110_linear_196_10917)" />
                        <path d="M17.5538 27.0303L20.3293 24.2548H14.7783L17.5538 27.0303Z" fill="url(#paint111_linear_196_10917)" />
                        <path d="M27.0302 17.5537L24.2554 14.7782V20.3292L27.0302 17.5537Z" fill="url(#paint112_linear_196_10917)" />
                        <path d="M24.2554 14.7782V10.8526H20.3291L24.2554 14.7782Z" fill="url(#paint113_linear_196_10917)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M3.76318 11.8417L6.12918 17.5537L9.47518 9.47498L3.76318 11.8417Z" fill="url(#paint114_linear_196_10917)" />
                        <path d="M11.8418 3.76297L9.4751 9.47497L17.5538 6.12897L11.8418 3.76297Z" fill="url(#paint115_linear_196_10917)" />
                        <path d="M3.76318 23.2657L9.47518 25.6324L6.12918 17.5537L3.76318 23.2657Z" fill="url(#paint116_linear_196_10917)" />
                        <path d="M31.3448 23.2657L28.9781 17.5537L25.6328 25.6324L31.3448 23.2657Z" fill="url(#paint117_linear_196_10917)" />
                        <path d="M11.8418 31.3444L17.5538 28.9784L9.4751 25.6324L11.8418 31.3444Z" fill="url(#paint118_linear_196_10917)" />
                        <path d="M23.2664 31.3444L25.6324 25.6324L17.5537 28.9784L23.2664 31.3444Z" fill="url(#paint119_linear_196_10917)" />
                        <path d="M31.3448 11.8417L25.6328 9.47498L28.9781 17.5537L31.3448 11.8417Z" fill="url(#paint120_linear_196_10917)" />
                        <path d="M25.6324 9.47497L23.2664 3.76297L17.5537 6.12897L25.6324 9.47497Z" fill="url(#paint121_linear_196_10917)" />
                    </g>
                    <path opacity="0.3" d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint122_linear_196_10917)" />
                    <path opacity="0.1" d="M33.674 17.4543L28.9812 28.7838L17.553 33.5179L6.12479 28.7838L1.43269 17.4557L1.39209 17.5537L6.12549 28.9812L17.5537 33.7153L28.9812 28.9812L33.7153 17.5537L33.674 17.4543Z" fill="white" />
                    <path opacity="0.5" d="M1.43339 17.6531L6.12619 6.32356L17.5544 1.58946L28.9826 6.32356L33.6747 17.6517L33.7153 17.5537L28.9819 6.12616L17.5537 1.39206L6.12619 6.12616L1.39209 17.5537L1.43339 17.6531Z" fill="#A6C8ED" />
                    <g style={{ mixBlendMode: "multiply" }}>
                        <path d="M28.9816 6.12659L17.5537 1.39206L6.12514 6.12659L1.39209 17.5537L6.12514 28.9816L17.5537 33.7154L28.9816 28.9816L33.7154 17.5537L28.9816 6.12659Z" fill="#F4BF36" />
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_196_10917" x1="6.1252" y1="13.2262" x2="9.47241" y2="13.2262" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_196_10917" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_196_10917" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_196_10917" x1="6.27745" y1="19.873" x2="10.9939" y2="17.871" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_196_10917" x1="6.1252" y1="15.88" x2="10.8592" y2="15.88" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_196_10917" x1="6.1252" y1="19.2273" x2="10.8592" y2="19.2273" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint6_linear_196_10917" x1="14.7016" y1="10.0627" x2="18.3135" y2="7.032" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint7_linear_196_10917" x1="11.5729" y1="7.96082" x2="12.4416" y2="12.8872" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint8_linear_196_10917" x1="17.9886" y1="11.0262" x2="19.824" y2="6.24497" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint9_linear_196_10917" x1="19.5141" y1="7.79929" x2="24.2472" y2="7.79929" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint10_linear_196_10917" x1="11.161" y1="8.79771" x2="15.8767" y2="6.79603" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint11_linear_196_10917" x1="10.8592" y1="27.3082" x2="15.5923" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint12_linear_196_10917" x1="20.9008" y1="24.6542" x2="25.634" y2="24.6542" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint13_linear_196_10917" x1="19.2298" y1="28.3116" x2="23.9455" y2="26.3099" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint14_linear_196_10917" x1="6.1252" y1="21.8813" x2="9.47241" y2="21.8813" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint15_linear_196_10917" x1="16.7631" y1="27.8093" x2="20.615" y2="25.2111" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint16_linear_196_10917" x1="22.2869" y1="23.2679" x2="27.0209" y2="23.2679" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint17_linear_196_10917" x1="8.08557" y1="23.2679" x2="12.8197" y2="23.2679" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint18_linear_196_10917" x1="14.2063" y1="26.6148" x2="17.5534" y2="26.6148" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint19_linear_196_10917" x1="11.2499" y1="27.1708" x2="13.0853" y2="22.3896" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint20_linear_196_10917" x1="20.9008" y1="10.4536" x2="25.634" y2="10.4536" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint21_linear_196_10917" x1="11.6988" y1="19.9893" x2="17.3881" y2="17.2144" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint22_linear_196_10917" x1="21.2137" y1="9.42334" x2="19.7324" y2="12.1909" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint23_linear_196_10917" x1="22.2869" y1="15.1871" x2="25.6343" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint24_linear_196_10917" x1="17.5536" y1="14.2065" x2="22.2869" y2="14.2065" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint25_linear_196_10917" x1="17.5536" y1="15.1871" x2="24.2475" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint26_linear_196_10917" x1="14.5549" y1="13.4865" x2="15.8531" y2="10.1044" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint27_linear_196_10917" x1="15.6248" y1="9.65069" x2="15.0458" y2="12.5457" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint28_linear_196_10917" x1="23.9881" y1="13.5744" x2="25.5719" y2="9.44851" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint29_linear_196_10917" x1="12.82" y1="23.9613" x2="17.5539" y2="23.9613" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint30_linear_196_10917" x1="9.47238" y1="15.1871" x2="12.8197" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint31_linear_196_10917" x1="11.1499" y1="13.5571" x2="11.4714" y2="17.2228" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1898" stopColor="#E5EEFD" />
                            <stop offset="0.3903" stopColor="#D2E2FC" />
                            <stop offset="0.5937" stopColor="#C6DAFB" />
                            <stop offset="0.8022" stopColor="#C2D8FB" />
                            <stop offset="0.9293" stopColor="#B6D0EE" />
                            <stop offset="1" stopColor="#ADCBE5" />
                        </linearGradient>
                        <linearGradient id="paint32_linear_196_10917" x1="10.4464" y1="17.6264" x2="11.3151" y2="22.553" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint33_linear_196_10917" x1="12.0043" y1="19.8213" x2="9.52433" y2="21.3161" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1898" stopColor="#E5EEFD" />
                            <stop offset="0.3903" stopColor="#D2E2FC" />
                            <stop offset="0.5937" stopColor="#C6DAFB" />
                            <stop offset="0.8022" stopColor="#C2D8FB" />
                            <stop offset="0.9293" stopColor="#B6D0EE" />
                            <stop offset="1" stopColor="#ADCBE5" />
                        </linearGradient>
                        <linearGradient id="paint34_linear_196_10917" x1="18.0586" y1="25.4377" x2="22.7744" y2="23.436" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint35_linear_196_10917" x1="24.2472" y1="19.2273" x2="28.9811" y2="19.2273" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint36_linear_196_10917" x1="25.3801" y1="14.3471" x2="27.5861" y2="18.3269" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint37_linear_196_10917" x1="22.4939" y1="18.5256" x2="24.2392" y2="21.6742" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint38_linear_196_10917" x1="25.634" y1="13.2262" x2="28.9811" y2="13.2262" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint39_linear_196_10917" x1="17.5536" y1="20.9009" x2="22.2869" y2="20.9009" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint40_linear_196_10917" x1="26.3129" y1="22.5807" x2="29.6766" y2="21.1529" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint41_linear_196_10917" x1="12.82" y1="20.9009" x2="17.5539" y2="20.9009" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint42_linear_196_10917" x1="10.8592" y1="15.1871" x2="17.5536" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint43_linear_196_10917" x1="17.5536" y1="19.9207" x2="24.2475" y2="19.9207" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint44_linear_196_10917" x1="15.0906" y1="11.2936" x2="16.2354" y2="17.7861" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint45_linear_196_10917" x1="4.57363" y1="26.681" x2="8.11527" y2="24.2922" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint46_linear_196_10917" x1="6.1252" y1="27.3082" x2="10.8592" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint47_linear_196_10917" x1="6.1252" y1="26.6148" x2="9.47241" y2="26.6148" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint48_linear_196_10917" x1="6.63623" y1="30.1853" x2="12.3087" y2="27.7774" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint49_linear_196_10917" x1="6.1252" y1="31.3484" x2="17.5536" y2="31.3484" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint50_linear_196_10917" x1="1.39207" y1="23.2675" x2="6.1252" y2="23.2675" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1929" stopColor="#C9E0EB" />
                            <stop offset="0.5344" stopColor="#CADEEF" />
                            <stop offset="0.8146" stopColor="#D7E8FA" />
                            <stop offset="1" stopColor="#D7E8FA" />
                        </linearGradient>
                        <linearGradient id="paint51_linear_196_10917" x1="14.7108" y1="28.648" x2="15.6631" y2="34.0488" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint52_linear_196_10917" x1="1.84685" y1="18.4861" x2="6.44289" y2="16.2445" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint53_linear_196_10917" x1="2.58657" y1="19.3246" x2="5.83967" y2="17.1303" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint54_linear_196_10917" x1="2.78671" y1="16.7806" x2="5.90652" y2="22.4089" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint55_linear_196_10917" x1="16.8094" y1="32.1895" x2="22.4983" y2="29.4148" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint56_linear_196_10917" x1="27.0944" y1="27.3754" x2="28.591" y2="26.1196" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint57_linear_196_10917" x1="27.8628" y1="22.4848" x2="29.0075" y2="28.9767" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint58_linear_196_10917" x1="24.2472" y1="27.3082" x2="28.9811" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint59_linear_196_10917" x1="28.9811" y1="23.2675" x2="33.7152" y2="23.2675" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1929" stopColor="#C9E0EB" />
                            <stop offset="0.5344" stopColor="#CADEEF" />
                            <stop offset="0.8146" stopColor="#D7E8FA" />
                            <stop offset="1" stopColor="#D7E8FA" />
                        </linearGradient>
                        <linearGradient id="paint60_linear_196_10917" x1="18.5613" y1="34.9162" x2="27.5983" y2="27.3332" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint61_linear_196_10917" x1="22.2869" y1="28.0011" x2="28.9813" y2="28.0011" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint62_linear_196_10917" x1="1.39207" y1="15.1871" x2="6.1252" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint63_linear_196_10917" x1="17.5536" y1="31.3484" x2="19.5143" y2="31.3484" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint64_linear_196_10917" x1="15.9829" y1="31.8435" x2="18.314" y2="29.8875" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint65_linear_196_10917" x1="24.6535" y1="8.91961" x2="29.2501" y2="6.67769" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint66_linear_196_10917" x1="26.9912" y1="10.8155" x2="30.5328" y2="8.42666" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint67_linear_196_10917" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint68_linear_196_10917" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint69_linear_196_10917" x1="25.634" y1="8.49268" x2="28.9811" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint70_linear_196_10917" x1="22.2869" y1="7.1064" x2="28.9813" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint71_linear_196_10917" x1="28.2983" y1="21.4739" x2="33.4074" y2="17.1868" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint72_linear_196_10917" x1="-0.301693" y1="13.5634" x2="7.81896" y2="10.1164" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint73_linear_196_10917" x1="1.39207" y1="11.8399" x2="6.1252" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint74_linear_196_10917" x1="29.4764" y1="18.144" x2="32.2545" y2="15.8128" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint75_linear_196_10917" x1="29.533" y1="16.4114" x2="31.8779" y2="14.8297" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint76_linear_196_10917" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint77_linear_196_10917" x1="28.9811" y1="18.534" x2="33.7152" y2="18.534" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint78_linear_196_10917" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint79_linear_196_10917" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint80_linear_196_10917" x1="6.1252" y1="7.79929" x2="10.8592" y2="7.79929" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint81_linear_196_10917" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint82_linear_196_10917" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint83_linear_196_10917" x1="19.7778" y1="2.95104" x2="20.3705" y2="6.31258" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint84_linear_196_10917" x1="4.57346" y1="10.52" x2="7.67693" y2="8.42666" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint85_linear_196_10917" x1="15.0366" y1="4.98668" x2="18.4863" y2="3.30416" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint86_linear_196_10917" x1="17.5536" y1="3.75904" x2="19.5143" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint87_linear_196_10917" x1="12.82" y1="3.75904" x2="17.5539" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint88_linear_196_10917" x1="11.6069" y1="2.44053" x2="12.2738" y2="6.22239" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint89_linear_196_10917" x1="17.5536" y1="20.4108" x2="22.2869" y2="20.4108" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint90_linear_196_10917" x1="19.5741" y1="19.5741" x2="22.921" y2="16.2271" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint91_linear_196_10917" x1="20.4109" y1="17.5537" x2="20.4109" y2="12.8205" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint92_linear_196_10917" x1="19.574" y1="15.5333" x2="16.2268" y2="12.1861" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint93_linear_196_10917" x1="17.5539" y1="14.6965" x2="12.82" y2="14.6965" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint94_linear_196_10917" x1="15.5333" y1="15.5333" x2="12.1861" y2="18.8806" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint95_linear_196_10917" x1="14.6963" y1="17.5536" x2="14.6963" y2="22.2877" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint96_linear_196_10917" x1="15.5333" y1="19.5739" x2="18.8806" y2="22.9212" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint97_linear_196_10917" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint98_linear_196_10917" x1="14.8959" y1="11.1373" x2="19.6364" y2="9.17365" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint99_linear_196_10917" x1="21.3122" y1="13.22" x2="24.6647" y2="11.8313" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint100_linear_196_10917" x1="10.1551" y1="13.1009" x2="14.8956" y2="11.1373" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint101_linear_196_10917" x1="15.4708" y1="25.9338" x2="20.2113" y2="23.9702" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint102_linear_196_10917" x1="8.47902" y1="18.536" x2="11.8313" y2="17.1474" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint103_linear_196_10917" x1="10.4428" y1="23.2763" x2="13.7946" y2="21.8879" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint104_linear_196_10917" x1="20.2113" y1="23.9707" x2="24.9521" y2="22.007" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint105_linear_196_10917" x1="23.2762" y1="17.9608" x2="26.628" y2="16.5724" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint106_linear_196_10917" x1="11.1397" y1="14.8972" x2="13.1027" y2="10.1582" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint107_linear_196_10917" x1="17.1474" y1="11.8335" x2="18.5351" y2="8.48339" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint108_linear_196_10917" x1="9.17724" y1="19.6353" x2="11.1398" y2="14.8972" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint109_linear_196_10917" x1="22.0047" y1="24.9491" x2="23.9677" y2="20.2101" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint110_linear_196_10917" x1="11.8337" y1="24.6616" x2="13.2218" y2="21.3107" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint111_linear_196_10917" x1="16.5726" y1="26.6242" x2="17.9604" y2="23.2738" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint112_linear_196_10917" x1="23.9676" y1="20.2101" x2="25.9302" y2="15.472" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint113_linear_196_10917" x1="21.8856" y1="13.7967" x2="23.2736" y2="10.4457" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint114_linear_196_10917" x1="6.61929" y1="17.5537" x2="6.61929" y2="9.47524" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint115_linear_196_10917" x1="13.5146" y1="9.47523" x2="13.5146" y2="3.76307" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint116_linear_196_10917" x1="6.61929" y1="25.6321" x2="6.61929" y2="17.5537" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint117_linear_196_10917" x1="28.4887" y1="25.6321" x2="28.4887" y2="17.5537" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint118_linear_196_10917" x1="13.5146" y1="31.3444" x2="13.5146" y2="25.6321" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint119_linear_196_10917" x1="21.5929" y1="31.3444" x2="21.5929" y2="25.6321" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint120_linear_196_10917" x1="28.4887" y1="17.5537" x2="28.4887" y2="9.47524" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint121_linear_196_10917" x1="21.5929" y1="9.47523" x2="21.5929" y2="3.76307" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint122_linear_196_10917" x1="9.47238" y1="24.6542" x2="14.2065" y2="24.6542" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#ADD3E5" />
                            <stop offset="0.0707" stopColor="#B6D9EE" />
                            <stop offset="0.1978" stopColor="#C2E1FB" />
                            <stop offset="0.4063" stopColor="#C6E3FB" />
                            <stop offset="0.6097" stopColor="#D2E9FC" />
                            <stop offset="0.8102" stopColor="#E5F2FD" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                    </defs>
                </svg>

            )
        } else if (color === "chameleon") {
            return (
                <svg width="28" height="28" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="#BCD8F4" />
                    <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="url(#paint0_linear_196_10779)" />
                    <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint1_linear_196_10779)" />
                    <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint2_linear_196_10779)" />
                    <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="#C4DAF2" />
                    <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint3_linear_196_10779)" />
                    <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="url(#paint4_linear_196_10779)" />
                    <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint5_linear_196_10779)" />
                    <path d="M17.5535 10.8596V6.12616H15.5921L14.2061 9.47216L17.5535 10.8596Z" fill="url(#paint6_linear_196_10779)" />
                    <path d="M14.2063 9.47215L10.8589 8.08685L9.47217 9.47215L12.8196 12.8203L14.2063 9.47215Z" fill="url(#paint7_linear_196_10779)" />
                    <path d="M20.9011 9.47216L19.5144 6.12616H17.5537V10.8596L20.9011 9.47216Z" fill="url(#paint8_linear_196_10779)" />
                    <path d="M24.2476 8.08686L22.2869 6.12616H19.5142L20.9009 9.47216L24.2476 8.08686Z" fill="url(#paint9_linear_196_10779)" />
                    <path d="M15.5923 6.12616H12.8196L10.8589 8.08686L14.2063 9.47216L15.5923 6.12616Z" fill="url(#paint10_linear_196_10779)" />
                    <path d="M10.8589 27.0205L12.8196 28.9812H15.5923L14.2063 25.6352L10.8589 27.0205Z" fill="url(#paint11_linear_196_10779)" />
                    <path d="M20.9009 25.6352L24.2476 27.0204L25.6343 25.6352L22.2869 22.2878L20.9009 25.6352Z" fill="url(#paint12_linear_196_10779)" />
                    <path d="M19.5142 28.9812H22.2869L24.2476 27.0205L20.9009 25.6352L19.5142 28.9812Z" fill="url(#paint13_linear_196_10779)" />
                    <path d="M6.12549 19.5144V22.2878L8.08549 24.2485L9.47219 20.9011L6.12549 19.5144Z" fill="url(#paint14_linear_196_10779)" />
                    <path d="M17.5537 24.2485V28.9812H19.5144L20.9011 25.6352L17.5537 24.2485Z" fill="url(#paint15_linear_196_10779)" />
                    <path d="M22.2871 22.2878L25.6345 25.6352L27.0212 24.2485L25.6345 20.9011L22.2871 22.2878Z" fill="url(#paint16_linear_196_10779)" />
                    <path d="M9.47215 20.9011L8.08545 24.2485L9.47215 25.6352L12.8195 22.2878L9.47215 20.9011Z" fill="url(#paint17_linear_196_10779)" />
                    <path d="M14.2061 25.6352L15.5921 28.9812H17.5535V24.2485L14.2061 25.6352Z" fill="url(#paint18_linear_196_10779)" />
                    <path d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint19_linear_196_10779)" />
                    <path d="M22.2869 12.8203L25.6343 9.47215L24.2476 8.08685L20.9009 9.47215L22.2869 12.8203Z" fill="url(#paint20_linear_196_10779)" />
                    <path d="M10.8589 17.5536L12.8196 22.2878L17.5537 17.5536H10.8589Z" fill="url(#paint21_linear_196_10779)" />
                    <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="#C5DDF8" />
                    <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="url(#paint22_linear_196_10779)" />
                    <path d="M25.6345 14.2063L22.2871 12.8203L24.2478 17.5537L25.6345 14.2063Z" fill="url(#paint23_linear_196_10779)" />
                    <path d="M22.2871 12.8203L17.5537 10.8596V17.5537L22.2871 12.8203Z" fill="url(#paint24_linear_196_10779)" />
                    <path d="M24.2478 17.5537L22.2871 12.8203L17.5537 17.5537H24.2478Z" fill="url(#paint25_linear_196_10779)" />
                    <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint26_linear_196_10779)" />
                    <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint27_linear_196_10779)" />
                    <path d="M25.6345 14.2063L27.0212 10.8596L25.6345 9.47217L22.2871 12.8203L25.6345 14.2063Z" fill="url(#paint28_linear_196_10779)" />
                    <path opacity="0.9" d="M14.2065 25.6352L17.5539 24.2485L12.8198 22.2878L14.2065 25.6352Z" fill="url(#paint29_linear_196_10779)" />
                    <path d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint30_linear_196_10779)" />
                    <path opacity="0.5" d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint31_linear_196_10779)" />
                    <path d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint32_linear_196_10779)" />
                    <path opacity="0.5" d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint33_linear_196_10779)" />
                    <path d="M20.9011 25.6352L22.2871 22.2878L17.5537 24.2485L20.9011 25.6352Z" fill="url(#paint34_linear_196_10779)" />
                    <path d="M25.6343 20.9011L28.981 19.5144V17.5536H24.2476L25.6343 20.9011Z" fill="url(#paint35_linear_196_10779)" />
                    <path d="M24.2476 17.5537H28.981V15.593L25.6343 14.2063L24.2476 17.5537Z" fill="url(#paint36_linear_196_10779)" />
                    <path d="M22.2871 22.2878L25.6345 20.9011L24.2478 17.5536L22.2871 22.2878Z" fill="url(#paint37_linear_196_10779)" />
                    <path d="M28.981 15.593V12.8203L27.021 10.8596L25.6343 14.2063L28.981 15.593Z" fill="url(#paint38_linear_196_10779)" />
                    <path opacity="0.9" d="M17.5537 24.2485L22.2871 22.2878L17.5537 17.5536V24.2485Z" fill="url(#paint39_linear_196_10779)" />
                    <path d="M25.6343 20.9011L27.021 24.2485L28.981 22.2878V19.5144L25.6343 20.9011Z" fill="url(#paint40_linear_196_10779)" />
                    <path d="M12.8198 22.2878L17.5539 24.2485V17.5536L12.8198 22.2878Z" fill="url(#paint41_linear_196_10779)" />
                    <path opacity="0.9" d="M12.8196 12.8203L10.8589 17.5537H17.5537L12.8196 12.8203Z" fill="url(#paint42_linear_196_10779)" />
                    <path d="M17.5537 17.5536L22.2871 22.2878L24.2478 17.5536H17.5537Z" fill="url(#paint43_linear_196_10779)" />
                    <path d="M17.5539 10.8596L12.8198 12.8203L17.5539 17.5537V10.8596Z" fill="url(#paint44_linear_196_10779)" />
                    <path d="M6.12549 22.2878V28.9812L8.08549 24.2485L6.12549 22.2878Z" fill="url(#paint45_linear_196_10779)" />
                    <path d="M9.47219 25.6352L6.12549 28.9812L10.8589 27.0205L9.47219 25.6352Z" fill="url(#paint46_linear_196_10779)" />
                    <path d="M8.08549 24.2485L6.12549 28.9812L9.47219 25.6352L8.08549 24.2485Z" fill="url(#paint47_linear_196_10779)" />
                    <path d="M10.8589 27.0204L6.12549 28.9811H12.8196L10.8589 27.0204Z" fill="url(#paint48_linear_196_10779)" />
                    <path d="M6.12549 28.9812L17.5537 33.7153L12.8196 28.9812H6.12549Z" fill="url(#paint49_linear_196_10779)" />
                    <path d="M1.39209 17.5536L6.12549 28.9811V22.2878L1.39209 17.5536Z" fill="url(#paint50_linear_196_10779)" />
                    <path d="M12.8198 28.9812L17.5539 33.7153L15.5925 28.9812H12.8198Z" fill="url(#paint51_linear_196_10779)" />
                    <path d="M6.12549 15.593L1.39209 17.5537H6.12549V15.593Z" fill="url(#paint52_linear_196_10779)" />
                    <path d="M6.12549 17.5536H1.39209L6.12549 19.5144V17.5536Z" fill="url(#paint53_linear_196_10779)" />
                    <path d="M6.12549 19.5144L1.39209 17.5536L6.12549 22.2878V19.5144Z" fill="url(#paint54_linear_196_10779)" />
                    <path d="M19.5144 28.9812L17.5537 33.7153L22.2871 28.9812H19.5144Z" fill="url(#paint55_linear_196_10779)" />
                    <path d="M25.6343 25.6352L28.981 28.9812L27.021 24.2485L25.6343 25.6352Z" fill="url(#paint56_linear_196_10779)" />
                    <path d="M27.021 24.2485L28.981 28.9812V22.2878L27.021 24.2485Z" fill="url(#paint57_linear_196_10779)" />
                    <path d="M24.2476 27.0205L28.981 28.9812L25.6343 25.6352L24.2476 27.0205Z" fill="url(#paint58_linear_196_10779)" />
                    <path d="M28.981 22.2878V28.9811L33.7151 17.5536L28.981 22.2878Z" fill="url(#paint59_linear_196_10779)" />
                    <path d="M17.5537 33.7153L28.9812 28.9812H22.2871L17.5537 33.7153Z" fill="url(#paint60_linear_196_10779)" />
                    <path d="M22.2871 28.9811H28.9812L24.2478 27.0204L22.2871 28.9811Z" fill="url(#paint61_linear_196_10779)" />
                    <path opacity="0.9" d="M6.12549 12.8203L1.39209 17.5537L6.12549 15.593V12.8203Z" fill="url(#paint62_linear_196_10779)" />
                    <path d="M17.5537 28.9812V33.7153L19.5144 28.9812H17.5537Z" fill="url(#paint63_linear_196_10779)" />
                    <path d="M15.5923 28.9812L17.5537 33.7153V28.9812H15.5923Z" fill="url(#paint64_linear_196_10779)" />
                    <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="#ABC8E0" />
                    <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="url(#paint65_linear_196_10779)" />
                    <path d="M28.981 12.8203V6.12616L27.021 10.8596L28.981 12.8203Z" fill="url(#paint66_linear_196_10779)" />
                    <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint67_linear_196_10779)" />
                    <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint68_linear_196_10779)" />
                    <path d="M27.021 10.8596L28.981 6.12616L25.6343 9.47216L27.021 10.8596Z" fill="url(#paint69_linear_196_10779)" />
                    <path opacity="0.9" d="M24.2478 8.08686L28.9812 6.12616H22.2871L24.2478 8.08686Z" fill="url(#paint70_linear_196_10779)" />
                    <path d="M28.981 22.2878L33.7151 17.5536L28.981 19.5144V22.2878Z" fill="url(#paint71_linear_196_10779)" />
                    <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint72_linear_196_10779)" />
                    <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint73_linear_196_10779)" />
                    <path d="M28.981 17.5537H33.7151L28.981 15.593V17.5537Z" fill="url(#paint74_linear_196_10779)" />
                    <path d="M28.981 15.593L33.7151 17.5537L28.981 12.8203V15.593Z" fill="url(#paint75_linear_196_10779)" />
                    <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint76_linear_196_10779)" />
                    <path d="M28.981 19.5144L33.7151 17.5536H28.981V19.5144Z" fill="url(#paint77_linear_196_10779)" />
                    <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint78_linear_196_10779)" />
                    <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint79_linear_196_10779)" />
                    <path d="M10.8589 8.08686L6.12549 6.12616L9.47219 9.47216L10.8589 8.08686Z" fill="url(#paint80_linear_196_10779)" />
                    <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint81_linear_196_10779)" />
                    <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint82_linear_196_10779)" />
                    <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="#C4DAF2" />
                    <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="url(#paint83_linear_196_10779)" />
                    <path d="M8.08549 10.8596L6.12549 6.12616V12.8203L8.08549 10.8596Z" fill="url(#paint84_linear_196_10779)" />
                    <path d="M17.5537 6.12616V1.39206L15.5923 6.12616H17.5537Z" fill="url(#paint85_linear_196_10779)" />
                    <path d="M19.5144 6.12616L17.5537 1.39206V6.12616H19.5144Z" fill="url(#paint86_linear_196_10779)" />
                    <path d="M15.5925 6.12616L17.5539 1.39206L12.8198 6.12616H15.5925Z" fill="url(#paint87_linear_196_10779)" />
                    <path d="M17.5537 1.39206L6.12549 6.12616H12.8196L17.5537 1.39206Z" fill="url(#paint88_linear_196_10779)" />
                    <g opacity="0.5">
                        <path d="M17.5537 17.5536L19.9211 23.2678L22.2871 22.2878L17.5537 17.5536Z" fill="url(#paint89_linear_196_10779)" />
                        <path d="M17.5537 17.5536L23.2678 19.9211L24.2478 17.5536H17.5537Z" fill="url(#paint90_linear_196_10779)" />
                        <path d="M17.5537 17.5537L23.2678 15.187L22.2871 12.8203L17.5537 17.5537Z" fill="url(#paint91_linear_196_10779)" />
                        <path d="M17.5537 17.5537L19.9211 11.8396L17.5537 10.8596V17.5537Z" fill="url(#paint92_linear_196_10779)" />
                        <path d="M17.5539 17.5537L15.1872 11.8396L12.8198 12.8203L17.5539 17.5537Z" fill="url(#paint93_linear_196_10779)" />
                        <path d="M17.5537 17.5537L11.8396 15.187L10.8589 17.5537H17.5537Z" fill="url(#paint94_linear_196_10779)" />
                        <path d="M17.5535 17.5536L11.8394 19.9211L12.8194 22.2878L17.5535 17.5536Z" fill="url(#paint95_linear_196_10779)" />
                        <path d="M17.5537 17.5536L15.187 23.2678L17.5537 24.2485V17.5536Z" fill="url(#paint96_linear_196_10779)" />
                    </g>
                    <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint97_linear_196_10779)" />
                    <g opacity="0.3">
                        <path d="M17.554 8.07285L14.7764 10.8497H20.3309L17.554 8.07285Z" fill="url(#paint98_linear_196_10779)" />
                        <path d="M24.2583 10.8497H20.3306L24.2583 14.7774V10.8497Z" fill="url(#paint99_linear_196_10779)" />
                        <path d="M10.8491 10.8497V14.7774L14.7761 10.8497H10.8491Z" fill="url(#paint100_linear_196_10779)" />
                        <path d="M17.554 27.0344L20.3309 24.2582H14.7764L17.554 27.0344Z" fill="url(#paint101_linear_196_10779)" />
                        <path d="M8.07227 17.5536L10.8492 20.3312V14.7774L8.07227 17.5536Z" fill="url(#paint102_linear_196_10779)" />
                        <path d="M10.8491 24.2582H14.7761L10.8491 20.3312V24.2582Z" fill="url(#paint103_linear_196_10779)" />
                        <path d="M24.2583 24.2582V20.3312L20.3306 24.2582H24.2583Z" fill="url(#paint104_linear_196_10779)" />
                        <path d="M24.2583 20.3312L27.0345 17.5536L24.2583 14.7774V20.3312Z" fill="url(#paint105_linear_196_10779)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M10.8525 10.8526V14.7782L14.7781 10.8526H10.8525Z" fill="url(#paint106_linear_196_10779)" />
                        <path d="M17.5538 8.07706L14.7783 10.8526H20.3293L17.5538 8.07706Z" fill="url(#paint107_linear_196_10779)" />
                        <path d="M8.07715 17.5537L10.8526 20.3292V14.7782L8.07715 17.5537Z" fill="url(#paint108_linear_196_10779)" />
                        <path d="M24.2554 24.2548V20.3292L20.3291 24.2548H24.2554Z" fill="url(#paint109_linear_196_10779)" />
                        <path d="M10.8525 24.2548H14.7781L10.8525 20.3292V24.2548Z" fill="url(#paint110_linear_196_10779)" />
                        <path d="M17.5538 27.0303L20.3293 24.2548H14.7783L17.5538 27.0303Z" fill="url(#paint111_linear_196_10779)" />
                        <path d="M27.0302 17.5537L24.2554 14.7782V20.3292L27.0302 17.5537Z" fill="url(#paint112_linear_196_10779)" />
                        <path d="M24.2554 14.7782V10.8526H20.3291L24.2554 14.7782Z" fill="url(#paint113_linear_196_10779)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M3.76318 11.8417L6.12918 17.5537L9.47518 9.47498L3.76318 11.8417Z" fill="url(#paint114_linear_196_10779)" />
                        <path d="M11.8418 3.76297L9.4751 9.47497L17.5538 6.12897L11.8418 3.76297Z" fill="url(#paint115_linear_196_10779)" />
                        <path d="M3.76318 23.2657L9.47518 25.6324L6.12918 17.5537L3.76318 23.2657Z" fill="url(#paint116_linear_196_10779)" />
                        <path d="M31.3448 23.2657L28.9781 17.5537L25.6328 25.6324L31.3448 23.2657Z" fill="url(#paint117_linear_196_10779)" />
                        <path d="M11.8418 31.3444L17.5538 28.9784L9.4751 25.6324L11.8418 31.3444Z" fill="url(#paint118_linear_196_10779)" />
                        <path d="M23.2664 31.3444L25.6324 25.6324L17.5537 28.9784L23.2664 31.3444Z" fill="url(#paint119_linear_196_10779)" />
                        <path d="M31.3448 11.8417L25.6328 9.47498L28.9781 17.5537L31.3448 11.8417Z" fill="url(#paint120_linear_196_10779)" />
                        <path d="M25.6324 9.47497L23.2664 3.76297L17.5537 6.12897L25.6324 9.47497Z" fill="url(#paint121_linear_196_10779)" />
                    </g>
                    <path opacity="0.3" d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint122_linear_196_10779)" />
                    <path opacity="0.1" d="M33.674 17.4543L28.9812 28.7838L17.553 33.5179L6.12479 28.7838L1.43269 17.4557L1.39209 17.5537L6.12549 28.9812L17.5537 33.7153L28.9812 28.9812L33.7153 17.5537L33.674 17.4543Z" fill="white" />
                    <path opacity="0.5" d="M1.43339 17.6531L6.12619 6.32356L17.5544 1.58946L28.9826 6.32356L33.6747 17.6517L33.7153 17.5537L28.9819 6.12616L17.5537 1.39206L6.12619 6.12616L1.39209 17.5537L1.43339 17.6531Z" fill="#A6C8ED" />
                    <g style={{ mixBlendMode: "multiply" }}>
                        <path d="M28.9816 6.12659L17.5537 1.39206L6.12514 6.12659L1.39209 17.5537L6.12514 28.9816L17.5537 33.7154L28.9816 28.9816L33.7154 17.5537L28.9816 6.12659Z" fill="url(#paint123_diamond_196_10779)" />
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_196_10779" x1="6.1252" y1="13.2262" x2="9.47241" y2="13.2262" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_196_10779" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_196_10779" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_196_10779" x1="6.27745" y1="19.873" x2="10.9939" y2="17.871" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_196_10779" x1="6.1252" y1="15.88" x2="10.8592" y2="15.88" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_196_10779" x1="6.1252" y1="19.2273" x2="10.8592" y2="19.2273" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint6_linear_196_10779" x1="14.7016" y1="10.0627" x2="18.3135" y2="7.032" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint7_linear_196_10779" x1="11.5729" y1="7.96082" x2="12.4416" y2="12.8872" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint8_linear_196_10779" x1="17.9886" y1="11.0262" x2="19.824" y2="6.24497" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint9_linear_196_10779" x1="19.5141" y1="7.79929" x2="24.2472" y2="7.79929" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint10_linear_196_10779" x1="11.161" y1="8.79771" x2="15.8767" y2="6.79603" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint11_linear_196_10779" x1="10.8592" y1="27.3082" x2="15.5923" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint12_linear_196_10779" x1="20.9008" y1="24.6542" x2="25.634" y2="24.6542" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint13_linear_196_10779" x1="19.2298" y1="28.3116" x2="23.9455" y2="26.3099" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint14_linear_196_10779" x1="6.1252" y1="21.8813" x2="9.47241" y2="21.8813" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint15_linear_196_10779" x1="16.7631" y1="27.8093" x2="20.615" y2="25.2111" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint16_linear_196_10779" x1="22.2869" y1="23.2679" x2="27.0209" y2="23.2679" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint17_linear_196_10779" x1="8.08557" y1="23.2679" x2="12.8197" y2="23.2679" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint18_linear_196_10779" x1="14.2063" y1="26.6148" x2="17.5534" y2="26.6148" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint19_linear_196_10779" x1="11.2499" y1="27.1708" x2="13.0853" y2="22.3896" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint20_linear_196_10779" x1="20.9008" y1="10.4536" x2="25.634" y2="10.4536" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint21_linear_196_10779" x1="11.6988" y1="19.9893" x2="17.3881" y2="17.2144" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint22_linear_196_10779" x1="21.2137" y1="9.42334" x2="19.7324" y2="12.1909" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint23_linear_196_10779" x1="22.2869" y1="15.1871" x2="25.6343" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint24_linear_196_10779" x1="17.5536" y1="14.2065" x2="22.2869" y2="14.2065" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint25_linear_196_10779" x1="17.5536" y1="15.1871" x2="24.2475" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint26_linear_196_10779" x1="14.5549" y1="13.4865" x2="15.8531" y2="10.1044" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint27_linear_196_10779" x1="15.6248" y1="9.65069" x2="15.0458" y2="12.5457" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint28_linear_196_10779" x1="23.9881" y1="13.5744" x2="25.5719" y2="9.44851" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint29_linear_196_10779" x1="12.82" y1="23.9613" x2="17.5539" y2="23.9613" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint30_linear_196_10779" x1="9.47238" y1="15.1871" x2="12.8197" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint31_linear_196_10779" x1="11.1499" y1="13.5571" x2="11.4714" y2="17.2228" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1898" stopColor="#E5EEFD" />
                            <stop offset="0.3903" stopColor="#D2E2FC" />
                            <stop offset="0.5937" stopColor="#C6DAFB" />
                            <stop offset="0.8022" stopColor="#C2D8FB" />
                            <stop offset="0.9293" stopColor="#B6D0EE" />
                            <stop offset="1" stopColor="#ADCBE5" />
                        </linearGradient>
                        <linearGradient id="paint32_linear_196_10779" x1="10.4464" y1="17.6264" x2="11.3151" y2="22.553" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint33_linear_196_10779" x1="12.0043" y1="19.8213" x2="9.52433" y2="21.3161" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1898" stopColor="#E5EEFD" />
                            <stop offset="0.3903" stopColor="#D2E2FC" />
                            <stop offset="0.5937" stopColor="#C6DAFB" />
                            <stop offset="0.8022" stopColor="#C2D8FB" />
                            <stop offset="0.9293" stopColor="#B6D0EE" />
                            <stop offset="1" stopColor="#ADCBE5" />
                        </linearGradient>
                        <linearGradient id="paint34_linear_196_10779" x1="18.0586" y1="25.4377" x2="22.7744" y2="23.436" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint35_linear_196_10779" x1="24.2472" y1="19.2273" x2="28.9811" y2="19.2273" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint36_linear_196_10779" x1="25.3801" y1="14.3471" x2="27.5861" y2="18.3269" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint37_linear_196_10779" x1="22.4939" y1="18.5256" x2="24.2392" y2="21.6742" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint38_linear_196_10779" x1="25.634" y1="13.2262" x2="28.9811" y2="13.2262" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint39_linear_196_10779" x1="17.5536" y1="20.9009" x2="22.2869" y2="20.9009" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint40_linear_196_10779" x1="26.3129" y1="22.5807" x2="29.6766" y2="21.1529" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint41_linear_196_10779" x1="12.82" y1="20.9009" x2="17.5539" y2="20.9009" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint42_linear_196_10779" x1="10.8592" y1="15.1871" x2="17.5536" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint43_linear_196_10779" x1="17.5536" y1="19.9207" x2="24.2475" y2="19.9207" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint44_linear_196_10779" x1="15.0906" y1="11.2936" x2="16.2354" y2="17.7861" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint45_linear_196_10779" x1="4.57363" y1="26.681" x2="8.11527" y2="24.2922" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint46_linear_196_10779" x1="6.1252" y1="27.3082" x2="10.8592" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint47_linear_196_10779" x1="6.1252" y1="26.6148" x2="9.47241" y2="26.6148" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint48_linear_196_10779" x1="6.63623" y1="30.1853" x2="12.3087" y2="27.7774" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint49_linear_196_10779" x1="6.1252" y1="31.3484" x2="17.5536" y2="31.3484" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint50_linear_196_10779" x1="1.39207" y1="23.2675" x2="6.1252" y2="23.2675" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1929" stopColor="#C9E0EB" />
                            <stop offset="0.5344" stopColor="#CADEEF" />
                            <stop offset="0.8146" stopColor="#D7E8FA" />
                            <stop offset="1" stopColor="#D7E8FA" />
                        </linearGradient>
                        <linearGradient id="paint51_linear_196_10779" x1="14.7108" y1="28.648" x2="15.6631" y2="34.0488" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint52_linear_196_10779" x1="1.84685" y1="18.4861" x2="6.44289" y2="16.2445" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint53_linear_196_10779" x1="2.58657" y1="19.3246" x2="5.83967" y2="17.1303" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint54_linear_196_10779" x1="2.78671" y1="16.7806" x2="5.90652" y2="22.4089" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint55_linear_196_10779" x1="16.8094" y1="32.1895" x2="22.4983" y2="29.4148" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint56_linear_196_10779" x1="27.0944" y1="27.3754" x2="28.591" y2="26.1196" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint57_linear_196_10779" x1="27.8628" y1="22.4848" x2="29.0075" y2="28.9767" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint58_linear_196_10779" x1="24.2472" y1="27.3082" x2="28.9811" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint59_linear_196_10779" x1="28.9811" y1="23.2675" x2="33.7152" y2="23.2675" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1929" stopColor="#C9E0EB" />
                            <stop offset="0.5344" stopColor="#CADEEF" />
                            <stop offset="0.8146" stopColor="#D7E8FA" />
                            <stop offset="1" stopColor="#D7E8FA" />
                        </linearGradient>
                        <linearGradient id="paint60_linear_196_10779" x1="18.5613" y1="34.9162" x2="27.5983" y2="27.3332" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint61_linear_196_10779" x1="22.2869" y1="28.0011" x2="28.9813" y2="28.0011" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint62_linear_196_10779" x1="1.39207" y1="15.1871" x2="6.1252" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint63_linear_196_10779" x1="17.5536" y1="31.3484" x2="19.5143" y2="31.3484" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint64_linear_196_10779" x1="15.9829" y1="31.8435" x2="18.314" y2="29.8875" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint65_linear_196_10779" x1="24.6535" y1="8.91961" x2="29.2501" y2="6.67769" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint66_linear_196_10779" x1="26.9912" y1="10.8155" x2="30.5328" y2="8.42666" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint67_linear_196_10779" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint68_linear_196_10779" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint69_linear_196_10779" x1="25.634" y1="8.49268" x2="28.9811" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint70_linear_196_10779" x1="22.2869" y1="7.1064" x2="28.9813" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint71_linear_196_10779" x1="28.2983" y1="21.4739" x2="33.4074" y2="17.1868" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint72_linear_196_10779" x1="-0.301693" y1="13.5634" x2="7.81896" y2="10.1164" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint73_linear_196_10779" x1="1.39207" y1="11.8399" x2="6.1252" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint74_linear_196_10779" x1="29.4764" y1="18.144" x2="32.2545" y2="15.8128" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint75_linear_196_10779" x1="29.533" y1="16.4114" x2="31.8779" y2="14.8297" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint76_linear_196_10779" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint77_linear_196_10779" x1="28.9811" y1="18.534" x2="33.7152" y2="18.534" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint78_linear_196_10779" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint79_linear_196_10779" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint80_linear_196_10779" x1="6.1252" y1="7.79929" x2="10.8592" y2="7.79929" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint81_linear_196_10779" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint82_linear_196_10779" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint83_linear_196_10779" x1="19.7778" y1="2.95104" x2="20.3705" y2="6.31258" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint84_linear_196_10779" x1="4.57346" y1="10.52" x2="7.67693" y2="8.42666" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint85_linear_196_10779" x1="15.0366" y1="4.98668" x2="18.4863" y2="3.30416" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint86_linear_196_10779" x1="17.5536" y1="3.75904" x2="19.5143" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint87_linear_196_10779" x1="12.82" y1="3.75904" x2="17.5539" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint88_linear_196_10779" x1="11.6069" y1="2.44053" x2="12.2738" y2="6.22239" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint89_linear_196_10779" x1="17.5536" y1="20.4108" x2="22.2869" y2="20.4108" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint90_linear_196_10779" x1="19.5741" y1="19.5741" x2="22.921" y2="16.2271" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint91_linear_196_10779" x1="20.4109" y1="17.5537" x2="20.4109" y2="12.8205" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint92_linear_196_10779" x1="19.574" y1="15.5333" x2="16.2268" y2="12.1861" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint93_linear_196_10779" x1="17.5539" y1="14.6965" x2="12.82" y2="14.6965" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint94_linear_196_10779" x1="15.5333" y1="15.5333" x2="12.1861" y2="18.8806" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint95_linear_196_10779" x1="14.6963" y1="17.5536" x2="14.6963" y2="22.2877" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint96_linear_196_10779" x1="15.5333" y1="19.5739" x2="18.8806" y2="22.9212" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint97_linear_196_10779" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint98_linear_196_10779" x1="14.8959" y1="11.1373" x2="19.6364" y2="9.17365" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint99_linear_196_10779" x1="21.3122" y1="13.22" x2="24.6647" y2="11.8313" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint100_linear_196_10779" x1="10.1551" y1="13.1009" x2="14.8956" y2="11.1373" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint101_linear_196_10779" x1="15.4708" y1="25.9338" x2="20.2113" y2="23.9702" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint102_linear_196_10779" x1="8.47902" y1="18.536" x2="11.8313" y2="17.1474" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint103_linear_196_10779" x1="10.4428" y1="23.2763" x2="13.7946" y2="21.8879" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint104_linear_196_10779" x1="20.2113" y1="23.9707" x2="24.9521" y2="22.007" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint105_linear_196_10779" x1="23.2762" y1="17.9608" x2="26.628" y2="16.5724" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint106_linear_196_10779" x1="11.1397" y1="14.8972" x2="13.1027" y2="10.1582" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint107_linear_196_10779" x1="17.1474" y1="11.8335" x2="18.5351" y2="8.48339" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint108_linear_196_10779" x1="9.17724" y1="19.6353" x2="11.1398" y2="14.8972" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint109_linear_196_10779" x1="22.0047" y1="24.9491" x2="23.9677" y2="20.2101" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint110_linear_196_10779" x1="11.8337" y1="24.6616" x2="13.2218" y2="21.3107" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint111_linear_196_10779" x1="16.5726" y1="26.6242" x2="17.9604" y2="23.2738" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint112_linear_196_10779" x1="23.9676" y1="20.2101" x2="25.9302" y2="15.472" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint113_linear_196_10779" x1="21.8856" y1="13.7967" x2="23.2736" y2="10.4457" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint114_linear_196_10779" x1="6.61929" y1="17.5537" x2="6.61929" y2="9.47524" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint115_linear_196_10779" x1="13.5146" y1="9.47523" x2="13.5146" y2="3.76307" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint116_linear_196_10779" x1="6.61929" y1="25.6321" x2="6.61929" y2="17.5537" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint117_linear_196_10779" x1="28.4887" y1="25.6321" x2="28.4887" y2="17.5537" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint118_linear_196_10779" x1="13.5146" y1="31.3444" x2="13.5146" y2="25.6321" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint119_linear_196_10779" x1="21.5929" y1="31.3444" x2="21.5929" y2="25.6321" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint120_linear_196_10779" x1="28.4887" y1="17.5537" x2="28.4887" y2="9.47524" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint121_linear_196_10779" x1="21.5929" y1="9.47523" x2="21.5929" y2="3.76307" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint122_linear_196_10779" x1="9.47238" y1="24.6542" x2="14.2065" y2="24.6542" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#ADD3E5" />
                            <stop offset="0.0707" stopColor="#B6D9EE" />
                            <stop offset="0.1978" stopColor="#C2E1FB" />
                            <stop offset="0.4063" stopColor="#C6E3FB" />
                            <stop offset="0.6097" stopColor="#D2E9FC" />
                            <stop offset="0.8102" stopColor="#E5F2FD" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <radialGradient id="paint123_diamond_196_10779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.4177 12.2301) rotate(81.6955) scale(21.7129 21.2612)">
                            <stop stopColor="#6C6D1C" />
                            <stop offset="1" stopColor="#545512" />
                        </radialGradient>
                    </defs>
                </svg>
            )
        } else if (color === "green") {
            return (
                <svg width="28" height="28" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="#BCD8F4" />
                    <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="url(#paint0_linear_196_9951)" />
                    <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint1_linear_196_9951)" />
                    <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint2_linear_196_9951)" />
                    <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="#C4DAF2" />
                    <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint3_linear_196_9951)" />
                    <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="url(#paint4_linear_196_9951)" />
                    <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint5_linear_196_9951)" />
                    <path d="M17.5535 10.8596V6.12616H15.5921L14.2061 9.47216L17.5535 10.8596Z" fill="url(#paint6_linear_196_9951)" />
                    <path d="M14.2063 9.47215L10.8589 8.08685L9.47217 9.47215L12.8196 12.8203L14.2063 9.47215Z" fill="url(#paint7_linear_196_9951)" />
                    <path d="M20.9011 9.47216L19.5144 6.12616H17.5537V10.8596L20.9011 9.47216Z" fill="url(#paint8_linear_196_9951)" />
                    <path d="M24.2476 8.08686L22.2869 6.12616H19.5142L20.9009 9.47216L24.2476 8.08686Z" fill="url(#paint9_linear_196_9951)" />
                    <path d="M15.5923 6.12616H12.8196L10.8589 8.08686L14.2063 9.47216L15.5923 6.12616Z" fill="url(#paint10_linear_196_9951)" />
                    <path d="M10.8589 27.0205L12.8196 28.9812H15.5923L14.2063 25.6352L10.8589 27.0205Z" fill="url(#paint11_linear_196_9951)" />
                    <path d="M20.9009 25.6352L24.2476 27.0204L25.6343 25.6352L22.2869 22.2878L20.9009 25.6352Z" fill="url(#paint12_linear_196_9951)" />
                    <path d="M19.5142 28.9812H22.2869L24.2476 27.0205L20.9009 25.6352L19.5142 28.9812Z" fill="url(#paint13_linear_196_9951)" />
                    <path d="M6.12549 19.5144V22.2878L8.08549 24.2485L9.47219 20.9011L6.12549 19.5144Z" fill="url(#paint14_linear_196_9951)" />
                    <path d="M17.5537 24.2485V28.9812H19.5144L20.9011 25.6352L17.5537 24.2485Z" fill="url(#paint15_linear_196_9951)" />
                    <path d="M22.2871 22.2878L25.6345 25.6352L27.0212 24.2485L25.6345 20.9011L22.2871 22.2878Z" fill="url(#paint16_linear_196_9951)" />
                    <path d="M9.47215 20.9011L8.08545 24.2485L9.47215 25.6352L12.8195 22.2878L9.47215 20.9011Z" fill="url(#paint17_linear_196_9951)" />
                    <path d="M14.2061 25.6352L15.5921 28.9812H17.5535V24.2485L14.2061 25.6352Z" fill="url(#paint18_linear_196_9951)" />
                    <path d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint19_linear_196_9951)" />
                    <path d="M22.2869 12.8203L25.6343 9.47215L24.2476 8.08685L20.9009 9.47215L22.2869 12.8203Z" fill="url(#paint20_linear_196_9951)" />
                    <path d="M10.8589 17.5536L12.8196 22.2878L17.5537 17.5536H10.8589Z" fill="url(#paint21_linear_196_9951)" />
                    <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="#C5DDF8" />
                    <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="url(#paint22_linear_196_9951)" />
                    <path d="M25.6345 14.2063L22.2871 12.8203L24.2478 17.5537L25.6345 14.2063Z" fill="url(#paint23_linear_196_9951)" />
                    <path d="M22.2871 12.8203L17.5537 10.8596V17.5537L22.2871 12.8203Z" fill="url(#paint24_linear_196_9951)" />
                    <path d="M24.2478 17.5537L22.2871 12.8203L17.5537 17.5537H24.2478Z" fill="url(#paint25_linear_196_9951)" />
                    <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint26_linear_196_9951)" />
                    <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint27_linear_196_9951)" />
                    <path d="M25.6345 14.2063L27.0212 10.8596L25.6345 9.47217L22.2871 12.8203L25.6345 14.2063Z" fill="url(#paint28_linear_196_9951)" />
                    <path opacity="0.9" d="M14.2065 25.6352L17.5539 24.2485L12.8198 22.2878L14.2065 25.6352Z" fill="url(#paint29_linear_196_9951)" />
                    <path d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint30_linear_196_9951)" />
                    <path opacity="0.5" d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint31_linear_196_9951)" />
                    <path d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint32_linear_196_9951)" />
                    <path opacity="0.5" d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint33_linear_196_9951)" />
                    <path d="M20.9011 25.6352L22.2871 22.2878L17.5537 24.2485L20.9011 25.6352Z" fill="url(#paint34_linear_196_9951)" />
                    <path d="M25.6343 20.9011L28.981 19.5144V17.5536H24.2476L25.6343 20.9011Z" fill="url(#paint35_linear_196_9951)" />
                    <path d="M24.2476 17.5537H28.981V15.593L25.6343 14.2063L24.2476 17.5537Z" fill="url(#paint36_linear_196_9951)" />
                    <path d="M22.2871 22.2878L25.6345 20.9011L24.2478 17.5536L22.2871 22.2878Z" fill="url(#paint37_linear_196_9951)" />
                    <path d="M28.981 15.593V12.8203L27.021 10.8596L25.6343 14.2063L28.981 15.593Z" fill="url(#paint38_linear_196_9951)" />
                    <path opacity="0.9" d="M17.5537 24.2485L22.2871 22.2878L17.5537 17.5536V24.2485Z" fill="url(#paint39_linear_196_9951)" />
                    <path d="M25.6343 20.9011L27.021 24.2485L28.981 22.2878V19.5144L25.6343 20.9011Z" fill="url(#paint40_linear_196_9951)" />
                    <path d="M12.8198 22.2878L17.5539 24.2485V17.5536L12.8198 22.2878Z" fill="url(#paint41_linear_196_9951)" />
                    <path opacity="0.9" d="M12.8196 12.8203L10.8589 17.5537H17.5537L12.8196 12.8203Z" fill="url(#paint42_linear_196_9951)" />
                    <path d="M17.5537 17.5536L22.2871 22.2878L24.2478 17.5536H17.5537Z" fill="url(#paint43_linear_196_9951)" />
                    <path d="M17.5539 10.8596L12.8198 12.8203L17.5539 17.5537V10.8596Z" fill="url(#paint44_linear_196_9951)" />
                    <path d="M6.12549 22.2878V28.9812L8.08549 24.2485L6.12549 22.2878Z" fill="url(#paint45_linear_196_9951)" />
                    <path d="M9.47219 25.6352L6.12549 28.9812L10.8589 27.0205L9.47219 25.6352Z" fill="url(#paint46_linear_196_9951)" />
                    <path d="M8.08549 24.2485L6.12549 28.9812L9.47219 25.6352L8.08549 24.2485Z" fill="url(#paint47_linear_196_9951)" />
                    <path d="M10.8589 27.0204L6.12549 28.9811H12.8196L10.8589 27.0204Z" fill="url(#paint48_linear_196_9951)" />
                    <path d="M6.12549 28.9812L17.5537 33.7153L12.8196 28.9812H6.12549Z" fill="url(#paint49_linear_196_9951)" />
                    <path d="M1.39209 17.5536L6.12549 28.9811V22.2878L1.39209 17.5536Z" fill="url(#paint50_linear_196_9951)" />
                    <path d="M12.8198 28.9812L17.5539 33.7153L15.5925 28.9812H12.8198Z" fill="url(#paint51_linear_196_9951)" />
                    <path d="M6.12549 15.593L1.39209 17.5537H6.12549V15.593Z" fill="url(#paint52_linear_196_9951)" />
                    <path d="M6.12549 17.5536H1.39209L6.12549 19.5144V17.5536Z" fill="url(#paint53_linear_196_9951)" />
                    <path d="M6.12549 19.5144L1.39209 17.5536L6.12549 22.2878V19.5144Z" fill="url(#paint54_linear_196_9951)" />
                    <path d="M19.5144 28.9812L17.5537 33.7153L22.2871 28.9812H19.5144Z" fill="url(#paint55_linear_196_9951)" />
                    <path d="M25.6343 25.6352L28.981 28.9812L27.021 24.2485L25.6343 25.6352Z" fill="url(#paint56_linear_196_9951)" />
                    <path d="M27.021 24.2485L28.981 28.9812V22.2878L27.021 24.2485Z" fill="url(#paint57_linear_196_9951)" />
                    <path d="M24.2476 27.0205L28.981 28.9812L25.6343 25.6352L24.2476 27.0205Z" fill="url(#paint58_linear_196_9951)" />
                    <path d="M28.981 22.2878V28.9811L33.7151 17.5536L28.981 22.2878Z" fill="url(#paint59_linear_196_9951)" />
                    <path d="M17.5537 33.7153L28.9812 28.9812H22.2871L17.5537 33.7153Z" fill="url(#paint60_linear_196_9951)" />
                    <path d="M22.2871 28.9811H28.9812L24.2478 27.0204L22.2871 28.9811Z" fill="url(#paint61_linear_196_9951)" />
                    <path opacity="0.9" d="M6.12549 12.8203L1.39209 17.5537L6.12549 15.593V12.8203Z" fill="url(#paint62_linear_196_9951)" />
                    <path d="M17.5537 28.9812V33.7153L19.5144 28.9812H17.5537Z" fill="url(#paint63_linear_196_9951)" />
                    <path d="M15.5923 28.9812L17.5537 33.7153V28.9812H15.5923Z" fill="url(#paint64_linear_196_9951)" />
                    <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="#ABC8E0" />
                    <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="url(#paint65_linear_196_9951)" />
                    <path d="M28.981 12.8203V6.12616L27.021 10.8596L28.981 12.8203Z" fill="url(#paint66_linear_196_9951)" />
                    <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint67_linear_196_9951)" />
                    <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint68_linear_196_9951)" />
                    <path d="M27.021 10.8596L28.981 6.12616L25.6343 9.47216L27.021 10.8596Z" fill="url(#paint69_linear_196_9951)" />
                    <path opacity="0.9" d="M24.2478 8.08686L28.9812 6.12616H22.2871L24.2478 8.08686Z" fill="url(#paint70_linear_196_9951)" />
                    <path d="M28.981 22.2878L33.7151 17.5536L28.981 19.5144V22.2878Z" fill="url(#paint71_linear_196_9951)" />
                    <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint72_linear_196_9951)" />
                    <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint73_linear_196_9951)" />
                    <path d="M28.981 17.5537H33.7151L28.981 15.593V17.5537Z" fill="url(#paint74_linear_196_9951)" />
                    <path d="M28.981 15.593L33.7151 17.5537L28.981 12.8203V15.593Z" fill="url(#paint75_linear_196_9951)" />
                    <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint76_linear_196_9951)" />
                    <path d="M28.981 19.5144L33.7151 17.5536H28.981V19.5144Z" fill="url(#paint77_linear_196_9951)" />
                    <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint78_linear_196_9951)" />
                    <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint79_linear_196_9951)" />
                    <path d="M10.8589 8.08686L6.12549 6.12616L9.47219 9.47216L10.8589 8.08686Z" fill="url(#paint80_linear_196_9951)" />
                    <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint81_linear_196_9951)" />
                    <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint82_linear_196_9951)" />
                    <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="#C4DAF2" />
                    <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="url(#paint83_linear_196_9951)" />
                    <path d="M8.08549 10.8596L6.12549 6.12616V12.8203L8.08549 10.8596Z" fill="url(#paint84_linear_196_9951)" />
                    <path d="M17.5537 6.12616V1.39206L15.5923 6.12616H17.5537Z" fill="url(#paint85_linear_196_9951)" />
                    <path d="M19.5144 6.12616L17.5537 1.39206V6.12616H19.5144Z" fill="url(#paint86_linear_196_9951)" />
                    <path d="M15.5925 6.12616L17.5539 1.39206L12.8198 6.12616H15.5925Z" fill="url(#paint87_linear_196_9951)" />
                    <path d="M17.5537 1.39206L6.12549 6.12616H12.8196L17.5537 1.39206Z" fill="url(#paint88_linear_196_9951)" />
                    <g opacity="0.5">
                        <path d="M17.5537 17.5536L19.9211 23.2678L22.2871 22.2878L17.5537 17.5536Z" fill="url(#paint89_linear_196_9951)" />
                        <path d="M17.5537 17.5536L23.2678 19.9211L24.2478 17.5536H17.5537Z" fill="url(#paint90_linear_196_9951)" />
                        <path d="M17.5537 17.5537L23.2678 15.187L22.2871 12.8203L17.5537 17.5537Z" fill="url(#paint91_linear_196_9951)" />
                        <path d="M17.5537 17.5537L19.9211 11.8396L17.5537 10.8596V17.5537Z" fill="url(#paint92_linear_196_9951)" />
                        <path d="M17.5539 17.5537L15.1872 11.8396L12.8198 12.8203L17.5539 17.5537Z" fill="url(#paint93_linear_196_9951)" />
                        <path d="M17.5537 17.5537L11.8396 15.187L10.8589 17.5537H17.5537Z" fill="url(#paint94_linear_196_9951)" />
                        <path d="M17.5535 17.5536L11.8394 19.9211L12.8194 22.2878L17.5535 17.5536Z" fill="url(#paint95_linear_196_9951)" />
                        <path d="M17.5537 17.5536L15.187 23.2678L17.5537 24.2485V17.5536Z" fill="url(#paint96_linear_196_9951)" />
                    </g>
                    <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint97_linear_196_9951)" />
                    <g opacity="0.3">
                        <path d="M17.554 8.07285L14.7764 10.8497H20.3309L17.554 8.07285Z" fill="url(#paint98_linear_196_9951)" />
                        <path d="M24.2583 10.8497H20.3306L24.2583 14.7774V10.8497Z" fill="url(#paint99_linear_196_9951)" />
                        <path d="M10.8491 10.8497V14.7774L14.7761 10.8497H10.8491Z" fill="url(#paint100_linear_196_9951)" />
                        <path d="M17.554 27.0344L20.3309 24.2582H14.7764L17.554 27.0344Z" fill="url(#paint101_linear_196_9951)" />
                        <path d="M8.07227 17.5536L10.8492 20.3312V14.7774L8.07227 17.5536Z" fill="url(#paint102_linear_196_9951)" />
                        <path d="M10.8491 24.2582H14.7761L10.8491 20.3312V24.2582Z" fill="url(#paint103_linear_196_9951)" />
                        <path d="M24.2583 24.2582V20.3312L20.3306 24.2582H24.2583Z" fill="url(#paint104_linear_196_9951)" />
                        <path d="M24.2583 20.3312L27.0345 17.5536L24.2583 14.7774V20.3312Z" fill="url(#paint105_linear_196_9951)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M10.8525 10.8526V14.7782L14.7781 10.8526H10.8525Z" fill="url(#paint106_linear_196_9951)" />
                        <path d="M17.5538 8.07706L14.7783 10.8526H20.3293L17.5538 8.07706Z" fill="url(#paint107_linear_196_9951)" />
                        <path d="M8.07715 17.5537L10.8526 20.3292V14.7782L8.07715 17.5537Z" fill="url(#paint108_linear_196_9951)" />
                        <path d="M24.2554 24.2548V20.3292L20.3291 24.2548H24.2554Z" fill="url(#paint109_linear_196_9951)" />
                        <path d="M10.8525 24.2548H14.7781L10.8525 20.3292V24.2548Z" fill="url(#paint110_linear_196_9951)" />
                        <path d="M17.5538 27.0303L20.3293 24.2548H14.7783L17.5538 27.0303Z" fill="url(#paint111_linear_196_9951)" />
                        <path d="M27.0302 17.5537L24.2554 14.7782V20.3292L27.0302 17.5537Z" fill="url(#paint112_linear_196_9951)" />
                        <path d="M24.2554 14.7782V10.8526H20.3291L24.2554 14.7782Z" fill="url(#paint113_linear_196_9951)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M3.76318 11.8417L6.12918 17.5537L9.47518 9.47498L3.76318 11.8417Z" fill="url(#paint114_linear_196_9951)" />
                        <path d="M11.8418 3.76297L9.4751 9.47497L17.5538 6.12897L11.8418 3.76297Z" fill="url(#paint115_linear_196_9951)" />
                        <path d="M3.76318 23.2657L9.47518 25.6324L6.12918 17.5537L3.76318 23.2657Z" fill="url(#paint116_linear_196_9951)" />
                        <path d="M31.3448 23.2657L28.9781 17.5537L25.6328 25.6324L31.3448 23.2657Z" fill="url(#paint117_linear_196_9951)" />
                        <path d="M11.8418 31.3444L17.5538 28.9784L9.4751 25.6324L11.8418 31.3444Z" fill="url(#paint118_linear_196_9951)" />
                        <path d="M23.2664 31.3444L25.6324 25.6324L17.5537 28.9784L23.2664 31.3444Z" fill="url(#paint119_linear_196_9951)" />
                        <path d="M31.3448 11.8417L25.6328 9.47498L28.9781 17.5537L31.3448 11.8417Z" fill="url(#paint120_linear_196_9951)" />
                        <path d="M25.6324 9.47497L23.2664 3.76297L17.5537 6.12897L25.6324 9.47497Z" fill="url(#paint121_linear_196_9951)" />
                    </g>
                    <path opacity="0.3" d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint122_linear_196_9951)" />
                    <path opacity="0.1" d="M33.674 17.4543L28.9812 28.7838L17.553 33.5179L6.12479 28.7838L1.43269 17.4557L1.39209 17.5537L6.12549 28.9812L17.5537 33.7153L28.9812 28.9812L33.7153 17.5537L33.674 17.4543Z" fill="white" />
                    <path opacity="0.5" d="M1.43339 17.6531L6.12619 6.32356L17.5544 1.58946L28.9826 6.32356L33.6747 17.6517L33.7153 17.5537L28.9819 6.12616L17.5537 1.39206L6.12619 6.12616L1.39209 17.5537L1.43339 17.6531Z" fill="#A6C8ED" />
                    <g style={{ mixBlendMode: "multiply" }}>
                        <path d="M28.9816 6.12659L17.5537 1.39206L6.12514 6.12659L1.39209 17.5537L6.12514 28.9816L17.5537 33.7154L28.9816 28.9816L33.7154 17.5537L28.9816 6.12659Z" fill="url(#paint123_diamond_196_9951)" />
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_196_9951" x1="6.1252" y1="13.2262" x2="9.47241" y2="13.2262" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_196_9951" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_196_9951" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_196_9951" x1="6.27745" y1="19.873" x2="10.9939" y2="17.871" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_196_9951" x1="6.1252" y1="15.88" x2="10.8592" y2="15.88" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_196_9951" x1="6.1252" y1="19.2273" x2="10.8592" y2="19.2273" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint6_linear_196_9951" x1="14.7016" y1="10.0627" x2="18.3135" y2="7.032" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint7_linear_196_9951" x1="11.5729" y1="7.96082" x2="12.4416" y2="12.8872" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint8_linear_196_9951" x1="17.9886" y1="11.0262" x2="19.824" y2="6.24497" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint9_linear_196_9951" x1="19.5141" y1="7.79929" x2="24.2472" y2="7.79929" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint10_linear_196_9951" x1="11.161" y1="8.79771" x2="15.8767" y2="6.79603" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint11_linear_196_9951" x1="10.8592" y1="27.3082" x2="15.5923" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint12_linear_196_9951" x1="20.9008" y1="24.6542" x2="25.634" y2="24.6542" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint13_linear_196_9951" x1="19.2298" y1="28.3116" x2="23.9455" y2="26.3099" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint14_linear_196_9951" x1="6.1252" y1="21.8813" x2="9.47241" y2="21.8813" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint15_linear_196_9951" x1="16.7631" y1="27.8093" x2="20.615" y2="25.2111" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint16_linear_196_9951" x1="22.2869" y1="23.2679" x2="27.0209" y2="23.2679" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint17_linear_196_9951" x1="8.08557" y1="23.2679" x2="12.8197" y2="23.2679" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint18_linear_196_9951" x1="14.2063" y1="26.6148" x2="17.5534" y2="26.6148" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint19_linear_196_9951" x1="11.2499" y1="27.1708" x2="13.0853" y2="22.3896" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint20_linear_196_9951" x1="20.9008" y1="10.4536" x2="25.634" y2="10.4536" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint21_linear_196_9951" x1="11.6988" y1="19.9893" x2="17.3881" y2="17.2144" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint22_linear_196_9951" x1="21.2137" y1="9.42334" x2="19.7324" y2="12.1909" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint23_linear_196_9951" x1="22.2869" y1="15.1871" x2="25.6343" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint24_linear_196_9951" x1="17.5536" y1="14.2065" x2="22.2869" y2="14.2065" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint25_linear_196_9951" x1="17.5536" y1="15.1871" x2="24.2475" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint26_linear_196_9951" x1="14.5549" y1="13.4865" x2="15.8531" y2="10.1044" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint27_linear_196_9951" x1="15.6248" y1="9.65069" x2="15.0458" y2="12.5457" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint28_linear_196_9951" x1="23.9881" y1="13.5744" x2="25.5719" y2="9.44851" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint29_linear_196_9951" x1="12.82" y1="23.9613" x2="17.5539" y2="23.9613" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint30_linear_196_9951" x1="9.47238" y1="15.1871" x2="12.8197" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint31_linear_196_9951" x1="11.1499" y1="13.5571" x2="11.4714" y2="17.2228" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1898" stopColor="#E5EEFD" />
                            <stop offset="0.3903" stopColor="#D2E2FC" />
                            <stop offset="0.5937" stopColor="#C6DAFB" />
                            <stop offset="0.8022" stopColor="#C2D8FB" />
                            <stop offset="0.9293" stopColor="#B6D0EE" />
                            <stop offset="1" stopColor="#ADCBE5" />
                        </linearGradient>
                        <linearGradient id="paint32_linear_196_9951" x1="10.4464" y1="17.6264" x2="11.3151" y2="22.553" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint33_linear_196_9951" x1="12.0043" y1="19.8213" x2="9.52433" y2="21.3161" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1898" stopColor="#E5EEFD" />
                            <stop offset="0.3903" stopColor="#D2E2FC" />
                            <stop offset="0.5937" stopColor="#C6DAFB" />
                            <stop offset="0.8022" stopColor="#C2D8FB" />
                            <stop offset="0.9293" stopColor="#B6D0EE" />
                            <stop offset="1" stopColor="#ADCBE5" />
                        </linearGradient>
                        <linearGradient id="paint34_linear_196_9951" x1="18.0586" y1="25.4377" x2="22.7744" y2="23.436" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint35_linear_196_9951" x1="24.2472" y1="19.2273" x2="28.9811" y2="19.2273" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint36_linear_196_9951" x1="25.3801" y1="14.3471" x2="27.5861" y2="18.3269" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint37_linear_196_9951" x1="22.4939" y1="18.5256" x2="24.2392" y2="21.6742" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint38_linear_196_9951" x1="25.634" y1="13.2262" x2="28.9811" y2="13.2262" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint39_linear_196_9951" x1="17.5536" y1="20.9009" x2="22.2869" y2="20.9009" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint40_linear_196_9951" x1="26.3129" y1="22.5807" x2="29.6766" y2="21.1529" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint41_linear_196_9951" x1="12.82" y1="20.9009" x2="17.5539" y2="20.9009" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint42_linear_196_9951" x1="10.8592" y1="15.1871" x2="17.5536" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint43_linear_196_9951" x1="17.5536" y1="19.9207" x2="24.2475" y2="19.9207" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint44_linear_196_9951" x1="15.0906" y1="11.2936" x2="16.2354" y2="17.7861" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint45_linear_196_9951" x1="4.57363" y1="26.681" x2="8.11527" y2="24.2922" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint46_linear_196_9951" x1="6.1252" y1="27.3082" x2="10.8592" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint47_linear_196_9951" x1="6.1252" y1="26.6148" x2="9.47241" y2="26.6148" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint48_linear_196_9951" x1="6.63623" y1="30.1853" x2="12.3087" y2="27.7774" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint49_linear_196_9951" x1="6.1252" y1="31.3484" x2="17.5536" y2="31.3484" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint50_linear_196_9951" x1="1.39207" y1="23.2675" x2="6.1252" y2="23.2675" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1929" stopColor="#C9E0EB" />
                            <stop offset="0.5344" stopColor="#CADEEF" />
                            <stop offset="0.8146" stopColor="#D7E8FA" />
                            <stop offset="1" stopColor="#D7E8FA" />
                        </linearGradient>
                        <linearGradient id="paint51_linear_196_9951" x1="14.7108" y1="28.648" x2="15.6631" y2="34.0488" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint52_linear_196_9951" x1="1.84685" y1="18.4861" x2="6.44289" y2="16.2445" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint53_linear_196_9951" x1="2.58657" y1="19.3246" x2="5.83967" y2="17.1303" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint54_linear_196_9951" x1="2.78671" y1="16.7806" x2="5.90652" y2="22.4089" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint55_linear_196_9951" x1="16.8094" y1="32.1895" x2="22.4983" y2="29.4148" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint56_linear_196_9951" x1="27.0944" y1="27.3754" x2="28.591" y2="26.1196" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint57_linear_196_9951" x1="27.8628" y1="22.4848" x2="29.0075" y2="28.9767" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint58_linear_196_9951" x1="24.2472" y1="27.3082" x2="28.9811" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint59_linear_196_9951" x1="28.9811" y1="23.2675" x2="33.7152" y2="23.2675" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1929" stopColor="#C9E0EB" />
                            <stop offset="0.5344" stopColor="#CADEEF" />
                            <stop offset="0.8146" stopColor="#D7E8FA" />
                            <stop offset="1" stopColor="#D7E8FA" />
                        </linearGradient>
                        <linearGradient id="paint60_linear_196_9951" x1="18.5613" y1="34.9162" x2="27.5983" y2="27.3332" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint61_linear_196_9951" x1="22.2869" y1="28.0011" x2="28.9813" y2="28.0011" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint62_linear_196_9951" x1="1.39207" y1="15.1871" x2="6.1252" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint63_linear_196_9951" x1="17.5536" y1="31.3484" x2="19.5143" y2="31.3484" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint64_linear_196_9951" x1="15.9829" y1="31.8435" x2="18.314" y2="29.8875" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint65_linear_196_9951" x1="24.6535" y1="8.91961" x2="29.2501" y2="6.67769" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint66_linear_196_9951" x1="26.9912" y1="10.8155" x2="30.5328" y2="8.42666" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint67_linear_196_9951" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint68_linear_196_9951" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint69_linear_196_9951" x1="25.634" y1="8.49268" x2="28.9811" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint70_linear_196_9951" x1="22.2869" y1="7.1064" x2="28.9813" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint71_linear_196_9951" x1="28.2983" y1="21.4739" x2="33.4074" y2="17.1868" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint72_linear_196_9951" x1="-0.301693" y1="13.5634" x2="7.81896" y2="10.1164" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint73_linear_196_9951" x1="1.39207" y1="11.8399" x2="6.1252" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint74_linear_196_9951" x1="29.4764" y1="18.144" x2="32.2545" y2="15.8128" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint75_linear_196_9951" x1="29.533" y1="16.4114" x2="31.8779" y2="14.8297" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint76_linear_196_9951" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint77_linear_196_9951" x1="28.9811" y1="18.534" x2="33.7152" y2="18.534" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint78_linear_196_9951" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint79_linear_196_9951" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint80_linear_196_9951" x1="6.1252" y1="7.79929" x2="10.8592" y2="7.79929" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint81_linear_196_9951" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint82_linear_196_9951" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint83_linear_196_9951" x1="19.7778" y1="2.95104" x2="20.3705" y2="6.31258" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint84_linear_196_9951" x1="4.57346" y1="10.52" x2="7.67693" y2="8.42666" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint85_linear_196_9951" x1="15.0366" y1="4.98668" x2="18.4863" y2="3.30416" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint86_linear_196_9951" x1="17.5536" y1="3.75904" x2="19.5143" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint87_linear_196_9951" x1="12.82" y1="3.75904" x2="17.5539" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint88_linear_196_9951" x1="11.6069" y1="2.44053" x2="12.2738" y2="6.22239" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint89_linear_196_9951" x1="17.5536" y1="20.4108" x2="22.2869" y2="20.4108" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint90_linear_196_9951" x1="19.5741" y1="19.5741" x2="22.921" y2="16.2271" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint91_linear_196_9951" x1="20.4109" y1="17.5537" x2="20.4109" y2="12.8205" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint92_linear_196_9951" x1="19.574" y1="15.5333" x2="16.2268" y2="12.1861" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint93_linear_196_9951" x1="17.5539" y1="14.6965" x2="12.82" y2="14.6965" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint94_linear_196_9951" x1="15.5333" y1="15.5333" x2="12.1861" y2="18.8806" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint95_linear_196_9951" x1="14.6963" y1="17.5536" x2="14.6963" y2="22.2877" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint96_linear_196_9951" x1="15.5333" y1="19.5739" x2="18.8806" y2="22.9212" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint97_linear_196_9951" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint98_linear_196_9951" x1="14.8959" y1="11.1373" x2="19.6364" y2="9.17365" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint99_linear_196_9951" x1="21.3122" y1="13.22" x2="24.6647" y2="11.8313" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint100_linear_196_9951" x1="10.1551" y1="13.1009" x2="14.8956" y2="11.1373" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint101_linear_196_9951" x1="15.4708" y1="25.9338" x2="20.2113" y2="23.9702" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint102_linear_196_9951" x1="8.47902" y1="18.536" x2="11.8313" y2="17.1474" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint103_linear_196_9951" x1="10.4428" y1="23.2763" x2="13.7946" y2="21.8879" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint104_linear_196_9951" x1="20.2113" y1="23.9707" x2="24.9521" y2="22.007" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint105_linear_196_9951" x1="23.2762" y1="17.9608" x2="26.628" y2="16.5724" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint106_linear_196_9951" x1="11.1397" y1="14.8972" x2="13.1027" y2="10.1582" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint107_linear_196_9951" x1="17.1474" y1="11.8335" x2="18.5351" y2="8.48339" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint108_linear_196_9951" x1="9.17724" y1="19.6353" x2="11.1398" y2="14.8972" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint109_linear_196_9951" x1="22.0047" y1="24.9491" x2="23.9677" y2="20.2101" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint110_linear_196_9951" x1="11.8337" y1="24.6616" x2="13.2218" y2="21.3107" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint111_linear_196_9951" x1="16.5726" y1="26.6242" x2="17.9604" y2="23.2738" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint112_linear_196_9951" x1="23.9676" y1="20.2101" x2="25.9302" y2="15.472" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint113_linear_196_9951" x1="21.8856" y1="13.7967" x2="23.2736" y2="10.4457" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint114_linear_196_9951" x1="6.61929" y1="17.5537" x2="6.61929" y2="9.47524" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint115_linear_196_9951" x1="13.5146" y1="9.47523" x2="13.5146" y2="3.76307" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint116_linear_196_9951" x1="6.61929" y1="25.6321" x2="6.61929" y2="17.5537" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint117_linear_196_9951" x1="28.4887" y1="25.6321" x2="28.4887" y2="17.5537" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint118_linear_196_9951" x1="13.5146" y1="31.3444" x2="13.5146" y2="25.6321" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint119_linear_196_9951" x1="21.5929" y1="31.3444" x2="21.5929" y2="25.6321" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint120_linear_196_9951" x1="28.4887" y1="17.5537" x2="28.4887" y2="9.47524" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint121_linear_196_9951" x1="21.5929" y1="9.47523" x2="21.5929" y2="3.76307" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint122_linear_196_9951" x1="9.47238" y1="24.6542" x2="14.2065" y2="24.6542" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#ADD3E5" />
                            <stop offset="0.0707" stopColor="#B6D9EE" />
                            <stop offset="0.1978" stopColor="#C2E1FB" />
                            <stop offset="0.4063" stopColor="#C6E3FB" />
                            <stop offset="0.6097" stopColor="#D2E9FC" />
                            <stop offset="0.8102" stopColor="#E5F2FD" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <radialGradient id="paint123_diamond_196_9951" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.4177 12.2301) rotate(81.6955) scale(21.7129)">
                            <stop stopColor="#9CCF7D" />
                            <stop offset="1" stopColor="#679A47" />
                        </radialGradient>
                    </defs>
                </svg>
            )
        } else if (color === "brown") {
            return (<svg width="28" height="28" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.58418 11.1965L6.73828 13.0431V15.6541L9.89038 14.3479L8.58418 11.1965Z" fill="#0D2ED5" />
                <path opacity="0.3" d="M8.58418 11.1965L6.73828 13.0431V15.6541L9.89038 14.3479L8.58418 11.1965Z" fill="url(#paint0_linear_190_5522)" />
                <path d="M13.0423 13.0431L9.89018 9.89032L8.58398 11.1965L9.89018 14.3479L13.0423 13.0431Z" fill="url(#paint1_linear_190_5522)" />
                <path d="M13.0423 13.0431L9.89018 9.89032L8.58398 11.1965L9.89018 14.3479L13.0423 13.0431Z" fill="url(#paint2_linear_190_5522)" />
                <path d="M9.89038 14.3479L6.73828 15.6541V17.5H11.1966L9.89038 14.3479Z" fill="#2D42CC" />
                <path d="M11.1966 17.5H6.73828V19.3466L9.89038 20.6521L11.1966 17.5Z" fill="url(#paint3_linear_190_5522)" />
                <path opacity="0.3" d="M9.89038 14.3479L6.73828 15.6541V17.5H11.1966L9.89038 14.3479Z" fill="url(#paint4_linear_190_5522)" />
                <path opacity="0.3" d="M11.1966 17.5H6.73828V19.3466L9.89038 20.6521L11.1966 17.5Z" fill="url(#paint5_linear_190_5522)" />
                <path d="M17.4998 11.1965V6.73962H15.6532L14.3477 9.89032L17.4998 11.1965Z" fill="url(#paint6_linear_190_5522)" />
                <path d="M14.3477 9.89031L11.1963 8.58551L9.89014 9.89031L13.0422 13.0431L14.3477 9.89031Z" fill="#2E40B6" />
                <path d="M20.6521 9.89032L19.3459 6.73962H17.5V11.1965L20.6521 9.89032Z" fill="url(#paint7_linear_190_5522)" />
                <path d="M23.8026 8.58552L21.9567 6.73962H19.3457L20.6519 9.89032L23.8026 8.58552Z" fill="url(#paint8_linear_190_5522)" />
                <path d="M15.6532 6.73962H13.0422L11.1963 8.58552L14.3477 9.89032L15.6532 6.73962Z" fill="url(#paint9_linear_190_5522)" />
                <path d="M11.1963 26.4145L13.0422 28.2611H15.6532L14.3477 25.1097L11.1963 26.4145Z" fill="url(#paint10_linear_190_5522)" />
                <path d="M20.6519 25.1097L23.8026 26.4145L25.1088 25.1097L21.9567 21.9576L20.6519 25.1097Z" fill="#1F2C6D" />
                <path d="M19.3457 28.2611H21.9567L23.8026 26.4145L20.6519 25.1097L19.3457 28.2611Z" fill="url(#paint11_linear_190_5522)" />
                <path d="M6.73828 19.3466V21.9576L8.58418 23.8042L9.89038 20.6521L6.73828 19.3466Z" fill="url(#paint12_linear_190_5522)" />
                <path d="M17.5 23.8042V28.2611H19.3459L20.6521 25.1097L17.5 23.8042Z" fill="#0419A9" />
                <path d="M21.957 21.9576L25.1091 25.1097L26.4146 23.8042L25.1091 20.6521L21.957 21.9576Z" fill="url(#paint13_linear_190_5522)" />
                <path d="M9.89018 20.6521L8.58398 23.8042L9.89018 25.1097L13.0423 21.9576L9.89018 20.6521Z" fill="#051791" />
                <path d="M14.3477 25.1097L15.6532 28.2611H17.4998V23.8042L14.3477 25.1097Z" fill="url(#paint14_linear_190_5522)" />
                <path d="M13.0422 21.9576L9.89014 25.1097L11.1963 26.4145L14.3477 25.1097L13.0422 21.9576Z" fill="url(#paint15_linear_190_5522)" />
                <path d="M21.9567 13.0431L25.1088 9.89031L23.8026 8.58551L20.6519 9.89031L21.9567 13.0431Z" fill="url(#paint16_linear_190_5522)" />
                <path d="M11.1963 17.5L13.0422 21.9576L17.4998 17.5H11.1963Z" fill="#18256A" />
                <path d="M20.6521 9.89032L17.5 11.1965L21.9569 13.0431L20.6521 9.89032Z" fill="#2241E3" />
                <path opacity="0.6" d="M20.6521 9.89032L17.5 11.1965L21.9569 13.0431L20.6521 9.89032Z" fill="url(#paint17_linear_190_5522)" />
                <path d="M25.1091 14.3479L21.957 13.0431L23.8029 17.5L25.1091 14.3479Z" fill="url(#paint18_linear_190_5522)" />
                <path d="M21.9569 13.0431L17.5 11.1965V17.5L21.9569 13.0431Z" fill="#101E67" />
                <path d="M23.8028 17.5L21.9569 13.0431L17.5 17.5H23.8028Z" fill="url(#paint19_linear_190_5522)" />
                <path d="M14.348 9.89032L13.0425 13.0431L17.5001 11.1965L14.348 9.89032Z" fill="url(#paint20_linear_190_5522)" />
                <path opacity="0.5" d="M14.348 9.89032L13.0425 13.0431L17.5001 11.1965L14.348 9.89032Z" fill="url(#paint21_linear_190_5522)" />
                <path d="M25.1091 14.3479L26.4146 11.1965L25.1091 9.89032L21.957 13.0431L25.1091 14.3479Z" fill="url(#paint22_linear_190_5522)" />
                <path d="M14.348 25.1097L17.5001 23.8042L13.0425 21.9576L14.348 25.1097Z" fill="#172B96" />
                <path d="M9.89014 14.3479L11.1963 17.5L13.0422 13.0431L9.89014 14.3479Z" fill="#304EE3" />
                <path opacity="0.5" d="M9.89014 14.3479L11.1963 17.5L13.0422 13.0431L9.89014 14.3479Z" fill="url(#paint23_linear_190_5522)" />
                <path d="M9.89014 20.6521L13.0422 21.9576L11.1963 17.5L9.89014 20.6521Z" fill="#2033B2" />
                <path opacity="0.5" d="M9.89014 20.6521L13.0422 21.9576L11.1963 17.5L9.89014 20.6521Z" fill="url(#paint24_linear_190_5522)" />
                <path d="M20.6521 25.1097L21.9569 21.9576L17.5 23.8042L20.6521 25.1097Z" fill="url(#paint25_linear_190_5522)" />
                <path d="M25.1089 20.6521L28.261 19.3466V17.5H23.8027L25.1089 20.6521Z" fill="#304EE3" />
                <path d="M23.8027 17.5H28.261V15.6541L25.1089 14.3479L23.8027 17.5Z" fill="#152EE1" />
                <path d="M21.957 21.9576L25.1091 20.6521L23.8029 17.5L21.957 21.9576Z" fill="#152EE1" />
                <path d="M28.261 15.6541V13.0431L26.4144 11.1965L25.1089 14.3479L28.261 15.6541Z" fill="#0D21AB" />
                <path d="M17.5 23.8042L21.9569 21.9576L17.5 17.5V23.8042Z" fill="url(#paint26_linear_190_5522)" />
                <path d="M25.1089 20.6521L26.4144 23.8042L28.261 21.9576V19.3466L25.1089 20.6521Z" fill="url(#paint27_linear_190_5522)" />
                <path d="M13.0425 21.9576L17.5001 23.8042V17.5L13.0425 21.9576Z" fill="url(#paint28_linear_190_5522)" />
                <path d="M13.0422 13.0431L11.1963 17.5H17.4998L13.0422 13.0431Z" fill="#172B96" />
                <path d="M17.5 17.5L21.9569 21.9576L23.8028 17.5H17.5Z" fill="url(#paint29_linear_190_5522)" />
                <path d="M17.5001 11.1965L13.0425 13.0431L17.5001 17.5V11.1965Z" fill="#1E31B1" />
                <path d="M6.73828 21.9576V28.2611L8.58418 23.8042L6.73828 21.9576Z" fill="#0419A9" />
                <path d="M9.89038 25.1097L6.73828 28.2611L11.1966 26.4145L9.89038 25.1097Z" fill="url(#paint30_linear_190_5522)" />
                <path d="M8.58418 23.8042L6.73828 28.2611L9.89038 25.1097L8.58418 23.8042Z" fill="url(#paint31_linear_190_5522)" />
                <path d="M11.1966 26.4145L6.73828 28.2611H13.0425L11.1966 26.4145Z" fill="url(#paint32_linear_190_5522)" />
                <path d="M6.73828 28.2611L17.5001 32.7187L13.0425 28.2611H6.73828Z" fill="url(#paint33_linear_190_5522)" />
                <path d="M2.28125 17.5L6.73815 28.2611V21.9576L2.28125 17.5Z" fill="url(#paint34_linear_190_5522)" />
                <path d="M13.0425 28.2611L17.5001 32.7187L15.6535 28.2611H13.0425Z" fill="#2033B2" />
                <path d="M6.73815 15.6541L2.28125 17.5H6.73815V15.6541Z" fill="#2336B2" />
                <path d="M6.73815 17.5H2.28125L6.73815 19.3466V17.5Z" fill="#0B1E7E" />
                <path d="M6.73815 19.3466L2.28125 17.5L6.73815 21.9576V19.3466Z" fill="#2638B3" />
                <path d="M19.3459 28.2611L17.5 32.7187L21.9569 28.2611H19.3459Z" fill="#0520DE" />
                <path d="M25.1089 25.1097L28.261 28.2611L26.4144 23.8042L25.1089 25.1097Z" fill="url(#paint35_linear_190_5522)" />
                <path d="M26.4146 23.8042L28.2612 28.2611V21.9576L26.4146 23.8042Z" fill="#2E40B6" />
                <path d="M23.8027 26.4145L28.261 28.2611L25.1089 25.1097L23.8027 26.4145Z" fill="#206BCC" />
                <path d="M28.2612 21.9576V28.2611L32.7188 17.5L28.2612 21.9576Z" fill="#00199D" />
                <path d="M17.5 32.7187L28.2611 28.2611H21.9569L17.5 32.7187Z" fill="url(#paint36_linear_190_5522)" />
                <path d="M21.957 28.2611H28.2612L23.8029 26.4145L21.957 28.2611Z" fill="#1529AE" />
                <path d="M6.73815 13.0431L2.28125 17.5L6.73815 15.6541V13.0431Z" fill="#0C218F" />
                <path d="M17.5 28.2611V32.7187L19.3459 28.2611H17.5Z" fill="#3E5CF3" />
                <path d="M15.6533 28.2611L17.4999 32.7187V28.2611H15.6533Z" fill="url(#paint37_linear_190_5522)" />
                <path d="M25.1089 9.89032L28.261 6.73962L23.8027 8.58552L25.1089 9.89032Z" fill="#041584" />
                <path opacity="0.3" d="M25.1089 9.89032L28.261 6.73962L23.8027 8.58552L25.1089 9.89032Z" fill="url(#paint38_linear_190_5522)" />
                <path d="M28.2612 13.0431V6.73962L26.4146 11.1965L28.2612 13.0431Z" fill="#0B1E7E" />
                <path d="M28.2611 6.73961L17.5 2.28131L21.9569 6.73961H28.2611Z" fill="#206BCC" />
                <path opacity="0.3" d="M28.2611 6.73961L17.5 2.28131L21.9569 6.73961H28.2611Z" fill="url(#paint39_linear_190_5522)" />
                <path d="M26.4144 11.1965L28.261 6.73962L25.1089 9.89032L26.4144 11.1965Z" fill="#182CAF" />
                <path d="M23.8029 8.58552L28.2612 6.73962H21.957L23.8029 8.58552Z" fill="#172B96" />
                <path d="M28.2612 21.9576L32.7188 17.5L28.2612 19.3466V21.9576Z" fill="url(#paint40_linear_190_5522)" />
                <path d="M6.73815 6.73962L2.28125 17.5L6.73815 13.0431V6.73962Z" fill="url(#paint41_linear_190_5522)" />
                <path opacity="0.3" d="M6.73815 6.73962L2.28125 17.5L6.73815 13.0431V6.73962Z" fill="url(#paint42_linear_190_5522)" />
                <path d="M28.2612 17.5H32.7188L28.2612 15.6541V17.5Z" fill="url(#paint43_linear_190_5522)" />
                <path d="M28.2612 15.6541L32.7188 17.5L28.2612 13.0431V15.6541Z" fill="#0B1E7E" />
                <path d="M32.7188 17.5L28.2612 6.73962V13.0431L32.7188 17.5Z" fill="#182CAF" />
                <path d="M28.2612 19.3466L32.7188 17.5H28.2612V19.3466Z" fill="#23306F" />
                <path d="M13.0425 6.73962H6.73828L11.1966 8.58552L13.0425 6.73962Z" fill="#304EE3" />
                <path opacity="0.3" d="M13.0425 6.73962H6.73828L11.1966 8.58552L13.0425 6.73962Z" fill="url(#paint44_linear_190_5522)" />
                <path d="M11.1966 8.58552L6.73828 6.73962L9.89038 9.89032L11.1966 8.58552Z" fill="#152696" />
                <path d="M9.89038 9.89032L6.73828 6.73962L8.58418 11.1965L9.89038 9.89032Z" fill="url(#paint45_linear_190_5522)" />
                <path opacity="0.3" d="M9.89038 9.89032L6.73828 6.73962L8.58418 11.1965L9.89038 9.89032Z" fill="url(#paint46_linear_190_5522)" />
                <path d="M21.9569 6.73961L17.5 2.28131L19.3459 6.73961H21.9569Z" fill="#2D42CC" />
                <path opacity="0.2" d="M21.9569 6.73961L17.5 2.28131L19.3459 6.73961H21.9569Z" fill="url(#paint47_linear_190_5522)" />
                <path d="M8.58418 11.1965L6.73828 6.73962V13.0431L8.58418 11.1965Z" fill="#0B1E7E" />
                <path d="M17.4999 6.73961V2.28131L15.6533 6.73961H17.4999Z" fill="#0520DE" />
                <path d="M19.3459 6.73961L17.5 2.28131V6.73961H19.3459Z" fill="url(#paint48_linear_190_5522)" />
                <path d="M15.6535 6.73961L17.5001 2.28131L13.0425 6.73961H15.6535Z" fill="#051791" />
                <path d="M17.5001 2.28131L6.73828 6.73961H13.0425L17.5001 2.28131Z" fill="#2033B2" />
                <path opacity="0.3" d="M17.5 17.5L19.7288 22.8809L21.9569 21.9576L17.5 17.5Z" fill="url(#paint49_linear_190_5522)" />
                <path opacity="0.3" d="M17.5 17.5L22.8809 19.7288L23.8028 17.5H17.5Z" fill="url(#paint50_linear_190_5522)" />
                <path opacity="0.3" d="M17.5 17.5L22.8809 15.2712L21.9569 13.0431L17.5 17.5Z" fill="url(#paint51_linear_190_5522)" />
                <path opacity="0.3" d="M17.5 17.5L19.7288 12.1191L17.5 11.1965V17.5Z" fill="url(#paint52_linear_190_5522)" />
                <path opacity="0.3" d="M17.5001 17.5L15.2713 12.1191L13.0425 13.0431L17.5001 17.5Z" fill="url(#paint53_linear_190_5522)" />
                <path opacity="0.3" d="M17.4998 17.5L12.1189 15.2712L11.1963 17.5H17.4998Z" fill="url(#paint54_linear_190_5522)" />
                <path opacity="0.3" d="M17.5 17.5L12.1191 19.7288L13.0424 21.9576L17.5 17.5Z" fill="url(#paint55_linear_190_5522)" />
                <path opacity="0.3" d="M17.4998 17.5L15.271 22.8809L17.4998 23.8042V17.5Z" fill="url(#paint56_linear_190_5522)" />
                <path opacity="0.3" d="M32.7188 17.5L28.2612 6.73962V13.0431L32.7188 17.5Z" fill="url(#paint57_linear_190_5522)" />
                <g opacity="0.3">
                    <mask id="mask0_190_5522" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="2" y="2" width="31" height="31">
                        <path d="M28.2611 6.73961L17.5 2.28131L6.73815 6.73961L2.28125 17.5L6.73815 28.2611L17.5 32.7187L28.2611 28.2611L32.7187 17.5L28.2611 6.73961Z" fill="white" />
                    </mask>
                    <g mask="url(#mask0_190_5522)">
                        <path d="M7.46631 23.7706L8.53941 26.3893L11.1483 27.4827L11.163 23.7853L7.46631 23.7706Z" fill="#0D2ED5" />
                        <path opacity="0.3" d="M7.46631 23.7706L8.53941 26.3893L11.1483 27.4827L11.163 23.7853L7.46631 23.7706Z" fill="url(#paint58_linear_190_5522)" />
                        <path d="M11.1794 20.0886L6.7085 21.9184L7.4666 23.7706L11.1633 23.7853L11.1794 20.0886Z" fill="url(#paint59_linear_190_5522)" />
                        <path d="M11.1794 20.0886L6.7085 21.9184L7.4666 23.7706L11.1633 23.7853L11.1794 20.0886Z" fill="url(#paint60_linear_190_5522)" />
                        <path d="M11.1631 23.7853L11.1484 27.4827L12.9936 28.2555L14.8605 23.8007L11.1631 23.7853Z" fill="#2D42CC" />
                        <path d="M14.8606 23.8007L12.9937 28.2555L14.8389 29.029L17.4639 26.4257L14.8606 23.8007Z" fill="url(#paint61_linear_190_5522)" />
                        <path opacity="0.3" d="M11.1631 23.7853L11.1484 27.4827L12.9936 28.2555L14.8605 23.8007L11.1631 23.7853Z" fill="url(#paint62_linear_190_5522)" />
                        <path opacity="0.3" d="M14.8606 23.8007L12.9937 28.2555L14.8389 29.029L17.4639 26.4257L14.8606 23.8007Z" fill="url(#paint63_linear_190_5522)" />
                        <path d="M11.2 14.8603L6.74518 12.9934L5.97168 14.8393L8.57498 17.4636L11.2 14.8603Z" fill="url(#paint64_linear_190_5522)" />
                        <path d="M8.57498 17.4636L5.95068 20.0669L6.70808 21.9184L11.179 20.0886L8.57498 17.4636Z" fill="#2E40B6" />
                        <path d="M11.2151 11.1629L7.51911 11.1489L6.74561 12.9934L11.2004 14.8603L11.2151 11.1629Z" fill="url(#paint65_linear_190_5522)" />
                        <path d="M11.2304 7.46762L8.61244 8.53932L7.51904 11.1489L11.215 11.1629L11.2304 7.46762Z" fill="url(#paint66_linear_190_5522)" />
                        <path d="M5.97209 14.8393L4.87939 17.4489L5.95109 20.0669L8.57539 17.4636L5.97209 14.8393Z" fill="url(#paint67_linear_190_5522)" />
                        <path d="M23.77 27.5338L26.388 26.4621L27.4814 23.8525L23.7854 23.8371L23.77 27.5338Z" fill="url(#paint68_linear_190_5522)" />
                        <path d="M26.4258 17.5364L29.0494 14.9338L28.292 13.0823L23.8218 14.9128L26.4258 17.5364Z" fill="#1F2C6D" />
                        <path d="M29.0284 20.1614L30.1211 17.5525L29.0494 14.9338L26.4258 17.5364L29.0284 20.1614Z" fill="url(#paint69_linear_190_5522)" />
                        <path d="M14.8389 29.029L17.4485 30.1224L20.0672 29.0507L17.4639 26.4257L14.8389 29.029Z" fill="url(#paint70_linear_190_5522)" />
                        <path d="M23.8008 20.1397L28.2549 22.0066L29.0284 20.1614L26.4258 17.5364L23.8008 20.1397Z" fill="#0419A9" />
                        <path d="M23.8218 14.9128L28.292 13.0823L27.5339 11.2301L23.8365 11.2154L23.8218 14.9128Z" fill="url(#paint71_linear_190_5522)" />
                        <path d="M17.4639 26.4257L20.0672 29.0507L21.9194 28.2926L20.0889 23.8224L17.4639 26.4257Z" fill="#051791" />
                        <path d="M23.7856 23.8371L27.4816 23.8525L28.2551 22.0066L23.801 20.1397L23.7856 23.8371Z" fill="url(#paint72_linear_190_5522)" />
                        <path d="M20.0889 23.8224L21.9194 28.2926L23.7702 27.5338L23.7856 23.8371L20.0889 23.8224Z" fill="url(#paint73_linear_190_5522)" />
                        <path d="M14.9122 11.179L13.081 6.70883L11.2302 7.46763L11.2148 11.1629L14.9122 11.179Z" fill="url(#paint74_linear_190_5522)" />
                        <path d="M14.8604 23.8007L20.0887 23.8224L17.5001 17.5L14.8604 23.8007Z" fill="#18256A" />
                        <path d="M11.2149 11.1629L11.2002 14.8603L14.9123 11.179L11.2149 11.1629Z" fill="#2241E3" />
                        <path opacity="0.6" d="M11.2149 11.1629L11.2002 14.8603L14.9123 11.179L11.2149 11.1629Z" fill="url(#paint75_linear_190_5522)" />
                        <path d="M17.5364 8.57571L14.9121 11.179L20.1397 11.2007L17.5364 8.57571Z" fill="url(#paint76_linear_190_5522)" />
                        <path d="M14.9123 11.179L11.2002 14.8603L17.5002 17.5L14.9123 11.179Z" fill="#101E67" />
                        <path d="M20.1397 11.2007L14.9121 11.179L17.5 17.5L20.1397 11.2007Z" fill="url(#paint77_linear_190_5522)" />
                        <path d="M8.5752 17.4636L11.1792 20.0886L11.2002 14.8603L8.5752 17.4636Z" fill="url(#paint78_linear_190_5522)" />
                        <path opacity="0.5" d="M8.5752 17.4636L11.1792 20.0886L11.2002 14.8603L8.5752 17.4636Z" fill="url(#paint79_linear_190_5522)" />
                        <path d="M17.5366 8.57571L14.9333 5.95071L13.0811 6.70881L14.9123 11.179L17.5366 8.57571Z" fill="url(#paint80_linear_190_5522)" />
                        <path d="M23.7856 23.8371L23.801 20.1397L20.0889 23.8224L23.7856 23.8371Z" fill="#172B96" />
                        <path d="M11.1631 23.7853L14.8605 23.8007L11.1792 20.0886L11.1631 23.7853Z" fill="#304EE3" />
                        <path opacity="0.5" d="M11.1631 23.7853L14.8605 23.8007L11.1792 20.0886L11.1631 23.7853Z" fill="url(#paint81_linear_190_5522)" />
                        <path d="M17.4637 26.4257L20.0887 23.8224L14.8604 23.8007L17.4637 26.4257Z" fill="#2033B2" />
                        <path opacity="0.5" d="M17.4637 26.4257L20.0887 23.8224L14.8604 23.8007L17.4637 26.4257Z" fill="url(#paint82_linear_190_5522)" />
                        <path d="M26.4258 17.5364L23.8218 14.9128L23.8008 20.1397L26.4258 17.5364Z" fill="url(#paint83_linear_190_5522)" />
                        <path d="M23.8363 11.2154L23.8517 7.51871L22.0065 6.74521L20.1396 11.2007L23.8363 11.2154Z" fill="#304EE3" />
                        <path d="M20.1399 11.2007L22.0068 6.74521L20.1616 5.97241L17.5366 8.57571L20.1399 11.2007Z" fill="#152EE1" />
                        <path d="M23.8216 14.9128L23.8363 11.2154L20.1396 11.2007L23.8216 14.9128Z" fill="#152EE1" />
                        <path d="M20.1614 5.97243L17.5525 4.87903L14.9331 5.95073L17.5364 8.57573L20.1614 5.97243Z" fill="#0D21AB" />
                        <path d="M23.8007 20.1397L23.8217 14.9128L17.5 17.5L23.8007 20.1397Z" fill="url(#paint84_linear_190_5522)" />
                        <path d="M23.8364 11.2154L27.5338 11.2301L26.4621 8.61211L23.8518 7.51871L23.8364 11.2154Z" fill="url(#paint85_linear_190_5522)" />
                        <path d="M20.0886 23.8224L23.8007 20.1397L17.5 17.5L20.0886 23.8224Z" fill="url(#paint86_linear_190_5522)" />
                        <path d="M11.1792 20.0886L14.8605 23.8007L17.5002 17.5L11.1792 20.0886Z" fill="#172B96" />
                        <path d="M17.5 17.5L23.8217 14.9128L20.1397 11.2007L17.5 17.5Z" fill="url(#paint87_linear_190_5522)" />
                        <path d="M11.2002 14.8603L11.1792 20.0886L17.5002 17.5L11.2002 14.8603Z" fill="#1E31B1" />
                        <path d="M17.4482 30.1224L23.7482 32.7621L20.0669 29.0507L17.4482 30.1224Z" fill="#0419A9" />
                        <path d="M21.9194 28.2926L23.7485 32.7621L23.7702 27.5338L21.9194 28.2926Z" fill="url(#paint88_linear_190_5522)" />
                        <path d="M20.0669 29.0507L23.7482 32.7621L21.9191 28.2926L20.0669 29.0507Z" fill="url(#paint89_linear_190_5522)" />
                        <path d="M23.7702 27.5338L23.7485 32.7621L26.3882 26.4621L23.7702 27.5338Z" fill="url(#paint90_linear_190_5522)" />
                        <path d="M23.7485 32.7621L32.7106 23.8735L26.3882 26.4621L23.7485 32.7621Z" fill="url(#paint91_linear_190_5522)" />
                        <path d="M11.1274 32.7103L23.7484 32.7621L17.4484 30.1224L11.1274 32.7103Z" fill="url(#paint92_linear_190_5522)" />
                        <path d="M26.3882 26.4621L32.7106 23.8735L27.4816 23.8525L26.3882 26.4621Z" fill="#2033B2" />
                        <path d="M11.1484 27.4827L11.1274 32.7103L12.9936 28.2555L11.1484 27.4827Z" fill="#2336B2" />
                        <path d="M12.9936 28.2555L11.1274 32.7103L14.8388 29.029L12.9936 28.2555Z" fill="#0B1E7E" />
                        <path d="M14.8388 29.029L11.1274 32.7103L17.4484 30.1224L14.8388 29.029Z" fill="#2638B3" />
                        <path d="M29.0283 20.1614L32.7103 23.8735L30.121 17.5525L29.0283 20.1614Z" fill="#0520DE" />
                        <path d="M28.2923 13.0823L32.7618 11.2518L27.5342 11.2301L28.2923 13.0823Z" fill="url(#paint93_linear_190_5522)" />
                        <path d="M27.5341 11.2301L32.7617 11.2518L26.4624 8.61212L27.5341 11.2301Z" fill="#2E40B6" />
                        <path d="M29.0494 14.9338L32.7615 11.2518L28.292 13.0823L29.0494 14.9338Z" fill="#206BCC" />
                        <path d="M26.4621 8.6121L32.7614 11.2518L23.8735 2.29041L26.4621 8.6121Z" fill="#00199D" />
                        <path d="M32.7104 23.8735L32.7615 11.2518L30.1211 17.5525L32.7104 23.8735Z" fill="url(#paint94_linear_190_5522)" />
                        <path d="M30.121 17.5525L32.7614 11.2518L29.0493 14.9338L30.121 17.5525Z" fill="#1529AE" />
                        <path d="M8.53955 26.3893L11.1275 32.7103L11.1485 27.4827L8.53955 26.3893Z" fill="#0C218F" />
                        <path d="M28.2549 22.0066L32.7104 23.8735L29.0284 20.1614L28.2549 22.0066Z" fill="#3E5CF3" />
                        <path d="M27.4814 23.8525L32.7104 23.8735L28.2549 22.0066L27.4814 23.8525Z" fill="url(#paint95_linear_190_5522)" />
                        <path d="M13.0813 6.70882L11.2522 2.23932L11.2305 7.46762L13.0813 6.70882Z" fill="#041584" />
                        <path opacity="0.3" d="M13.0813 6.70882L11.2522 2.23932L11.2305 7.46762L13.0813 6.70882Z" fill="url(#paint96_linear_190_5522)" />
                        <path d="M17.5527 4.87902L11.252 2.23932L14.9333 5.95072L17.5527 4.87902Z" fill="#0B1E7E" />
                        <path d="M11.2519 2.23932L2.29053 11.1272L8.61223 8.53932L11.2519 2.23932Z" fill="#206BCC" />
                        <path opacity="0.3" d="M11.2519 2.23932L2.29053 11.1272L8.61223 8.53932L11.2519 2.23932Z" fill="url(#paint97_linear_190_5522)" />
                        <path d="M14.9333 5.95072L11.252 2.23932L13.0811 6.70882L14.9333 5.95072Z" fill="#182CAF" />
                        <path d="M11.2303 7.46762L11.252 2.23932L8.6123 8.53932L11.2303 7.46762Z" fill="#172B96" />
                        <path d="M26.4624 8.6121L23.8738 2.29041L23.8521 7.51871L26.4624 8.6121Z" fill="url(#paint98_linear_190_5522)" />
                        <path d="M2.23877 23.7489L11.1274 32.7103L8.53947 26.3893L2.23877 23.7489Z" fill="url(#paint99_linear_190_5522)" />
                        <path opacity="0.3" d="M2.23877 23.7489L11.1274 32.7103L8.53947 26.3893L2.23877 23.7489Z" fill="url(#paint100_linear_190_5522)" />
                        <path d="M22.0068 6.74521L23.8737 2.29041L20.1616 5.97241L22.0068 6.74521Z" fill="url(#paint101_linear_190_5522)" />
                        <path d="M20.1616 5.97241L23.8737 2.29041L17.5527 4.87901L20.1616 5.97241Z" fill="#0B1E7E" />
                        <path d="M23.8737 2.29042L11.252 2.23932L17.5527 4.87902L23.8737 2.29042Z" fill="#182CAF" />
                        <path d="M23.852 7.5187L23.8737 2.29041L22.0068 6.7452L23.852 7.5187Z" fill="#23306F" />
                        <path d="M4.87917 17.4489L2.23877 23.7489L5.95087 20.0669L4.87917 17.4489Z" fill="#304EE3" />
                        <path opacity="0.3" d="M4.87917 17.4489L2.23877 23.7489L5.95087 20.0669L4.87917 17.4489Z" fill="url(#paint102_linear_190_5522)" />
                        <path d="M5.95087 20.0669L2.23877 23.7489L6.70827 21.9184L5.95087 20.0669Z" fill="#152696" />
                        <path d="M6.70827 21.9184L2.23877 23.7489L7.46637 23.7706L6.70827 21.9184Z" fill="url(#paint103_linear_190_5522)" />
                        <path opacity="0.3" d="M6.70827 21.9184L2.23877 23.7489L7.46637 23.7706L6.70827 21.9184Z" fill="url(#paint104_linear_190_5522)" />
                        <path d="M8.61223 8.53931L2.29053 11.1272L7.51883 11.1489L8.61223 8.53931Z" fill="#2D42CC" />
                        <path opacity="0.2" d="M8.61223 8.53931L2.29053 11.1272L7.51883 11.1489L8.61223 8.53931Z" fill="url(#paint105_linear_190_5522)" />
                        <path d="M7.46637 23.7706L2.23877 23.7489L8.53947 26.3893L7.46637 23.7706Z" fill="#0B1E7E" />
                        <path d="M6.74533 12.9934L2.29053 11.1272L5.97183 14.8393L6.74533 12.9934Z" fill="#0520DE" />
                        <path d="M7.51883 11.1489L2.29053 11.1272L6.74533 12.9934L7.51883 11.1489Z" fill="url(#paint106_linear_190_5522)" />
                        <path d="M5.97183 14.8393L2.29053 11.1272L4.87913 17.4489L5.97183 14.8393Z" fill="#051791" />
                        <path d="M2.29057 11.1272L2.23877 23.7489L4.87917 17.4489L2.29057 11.1272Z" fill="#2033B2" />
                        <path opacity="0.3" d="M17.5 17.5L23.8112 17.5259L23.8217 14.9128L17.5 17.5Z" fill="url(#paint107_linear_190_5522)" />
                        <path opacity="0.3" d="M17.5 17.5L21.9814 13.0557L20.1397 11.2007L17.5 17.5Z" fill="url(#paint108_linear_190_5522)" />
                        <path opacity="0.3" d="M17.5 17.5L17.5259 11.1888L14.9121 11.179L17.5 17.5Z" fill="url(#paint109_linear_190_5522)" />
                        <path opacity="0.3" d="M17.5002 17.5L13.0559 13.0186L11.2002 14.8603L17.5002 17.5Z" fill="url(#paint110_linear_190_5522)" />
                        <path opacity="0.3" d="M17.5002 17.5L11.189 17.4741L11.1792 20.0886L17.5002 17.5Z" fill="url(#paint111_linear_190_5522)" />
                        <path opacity="0.3" d="M17.5002 17.5L13.0195 21.9443L14.8605 23.8007L17.5002 17.5Z" fill="url(#paint112_linear_190_5522)" />
                        <path opacity="0.3" d="M17.5003 17.5L17.4751 23.8112L20.0889 23.8224L17.5003 17.5Z" fill="url(#paint113_linear_190_5522)" />
                        <path opacity="0.3" d="M17.5 17.5L21.9443 21.9807L23.8007 20.1397L17.5 17.5Z" fill="url(#paint114_linear_190_5522)" />
                        <path opacity="0.3" d="M23.8737 2.29042L11.252 2.23932L17.5527 4.87902L23.8737 2.29042Z" fill="url(#paint115_linear_190_5522)" />
                    </g>
                </g>
                <g opacity="0.3">
                    <path d="M17.5 8.57291L14.8848 11.1874H20.1145L17.5 8.57291Z" fill="url(#paint116_linear_190_5522)" />
                    <path d="M23.8131 11.1874H20.1143L23.8131 14.8855V11.1874Z" fill="url(#paint117_linear_190_5522)" />
                    <path d="M11.1865 11.1874V14.8855L14.8846 11.1874H11.1865Z" fill="url(#paint118_linear_190_5522)" />
                    <path d="M17.5 26.4278L20.1145 23.8133H14.8848L17.5 26.4278Z" fill="url(#paint119_linear_190_5522)" />
                    <path d="M8.57129 17.5L11.1865 20.1152V14.8855L8.57129 17.5Z" fill="url(#paint120_linear_190_5522)" />
                    <path d="M11.1865 23.8133H14.8846L11.1865 20.1152V23.8133Z" fill="url(#paint121_linear_190_5522)" />
                    <path d="M23.8131 23.8133V20.1152L20.1143 23.8133H23.8131Z" fill="url(#paint122_linear_190_5522)" />
                    <path d="M23.813 20.1152L26.4275 17.5L23.813 14.8855V20.1152Z" fill="url(#paint123_linear_190_5522)" />
                </g>
                <g opacity="0.3">
                    <path d="M11.1895 11.1895V14.8862L14.8862 11.1895H11.1895Z" fill="url(#paint124_linear_190_5522)" />
                    <path d="M17.4995 8.57642L14.8857 11.1895H20.1133L17.4995 8.57642Z" fill="url(#paint125_linear_190_5522)" />
                    <path d="M8.57617 17.5L11.1893 20.1138V14.8862L8.57617 17.5Z" fill="url(#paint126_linear_190_5522)" />
                    <path d="M23.8105 23.8105V20.1138L20.1138 23.8105H23.8105Z" fill="url(#paint127_linear_190_5522)" />
                    <path d="M11.1895 23.8105H14.8862L11.1895 20.1138V23.8105Z" fill="url(#paint128_linear_190_5522)" />
                    <path d="M17.4995 26.4243L20.1133 23.8105H14.8857L17.4995 26.4243Z" fill="url(#paint129_linear_190_5522)" />
                    <path d="M26.4232 17.5L23.8101 14.8862V20.1138L26.4232 17.5Z" fill="url(#paint130_linear_190_5522)" />
                    <path d="M23.8105 14.8862V11.1895H20.1138L23.8105 14.8862Z" fill="url(#paint131_linear_190_5522)" />
                </g>
                <g opacity="0.3">
                    <path d="M4.51416 12.1212L6.74156 17.5L9.89296 9.89313L4.51416 12.1212Z" fill="url(#paint132_linear_190_5522)" />
                    <path d="M12.1212 4.51431L9.89307 9.89311L17.5 6.74241L12.1212 4.51431Z" fill="url(#paint133_linear_190_5522)" />
                    <path d="M4.51416 22.8788L9.89296 25.1069L6.74156 17.5L4.51416 22.8788Z" fill="url(#paint134_linear_190_5522)" />
                    <path d="M30.4857 22.8788L28.2569 17.5L25.1069 25.1069L30.4857 22.8788Z" fill="url(#paint135_linear_190_5522)" />
                    <path d="M12.1212 30.4864L17.5 28.2583L9.89307 25.1069L12.1212 30.4864Z" fill="url(#paint136_linear_190_5522)" />
                    <path d="M22.8788 30.4864L25.1069 25.1069L17.5 28.2583L22.8788 30.4864Z" fill="url(#paint137_linear_190_5522)" />
                    <path d="M30.4857 12.1212L25.1069 9.89313L28.2569 17.5L30.4857 12.1212Z" fill="url(#paint138_linear_190_5522)" />
                    <path d="M25.1069 9.89311L22.8788 4.51431L17.5 6.74241L25.1069 9.89311Z" fill="url(#paint139_linear_190_5522)" />
                </g>
                <path opacity="0.3" d="M13.0422 21.9576L9.89014 25.1097L11.1963 26.4145L14.3477 25.1097L13.0422 21.9576Z" fill="url(#paint140_linear_190_5522)" />
                <path opacity="0.1" d="M32.6802 17.4069L28.2604 28.0749L17.4993 32.5325L6.73815 28.0749L2.31975 17.4076L2.28125 17.5L6.73815 28.2611L17.5 32.7187L28.2611 28.2611L32.7187 17.5L32.6802 17.4069Z" fill="white" />
                <path opacity="0.5" d="M2.31975 17.5938L6.73955 6.92511L17.5007 2.46751L28.2618 6.92511L32.6802 17.5924L32.7187 17.5L28.2618 6.73961L17.5 2.28131L6.73955 6.73961L2.28125 17.5L2.31975 17.5938Z" fill="#0C46B7" />
                <g style={{ mixBlendMode: "color" }}>
                    <path d="M28.2611 6.73961L17.5 2.28131L6.73815 6.73961L2.28125 17.5L6.73815 28.2611L17.5 32.7187L28.2611 28.2611L32.7187 17.5L28.2611 6.73961Z" fill="#FF7A00" />
                </g>
                <defs>
                    <linearGradient id="paint0_linear_190_5522" x1="6.73851" y1="13.4251" x2="9.89039" y2="13.4251" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1615" stopColor="#C5D8FB" />
                        <stop offset="0.393" stopColor="#78A3F7" />
                        <stop offset="0.5845" stopColor="#407DF3" />
                        <stop offset="0.7261" stopColor="#1D65F1" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_190_5522" x1="8.58432" y1="12.1193" x2="13.0422" y2="12.1193" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0F1F88" />
                        <stop offset="1" stopColor="#0D24C1" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_190_5522" x1="8.58432" y1="12.1193" x2="13.0422" y2="12.1193" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0F1F88" />
                        <stop offset="1" stopColor="#0D24C1" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_190_5522" x1="6.73851" y1="19.076" x2="11.1963" y2="19.076" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002DAE" />
                        <stop offset="1" stopColor="#3044D1" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_190_5522" x1="6.73851" y1="15.9241" x2="11.1963" y2="15.9241" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#004A99" />
                        <stop offset="0.0785" stopColor="#0752C1" />
                        <stop offset="0.1558" stopColor="#0E59E3" />
                        <stop offset="0.1978" stopColor="#105CF0" />
                        <stop offset="0.3047" stopColor="#145FF0" />
                        <stop offset="0.4091" stopColor="#2067F1" />
                        <stop offset="0.5124" stopColor="#3374F2" />
                        <stop offset="0.6152" stopColor="#4F87F4" />
                        <stop offset="0.7176" stopColor="#729FF6" />
                        <stop offset="0.8197" stopColor="#9EBDF9" />
                        <stop offset="0.9195" stopColor="#D1DFFC" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_190_5522" x1="6.73851" y1="19.076" x2="11.1963" y2="19.076" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#004A99" />
                        <stop offset="0.0785" stopColor="#0752C1" />
                        <stop offset="0.1558" stopColor="#0E59E3" />
                        <stop offset="0.1978" stopColor="#105CF0" />
                        <stop offset="0.3047" stopColor="#145FF0" />
                        <stop offset="0.4091" stopColor="#2067F1" />
                        <stop offset="0.5124" stopColor="#3374F2" />
                        <stop offset="0.6152" stopColor="#4F87F4" />
                        <stop offset="0.7176" stopColor="#729FF6" />
                        <stop offset="0.8197" stopColor="#9EBDF9" />
                        <stop offset="0.9195" stopColor="#D1DFFC" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient id="paint6_linear_190_5522" x1="14.348" y1="8.96787" x2="17.4997" y2="8.96787" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4971E5" />
                        <stop offset="1" stopColor="#233DC3" />
                    </linearGradient>
                    <linearGradient id="paint7_linear_190_5522" x1="17.4999" y1="8.96787" x2="20.6519" y2="8.96787" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5379E5" />
                        <stop offset="1" stopColor="#152EE1" />
                    </linearGradient>
                    <linearGradient id="paint8_linear_190_5522" x1="19.346" y1="8.31494" x2="23.8029" y2="8.31494" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0C20A8" />
                        <stop offset="1" stopColor="#0B196F" />
                    </linearGradient>
                    <linearGradient id="paint9_linear_190_5522" x1="11.196" y1="8.31494" x2="15.6529" y2="8.31494" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002DAE" />
                        <stop offset="1" stopColor="#3044D1" />
                    </linearGradient>
                    <linearGradient id="paint10_linear_190_5522" x1="11.196" y1="26.6854" x2="15.6529" y2="26.6854" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0C20A8" />
                        <stop offset="1" stopColor="#0B196F" />
                    </linearGradient>
                    <linearGradient id="paint11_linear_190_5522" x1="19.346" y1="26.6854" x2="23.8029" y2="26.6854" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002DAE" />
                        <stop offset="1" stopColor="#3044D1" />
                    </linearGradient>
                    <linearGradient id="paint12_linear_190_5522" x1="6.73851" y1="21.5752" x2="9.89039" y2="21.5752" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#1125B4" />
                        <stop offset="1" stopColor="#4971E5" />
                    </linearGradient>
                    <linearGradient id="paint13_linear_190_5522" x1="21.9571" y1="22.8809" x2="26.4149" y2="22.8809" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0C20A8" />
                        <stop offset="1" stopColor="#0B196F" />
                    </linearGradient>
                    <linearGradient id="paint14_linear_190_5522" x1="14.348" y1="26.0324" x2="17.4997" y2="26.0324" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#1125B4" />
                        <stop offset="1" stopColor="#4971E5" />
                    </linearGradient>
                    <linearGradient id="paint15_linear_190_5522" x1="9.89014" y1="24.1863" x2="14.348" y2="24.1863" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5379E5" />
                        <stop offset="1" stopColor="#152EE1" />
                    </linearGradient>
                    <linearGradient id="paint16_linear_190_5522" x1="20.6517" y1="10.8144" x2="25.1088" y2="10.8144" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2F4DE3" />
                        <stop offset="0.1725" stopColor="#3351E3" />
                        <stop offset="0.3607" stopColor="#3D5FE4" />
                        <stop offset="0.5558" stopColor="#4F74E5" />
                        <stop offset="0.5907" stopColor="#5379E5" />
                    </linearGradient>
                    <linearGradient id="paint17_linear_190_5522" x1="20.9464" y1="9.8442" x2="19.5516" y2="12.4503" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1615" stopColor="#C5D8FB" />
                        <stop offset="0.393" stopColor="#78A3F7" />
                        <stop offset="0.5845" stopColor="#407DF3" />
                        <stop offset="0.7261" stopColor="#1D65F1" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint18_linear_190_5522" x1="21.9571" y1="15.2716" x2="25.1091" y2="15.2716" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#1125B4" />
                        <stop offset="1" stopColor="#4971E5" />
                    </linearGradient>
                    <linearGradient id="paint19_linear_190_5522" x1="17.4999" y1="15.2716" x2="23.8031" y2="15.2716" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0F1F88" />
                        <stop offset="1" stopColor="#0D24C1" />
                    </linearGradient>
                    <linearGradient id="paint20_linear_190_5522" x1="13.0424" y1="11.4668" x2="17.5" y2="11.4668" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5379E5" />
                        <stop offset="1" stopColor="#152EE1" />
                    </linearGradient>
                    <linearGradient id="paint21_linear_190_5522" x1="15.6835" y1="10.0583" x2="15.1383" y2="12.7844" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.0805" stopColor="#D1DFFC" />
                        <stop offset="0.1803" stopColor="#9EBDF9" />
                        <stop offset="0.2824" stopColor="#729FF6" />
                        <stop offset="0.3848" stopColor="#4F87F4" />
                        <stop offset="0.4876" stopColor="#3374F2" />
                        <stop offset="0.5909" stopColor="#2067F1" />
                        <stop offset="0.6953" stopColor="#145FF0" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint22_linear_190_5522" x1="21.9571" y1="12.1193" x2="26.4149" y2="12.1193" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5379E5" />
                        <stop offset="1" stopColor="#152EE1" />
                    </linearGradient>
                    <linearGradient id="paint23_linear_190_5522" x1="11.4698" y1="13.7368" x2="11.7725" y2="17.1885" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.0805" stopColor="#D1DFFC" />
                        <stop offset="0.1803" stopColor="#9EBDF9" />
                        <stop offset="0.2824" stopColor="#729FF6" />
                        <stop offset="0.3848" stopColor="#4F87F4" />
                        <stop offset="0.4876" stopColor="#3374F2" />
                        <stop offset="0.5909" stopColor="#2067F1" />
                        <stop offset="0.6953" stopColor="#145FF0" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint24_linear_190_5522" x1="12.2743" y1="19.6354" x2="9.93906" y2="21.0429" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.0805" stopColor="#D1DFFC" />
                        <stop offset="0.1803" stopColor="#9EBDF9" />
                        <stop offset="0.2824" stopColor="#729FF6" />
                        <stop offset="0.3848" stopColor="#4F87F4" />
                        <stop offset="0.4876" stopColor="#3374F2" />
                        <stop offset="0.5909" stopColor="#2067F1" />
                        <stop offset="0.6953" stopColor="#145FF0" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint25_linear_190_5522" x1="17.4999" y1="23.5338" x2="21.957" y2="23.5338" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002DAE" />
                        <stop offset="1" stopColor="#3044D1" />
                    </linearGradient>
                    <linearGradient id="paint26_linear_190_5522" x1="17.4999" y1="20.652" x2="21.957" y2="20.652" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4158F1" />
                        <stop offset="1" stopColor="#0D24C1" />
                    </linearGradient>
                    <linearGradient id="paint27_linear_190_5522" x1="25.1089" y1="21.5752" x2="28.2606" y2="21.5752" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002DAE" />
                        <stop offset="1" stopColor="#3044D1" />
                    </linearGradient>
                    <linearGradient id="paint28_linear_190_5522" x1="13.0424" y1="20.652" x2="17.5" y2="20.652" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#1125B4" />
                        <stop offset="1" stopColor="#4971E5" />
                    </linearGradient>
                    <linearGradient id="paint29_linear_190_5522" x1="17.4999" y1="19.7289" x2="23.8031" y2="19.7289" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint30_linear_190_5522" x1="6.73851" y1="26.6854" x2="11.1963" y2="26.6854" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint31_linear_190_5522" x1="6.73851" y1="26.0324" x2="9.89039" y2="26.0324" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2F4DE3" />
                        <stop offset="0.1725" stopColor="#3351E3" />
                        <stop offset="0.3607" stopColor="#3D5FE4" />
                        <stop offset="0.5558" stopColor="#4F74E5" />
                        <stop offset="0.5907" stopColor="#5379E5" />
                    </linearGradient>
                    <linearGradient id="paint32_linear_190_5522" x1="6.73851" y1="27.3378" x2="13.0424" y2="27.3378" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002DAE" />
                        <stop offset="1" stopColor="#3044D1" />
                    </linearGradient>
                    <linearGradient id="paint33_linear_190_5522" x1="6.73851" y1="30.4898" x2="17.5" y2="30.4898" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0F1F88" />
                        <stop offset="1" stopColor="#0D24C1" />
                    </linearGradient>
                    <linearGradient id="paint34_linear_190_5522" x1="2.28145" y1="22.8805" x2="6.73837" y2="22.8805" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0D24BE" />
                        <stop offset="1" stopColor="#0E1D85" />
                    </linearGradient>
                    <linearGradient id="paint35_linear_190_5522" x1="25.1089" y1="26.0324" x2="28.2606" y2="26.0324" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4971E5" />
                        <stop offset="1" stopColor="#233DC3" />
                    </linearGradient>
                    <linearGradient id="paint36_linear_190_5522" x1="17.4999" y1="30.4898" x2="28.2607" y2="30.4898" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4971E5" />
                        <stop offset="1" stopColor="#233DC3" />
                    </linearGradient>
                    <linearGradient id="paint37_linear_190_5522" x1="15.653" y1="30.4898" x2="17.4999" y2="30.4898" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4971E5" />
                        <stop offset="1" stopColor="#233DC3" />
                    </linearGradient>
                    <linearGradient id="paint38_linear_190_5522" x1="23.8031" y1="8.31494" x2="28.2607" y2="8.31494" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#004A99" />
                        <stop offset="0.0785" stopColor="#0752C1" />
                        <stop offset="0.1558" stopColor="#0E59E3" />
                        <stop offset="0.1978" stopColor="#105CF0" />
                        <stop offset="0.2739" stopColor="#1D65F1" />
                        <stop offset="0.4155" stopColor="#407DF3" />
                        <stop offset="0.607" stopColor="#78A3F7" />
                        <stop offset="0.8385" stopColor="#C5D8FB" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient id="paint39_linear_190_5522" x1="17.4999" y1="4.51045" x2="28.2607" y2="4.51045" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1615" stopColor="#C5D8FB" />
                        <stop offset="0.393" stopColor="#78A3F7" />
                        <stop offset="0.5845" stopColor="#407DF3" />
                        <stop offset="0.7261" stopColor="#1D65F1" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint40_linear_190_5522" x1="28.2609" y1="19.7289" x2="32.7188" y2="19.7289" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4971E5" />
                        <stop offset="1" stopColor="#233DC3" />
                    </linearGradient>
                    <linearGradient id="paint41_linear_190_5522" x1="2.28145" y1="12.1198" x2="6.73837" y2="12.1198" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002DAE" />
                        <stop offset="1" stopColor="#3044D1" />
                    </linearGradient>
                    <linearGradient id="paint42_linear_190_5522" x1="2.28145" y1="12.1198" x2="6.73837" y2="12.1198" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1615" stopColor="#C5D8FB" />
                        <stop offset="0.393" stopColor="#78A3F7" />
                        <stop offset="0.5845" stopColor="#407DF3" />
                        <stop offset="0.7261" stopColor="#1D65F1" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint43_linear_190_5522" x1="28.2609" y1="16.577" x2="32.7188" y2="16.577" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4971E5" />
                        <stop offset="1" stopColor="#233DC3" />
                    </linearGradient>
                    <linearGradient id="paint44_linear_190_5522" x1="6.73851" y1="7.66248" x2="13.0424" y2="7.66248" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1615" stopColor="#C5D8FB" />
                        <stop offset="0.393" stopColor="#78A3F7" />
                        <stop offset="0.5845" stopColor="#407DF3" />
                        <stop offset="0.7261" stopColor="#1D65F1" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint45_linear_190_5522" x1="6.73851" y1="8.96787" x2="9.89039" y2="8.96787" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint46_linear_190_5522" x1="6.73851" y1="8.96787" x2="9.89039" y2="8.96787" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1615" stopColor="#C5D8FB" />
                        <stop offset="0.393" stopColor="#78A3F7" />
                        <stop offset="0.5845" stopColor="#407DF3" />
                        <stop offset="0.7261" stopColor="#1D65F1" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint47_linear_190_5522" x1="19.6471" y1="3.77847" x2="19.9855" y2="6.82375" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.0805" stopColor="#D1DFFC" />
                        <stop offset="0.1803" stopColor="#9EBDF9" />
                        <stop offset="0.2824" stopColor="#729FF6" />
                        <stop offset="0.3848" stopColor="#4F87F4" />
                        <stop offset="0.4876" stopColor="#3374F2" />
                        <stop offset="0.5909" stopColor="#2067F1" />
                        <stop offset="0.6953" stopColor="#145FF0" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint48_linear_190_5522" x1="17.4999" y1="4.51045" x2="19.3462" y2="4.51045" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0C20A8" />
                        <stop offset="1" stopColor="#0B196F" />
                    </linearGradient>
                    <linearGradient id="paint49_linear_190_5522" x1="17.4999" y1="20.1905" x2="21.957" y2="20.1905" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint50_linear_190_5522" x1="19.4024" y1="19.4026" x2="22.554" y2="16.251" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint51_linear_190_5522" x1="20.1904" y1="17.5001" x2="20.1904" y2="13.0432" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint52_linear_190_5522" x1="19.4024" y1="15.5976" x2="16.2505" y2="12.4457" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint53_linear_190_5522" x1="17.5" y1="14.8096" x2="13.0424" y2="14.8096" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint54_linear_190_5522" x1="15.5973" y1="15.5977" x2="12.4454" y2="18.7495" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint55_linear_190_5522" x1="14.8096" y1="17.5001" x2="14.8096" y2="21.9578" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint56_linear_190_5522" x1="15.5973" y1="19.4025" x2="18.7493" y2="22.5544" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint57_linear_190_5522" x1="28.2609" y1="12.1198" x2="32.7188" y2="12.1198" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1615" stopColor="#C5D8FB" />
                        <stop offset="0.393" stopColor="#78A3F7" />
                        <stop offset="0.5845" stopColor="#407DF3" />
                        <stop offset="0.7261" stopColor="#1D65F1" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint58_linear_190_5522" x1="8.92083" y1="26.549" x2="10.2409" y2="23.399" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1615" stopColor="#C5D8FB" />
                        <stop offset="0.393" stopColor="#78A3F7" />
                        <stop offset="0.5845" stopColor="#407DF3" />
                        <stop offset="0.7261" stopColor="#1D65F1" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint59_linear_190_5522" x1="8.38901" y1="24.1573" x2="10.2561" y2="19.7019" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0F1F88" />
                        <stop offset="1" stopColor="#0D24C1" />
                    </linearGradient>
                    <linearGradient id="paint60_linear_190_5522" x1="8.38901" y1="24.1573" x2="10.2561" y2="19.7019" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0F1F88" />
                        <stop offset="1" stopColor="#0D24C1" />
                    </linearGradient>
                    <linearGradient id="paint61_linear_190_5522" x1="14.5685" y1="28.9155" x2="16.4354" y2="24.4604" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002DAE" />
                        <stop offset="1" stopColor="#3044D1" />
                    </linearGradient>
                    <linearGradient id="paint62_linear_190_5522" x1="11.4185" y1="27.5955" x2="13.2855" y2="23.1404" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#004A99" />
                        <stop offset="0.0785" stopColor="#0752C1" />
                        <stop offset="0.1558" stopColor="#0E59E3" />
                        <stop offset="0.1978" stopColor="#105CF0" />
                        <stop offset="0.3047" stopColor="#145FF0" />
                        <stop offset="0.4091" stopColor="#2067F1" />
                        <stop offset="0.5124" stopColor="#3374F2" />
                        <stop offset="0.6152" stopColor="#4F87F4" />
                        <stop offset="0.7176" stopColor="#729FF6" />
                        <stop offset="0.8197" stopColor="#9EBDF9" />
                        <stop offset="0.9195" stopColor="#D1DFFC" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient id="paint63_linear_190_5522" x1="14.5685" y1="28.9155" x2="16.4354" y2="24.4604" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#004A99" />
                        <stop offset="0.0785" stopColor="#0752C1" />
                        <stop offset="0.1558" stopColor="#0E59E3" />
                        <stop offset="0.1978" stopColor="#105CF0" />
                        <stop offset="0.3047" stopColor="#145FF0" />
                        <stop offset="0.4091" stopColor="#2067F1" />
                        <stop offset="0.5124" stopColor="#3374F2" />
                        <stop offset="0.6152" stopColor="#4F87F4" />
                        <stop offset="0.7176" stopColor="#729FF6" />
                        <stop offset="0.8197" stopColor="#9EBDF9" />
                        <stop offset="0.9195" stopColor="#D1DFFC" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient id="paint64_linear_190_5522" x1="7.6526" y1="17.0769" x2="8.97268" y2="13.9268" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4971E5" />
                        <stop offset="1" stopColor="#233DC3" />
                    </linearGradient>
                    <linearGradient id="paint65_linear_190_5522" x1="8.97302" y1="13.927" x2="10.2932" y2="10.7767" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5379E5" />
                        <stop offset="1" stopColor="#152EE1" />
                    </linearGradient>
                    <linearGradient id="paint66_linear_190_5522" x1="9.09347" y1="11.8085" x2="10.9602" y2="7.354" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0C20A8" />
                        <stop offset="1" stopColor="#0B196F" />
                    </linearGradient>
                    <linearGradient id="paint67_linear_190_5522" x1="5.68042" y1="19.9537" x2="7.54705" y2="15.4994" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002DAE" />
                        <stop offset="1" stopColor="#3044D1" />
                    </linearGradient>
                    <linearGradient id="paint68_linear_190_5522" x1="24.0403" y1="27.647" x2="25.907" y2="23.1925" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0C20A8" />
                        <stop offset="1" stopColor="#0B196F" />
                    </linearGradient>
                    <linearGradient id="paint69_linear_190_5522" x1="27.4534" y1="19.5015" x2="29.32" y2="15.0472" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002DAE" />
                        <stop offset="1" stopColor="#3044D1" />
                    </linearGradient>
                    <linearGradient id="paint70_linear_190_5522" x1="17.0663" y1="29.9622" x2="18.3864" y2="26.8122" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#1125B4" />
                        <stop offset="1" stopColor="#4971E5" />
                    </linearGradient>
                    <linearGradient id="paint71_linear_190_5522" x1="24.7443" y1="15.2991" x2="26.6114" y2="10.8437" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0C20A8" />
                        <stop offset="1" stopColor="#0B196F" />
                    </linearGradient>
                    <linearGradient id="paint72_linear_190_5522" x1="24.7079" y1="24.2234" x2="26.028" y2="21.0732" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#1125B4" />
                        <stop offset="1" stopColor="#4971E5" />
                    </linearGradient>
                    <linearGradient id="paint73_linear_190_5522" x1="20.9958" y1="27.9055" x2="22.8629" y2="23.4502" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5379E5" />
                        <stop offset="1" stopColor="#152EE1" />
                    </linearGradient>
                    <linearGradient id="paint74_linear_190_5522" x1="12.1383" y1="11.55" x2="14.0049" y2="7.09577" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2F4DE3" />
                        <stop offset="0.1725" stopColor="#3351E3" />
                        <stop offset="0.3607" stopColor="#3D5FE4" />
                        <stop offset="0.5558" stopColor="#4F74E5" />
                        <stop offset="0.5907" stopColor="#5379E5" />
                    </linearGradient>
                    <linearGradient id="paint75_linear_190_5522" x1="11.292" y1="10.8494" x2="13.3125" y2="13.335" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1615" stopColor="#C5D8FB" />
                        <stop offset="0.393" stopColor="#78A3F7" />
                        <stop offset="0.5845" stopColor="#407DF3" />
                        <stop offset="0.7261" stopColor="#1D65F1" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint76_linear_190_5522" x1="17.1393" y1="12.1126" x2="18.4595" y2="8.96235" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#1125B4" />
                        <stop offset="1" stopColor="#4971E5" />
                    </linearGradient>
                    <linearGradient id="paint77_linear_190_5522" x1="15.273" y1="16.5667" x2="17.9128" y2="10.2674" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0F1F88" />
                        <stop offset="1" stopColor="#0D24C1" />
                    </linearGradient>
                    <linearGradient id="paint78_linear_190_5522" x1="9.60366" y1="19.4286" x2="11.4706" y2="14.9735" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5379E5" />
                        <stop offset="1" stopColor="#152EE1" />
                    </linearGradient>
                    <linearGradient id="paint79_linear_190_5522" x1="9.30204" y1="16.1991" x2="11.7983" y2="17.8858" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.0805" stopColor="#D1DFFC" />
                        <stop offset="0.1803" stopColor="#9EBDF9" />
                        <stop offset="0.2824" stopColor="#729FF6" />
                        <stop offset="0.3848" stopColor="#4F87F4" />
                        <stop offset="0.4876" stopColor="#3374F2" />
                        <stop offset="0.5909" stopColor="#2067F1" />
                        <stop offset="0.6953" stopColor="#145FF0" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint80_linear_190_5522" x1="13.9889" y1="10.7923" x2="15.8559" y2="6.33699" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5379E5" />
                        <stop offset="1" stopColor="#152EE1" />
                    </linearGradient>
                    <linearGradient id="paint81_linear_190_5522" x1="11.2138" y1="21.9508" x2="14.7904" y2="23.0939" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.0805" stopColor="#D1DFFC" />
                        <stop offset="0.1803" stopColor="#9EBDF9" />
                        <stop offset="0.2824" stopColor="#729FF6" />
                        <stop offset="0.3848" stopColor="#4F87F4" />
                        <stop offset="0.4876" stopColor="#3374F2" />
                        <stop offset="0.5909" stopColor="#2067F1" />
                        <stop offset="0.6953" stopColor="#145FF0" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint82_linear_190_5522" x1="17.4459" y1="23.617" x2="17.8745" y2="26.5404" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.0805" stopColor="#D1DFFC" />
                        <stop offset="0.1803" stopColor="#9EBDF9" />
                        <stop offset="0.2824" stopColor="#729FF6" />
                        <stop offset="0.3848" stopColor="#4F87F4" />
                        <stop offset="0.4876" stopColor="#3374F2" />
                        <stop offset="0.5909" stopColor="#2067F1" />
                        <stop offset="0.6953" stopColor="#145FF0" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint83_linear_190_5522" x1="23.5304" y1="20.0268" x2="25.397" y2="15.5726" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002DAE" />
                        <stop offset="1" stopColor="#3044D1" />
                    </linearGradient>
                    <linearGradient id="paint84_linear_190_5522" x1="20.6503" y1="18.8201" x2="22.5169" y2="14.3657" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4158F1" />
                        <stop offset="1" stopColor="#0D24C1" />
                    </linearGradient>
                    <linearGradient id="paint85_linear_190_5522" x1="24.7593" y1="11.602" x2="26.0793" y2="8.45194" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002DAE" />
                        <stop offset="1" stopColor="#3044D1" />
                    </linearGradient>
                    <linearGradient id="paint86_linear_190_5522" x1="18.7833" y1="23.2752" x2="20.6503" y2="18.82" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#1125B4" />
                        <stop offset="1" stopColor="#4971E5" />
                    </linearGradient>
                    <linearGradient id="paint87_linear_190_5522" x1="19.7275" y1="18.4335" x2="22.3673" y2="12.1341" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint88_linear_190_5522" x1="22.1738" y1="32.1021" x2="24.0407" y2="27.6471" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint89_linear_190_5522" x1="21.5209" y1="31.8286" x2="22.8409" y2="28.6788" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2F4DE3" />
                        <stop offset="0.1725" stopColor="#3351E3" />
                        <stop offset="0.3607" stopColor="#3D5FE4" />
                        <stop offset="0.5558" stopColor="#4F74E5" />
                        <stop offset="0.5907" stopColor="#5379E5" />
                    </linearGradient>
                    <linearGradient id="paint90_linear_190_5522" x1="22.8258" y1="32.3753" x2="25.4659" y2="26.0752" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002DAE" />
                        <stop offset="1" stopColor="#3044D1" />
                    </linearGradient>
                    <linearGradient id="paint91_linear_190_5522" x1="25.9757" y1="33.6953" x2="30.4829" y2="22.94" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0F1F88" />
                        <stop offset="1" stopColor="#0D24C1" />
                    </linearGradient>
                    <linearGradient id="paint92_linear_190_5522" x1="16.5043" y1="34.9634" x2="18.3712" y2="30.5086" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0D24BE" />
                        <stop offset="1" stopColor="#0E1D85" />
                    </linearGradient>
                    <linearGradient id="paint93_linear_190_5522" x1="29.2144" y1="13.4687" x2="30.5345" y2="10.3185" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4971E5" />
                        <stop offset="1" stopColor="#233DC3" />
                    </linearGradient>
                    <linearGradient id="paint94_linear_190_5522" x1="30.4823" y1="22.9399" x2="34.9891" y2="12.1854" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4971E5" />
                        <stop offset="1" stopColor="#233DC3" />
                    </linearGradient>
                    <linearGradient id="paint95_linear_190_5522" x1="29.709" y1="24.7858" x2="30.4826" y2="22.9399" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4971E5" />
                        <stop offset="1" stopColor="#233DC3" />
                    </linearGradient>
                    <linearGradient id="paint96_linear_190_5522" x1="10.96" y1="7.35392" x2="12.8269" y2="2.89893" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#004A99" />
                        <stop offset="0.0785" stopColor="#0752C1" />
                        <stop offset="0.1558" stopColor="#0E59E3" />
                        <stop offset="0.1978" stopColor="#105CF0" />
                        <stop offset="0.2739" stopColor="#1D65F1" />
                        <stop offset="0.4155" stopColor="#407DF3" />
                        <stop offset="0.607" stopColor="#78A3F7" />
                        <stop offset="0.8385" stopColor="#C5D8FB" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient id="paint97_linear_190_5522" x1="4.51782" y1="12.0604" x2="9.0247" y2="1.30567" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1615" stopColor="#C5D8FB" />
                        <stop offset="0.393" stopColor="#78A3F7" />
                        <stop offset="0.5845" stopColor="#407DF3" />
                        <stop offset="0.7261" stopColor="#1D65F1" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint98_linear_190_5522" x1="24.2343" y1="7.67884" x2="26.1013" y2="3.22368" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4971E5" />
                        <stop offset="1" stopColor="#233DC3" />
                    </linearGradient>
                    <linearGradient id="paint99_linear_190_5522" x1="5.74973" y1="30.4566" x2="7.61634" y2="26.0023" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002DAE" />
                        <stop offset="1" stopColor="#3044D1" />
                    </linearGradient>
                    <linearGradient id="paint100_linear_190_5522" x1="5.74973" y1="30.4566" x2="7.61634" y2="26.0023" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1615" stopColor="#C5D8FB" />
                        <stop offset="0.393" stopColor="#78A3F7" />
                        <stop offset="0.5845" stopColor="#407DF3" />
                        <stop offset="0.7261" stopColor="#1D65F1" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint101_linear_190_5522" x1="21.0842" y1="6.3589" x2="22.9513" y2="1.90364" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4971E5" />
                        <stop offset="1" stopColor="#233DC3" />
                    </linearGradient>
                    <linearGradient id="paint102_linear_190_5522" x1="3.1613" y1="24.1357" x2="5.80143" y2="17.8356" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1615" stopColor="#C5D8FB" />
                        <stop offset="0.393" stopColor="#78A3F7" />
                        <stop offset="0.5845" stopColor="#407DF3" />
                        <stop offset="0.7261" stopColor="#1D65F1" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint103_linear_190_5522" x1="4.46606" y1="24.6824" x2="5.78615" y2="21.5323" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint104_linear_190_5522" x1="4.46606" y1="24.6824" x2="5.78615" y2="21.5323" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1615" stopColor="#C5D8FB" />
                        <stop offset="0.393" stopColor="#78A3F7" />
                        <stop offset="0.5845" stopColor="#407DF3" />
                        <stop offset="0.7261" stopColor="#1D65F1" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint105_linear_190_5522" x1="4.68559" y1="9.60785" x2="7.87067" y2="10.5451" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.0805" stopColor="#D1DFFC" />
                        <stop offset="0.1803" stopColor="#9EBDF9" />
                        <stop offset="0.2824" stopColor="#729FF6" />
                        <stop offset="0.3848" stopColor="#4F87F4" />
                        <stop offset="0.4876" stopColor="#3374F2" />
                        <stop offset="0.5909" stopColor="#2067F1" />
                        <stop offset="0.6953" stopColor="#145FF0" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint106_linear_190_5522" x1="4.51788" y1="12.0604" x2="5.29116" y2="10.2152" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0C20A8" />
                        <stop offset="1" stopColor="#0B196F" />
                    </linearGradient>
                    <linearGradient id="paint107_linear_190_5522" x1="20.1889" y1="18.6268" x2="22.0556" y2="14.1724" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint108_linear_190_5522" x1="20.1983" y1="16.3954" x2="18.3683" y2="11.9259" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint109_linear_190_5522" x1="18.627" y1="14.8112" x2="14.1725" y2="12.9445" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint110_linear_190_5522" x1="16.3957" y1="14.802" x2="11.9256" y2="16.6322" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint111_linear_190_5522" x1="14.8115" y1="16.3733" x2="12.9445" y2="20.8286" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint112_linear_190_5522" x1="14.8024" y1="18.6048" x2="16.6326" y2="23.0748" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint113_linear_190_5522" x1="16.3737" y1="20.1889" x2="20.8289" y2="22.0559" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint114_linear_190_5522" x1="18.6049" y1="20.1981" x2="23.0748" y2="18.3679" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2032A8" />
                        <stop offset="1" stopColor="#213BE2" />
                    </linearGradient>
                    <linearGradient id="paint115_linear_190_5522" x1="16.6292" y1="4.49241" x2="18.4964" y2="0.0368127" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1615" stopColor="#C5D8FB" />
                        <stop offset="0.393" stopColor="#78A3F7" />
                        <stop offset="0.5845" stopColor="#407DF3" />
                        <stop offset="0.7261" stopColor="#1D65F1" />
                        <stop offset="0.8022" stopColor="#105CF0" />
                        <stop offset="0.8442" stopColor="#0E59E3" />
                        <stop offset="0.9215" stopColor="#0752C1" />
                        <stop offset="1" stopColor="#004A99" />
                    </linearGradient>
                    <linearGradient id="paint116_linear_190_5522" x1="14.997" y1="11.4581" x2="19.4609" y2="9.6091" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint117_linear_190_5522" x1="21.039" y1="13.4193" x2="24.1958" y2="12.1117" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint118_linear_190_5522" x1="10.533" y1="13.3072" x2="14.9968" y2="11.4581" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint119_linear_190_5522" x1="15.5384" y1="25.3912" x2="20.0023" y2="23.5422" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint120_linear_190_5522" x1="8.9546" y1="18.4251" x2="12.1113" y2="17.1175" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint121_linear_190_5522" x1="10.8039" y1="22.8888" x2="13.9601" y2="21.5814" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint122_linear_190_5522" x1="20.0023" y1="23.5427" x2="24.4665" y2="21.6935" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint123_linear_190_5522" x1="22.8882" y1="17.8834" x2="26.0445" y2="16.576" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint124_linear_190_5522" x1="11.4603" y1="14.9987" x2="13.3088" y2="10.5362" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint125_linear_190_5522" x1="17.1169" y1="12.1138" x2="18.4236" y2="8.95912" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint126_linear_190_5522" x1="9.6121" y1="19.4601" x2="11.4601" y2="14.9987" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint127_linear_190_5522" x1="21.6913" y1="24.464" x2="23.5398" y2="20.0014" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint128_linear_190_5522" x1="12.1138" y1="24.1933" x2="13.4209" y2="21.0377" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint129_linear_190_5522" x1="16.5756" y1="26.0413" x2="17.8824" y2="22.8864" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint130_linear_190_5522" x1="23.5393" y1="20.0014" x2="25.3873" y2="15.5399" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint131_linear_190_5522" x1="21.5792" y1="13.9623" x2="22.8862" y2="10.8069" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint132_linear_190_5522" x1="7.20336" y1="17.5001" x2="7.20336" y2="9.89308" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint133_linear_190_5522" x1="13.6965" y1="9.89306" x2="13.6965" y2="4.5141" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint134_linear_190_5522" x1="7.20336" y1="25.1071" x2="7.20336" y2="17.5001" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint135_linear_190_5522" x1="27.7965" y1="25.1071" x2="27.7965" y2="17.5001" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint136_linear_190_5522" x1="13.6965" y1="30.4861" x2="13.6965" y2="25.1071" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint137_linear_190_5522" x1="21.3035" y1="30.4861" x2="21.3035" y2="25.1071" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint138_linear_190_5522" x1="27.7965" y1="17.5001" x2="27.7965" y2="9.89308" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint139_linear_190_5522" x1="21.3035" y1="9.89306" x2="21.3035" y2="4.5141" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#076999" />
                        <stop offset="0.25" stopColor="#06618C" />
                        <stop offset="0.7086" stopColor="#024E6B" />
                        <stop offset="1" stopColor="#003F52" />
                    </linearGradient>
                    <linearGradient id="paint140_linear_190_5522" x1="9.89014" y1="24.1863" x2="14.348" y2="24.1863" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#004A99" />
                        <stop offset="0.0785" stopColor="#0752C1" />
                        <stop offset="0.1558" stopColor="#0E59E3" />
                        <stop offset="0.1978" stopColor="#105CF0" />
                        <stop offset="0.3047" stopColor="#145FF0" />
                        <stop offset="0.4091" stopColor="#2067F1" />
                        <stop offset="0.5124" stopColor="#3374F2" />
                        <stop offset="0.6152" stopColor="#4F87F4" />
                        <stop offset="0.7176" stopColor="#729FF6" />
                        <stop offset="0.8197" stopColor="#9EBDF9" />
                        <stop offset="0.9195" stopColor="#D1DFFC" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                </defs>
            </svg>
            )
        } else if (color === "pink") {
            return (
                <svg width="28" height="28" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="#BCD8F4" />
                    <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="url(#paint0_linear_196_10089)" />
                    <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint1_linear_196_10089)" />
                    <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint2_linear_196_10089)" />
                    <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="#C4DAF2" />
                    <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint3_linear_196_10089)" />
                    <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="url(#paint4_linear_196_10089)" />
                    <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint5_linear_196_10089)" />
                    <path d="M17.5535 10.8596V6.12616H15.5921L14.2061 9.47216L17.5535 10.8596Z" fill="url(#paint6_linear_196_10089)" />
                    <path d="M14.2063 9.47215L10.8589 8.08685L9.47217 9.47215L12.8196 12.8203L14.2063 9.47215Z" fill="url(#paint7_linear_196_10089)" />
                    <path d="M20.9011 9.47216L19.5144 6.12616H17.5537V10.8596L20.9011 9.47216Z" fill="url(#paint8_linear_196_10089)" />
                    <path d="M24.2476 8.08686L22.2869 6.12616H19.5142L20.9009 9.47216L24.2476 8.08686Z" fill="url(#paint9_linear_196_10089)" />
                    <path d="M15.5923 6.12616H12.8196L10.8589 8.08686L14.2063 9.47216L15.5923 6.12616Z" fill="url(#paint10_linear_196_10089)" />
                    <path d="M10.8589 27.0205L12.8196 28.9812H15.5923L14.2063 25.6352L10.8589 27.0205Z" fill="url(#paint11_linear_196_10089)" />
                    <path d="M20.9009 25.6352L24.2476 27.0204L25.6343 25.6352L22.2869 22.2878L20.9009 25.6352Z" fill="url(#paint12_linear_196_10089)" />
                    <path d="M19.5142 28.9812H22.2869L24.2476 27.0205L20.9009 25.6352L19.5142 28.9812Z" fill="url(#paint13_linear_196_10089)" />
                    <path d="M6.12549 19.5144V22.2878L8.08549 24.2485L9.47219 20.9011L6.12549 19.5144Z" fill="url(#paint14_linear_196_10089)" />
                    <path d="M17.5537 24.2485V28.9812H19.5144L20.9011 25.6352L17.5537 24.2485Z" fill="url(#paint15_linear_196_10089)" />
                    <path d="M22.2871 22.2878L25.6345 25.6352L27.0212 24.2485L25.6345 20.9011L22.2871 22.2878Z" fill="url(#paint16_linear_196_10089)" />
                    <path d="M9.47215 20.9011L8.08545 24.2485L9.47215 25.6352L12.8195 22.2878L9.47215 20.9011Z" fill="url(#paint17_linear_196_10089)" />
                    <path d="M14.2061 25.6352L15.5921 28.9812H17.5535V24.2485L14.2061 25.6352Z" fill="url(#paint18_linear_196_10089)" />
                    <path d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint19_linear_196_10089)" />
                    <path d="M22.2869 12.8203L25.6343 9.47215L24.2476 8.08685L20.9009 9.47215L22.2869 12.8203Z" fill="url(#paint20_linear_196_10089)" />
                    <path d="M10.8589 17.5536L12.8196 22.2878L17.5537 17.5536H10.8589Z" fill="url(#paint21_linear_196_10089)" />
                    <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="#C5DDF8" />
                    <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="url(#paint22_linear_196_10089)" />
                    <path d="M25.6345 14.2063L22.2871 12.8203L24.2478 17.5537L25.6345 14.2063Z" fill="url(#paint23_linear_196_10089)" />
                    <path d="M22.2871 12.8203L17.5537 10.8596V17.5537L22.2871 12.8203Z" fill="url(#paint24_linear_196_10089)" />
                    <path d="M24.2478 17.5537L22.2871 12.8203L17.5537 17.5537H24.2478Z" fill="url(#paint25_linear_196_10089)" />
                    <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint26_linear_196_10089)" />
                    <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint27_linear_196_10089)" />
                    <path d="M25.6345 14.2063L27.0212 10.8596L25.6345 9.47217L22.2871 12.8203L25.6345 14.2063Z" fill="url(#paint28_linear_196_10089)" />
                    <path opacity="0.9" d="M14.2065 25.6352L17.5539 24.2485L12.8198 22.2878L14.2065 25.6352Z" fill="url(#paint29_linear_196_10089)" />
                    <path d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint30_linear_196_10089)" />
                    <path opacity="0.5" d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint31_linear_196_10089)" />
                    <path d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint32_linear_196_10089)" />
                    <path opacity="0.5" d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint33_linear_196_10089)" />
                    <path d="M20.9011 25.6352L22.2871 22.2878L17.5537 24.2485L20.9011 25.6352Z" fill="url(#paint34_linear_196_10089)" />
                    <path d="M25.6343 20.9011L28.981 19.5144V17.5536H24.2476L25.6343 20.9011Z" fill="url(#paint35_linear_196_10089)" />
                    <path d="M24.2476 17.5537H28.981V15.593L25.6343 14.2063L24.2476 17.5537Z" fill="url(#paint36_linear_196_10089)" />
                    <path d="M22.2871 22.2878L25.6345 20.9011L24.2478 17.5536L22.2871 22.2878Z" fill="url(#paint37_linear_196_10089)" />
                    <path d="M28.981 15.593V12.8203L27.021 10.8596L25.6343 14.2063L28.981 15.593Z" fill="url(#paint38_linear_196_10089)" />
                    <path opacity="0.9" d="M17.5537 24.2485L22.2871 22.2878L17.5537 17.5536V24.2485Z" fill="url(#paint39_linear_196_10089)" />
                    <path d="M25.6343 20.9011L27.021 24.2485L28.981 22.2878V19.5144L25.6343 20.9011Z" fill="url(#paint40_linear_196_10089)" />
                    <path d="M12.8198 22.2878L17.5539 24.2485V17.5536L12.8198 22.2878Z" fill="url(#paint41_linear_196_10089)" />
                    <path opacity="0.9" d="M12.8196 12.8203L10.8589 17.5537H17.5537L12.8196 12.8203Z" fill="url(#paint42_linear_196_10089)" />
                    <path d="M17.5537 17.5536L22.2871 22.2878L24.2478 17.5536H17.5537Z" fill="url(#paint43_linear_196_10089)" />
                    <path d="M17.5539 10.8596L12.8198 12.8203L17.5539 17.5537V10.8596Z" fill="url(#paint44_linear_196_10089)" />
                    <path d="M6.12549 22.2878V28.9812L8.08549 24.2485L6.12549 22.2878Z" fill="url(#paint45_linear_196_10089)" />
                    <path d="M9.47219 25.6352L6.12549 28.9812L10.8589 27.0205L9.47219 25.6352Z" fill="url(#paint46_linear_196_10089)" />
                    <path d="M8.08549 24.2485L6.12549 28.9812L9.47219 25.6352L8.08549 24.2485Z" fill="url(#paint47_linear_196_10089)" />
                    <path d="M10.8589 27.0204L6.12549 28.9811H12.8196L10.8589 27.0204Z" fill="url(#paint48_linear_196_10089)" />
                    <path d="M6.12549 28.9812L17.5537 33.7153L12.8196 28.9812H6.12549Z" fill="url(#paint49_linear_196_10089)" />
                    <path d="M1.39209 17.5536L6.12549 28.9811V22.2878L1.39209 17.5536Z" fill="url(#paint50_linear_196_10089)" />
                    <path d="M12.8198 28.9812L17.5539 33.7153L15.5925 28.9812H12.8198Z" fill="url(#paint51_linear_196_10089)" />
                    <path d="M6.12549 15.593L1.39209 17.5537H6.12549V15.593Z" fill="url(#paint52_linear_196_10089)" />
                    <path d="M6.12549 17.5536H1.39209L6.12549 19.5144V17.5536Z" fill="url(#paint53_linear_196_10089)" />
                    <path d="M6.12549 19.5144L1.39209 17.5536L6.12549 22.2878V19.5144Z" fill="url(#paint54_linear_196_10089)" />
                    <path d="M19.5144 28.9812L17.5537 33.7153L22.2871 28.9812H19.5144Z" fill="url(#paint55_linear_196_10089)" />
                    <path d="M25.6343 25.6352L28.981 28.9812L27.021 24.2485L25.6343 25.6352Z" fill="url(#paint56_linear_196_10089)" />
                    <path d="M27.021 24.2485L28.981 28.9812V22.2878L27.021 24.2485Z" fill="url(#paint57_linear_196_10089)" />
                    <path d="M24.2476 27.0205L28.981 28.9812L25.6343 25.6352L24.2476 27.0205Z" fill="url(#paint58_linear_196_10089)" />
                    <path d="M28.981 22.2878V28.9811L33.7151 17.5536L28.981 22.2878Z" fill="url(#paint59_linear_196_10089)" />
                    <path d="M17.5537 33.7153L28.9812 28.9812H22.2871L17.5537 33.7153Z" fill="url(#paint60_linear_196_10089)" />
                    <path d="M22.2871 28.9811H28.9812L24.2478 27.0204L22.2871 28.9811Z" fill="url(#paint61_linear_196_10089)" />
                    <path opacity="0.9" d="M6.12549 12.8203L1.39209 17.5537L6.12549 15.593V12.8203Z" fill="url(#paint62_linear_196_10089)" />
                    <path d="M17.5537 28.9812V33.7153L19.5144 28.9812H17.5537Z" fill="url(#paint63_linear_196_10089)" />
                    <path d="M15.5923 28.9812L17.5537 33.7153V28.9812H15.5923Z" fill="url(#paint64_linear_196_10089)" />
                    <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="#ABC8E0" />
                    <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="url(#paint65_linear_196_10089)" />
                    <path d="M28.981 12.8203V6.12616L27.021 10.8596L28.981 12.8203Z" fill="url(#paint66_linear_196_10089)" />
                    <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint67_linear_196_10089)" />
                    <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint68_linear_196_10089)" />
                    <path d="M27.021 10.8596L28.981 6.12616L25.6343 9.47216L27.021 10.8596Z" fill="url(#paint69_linear_196_10089)" />
                    <path opacity="0.9" d="M24.2478 8.08686L28.9812 6.12616H22.2871L24.2478 8.08686Z" fill="url(#paint70_linear_196_10089)" />
                    <path d="M28.981 22.2878L33.7151 17.5536L28.981 19.5144V22.2878Z" fill="url(#paint71_linear_196_10089)" />
                    <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint72_linear_196_10089)" />
                    <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint73_linear_196_10089)" />
                    <path d="M28.981 17.5537H33.7151L28.981 15.593V17.5537Z" fill="url(#paint74_linear_196_10089)" />
                    <path d="M28.981 15.593L33.7151 17.5537L28.981 12.8203V15.593Z" fill="url(#paint75_linear_196_10089)" />
                    <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint76_linear_196_10089)" />
                    <path d="M28.981 19.5144L33.7151 17.5536H28.981V19.5144Z" fill="url(#paint77_linear_196_10089)" />
                    <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint78_linear_196_10089)" />
                    <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint79_linear_196_10089)" />
                    <path d="M10.8589 8.08686L6.12549 6.12616L9.47219 9.47216L10.8589 8.08686Z" fill="url(#paint80_linear_196_10089)" />
                    <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint81_linear_196_10089)" />
                    <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint82_linear_196_10089)" />
                    <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="#C4DAF2" />
                    <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="url(#paint83_linear_196_10089)" />
                    <path d="M8.08549 10.8596L6.12549 6.12616V12.8203L8.08549 10.8596Z" fill="url(#paint84_linear_196_10089)" />
                    <path d="M17.5537 6.12616V1.39206L15.5923 6.12616H17.5537Z" fill="url(#paint85_linear_196_10089)" />
                    <path d="M19.5144 6.12616L17.5537 1.39206V6.12616H19.5144Z" fill="url(#paint86_linear_196_10089)" />
                    <path d="M15.5925 6.12616L17.5539 1.39206L12.8198 6.12616H15.5925Z" fill="url(#paint87_linear_196_10089)" />
                    <path d="M17.5537 1.39206L6.12549 6.12616H12.8196L17.5537 1.39206Z" fill="url(#paint88_linear_196_10089)" />
                    <g opacity="0.5">
                        <path d="M17.5537 17.5536L19.9211 23.2678L22.2871 22.2878L17.5537 17.5536Z" fill="url(#paint89_linear_196_10089)" />
                        <path d="M17.5537 17.5536L23.2678 19.9211L24.2478 17.5536H17.5537Z" fill="url(#paint90_linear_196_10089)" />
                        <path d="M17.5537 17.5537L23.2678 15.187L22.2871 12.8203L17.5537 17.5537Z" fill="url(#paint91_linear_196_10089)" />
                        <path d="M17.5537 17.5537L19.9211 11.8396L17.5537 10.8596V17.5537Z" fill="url(#paint92_linear_196_10089)" />
                        <path d="M17.5539 17.5537L15.1872 11.8396L12.8198 12.8203L17.5539 17.5537Z" fill="url(#paint93_linear_196_10089)" />
                        <path d="M17.5537 17.5537L11.8396 15.187L10.8589 17.5537H17.5537Z" fill="url(#paint94_linear_196_10089)" />
                        <path d="M17.5535 17.5536L11.8394 19.9211L12.8194 22.2878L17.5535 17.5536Z" fill="url(#paint95_linear_196_10089)" />
                        <path d="M17.5537 17.5536L15.187 23.2678L17.5537 24.2485V17.5536Z" fill="url(#paint96_linear_196_10089)" />
                    </g>
                    <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint97_linear_196_10089)" />
                    <g opacity="0.3">
                        <path d="M17.554 8.07285L14.7764 10.8497H20.3309L17.554 8.07285Z" fill="url(#paint98_linear_196_10089)" />
                        <path d="M24.2583 10.8497H20.3306L24.2583 14.7774V10.8497Z" fill="url(#paint99_linear_196_10089)" />
                        <path d="M10.8491 10.8497V14.7774L14.7761 10.8497H10.8491Z" fill="url(#paint100_linear_196_10089)" />
                        <path d="M17.554 27.0344L20.3309 24.2582H14.7764L17.554 27.0344Z" fill="url(#paint101_linear_196_10089)" />
                        <path d="M8.07227 17.5536L10.8492 20.3312V14.7774L8.07227 17.5536Z" fill="url(#paint102_linear_196_10089)" />
                        <path d="M10.8491 24.2582H14.7761L10.8491 20.3312V24.2582Z" fill="url(#paint103_linear_196_10089)" />
                        <path d="M24.2583 24.2582V20.3312L20.3306 24.2582H24.2583Z" fill="url(#paint104_linear_196_10089)" />
                        <path d="M24.2583 20.3312L27.0345 17.5536L24.2583 14.7774V20.3312Z" fill="url(#paint105_linear_196_10089)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M10.8525 10.8526V14.7782L14.7781 10.8526H10.8525Z" fill="url(#paint106_linear_196_10089)" />
                        <path d="M17.5538 8.07706L14.7783 10.8526H20.3293L17.5538 8.07706Z" fill="url(#paint107_linear_196_10089)" />
                        <path d="M8.07715 17.5537L10.8526 20.3292V14.7782L8.07715 17.5537Z" fill="url(#paint108_linear_196_10089)" />
                        <path d="M24.2554 24.2548V20.3292L20.3291 24.2548H24.2554Z" fill="url(#paint109_linear_196_10089)" />
                        <path d="M10.8525 24.2548H14.7781L10.8525 20.3292V24.2548Z" fill="url(#paint110_linear_196_10089)" />
                        <path d="M17.5538 27.0303L20.3293 24.2548H14.7783L17.5538 27.0303Z" fill="url(#paint111_linear_196_10089)" />
                        <path d="M27.0302 17.5537L24.2554 14.7782V20.3292L27.0302 17.5537Z" fill="url(#paint112_linear_196_10089)" />
                        <path d="M24.2554 14.7782V10.8526H20.3291L24.2554 14.7782Z" fill="url(#paint113_linear_196_10089)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M3.76318 11.8417L6.12918 17.5537L9.47518 9.47498L3.76318 11.8417Z" fill="url(#paint114_linear_196_10089)" />
                        <path d="M11.8418 3.76297L9.4751 9.47497L17.5538 6.12897L11.8418 3.76297Z" fill="url(#paint115_linear_196_10089)" />
                        <path d="M3.76318 23.2657L9.47518 25.6324L6.12918 17.5537L3.76318 23.2657Z" fill="url(#paint116_linear_196_10089)" />
                        <path d="M31.3448 23.2657L28.9781 17.5537L25.6328 25.6324L31.3448 23.2657Z" fill="url(#paint117_linear_196_10089)" />
                        <path d="M11.8418 31.3444L17.5538 28.9784L9.4751 25.6324L11.8418 31.3444Z" fill="url(#paint118_linear_196_10089)" />
                        <path d="M23.2664 31.3444L25.6324 25.6324L17.5537 28.9784L23.2664 31.3444Z" fill="url(#paint119_linear_196_10089)" />
                        <path d="M31.3448 11.8417L25.6328 9.47498L28.9781 17.5537L31.3448 11.8417Z" fill="url(#paint120_linear_196_10089)" />
                        <path d="M25.6324 9.47497L23.2664 3.76297L17.5537 6.12897L25.6324 9.47497Z" fill="url(#paint121_linear_196_10089)" />
                    </g>
                    <path opacity="0.3" d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint122_linear_196_10089)" />
                    <path opacity="0.1" d="M33.674 17.4543L28.9812 28.7838L17.553 33.5179L6.12479 28.7838L1.43269 17.4557L1.39209 17.5537L6.12549 28.9812L17.5537 33.7153L28.9812 28.9812L33.7153 17.5537L33.674 17.4543Z" fill="white" />
                    <path opacity="0.5" d="M1.43339 17.6531L6.12619 6.32356L17.5544 1.58946L28.9826 6.32356L33.6747 17.6517L33.7153 17.5537L28.9819 6.12616L17.5537 1.39206L6.12619 6.12616L1.39209 17.5537L1.43339 17.6531Z" fill="#A6C8ED" />
                    <g style={{ mixBlendMode: "color" }}>
                        <path d="M28.9816 6.12659L17.5537 1.39206L6.12514 6.12659L1.39209 17.5537L6.12514 28.9816L17.5537 33.7154L28.9816 28.9816L33.7154 17.5537L28.9816 6.12659Z" fill="#FBBCC5" fillOpacity="0.88" />
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_196_10089" x1="6.1252" y1="13.2262" x2="9.47241" y2="13.2262" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_196_10089" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_196_10089" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_196_10089" x1="6.27745" y1="19.873" x2="10.9939" y2="17.871" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_196_10089" x1="6.1252" y1="15.88" x2="10.8592" y2="15.88" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_196_10089" x1="6.1252" y1="19.2273" x2="10.8592" y2="19.2273" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint6_linear_196_10089" x1="14.7016" y1="10.0627" x2="18.3135" y2="7.032" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint7_linear_196_10089" x1="11.5729" y1="7.96082" x2="12.4416" y2="12.8872" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint8_linear_196_10089" x1="17.9886" y1="11.0262" x2="19.824" y2="6.24497" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint9_linear_196_10089" x1="19.5141" y1="7.79929" x2="24.2472" y2="7.79929" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint10_linear_196_10089" x1="11.161" y1="8.79771" x2="15.8767" y2="6.79603" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint11_linear_196_10089" x1="10.8592" y1="27.3082" x2="15.5923" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint12_linear_196_10089" x1="20.9008" y1="24.6542" x2="25.634" y2="24.6542" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint13_linear_196_10089" x1="19.2298" y1="28.3116" x2="23.9455" y2="26.3099" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint14_linear_196_10089" x1="6.1252" y1="21.8813" x2="9.47241" y2="21.8813" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint15_linear_196_10089" x1="16.7631" y1="27.8093" x2="20.615" y2="25.2111" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint16_linear_196_10089" x1="22.2869" y1="23.2679" x2="27.0209" y2="23.2679" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint17_linear_196_10089" x1="8.08557" y1="23.2679" x2="12.8197" y2="23.2679" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint18_linear_196_10089" x1="14.2063" y1="26.6148" x2="17.5534" y2="26.6148" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint19_linear_196_10089" x1="11.2499" y1="27.1708" x2="13.0853" y2="22.3896" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint20_linear_196_10089" x1="20.9008" y1="10.4536" x2="25.634" y2="10.4536" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint21_linear_196_10089" x1="11.6988" y1="19.9893" x2="17.3881" y2="17.2144" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint22_linear_196_10089" x1="21.2137" y1="9.42334" x2="19.7324" y2="12.1909" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint23_linear_196_10089" x1="22.2869" y1="15.1871" x2="25.6343" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint24_linear_196_10089" x1="17.5536" y1="14.2065" x2="22.2869" y2="14.2065" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint25_linear_196_10089" x1="17.5536" y1="15.1871" x2="24.2475" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint26_linear_196_10089" x1="14.5549" y1="13.4865" x2="15.8531" y2="10.1044" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint27_linear_196_10089" x1="15.6248" y1="9.65069" x2="15.0458" y2="12.5457" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint28_linear_196_10089" x1="23.9881" y1="13.5744" x2="25.5719" y2="9.44851" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint29_linear_196_10089" x1="12.82" y1="23.9613" x2="17.5539" y2="23.9613" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint30_linear_196_10089" x1="9.47238" y1="15.1871" x2="12.8197" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint31_linear_196_10089" x1="11.1499" y1="13.5571" x2="11.4714" y2="17.2228" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1898" stopColor="#E5EEFD" />
                            <stop offset="0.3903" stopColor="#D2E2FC" />
                            <stop offset="0.5937" stopColor="#C6DAFB" />
                            <stop offset="0.8022" stopColor="#C2D8FB" />
                            <stop offset="0.9293" stopColor="#B6D0EE" />
                            <stop offset="1" stopColor="#ADCBE5" />
                        </linearGradient>
                        <linearGradient id="paint32_linear_196_10089" x1="10.4464" y1="17.6264" x2="11.3151" y2="22.553" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint33_linear_196_10089" x1="12.0043" y1="19.8213" x2="9.52433" y2="21.3161" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1898" stopColor="#E5EEFD" />
                            <stop offset="0.3903" stopColor="#D2E2FC" />
                            <stop offset="0.5937" stopColor="#C6DAFB" />
                            <stop offset="0.8022" stopColor="#C2D8FB" />
                            <stop offset="0.9293" stopColor="#B6D0EE" />
                            <stop offset="1" stopColor="#ADCBE5" />
                        </linearGradient>
                        <linearGradient id="paint34_linear_196_10089" x1="18.0586" y1="25.4377" x2="22.7744" y2="23.436" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint35_linear_196_10089" x1="24.2472" y1="19.2273" x2="28.9811" y2="19.2273" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint36_linear_196_10089" x1="25.3801" y1="14.3471" x2="27.5861" y2="18.3269" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint37_linear_196_10089" x1="22.4939" y1="18.5256" x2="24.2392" y2="21.6742" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint38_linear_196_10089" x1="25.634" y1="13.2262" x2="28.9811" y2="13.2262" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint39_linear_196_10089" x1="17.5536" y1="20.9009" x2="22.2869" y2="20.9009" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint40_linear_196_10089" x1="26.3129" y1="22.5807" x2="29.6766" y2="21.1529" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint41_linear_196_10089" x1="12.82" y1="20.9009" x2="17.5539" y2="20.9009" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint42_linear_196_10089" x1="10.8592" y1="15.1871" x2="17.5536" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint43_linear_196_10089" x1="17.5536" y1="19.9207" x2="24.2475" y2="19.9207" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint44_linear_196_10089" x1="15.0906" y1="11.2936" x2="16.2354" y2="17.7861" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint45_linear_196_10089" x1="4.57363" y1="26.681" x2="8.11527" y2="24.2922" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint46_linear_196_10089" x1="6.1252" y1="27.3082" x2="10.8592" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint47_linear_196_10089" x1="6.1252" y1="26.6148" x2="9.47241" y2="26.6148" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint48_linear_196_10089" x1="6.63623" y1="30.1853" x2="12.3087" y2="27.7774" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint49_linear_196_10089" x1="6.1252" y1="31.3484" x2="17.5536" y2="31.3484" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint50_linear_196_10089" x1="1.39207" y1="23.2675" x2="6.1252" y2="23.2675" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1929" stopColor="#C9E0EB" />
                            <stop offset="0.5344" stopColor="#CADEEF" />
                            <stop offset="0.8146" stopColor="#D7E8FA" />
                            <stop offset="1" stopColor="#D7E8FA" />
                        </linearGradient>
                        <linearGradient id="paint51_linear_196_10089" x1="14.7108" y1="28.648" x2="15.6631" y2="34.0488" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint52_linear_196_10089" x1="1.84685" y1="18.4861" x2="6.44289" y2="16.2445" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint53_linear_196_10089" x1="2.58657" y1="19.3246" x2="5.83967" y2="17.1303" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint54_linear_196_10089" x1="2.78671" y1="16.7806" x2="5.90652" y2="22.4089" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint55_linear_196_10089" x1="16.8094" y1="32.1895" x2="22.4983" y2="29.4148" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint56_linear_196_10089" x1="27.0944" y1="27.3754" x2="28.591" y2="26.1196" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint57_linear_196_10089" x1="27.8628" y1="22.4848" x2="29.0075" y2="28.9767" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint58_linear_196_10089" x1="24.2472" y1="27.3082" x2="28.9811" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint59_linear_196_10089" x1="28.9811" y1="23.2675" x2="33.7152" y2="23.2675" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1929" stopColor="#C9E0EB" />
                            <stop offset="0.5344" stopColor="#CADEEF" />
                            <stop offset="0.8146" stopColor="#D7E8FA" />
                            <stop offset="1" stopColor="#D7E8FA" />
                        </linearGradient>
                        <linearGradient id="paint60_linear_196_10089" x1="18.5613" y1="34.9162" x2="27.5983" y2="27.3332" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint61_linear_196_10089" x1="22.2869" y1="28.0011" x2="28.9813" y2="28.0011" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint62_linear_196_10089" x1="1.39207" y1="15.1871" x2="6.1252" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint63_linear_196_10089" x1="17.5536" y1="31.3484" x2="19.5143" y2="31.3484" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint64_linear_196_10089" x1="15.9829" y1="31.8435" x2="18.314" y2="29.8875" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint65_linear_196_10089" x1="24.6535" y1="8.91961" x2="29.2501" y2="6.67769" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint66_linear_196_10089" x1="26.9912" y1="10.8155" x2="30.5328" y2="8.42666" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint67_linear_196_10089" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint68_linear_196_10089" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint69_linear_196_10089" x1="25.634" y1="8.49268" x2="28.9811" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint70_linear_196_10089" x1="22.2869" y1="7.1064" x2="28.9813" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint71_linear_196_10089" x1="28.2983" y1="21.4739" x2="33.4074" y2="17.1868" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint72_linear_196_10089" x1="-0.301693" y1="13.5634" x2="7.81896" y2="10.1164" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint73_linear_196_10089" x1="1.39207" y1="11.8399" x2="6.1252" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint74_linear_196_10089" x1="29.4764" y1="18.144" x2="32.2545" y2="15.8128" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint75_linear_196_10089" x1="29.533" y1="16.4114" x2="31.8779" y2="14.8297" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint76_linear_196_10089" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint77_linear_196_10089" x1="28.9811" y1="18.534" x2="33.7152" y2="18.534" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint78_linear_196_10089" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint79_linear_196_10089" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint80_linear_196_10089" x1="6.1252" y1="7.79929" x2="10.8592" y2="7.79929" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint81_linear_196_10089" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint82_linear_196_10089" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint83_linear_196_10089" x1="19.7778" y1="2.95104" x2="20.3705" y2="6.31258" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint84_linear_196_10089" x1="4.57346" y1="10.52" x2="7.67693" y2="8.42666" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint85_linear_196_10089" x1="15.0366" y1="4.98668" x2="18.4863" y2="3.30416" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint86_linear_196_10089" x1="17.5536" y1="3.75904" x2="19.5143" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint87_linear_196_10089" x1="12.82" y1="3.75904" x2="17.5539" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint88_linear_196_10089" x1="11.6069" y1="2.44053" x2="12.2738" y2="6.22239" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint89_linear_196_10089" x1="17.5536" y1="20.4108" x2="22.2869" y2="20.4108" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint90_linear_196_10089" x1="19.5741" y1="19.5741" x2="22.921" y2="16.2271" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint91_linear_196_10089" x1="20.4109" y1="17.5537" x2="20.4109" y2="12.8205" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint92_linear_196_10089" x1="19.574" y1="15.5333" x2="16.2268" y2="12.1861" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint93_linear_196_10089" x1="17.5539" y1="14.6965" x2="12.82" y2="14.6965" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint94_linear_196_10089" x1="15.5333" y1="15.5333" x2="12.1861" y2="18.8806" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint95_linear_196_10089" x1="14.6963" y1="17.5536" x2="14.6963" y2="22.2877" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint96_linear_196_10089" x1="15.5333" y1="19.5739" x2="18.8806" y2="22.9212" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint97_linear_196_10089" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint98_linear_196_10089" x1="14.8959" y1="11.1373" x2="19.6364" y2="9.17365" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint99_linear_196_10089" x1="21.3122" y1="13.22" x2="24.6647" y2="11.8313" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint100_linear_196_10089" x1="10.1551" y1="13.1009" x2="14.8956" y2="11.1373" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint101_linear_196_10089" x1="15.4708" y1="25.9338" x2="20.2113" y2="23.9702" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint102_linear_196_10089" x1="8.47902" y1="18.536" x2="11.8313" y2="17.1474" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint103_linear_196_10089" x1="10.4428" y1="23.2763" x2="13.7946" y2="21.8879" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint104_linear_196_10089" x1="20.2113" y1="23.9707" x2="24.9521" y2="22.007" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint105_linear_196_10089" x1="23.2762" y1="17.9608" x2="26.628" y2="16.5724" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint106_linear_196_10089" x1="11.1397" y1="14.8972" x2="13.1027" y2="10.1582" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint107_linear_196_10089" x1="17.1474" y1="11.8335" x2="18.5351" y2="8.48339" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint108_linear_196_10089" x1="9.17724" y1="19.6353" x2="11.1398" y2="14.8972" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint109_linear_196_10089" x1="22.0047" y1="24.9491" x2="23.9677" y2="20.2101" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint110_linear_196_10089" x1="11.8337" y1="24.6616" x2="13.2218" y2="21.3107" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint111_linear_196_10089" x1="16.5726" y1="26.6242" x2="17.9604" y2="23.2738" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint112_linear_196_10089" x1="23.9676" y1="20.2101" x2="25.9302" y2="15.472" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint113_linear_196_10089" x1="21.8856" y1="13.7967" x2="23.2736" y2="10.4457" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint114_linear_196_10089" x1="6.61929" y1="17.5537" x2="6.61929" y2="9.47524" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint115_linear_196_10089" x1="13.5146" y1="9.47523" x2="13.5146" y2="3.76307" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint116_linear_196_10089" x1="6.61929" y1="25.6321" x2="6.61929" y2="17.5537" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint117_linear_196_10089" x1="28.4887" y1="25.6321" x2="28.4887" y2="17.5537" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint118_linear_196_10089" x1="13.5146" y1="31.3444" x2="13.5146" y2="25.6321" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint119_linear_196_10089" x1="21.5929" y1="31.3444" x2="21.5929" y2="25.6321" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint120_linear_196_10089" x1="28.4887" y1="17.5537" x2="28.4887" y2="9.47524" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint121_linear_196_10089" x1="21.5929" y1="9.47523" x2="21.5929" y2="3.76307" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint122_linear_196_10089" x1="9.47238" y1="24.6542" x2="14.2065" y2="24.6542" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#ADD3E5" />
                            <stop offset="0.0707" stopColor="#B6D9EE" />
                            <stop offset="0.1978" stopColor="#C2E1FB" />
                            <stop offset="0.4063" stopColor="#C6E3FB" />
                            <stop offset="0.6097" stopColor="#D2E9FC" />
                            <stop offset="0.8102" stopColor="#E5F2FD" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                    </defs>
                </svg>
            )
        } else if (color === "purple") {
            return (<svg width="28" height="28" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="#BCD8F4" />
                <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="url(#paint0_linear_196_10227)" />
                <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint1_linear_196_10227)" />
                <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint2_linear_196_10227)" />
                <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="#C4DAF2" />
                <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint3_linear_196_10227)" />
                <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="url(#paint4_linear_196_10227)" />
                <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint5_linear_196_10227)" />
                <path d="M17.5535 10.8596V6.12616H15.5921L14.2061 9.47216L17.5535 10.8596Z" fill="url(#paint6_linear_196_10227)" />
                <path d="M14.2063 9.47215L10.8589 8.08685L9.47217 9.47215L12.8196 12.8203L14.2063 9.47215Z" fill="url(#paint7_linear_196_10227)" />
                <path d="M20.9011 9.47216L19.5144 6.12616H17.5537V10.8596L20.9011 9.47216Z" fill="url(#paint8_linear_196_10227)" />
                <path d="M24.2476 8.08686L22.2869 6.12616H19.5142L20.9009 9.47216L24.2476 8.08686Z" fill="url(#paint9_linear_196_10227)" />
                <path d="M15.5923 6.12616H12.8196L10.8589 8.08686L14.2063 9.47216L15.5923 6.12616Z" fill="url(#paint10_linear_196_10227)" />
                <path d="M10.8589 27.0205L12.8196 28.9812H15.5923L14.2063 25.6352L10.8589 27.0205Z" fill="url(#paint11_linear_196_10227)" />
                <path d="M20.9009 25.6352L24.2476 27.0204L25.6343 25.6352L22.2869 22.2878L20.9009 25.6352Z" fill="url(#paint12_linear_196_10227)" />
                <path d="M19.5142 28.9812H22.2869L24.2476 27.0205L20.9009 25.6352L19.5142 28.9812Z" fill="url(#paint13_linear_196_10227)" />
                <path d="M6.12549 19.5144V22.2878L8.08549 24.2485L9.47219 20.9011L6.12549 19.5144Z" fill="url(#paint14_linear_196_10227)" />
                <path d="M17.5537 24.2485V28.9812H19.5144L20.9011 25.6352L17.5537 24.2485Z" fill="url(#paint15_linear_196_10227)" />
                <path d="M22.2871 22.2878L25.6345 25.6352L27.0212 24.2485L25.6345 20.9011L22.2871 22.2878Z" fill="url(#paint16_linear_196_10227)" />
                <path d="M9.47215 20.9011L8.08545 24.2485L9.47215 25.6352L12.8195 22.2878L9.47215 20.9011Z" fill="url(#paint17_linear_196_10227)" />
                <path d="M14.2061 25.6352L15.5921 28.9812H17.5535V24.2485L14.2061 25.6352Z" fill="url(#paint18_linear_196_10227)" />
                <path d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint19_linear_196_10227)" />
                <path d="M22.2869 12.8203L25.6343 9.47215L24.2476 8.08685L20.9009 9.47215L22.2869 12.8203Z" fill="url(#paint20_linear_196_10227)" />
                <path d="M10.8589 17.5536L12.8196 22.2878L17.5537 17.5536H10.8589Z" fill="url(#paint21_linear_196_10227)" />
                <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="#C5DDF8" />
                <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="url(#paint22_linear_196_10227)" />
                <path d="M25.6345 14.2063L22.2871 12.8203L24.2478 17.5537L25.6345 14.2063Z" fill="url(#paint23_linear_196_10227)" />
                <path d="M22.2871 12.8203L17.5537 10.8596V17.5537L22.2871 12.8203Z" fill="url(#paint24_linear_196_10227)" />
                <path d="M24.2478 17.5537L22.2871 12.8203L17.5537 17.5537H24.2478Z" fill="url(#paint25_linear_196_10227)" />
                <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint26_linear_196_10227)" />
                <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint27_linear_196_10227)" />
                <path d="M25.6345 14.2063L27.0212 10.8596L25.6345 9.47217L22.2871 12.8203L25.6345 14.2063Z" fill="url(#paint28_linear_196_10227)" />
                <path opacity="0.9" d="M14.2065 25.6352L17.5539 24.2485L12.8198 22.2878L14.2065 25.6352Z" fill="url(#paint29_linear_196_10227)" />
                <path d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint30_linear_196_10227)" />
                <path opacity="0.5" d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint31_linear_196_10227)" />
                <path d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint32_linear_196_10227)" />
                <path opacity="0.5" d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint33_linear_196_10227)" />
                <path d="M20.9011 25.6352L22.2871 22.2878L17.5537 24.2485L20.9011 25.6352Z" fill="url(#paint34_linear_196_10227)" />
                <path d="M25.6343 20.9011L28.981 19.5144V17.5536H24.2476L25.6343 20.9011Z" fill="url(#paint35_linear_196_10227)" />
                <path d="M24.2476 17.5537H28.981V15.593L25.6343 14.2063L24.2476 17.5537Z" fill="url(#paint36_linear_196_10227)" />
                <path d="M22.2871 22.2878L25.6345 20.9011L24.2478 17.5536L22.2871 22.2878Z" fill="url(#paint37_linear_196_10227)" />
                <path d="M28.981 15.593V12.8203L27.021 10.8596L25.6343 14.2063L28.981 15.593Z" fill="url(#paint38_linear_196_10227)" />
                <path opacity="0.9" d="M17.5537 24.2485L22.2871 22.2878L17.5537 17.5536V24.2485Z" fill="url(#paint39_linear_196_10227)" />
                <path d="M25.6343 20.9011L27.021 24.2485L28.981 22.2878V19.5144L25.6343 20.9011Z" fill="url(#paint40_linear_196_10227)" />
                <path d="M12.8198 22.2878L17.5539 24.2485V17.5536L12.8198 22.2878Z" fill="url(#paint41_linear_196_10227)" />
                <path opacity="0.9" d="M12.8196 12.8203L10.8589 17.5537H17.5537L12.8196 12.8203Z" fill="url(#paint42_linear_196_10227)" />
                <path d="M17.5537 17.5536L22.2871 22.2878L24.2478 17.5536H17.5537Z" fill="url(#paint43_linear_196_10227)" />
                <path d="M17.5539 10.8596L12.8198 12.8203L17.5539 17.5537V10.8596Z" fill="url(#paint44_linear_196_10227)" />
                <path d="M6.12549 22.2878V28.9812L8.08549 24.2485L6.12549 22.2878Z" fill="url(#paint45_linear_196_10227)" />
                <path d="M9.47219 25.6352L6.12549 28.9812L10.8589 27.0205L9.47219 25.6352Z" fill="url(#paint46_linear_196_10227)" />
                <path d="M8.08549 24.2485L6.12549 28.9812L9.47219 25.6352L8.08549 24.2485Z" fill="url(#paint47_linear_196_10227)" />
                <path d="M10.8589 27.0204L6.12549 28.9811H12.8196L10.8589 27.0204Z" fill="url(#paint48_linear_196_10227)" />
                <path d="M6.12549 28.9812L17.5537 33.7153L12.8196 28.9812H6.12549Z" fill="url(#paint49_linear_196_10227)" />
                <path d="M1.39209 17.5536L6.12549 28.9811V22.2878L1.39209 17.5536Z" fill="url(#paint50_linear_196_10227)" />
                <path d="M12.8198 28.9812L17.5539 33.7153L15.5925 28.9812H12.8198Z" fill="url(#paint51_linear_196_10227)" />
                <path d="M6.12549 15.593L1.39209 17.5537H6.12549V15.593Z" fill="url(#paint52_linear_196_10227)" />
                <path d="M6.12549 17.5536H1.39209L6.12549 19.5144V17.5536Z" fill="url(#paint53_linear_196_10227)" />
                <path d="M6.12549 19.5144L1.39209 17.5536L6.12549 22.2878V19.5144Z" fill="url(#paint54_linear_196_10227)" />
                <path d="M19.5144 28.9812L17.5537 33.7153L22.2871 28.9812H19.5144Z" fill="url(#paint55_linear_196_10227)" />
                <path d="M25.6343 25.6352L28.981 28.9812L27.021 24.2485L25.6343 25.6352Z" fill="url(#paint56_linear_196_10227)" />
                <path d="M27.021 24.2485L28.981 28.9812V22.2878L27.021 24.2485Z" fill="url(#paint57_linear_196_10227)" />
                <path d="M24.2476 27.0205L28.981 28.9812L25.6343 25.6352L24.2476 27.0205Z" fill="url(#paint58_linear_196_10227)" />
                <path d="M28.981 22.2878V28.9811L33.7151 17.5536L28.981 22.2878Z" fill="url(#paint59_linear_196_10227)" />
                <path d="M17.5537 33.7153L28.9812 28.9812H22.2871L17.5537 33.7153Z" fill="url(#paint60_linear_196_10227)" />
                <path d="M22.2871 28.9811H28.9812L24.2478 27.0204L22.2871 28.9811Z" fill="url(#paint61_linear_196_10227)" />
                <path opacity="0.9" d="M6.12549 12.8203L1.39209 17.5537L6.12549 15.593V12.8203Z" fill="url(#paint62_linear_196_10227)" />
                <path d="M17.5537 28.9812V33.7153L19.5144 28.9812H17.5537Z" fill="url(#paint63_linear_196_10227)" />
                <path d="M15.5923 28.9812L17.5537 33.7153V28.9812H15.5923Z" fill="url(#paint64_linear_196_10227)" />
                <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="#ABC8E0" />
                <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="url(#paint65_linear_196_10227)" />
                <path d="M28.981 12.8203V6.12616L27.021 10.8596L28.981 12.8203Z" fill="url(#paint66_linear_196_10227)" />
                <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint67_linear_196_10227)" />
                <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint68_linear_196_10227)" />
                <path d="M27.021 10.8596L28.981 6.12616L25.6343 9.47216L27.021 10.8596Z" fill="url(#paint69_linear_196_10227)" />
                <path opacity="0.9" d="M24.2478 8.08686L28.9812 6.12616H22.2871L24.2478 8.08686Z" fill="url(#paint70_linear_196_10227)" />
                <path d="M28.981 22.2878L33.7151 17.5536L28.981 19.5144V22.2878Z" fill="url(#paint71_linear_196_10227)" />
                <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint72_linear_196_10227)" />
                <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint73_linear_196_10227)" />
                <path d="M28.981 17.5537H33.7151L28.981 15.593V17.5537Z" fill="url(#paint74_linear_196_10227)" />
                <path d="M28.981 15.593L33.7151 17.5537L28.981 12.8203V15.593Z" fill="url(#paint75_linear_196_10227)" />
                <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint76_linear_196_10227)" />
                <path d="M28.981 19.5144L33.7151 17.5536H28.981V19.5144Z" fill="url(#paint77_linear_196_10227)" />
                <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint78_linear_196_10227)" />
                <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint79_linear_196_10227)" />
                <path d="M10.8589 8.08686L6.12549 6.12616L9.47219 9.47216L10.8589 8.08686Z" fill="url(#paint80_linear_196_10227)" />
                <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint81_linear_196_10227)" />
                <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint82_linear_196_10227)" />
                <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="#C4DAF2" />
                <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="url(#paint83_linear_196_10227)" />
                <path d="M8.08549 10.8596L6.12549 6.12616V12.8203L8.08549 10.8596Z" fill="url(#paint84_linear_196_10227)" />
                <path d="M17.5537 6.12616V1.39206L15.5923 6.12616H17.5537Z" fill="url(#paint85_linear_196_10227)" />
                <path d="M19.5144 6.12616L17.5537 1.39206V6.12616H19.5144Z" fill="url(#paint86_linear_196_10227)" />
                <path d="M15.5925 6.12616L17.5539 1.39206L12.8198 6.12616H15.5925Z" fill="url(#paint87_linear_196_10227)" />
                <path d="M17.5537 1.39206L6.12549 6.12616H12.8196L17.5537 1.39206Z" fill="url(#paint88_linear_196_10227)" />
                <g opacity="0.5">
                    <path d="M17.5537 17.5536L19.9211 23.2678L22.2871 22.2878L17.5537 17.5536Z" fill="url(#paint89_linear_196_10227)" />
                    <path d="M17.5537 17.5536L23.2678 19.9211L24.2478 17.5536H17.5537Z" fill="url(#paint90_linear_196_10227)" />
                    <path d="M17.5537 17.5537L23.2678 15.187L22.2871 12.8203L17.5537 17.5537Z" fill="url(#paint91_linear_196_10227)" />
                    <path d="M17.5537 17.5537L19.9211 11.8396L17.5537 10.8596V17.5537Z" fill="url(#paint92_linear_196_10227)" />
                    <path d="M17.5539 17.5537L15.1872 11.8396L12.8198 12.8203L17.5539 17.5537Z" fill="url(#paint93_linear_196_10227)" />
                    <path d="M17.5537 17.5537L11.8396 15.187L10.8589 17.5537H17.5537Z" fill="url(#paint94_linear_196_10227)" />
                    <path d="M17.5535 17.5536L11.8394 19.9211L12.8194 22.2878L17.5535 17.5536Z" fill="url(#paint95_linear_196_10227)" />
                    <path d="M17.5537 17.5536L15.187 23.2678L17.5537 24.2485V17.5536Z" fill="url(#paint96_linear_196_10227)" />
                </g>
                <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint97_linear_196_10227)" />
                <g opacity="0.3">
                    <path d="M17.554 8.07285L14.7764 10.8497H20.3309L17.554 8.07285Z" fill="url(#paint98_linear_196_10227)" />
                    <path d="M24.2583 10.8497H20.3306L24.2583 14.7774V10.8497Z" fill="url(#paint99_linear_196_10227)" />
                    <path d="M10.8491 10.8497V14.7774L14.7761 10.8497H10.8491Z" fill="url(#paint100_linear_196_10227)" />
                    <path d="M17.554 27.0344L20.3309 24.2582H14.7764L17.554 27.0344Z" fill="url(#paint101_linear_196_10227)" />
                    <path d="M8.07227 17.5536L10.8492 20.3312V14.7774L8.07227 17.5536Z" fill="url(#paint102_linear_196_10227)" />
                    <path d="M10.8491 24.2582H14.7761L10.8491 20.3312V24.2582Z" fill="url(#paint103_linear_196_10227)" />
                    <path d="M24.2583 24.2582V20.3312L20.3306 24.2582H24.2583Z" fill="url(#paint104_linear_196_10227)" />
                    <path d="M24.2583 20.3312L27.0345 17.5536L24.2583 14.7774V20.3312Z" fill="url(#paint105_linear_196_10227)" />
                </g>
                <g opacity="0.3">
                    <path d="M10.8525 10.8526V14.7782L14.7781 10.8526H10.8525Z" fill="url(#paint106_linear_196_10227)" />
                    <path d="M17.5538 8.07706L14.7783 10.8526H20.3293L17.5538 8.07706Z" fill="url(#paint107_linear_196_10227)" />
                    <path d="M8.07715 17.5537L10.8526 20.3292V14.7782L8.07715 17.5537Z" fill="url(#paint108_linear_196_10227)" />
                    <path d="M24.2554 24.2548V20.3292L20.3291 24.2548H24.2554Z" fill="url(#paint109_linear_196_10227)" />
                    <path d="M10.8525 24.2548H14.7781L10.8525 20.3292V24.2548Z" fill="url(#paint110_linear_196_10227)" />
                    <path d="M17.5538 27.0303L20.3293 24.2548H14.7783L17.5538 27.0303Z" fill="url(#paint111_linear_196_10227)" />
                    <path d="M27.0302 17.5537L24.2554 14.7782V20.3292L27.0302 17.5537Z" fill="url(#paint112_linear_196_10227)" />
                    <path d="M24.2554 14.7782V10.8526H20.3291L24.2554 14.7782Z" fill="url(#paint113_linear_196_10227)" />
                </g>
                <g opacity="0.3">
                    <path d="M3.76318 11.8417L6.12918 17.5537L9.47518 9.47498L3.76318 11.8417Z" fill="url(#paint114_linear_196_10227)" />
                    <path d="M11.8418 3.76297L9.4751 9.47497L17.5538 6.12897L11.8418 3.76297Z" fill="url(#paint115_linear_196_10227)" />
                    <path d="M3.76318 23.2657L9.47518 25.6324L6.12918 17.5537L3.76318 23.2657Z" fill="url(#paint116_linear_196_10227)" />
                    <path d="M31.3448 23.2657L28.9781 17.5537L25.6328 25.6324L31.3448 23.2657Z" fill="url(#paint117_linear_196_10227)" />
                    <path d="M11.8418 31.3444L17.5538 28.9784L9.4751 25.6324L11.8418 31.3444Z" fill="url(#paint118_linear_196_10227)" />
                    <path d="M23.2664 31.3444L25.6324 25.6324L17.5537 28.9784L23.2664 31.3444Z" fill="url(#paint119_linear_196_10227)" />
                    <path d="M31.3448 11.8417L25.6328 9.47498L28.9781 17.5537L31.3448 11.8417Z" fill="url(#paint120_linear_196_10227)" />
                    <path d="M25.6324 9.47497L23.2664 3.76297L17.5537 6.12897L25.6324 9.47497Z" fill="url(#paint121_linear_196_10227)" />
                </g>
                <path opacity="0.3" d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint122_linear_196_10227)" />
                <path opacity="0.1" d="M33.674 17.4543L28.9812 28.7838L17.553 33.5179L6.12479 28.7838L1.43269 17.4557L1.39209 17.5537L6.12549 28.9812L17.5537 33.7153L28.9812 28.9812L33.7153 17.5537L33.674 17.4543Z" fill="white" />
                <path opacity="0.5" d="M1.43339 17.6531L6.12619 6.32356L17.5544 1.58946L28.9826 6.32356L33.6747 17.6517L33.7153 17.5537L28.9819 6.12616L17.5537 1.39206L6.12619 6.12616L1.39209 17.5537L1.43339 17.6531Z" fill="#A6C8ED" />
                <g style={{ mixBlendMode: "multiply" }}>
                    <path d="M28.9816 6.12659L17.5537 1.39206L6.12514 6.12659L1.39209 17.5537L6.12514 28.9816L17.5537 33.7154L28.9816 28.9816L33.7154 17.5537L28.9816 6.12659Z" fill="#D633A8" />
                </g>
                <defs>
                    <linearGradient id="paint0_linear_196_10227" x1="6.1252" y1="13.2262" x2="9.47241" y2="13.2262" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CCE0FA" />
                        <stop offset="0.6681" stopColor="#C9DCF9" />
                        <stop offset="0.724" stopColor="#B9C9E9" />
                        <stop offset="0.8121" stopColor="#A6B0D5" />
                        <stop offset="0.8931" stopColor="#9AA1C9" />
                        <stop offset="0.9596" stopColor="#969CC5" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_196_10227" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E0EBFF" />
                        <stop offset="0.4828" stopColor="#EBF2FF" />
                        <stop offset="0.5393" stopColor="#F0F5FF" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_196_10227" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E0EBFF" />
                        <stop offset="0.4828" stopColor="#EBF2FF" />
                        <stop offset="0.5393" stopColor="#F0F5FF" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_196_10227" x1="6.27745" y1="19.873" x2="10.9939" y2="17.871" gradientUnits="userSpaceOnUse">
                        <stop offset="0.194" stopColor="#AFCED9" />
                        <stop offset="0.2378" stopColor="#BAD6E1" />
                        <stop offset="0.3456" stopColor="#D1E5F2" />
                        <stop offset="0.4483" stopColor="#DEEEFC" />
                        <stop offset="0.5393" stopColor="#E3F1FF" />
                        <stop offset="0.8221" stopColor="#FEFEFD" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_196_10227" x1="6.1252" y1="15.88" x2="10.8592" y2="15.88" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E0EBFF" />
                        <stop offset="0.4828" stopColor="#EBF2FF" />
                        <stop offset="0.5393" stopColor="#F0F5FF" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_196_10227" x1="6.1252" y1="19.2273" x2="10.8592" y2="19.2273" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E0EBFF" />
                        <stop offset="0.4828" stopColor="#EBF2FF" />
                        <stop offset="0.5393" stopColor="#F0F5FF" />
                    </linearGradient>
                    <linearGradient id="paint6_linear_196_10227" x1="14.7016" y1="10.0627" x2="18.3135" y2="7.032" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#B1B9E2" />
                        <stop offset="0.0638" stopColor="#B6BFE5" />
                        <stop offset="0.1464" stopColor="#C4CFEE" />
                        <stop offset="0.2391" stopColor="#DCE9FD" />
                        <stop offset="0.2526" stopColor="#E0EEFF" />
                        <stop offset="0.4048" stopColor="#E4F0FF" />
                        <stop offset="0.4823" stopColor="#E9F3FF" />
                        <stop offset="0.5831" stopColor="#E6F0FD" />
                        <stop offset="0.6609" stopColor="#DCE7F8" />
                        <stop offset="0.7311" stopColor="#CCD9EE" />
                        <stop offset="0.7964" stopColor="#B5C4E0" />
                        <stop offset="0.8141" stopColor="#ADBDDC" />
                    </linearGradient>
                    <linearGradient id="paint7_linear_196_10227" x1="11.5729" y1="7.96082" x2="12.4416" y2="12.8872" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0745" stopColor="#C6E0FF" />
                        <stop offset="0.1248" stopColor="#B6CAEC" />
                        <stop offset="0.2357" stopColor="#969CC5" />
                        <stop offset="0.3834" stopColor="#B4C3E3" />
                        <stop offset="0.5303" stopColor="#CFE5FD" />
                        <stop offset="0.7108" stopColor="#DAEBFF" />
                        <stop offset="1" stopColor="#B9D8F4" />
                    </linearGradient>
                    <linearGradient id="paint8_linear_196_10227" x1="17.9886" y1="11.0262" x2="19.824" y2="6.24497" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#DDE7FE" />
                        <stop offset="0.4345" stopColor="#D4DEF7" />
                        <stop offset="0.463" stopColor="#B6BFDD" />
                        <stop offset="0.4908" stopColor="#A0A8CB" />
                        <stop offset="0.5171" stopColor="#939BC0" />
                        <stop offset="0.5404" stopColor="#8E96BC" />
                    </linearGradient>
                    <linearGradient id="paint9_linear_196_10227" x1="19.5141" y1="7.79929" x2="24.2472" y2="7.79929" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E7F0FF" />
                        <stop offset="0.4617" stopColor="#D6E6FB" />
                        <stop offset="0.5034" stopColor="#C9DEF7" />
                        <stop offset="0.5404" stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint10_linear_196_10227" x1="11.161" y1="8.79771" x2="15.8767" y2="6.79603" gradientUnits="userSpaceOnUse">
                        <stop offset="0.194" stopColor="#AFCED9" />
                        <stop offset="0.2378" stopColor="#BAD6E1" />
                        <stop offset="0.3456" stopColor="#D1E5F2" />
                        <stop offset="0.4483" stopColor="#DEEEFC" />
                        <stop offset="0.5393" stopColor="#E3F1FF" />
                        <stop offset="0.8221" stopColor="#FEFEFD" />
                    </linearGradient>
                    <linearGradient id="paint11_linear_196_10227" x1="10.8592" y1="27.3082" x2="15.5923" y2="27.3082" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E7F0FF" />
                        <stop offset="0.4617" stopColor="#D6E6FB" />
                        <stop offset="0.5034" stopColor="#C9DEF7" />
                        <stop offset="0.5404" stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint12_linear_196_10227" x1="20.9008" y1="24.6542" x2="25.634" y2="24.6542" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint13_linear_196_10227" x1="19.2298" y1="28.3116" x2="23.9455" y2="26.3099" gradientUnits="userSpaceOnUse">
                        <stop offset="0.194" stopColor="#AFCED9" />
                        <stop offset="0.2378" stopColor="#BAD6E1" />
                        <stop offset="0.3456" stopColor="#D1E5F2" />
                        <stop offset="0.4483" stopColor="#DEEEFC" />
                        <stop offset="0.5393" stopColor="#E3F1FF" />
                        <stop offset="0.8221" stopColor="#FEFEFD" />
                    </linearGradient>
                    <linearGradient id="paint14_linear_196_10227" x1="6.1252" y1="21.8813" x2="9.47241" y2="21.8813" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CCE0FA" />
                        <stop offset="0.6681" stopColor="#C9DCF9" />
                        <stop offset="0.724" stopColor="#B9C9E9" />
                        <stop offset="0.8121" stopColor="#A6B0D5" />
                        <stop offset="0.8931" stopColor="#9AA1C9" />
                        <stop offset="0.9596" stopColor="#969CC5" />
                    </linearGradient>
                    <linearGradient id="paint15_linear_196_10227" x1="16.7631" y1="27.8093" x2="20.615" y2="25.2111" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E7F0FF" />
                        <stop offset="0.4617" stopColor="#D6E6FB" />
                        <stop offset="0.5034" stopColor="#C9DEF7" />
                        <stop offset="0.5404" stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint16_linear_196_10227" x1="22.2869" y1="23.2679" x2="27.0209" y2="23.2679" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E7F0FF" />
                        <stop offset="0.4617" stopColor="#D6E6FB" />
                        <stop offset="0.5034" stopColor="#C9DEF7" />
                        <stop offset="0.5404" stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint17_linear_196_10227" x1="8.08557" y1="23.2679" x2="12.8197" y2="23.2679" gradientUnits="userSpaceOnUse">
                        <stop offset="0.3389" stopColor="#F3FCFF" />
                        <stop offset="0.352" stopColor="#F0FAFE" />
                        <stop offset="0.4365" stopColor="#E3EEFB" />
                        <stop offset="0.5115" stopColor="#DEEAFA" />
                        <stop offset="0.7158" stopColor="#DAE6F8" />
                        <stop offset="0.8158" stopColor="#CAD3F2" />
                    </linearGradient>
                    <linearGradient id="paint18_linear_196_10227" x1="14.2063" y1="26.6148" x2="17.5534" y2="26.6148" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CCE0FA" />
                        <stop offset="0.6681" stopColor="#C9DCF9" />
                        <stop offset="0.724" stopColor="#B9C9E9" />
                        <stop offset="0.8121" stopColor="#A6B0D5" />
                        <stop offset="0.8931" stopColor="#9AA1C9" />
                        <stop offset="0.9596" stopColor="#969CC5" />
                    </linearGradient>
                    <linearGradient id="paint19_linear_196_10227" x1="11.2499" y1="27.1708" x2="13.0853" y2="22.3896" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#DDE7FE" />
                        <stop offset="0.4345" stopColor="#D4DEF7" />
                        <stop offset="0.463" stopColor="#B6BFDD" />
                        <stop offset="0.4908" stopColor="#A0A8CB" />
                        <stop offset="0.5171" stopColor="#939BC0" />
                        <stop offset="0.5404" stopColor="#8E96BC" />
                    </linearGradient>
                    <linearGradient id="paint20_linear_196_10227" x1="20.9008" y1="10.4536" x2="25.634" y2="10.4536" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E0EBFF" />
                        <stop offset="0.4828" stopColor="#EBF2FF" />
                        <stop offset="0.5393" stopColor="#F0F5FF" />
                    </linearGradient>
                    <linearGradient id="paint21_linear_196_10227" x1="11.6988" y1="19.9893" x2="17.3881" y2="17.2144" gradientUnits="userSpaceOnUse">
                        <stop offset="0.3389" stopColor="#F3FCFF" />
                        <stop offset="0.352" stopColor="#F0FAFE" />
                        <stop offset="0.4365" stopColor="#E3EEFB" />
                        <stop offset="0.5115" stopColor="#DEEAFA" />
                        <stop offset="0.7158" stopColor="#DAE6F8" />
                        <stop offset="0.8158" stopColor="#CAD3F2" />
                    </linearGradient>
                    <linearGradient id="paint22_linear_196_10227" x1="21.2137" y1="9.42334" x2="19.7324" y2="12.1909" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CCE0FA" />
                        <stop offset="0.6681" stopColor="#C9DCF9" />
                        <stop offset="0.724" stopColor="#B9C9E9" />
                        <stop offset="0.8121" stopColor="#A6B0D5" />
                        <stop offset="0.8931" stopColor="#9AA1C9" />
                        <stop offset="0.9596" stopColor="#969CC5" />
                    </linearGradient>
                    <linearGradient id="paint23_linear_196_10227" x1="22.2869" y1="15.1871" x2="25.6343" y2="15.1871" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CCE0FA" />
                        <stop offset="0.6681" stopColor="#C9DCF9" />
                        <stop offset="0.724" stopColor="#B9C9E9" />
                        <stop offset="0.8121" stopColor="#A6B0D5" />
                        <stop offset="0.8931" stopColor="#9AA1C9" />
                        <stop offset="0.9596" stopColor="#969CC5" />
                    </linearGradient>
                    <linearGradient id="paint24_linear_196_10227" x1="17.5536" y1="14.2065" x2="22.2869" y2="14.2065" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#B1B9E2" />
                        <stop offset="0.0638" stopColor="#B6BFE5" />
                        <stop offset="0.1464" stopColor="#C4CFEE" />
                        <stop offset="0.2391" stopColor="#DCE9FD" />
                        <stop offset="0.2526" stopColor="#E0EEFF" />
                        <stop offset="0.4048" stopColor="#E4F0FF" />
                        <stop offset="0.4823" stopColor="#E9F3FF" />
                        <stop offset="0.5831" stopColor="#E6F0FD" />
                        <stop offset="0.6609" stopColor="#DCE7F8" />
                        <stop offset="0.7311" stopColor="#CCD9EE" />
                        <stop offset="0.7964" stopColor="#B5C4E0" />
                        <stop offset="0.8141" stopColor="#ADBDDC" />
                    </linearGradient>
                    <linearGradient id="paint25_linear_196_10227" x1="17.5536" y1="15.1871" x2="24.2475" y2="15.1871" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E0EBFF" />
                        <stop offset="0.4828" stopColor="#EBF2FF" />
                        <stop offset="0.5393" stopColor="#F0F5FF" />
                    </linearGradient>
                    <linearGradient id="paint26_linear_196_10227" x1="14.5549" y1="13.4865" x2="15.8531" y2="10.1044" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#DDE7FE" />
                        <stop offset="0.4345" stopColor="#D4DEF7" />
                        <stop offset="0.463" stopColor="#B6BFDD" />
                        <stop offset="0.4908" stopColor="#A0A8CB" />
                        <stop offset="0.5171" stopColor="#939BC0" />
                        <stop offset="0.5404" stopColor="#8E96BC" />
                    </linearGradient>
                    <linearGradient id="paint27_linear_196_10227" x1="15.6248" y1="9.65069" x2="15.0458" y2="12.5457" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E7F0FF" />
                        <stop offset="0.4617" stopColor="#D6E6FB" />
                        <stop offset="0.5034" stopColor="#C9DEF7" />
                        <stop offset="0.5404" stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint28_linear_196_10227" x1="23.9881" y1="13.5744" x2="25.5719" y2="9.44851" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#DDE7FE" />
                        <stop offset="0.4345" stopColor="#D4DEF7" />
                        <stop offset="0.463" stopColor="#B6BFDD" />
                        <stop offset="0.4908" stopColor="#A0A8CB" />
                        <stop offset="0.5171" stopColor="#939BC0" />
                        <stop offset="0.5404" stopColor="#8E96BC" />
                    </linearGradient>
                    <linearGradient id="paint29_linear_196_10227" x1="12.82" y1="23.9613" x2="17.5539" y2="23.9613" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CDE7F8" />
                        <stop offset="0.4833" stopColor="#E3F2FB" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient id="paint30_linear_196_10227" x1="9.47238" y1="15.1871" x2="12.8197" y2="15.1871" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFCED9" />
                        <stop offset="0.1268" stopColor="#BAD6E1" />
                        <stop offset="0.439" stopColor="#D1E5F2" />
                        <stop offset="0.7363" stopColor="#DEEEFC" />
                        <stop offset="1" stopColor="#E3F1FF" />
                    </linearGradient>
                    <linearGradient id="paint31_linear_196_10227" x1="11.1499" y1="13.5571" x2="11.4714" y2="17.2228" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1898" stopColor="#E5EEFD" />
                        <stop offset="0.3903" stopColor="#D2E2FC" />
                        <stop offset="0.5937" stopColor="#C6DAFB" />
                        <stop offset="0.8022" stopColor="#C2D8FB" />
                        <stop offset="0.9293" stopColor="#B6D0EE" />
                        <stop offset="1" stopColor="#ADCBE5" />
                    </linearGradient>
                    <linearGradient id="paint32_linear_196_10227" x1="10.4464" y1="17.6264" x2="11.3151" y2="22.553" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0745" stopColor="#C6E0FF" />
                        <stop offset="0.1248" stopColor="#B6CAEC" />
                        <stop offset="0.2357" stopColor="#969CC5" />
                        <stop offset="0.3834" stopColor="#B4C3E3" />
                        <stop offset="0.5303" stopColor="#CFE5FD" />
                        <stop offset="0.7108" stopColor="#DAEBFF" />
                        <stop offset="1" stopColor="#B9D8F4" />
                    </linearGradient>
                    <linearGradient id="paint33_linear_196_10227" x1="12.0043" y1="19.8213" x2="9.52433" y2="21.3161" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="0.1898" stopColor="#E5EEFD" />
                        <stop offset="0.3903" stopColor="#D2E2FC" />
                        <stop offset="0.5937" stopColor="#C6DAFB" />
                        <stop offset="0.8022" stopColor="#C2D8FB" />
                        <stop offset="0.9293" stopColor="#B6D0EE" />
                        <stop offset="1" stopColor="#ADCBE5" />
                    </linearGradient>
                    <linearGradient id="paint34_linear_196_10227" x1="18.0586" y1="25.4377" x2="22.7744" y2="23.436" gradientUnits="userSpaceOnUse">
                        <stop offset="0.194" stopColor="#AFCED9" />
                        <stop offset="0.2378" stopColor="#BAD6E1" />
                        <stop offset="0.3456" stopColor="#D1E5F2" />
                        <stop offset="0.4483" stopColor="#DEEEFC" />
                        <stop offset="0.5393" stopColor="#E3F1FF" />
                        <stop offset="0.8221" stopColor="#FEFEFD" />
                    </linearGradient>
                    <linearGradient id="paint35_linear_196_10227" x1="24.2472" y1="19.2273" x2="28.9811" y2="19.2273" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFCED9" />
                        <stop offset="0.1268" stopColor="#BAD6E1" />
                        <stop offset="0.439" stopColor="#D1E5F2" />
                        <stop offset="0.7363" stopColor="#DEEEFC" />
                        <stop offset="1" stopColor="#E3F1FF" />
                    </linearGradient>
                    <linearGradient id="paint36_linear_196_10227" x1="25.3801" y1="14.3471" x2="27.5861" y2="18.3269" gradientUnits="userSpaceOnUse">
                        <stop offset="0.194" stopColor="#AFCED9" />
                        <stop offset="0.3016" stopColor="#BBD6E2" />
                        <stop offset="0.4956" stopColor="#DBECF9" />
                        <stop offset="0.5393" stopColor="#E3F1FF" />
                        <stop offset="1" stopColor="#C3DAF4" />
                    </linearGradient>
                    <linearGradient id="paint37_linear_196_10227" x1="22.4939" y1="18.5256" x2="24.2392" y2="21.6742" gradientUnits="userSpaceOnUse">
                        <stop offset="0.194" stopColor="#AFCED9" />
                        <stop offset="0.3016" stopColor="#BBD6E2" />
                        <stop offset="0.4956" stopColor="#DBECF9" />
                        <stop offset="0.5393" stopColor="#E3F1FF" />
                        <stop offset="1" stopColor="#C3DAF4" />
                    </linearGradient>
                    <linearGradient id="paint38_linear_196_10227" x1="25.634" y1="13.2262" x2="28.9811" y2="13.2262" gradientUnits="userSpaceOnUse">
                        <stop offset="0.3389" stopColor="#F3FCFF" />
                        <stop offset="0.352" stopColor="#F0FAFE" />
                        <stop offset="0.4365" stopColor="#E3EEFB" />
                        <stop offset="0.5115" stopColor="#DEEAFA" />
                        <stop offset="0.7158" stopColor="#DAE6F8" />
                        <stop offset="0.8158" stopColor="#CAD3F2" />
                    </linearGradient>
                    <linearGradient id="paint39_linear_196_10227" x1="17.5536" y1="20.9009" x2="22.2869" y2="20.9009" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CDE7F8" />
                        <stop offset="0.4833" stopColor="#E3F2FB" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient id="paint40_linear_196_10227" x1="26.3129" y1="22.5807" x2="29.6766" y2="21.1529" gradientUnits="userSpaceOnUse">
                        <stop offset="0.194" stopColor="#AFCED9" />
                        <stop offset="0.2378" stopColor="#BAD6E1" />
                        <stop offset="0.3456" stopColor="#D1E5F2" />
                        <stop offset="0.4483" stopColor="#DEEEFC" />
                        <stop offset="0.5393" stopColor="#E3F1FF" />
                        <stop offset="0.8221" stopColor="#FEFEFD" />
                    </linearGradient>
                    <linearGradient id="paint41_linear_196_10227" x1="12.82" y1="20.9009" x2="17.5539" y2="20.9009" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CCE0FA" />
                        <stop offset="0.6681" stopColor="#C9DCF9" />
                        <stop offset="0.724" stopColor="#B9C9E9" />
                        <stop offset="0.8121" stopColor="#A6B0D5" />
                        <stop offset="0.8931" stopColor="#9AA1C9" />
                        <stop offset="0.9596" stopColor="#969CC5" />
                    </linearGradient>
                    <linearGradient id="paint42_linear_196_10227" x1="10.8592" y1="15.1871" x2="17.5536" y2="15.1871" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CDE7F8" />
                        <stop offset="0.4833" stopColor="#E3F2FB" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient id="paint43_linear_196_10227" x1="17.5536" y1="19.9207" x2="24.2475" y2="19.9207" gradientUnits="userSpaceOnUse">
                        <stop offset="0.2198" stopColor="#7D85A4" />
                        <stop offset="0.4596" stopColor="#C4DBF6" />
                        <stop offset="0.4966" stopColor="#C9DEF7" />
                        <stop offset="0.5383" stopColor="#D6E6FB" />
                        <stop offset="0.5726" stopColor="#E7F0FF" />
                    </linearGradient>
                    <linearGradient id="paint44_linear_196_10227" x1="15.0906" y1="11.2936" x2="16.2354" y2="17.7861" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0745" stopColor="#C6E0FF" />
                        <stop offset="0.1248" stopColor="#B6CAEC" />
                        <stop offset="0.2357" stopColor="#969CC5" />
                        <stop offset="0.3834" stopColor="#B4C3E3" />
                        <stop offset="0.5303" stopColor="#CFE5FD" />
                        <stop offset="0.7108" stopColor="#DAEBFF" />
                        <stop offset="1" stopColor="#B9D8F4" />
                    </linearGradient>
                    <linearGradient id="paint45_linear_196_10227" x1="4.57363" y1="26.681" x2="8.11527" y2="24.2922" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E7F0FF" />
                        <stop offset="0.4617" stopColor="#D6E6FB" />
                        <stop offset="0.5034" stopColor="#C9DEF7" />
                        <stop offset="0.5404" stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint46_linear_196_10227" x1="6.1252" y1="27.3082" x2="10.8592" y2="27.3082" gradientUnits="userSpaceOnUse">
                        <stop offset="0.2198" stopColor="#7D85A4" />
                        <stop offset="0.4596" stopColor="#C4DBF6" />
                        <stop offset="0.4966" stopColor="#C9DEF7" />
                        <stop offset="0.5383" stopColor="#D6E6FB" />
                        <stop offset="0.5726" stopColor="#E7F0FF" />
                    </linearGradient>
                    <linearGradient id="paint47_linear_196_10227" x1="6.1252" y1="26.6148" x2="9.47241" y2="26.6148" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E0EBFF" />
                        <stop offset="0.4828" stopColor="#EBF2FF" />
                        <stop offset="0.5393" stopColor="#F0F5FF" />
                    </linearGradient>
                    <linearGradient id="paint48_linear_196_10227" x1="6.63623" y1="30.1853" x2="12.3087" y2="27.7774" gradientUnits="userSpaceOnUse">
                        <stop offset="0.194" stopColor="#AFCED9" />
                        <stop offset="0.2378" stopColor="#BAD6E1" />
                        <stop offset="0.3456" stopColor="#D1E5F2" />
                        <stop offset="0.4483" stopColor="#DEEEFC" />
                        <stop offset="0.5393" stopColor="#E3F1FF" />
                        <stop offset="0.8221" stopColor="#FEFEFD" />
                    </linearGradient>
                    <linearGradient id="paint49_linear_196_10227" x1="6.1252" y1="31.3484" x2="17.5536" y2="31.3484" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E0EBFF" />
                        <stop offset="0.4828" stopColor="#EBF2FF" />
                        <stop offset="0.5393" stopColor="#F0F5FF" />
                    </linearGradient>
                    <linearGradient id="paint50_linear_196_10227" x1="1.39207" y1="23.2675" x2="6.1252" y2="23.2675" gradientUnits="userSpaceOnUse">
                        <stop offset="0.1929" stopColor="#C9E0EB" />
                        <stop offset="0.5344" stopColor="#CADEEF" />
                        <stop offset="0.8146" stopColor="#D7E8FA" />
                        <stop offset="1" stopColor="#D7E8FA" />
                    </linearGradient>
                    <linearGradient id="paint51_linear_196_10227" x1="14.7108" y1="28.648" x2="15.6631" y2="34.0488" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0745" stopColor="#C6E0FF" />
                        <stop offset="0.1248" stopColor="#B6CAEC" />
                        <stop offset="0.2357" stopColor="#969CC5" />
                        <stop offset="0.3834" stopColor="#B4C3E3" />
                        <stop offset="0.5303" stopColor="#CFE5FD" />
                        <stop offset="0.7108" stopColor="#DAEBFF" />
                        <stop offset="1" stopColor="#B9D8F4" />
                    </linearGradient>
                    <linearGradient id="paint52_linear_196_10227" x1="1.84685" y1="18.4861" x2="6.44289" y2="16.2445" gradientUnits="userSpaceOnUse">
                        <stop offset="0.3389" stopColor="#F3FCFF" />
                        <stop offset="0.352" stopColor="#F0FAFE" />
                        <stop offset="0.4365" stopColor="#E3EEFB" />
                        <stop offset="0.5115" stopColor="#DEEAFA" />
                        <stop offset="0.7158" stopColor="#DAE6F8" />
                        <stop offset="0.8158" stopColor="#CAD3F2" />
                    </linearGradient>
                    <linearGradient id="paint53_linear_196_10227" x1="2.58657" y1="19.3246" x2="5.83967" y2="17.1303" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E7F0FF" />
                        <stop offset="0.4617" stopColor="#D6E6FB" />
                        <stop offset="0.5034" stopColor="#C9DEF7" />
                        <stop offset="0.5404" stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint54_linear_196_10227" x1="2.78671" y1="16.7806" x2="5.90652" y2="22.4089" gradientUnits="userSpaceOnUse">
                        <stop offset="0.194" stopColor="#AFCED9" />
                        <stop offset="0.3016" stopColor="#BBD6E2" />
                        <stop offset="0.4956" stopColor="#DBECF9" />
                        <stop offset="0.5393" stopColor="#E3F1FF" />
                        <stop offset="1" stopColor="#C3DAF4" />
                    </linearGradient>
                    <linearGradient id="paint55_linear_196_10227" x1="16.8094" y1="32.1895" x2="22.4983" y2="29.4148" gradientUnits="userSpaceOnUse">
                        <stop offset="0.3389" stopColor="#F3FCFF" />
                        <stop offset="0.352" stopColor="#F0FAFE" />
                        <stop offset="0.4365" stopColor="#E3EEFB" />
                        <stop offset="0.5115" stopColor="#DEEAFA" />
                        <stop offset="0.7158" stopColor="#DAE6F8" />
                        <stop offset="0.8158" stopColor="#CAD3F2" />
                    </linearGradient>
                    <linearGradient id="paint56_linear_196_10227" x1="27.0944" y1="27.3754" x2="28.591" y2="26.1196" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#B1B9E2" />
                        <stop offset="0.0638" stopColor="#B6BFE5" />
                        <stop offset="0.1464" stopColor="#C4CFEE" />
                        <stop offset="0.2391" stopColor="#DCE9FD" />
                        <stop offset="0.2526" stopColor="#E0EEFF" />
                        <stop offset="0.4048" stopColor="#E4F0FF" />
                        <stop offset="0.4823" stopColor="#E9F3FF" />
                        <stop offset="0.5831" stopColor="#E6F0FD" />
                        <stop offset="0.6609" stopColor="#DCE7F8" />
                        <stop offset="0.7311" stopColor="#CCD9EE" />
                        <stop offset="0.7964" stopColor="#B5C4E0" />
                        <stop offset="0.8141" stopColor="#ADBDDC" />
                    </linearGradient>
                    <linearGradient id="paint57_linear_196_10227" x1="27.8628" y1="22.4848" x2="29.0075" y2="28.9767" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0745" stopColor="#C6E0FF" />
                        <stop offset="0.1248" stopColor="#B6CAEC" />
                        <stop offset="0.2357" stopColor="#969CC5" />
                        <stop offset="0.3834" stopColor="#B4C3E3" />
                        <stop offset="0.5303" stopColor="#CFE5FD" />
                        <stop offset="0.7108" stopColor="#DAEBFF" />
                        <stop offset="1" stopColor="#B9D8F4" />
                    </linearGradient>
                    <linearGradient id="paint58_linear_196_10227" x1="24.2472" y1="27.3082" x2="28.9811" y2="27.3082" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0080863" stopColor="#D9E8F9" />
                        <stop offset="0.113" stopColor="#E3EEFB" />
                        <stop offset="0.3197" stopColor="#F0F6FE" />
                        <stop offset="0.5034" stopColor="#F5F9FF" />
                        <stop offset="0.7479" stopColor="#F2F8FF" />
                        <stop offset="0.9366" stopColor="#E8F3FF" />
                        <stop offset="1" stopColor="#E3F1FF" />
                    </linearGradient>
                    <linearGradient id="paint59_linear_196_10227" x1="28.9811" y1="23.2675" x2="33.7152" y2="23.2675" gradientUnits="userSpaceOnUse">
                        <stop offset="0.1929" stopColor="#C9E0EB" />
                        <stop offset="0.5344" stopColor="#CADEEF" />
                        <stop offset="0.8146" stopColor="#D7E8FA" />
                        <stop offset="1" stopColor="#D7E8FA" />
                    </linearGradient>
                    <linearGradient id="paint60_linear_196_10227" x1="18.5613" y1="34.9162" x2="27.5983" y2="27.3332" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#B1B9E2" />
                        <stop offset="0.0638" stopColor="#B6BFE5" />
                        <stop offset="0.1464" stopColor="#C4CFEE" />
                        <stop offset="0.2391" stopColor="#DCE9FD" />
                        <stop offset="0.2526" stopColor="#E0EEFF" />
                        <stop offset="0.4048" stopColor="#E4F0FF" />
                        <stop offset="0.4823" stopColor="#E9F3FF" />
                        <stop offset="0.5831" stopColor="#E6F0FD" />
                        <stop offset="0.6609" stopColor="#DCE7F8" />
                        <stop offset="0.7311" stopColor="#CCD9EE" />
                        <stop offset="0.7964" stopColor="#B5C4E0" />
                        <stop offset="0.8141" stopColor="#ADBDDC" />
                    </linearGradient>
                    <linearGradient id="paint61_linear_196_10227" x1="22.2869" y1="28.0011" x2="28.9813" y2="28.0011" gradientUnits="userSpaceOnUse">
                        <stop offset="0.2198" stopColor="#7D85A4" />
                        <stop offset="0.4596" stopColor="#C4DBF6" />
                        <stop offset="0.4966" stopColor="#C9DEF7" />
                        <stop offset="0.5383" stopColor="#D6E6FB" />
                        <stop offset="0.5726" stopColor="#E7F0FF" />
                    </linearGradient>
                    <linearGradient id="paint62_linear_196_10227" x1="1.39207" y1="15.1871" x2="6.1252" y2="15.1871" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CDE7F8" />
                        <stop offset="0.4833" stopColor="#E3F2FB" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient id="paint63_linear_196_10227" x1="17.5536" y1="31.3484" x2="19.5143" y2="31.3484" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0080863" stopColor="#D9E8F9" />
                        <stop offset="0.113" stopColor="#E3EEFB" />
                        <stop offset="0.3197" stopColor="#F0F6FE" />
                        <stop offset="0.5034" stopColor="#F5F9FF" />
                        <stop offset="0.7479" stopColor="#F2F8FF" />
                        <stop offset="0.9366" stopColor="#E8F3FF" />
                        <stop offset="1" stopColor="#E3F1FF" />
                    </linearGradient>
                    <linearGradient id="paint64_linear_196_10227" x1="15.9829" y1="31.8435" x2="18.314" y2="29.8875" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#B1B9E2" />
                        <stop offset="0.0638" stopColor="#B6BFE5" />
                        <stop offset="0.1464" stopColor="#C4CFEE" />
                        <stop offset="0.2391" stopColor="#DCE9FD" />
                        <stop offset="0.2526" stopColor="#E0EEFF" />
                        <stop offset="0.4048" stopColor="#E4F0FF" />
                        <stop offset="0.4823" stopColor="#E9F3FF" />
                        <stop offset="0.5831" stopColor="#E6F0FD" />
                        <stop offset="0.6609" stopColor="#DCE7F8" />
                        <stop offset="0.7311" stopColor="#CCD9EE" />
                        <stop offset="0.7964" stopColor="#B5C4E0" />
                        <stop offset="0.8141" stopColor="#ADBDDC" />
                    </linearGradient>
                    <linearGradient id="paint65_linear_196_10227" x1="24.6535" y1="8.91961" x2="29.2501" y2="6.67769" gradientUnits="userSpaceOnUse">
                        <stop offset="0.3389" stopColor="#F3FCFF" />
                        <stop offset="0.352" stopColor="#F0FAFE" />
                        <stop offset="0.4365" stopColor="#E3EEFB" />
                        <stop offset="0.5115" stopColor="#DEEAFA" />
                        <stop offset="0.7158" stopColor="#DAE6F8" />
                        <stop offset="0.8158" stopColor="#CAD3F2" />
                    </linearGradient>
                    <linearGradient id="paint66_linear_196_10227" x1="26.9912" y1="10.8155" x2="30.5328" y2="8.42666" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E7F0FF" />
                        <stop offset="0.4617" stopColor="#D6E6FB" />
                        <stop offset="0.5034" stopColor="#C9DEF7" />
                        <stop offset="0.5404" stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint67_linear_196_10227" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0080863" stopColor="#D9E8F9" />
                        <stop offset="0.113" stopColor="#E3EEFB" />
                        <stop offset="0.3197" stopColor="#F0F6FE" />
                        <stop offset="0.5034" stopColor="#F5F9FF" />
                        <stop offset="0.7479" stopColor="#F2F8FF" />
                        <stop offset="0.9366" stopColor="#E8F3FF" />
                        <stop offset="1" stopColor="#E3F1FF" />
                    </linearGradient>
                    <linearGradient id="paint68_linear_196_10227" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CCE0FA" />
                        <stop offset="0.6681" stopColor="#C9DCF9" />
                        <stop offset="0.724" stopColor="#B9C9E9" />
                        <stop offset="0.8121" stopColor="#A6B0D5" />
                        <stop offset="0.8931" stopColor="#9AA1C9" />
                        <stop offset="0.9596" stopColor="#969CC5" />
                    </linearGradient>
                    <linearGradient id="paint69_linear_196_10227" x1="25.634" y1="8.49268" x2="28.9811" y2="8.49268" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFCED9" />
                        <stop offset="0.1268" stopColor="#BAD6E1" />
                        <stop offset="0.439" stopColor="#D1E5F2" />
                        <stop offset="0.7363" stopColor="#DEEEFC" />
                        <stop offset="1" stopColor="#E3F1FF" />
                    </linearGradient>
                    <linearGradient id="paint70_linear_196_10227" x1="22.2869" y1="7.1064" x2="28.9813" y2="7.1064" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CDE7F8" />
                        <stop offset="0.4833" stopColor="#E3F2FB" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient id="paint71_linear_196_10227" x1="28.2983" y1="21.4739" x2="33.4074" y2="17.1868" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#B1B9E2" />
                        <stop offset="0.0638" stopColor="#B6BFE5" />
                        <stop offset="0.1464" stopColor="#C4CFEE" />
                        <stop offset="0.2391" stopColor="#DCE9FD" />
                        <stop offset="0.2526" stopColor="#E0EEFF" />
                        <stop offset="0.4048" stopColor="#E4F0FF" />
                        <stop offset="0.4823" stopColor="#E9F3FF" />
                        <stop offset="0.5831" stopColor="#E6F0FD" />
                        <stop offset="0.6609" stopColor="#DCE7F8" />
                        <stop offset="0.7311" stopColor="#CCD9EE" />
                        <stop offset="0.7964" stopColor="#B5C4E0" />
                        <stop offset="0.8141" stopColor="#ADBDDC" />
                    </linearGradient>
                    <linearGradient id="paint72_linear_196_10227" x1="-0.301693" y1="13.5634" x2="7.81896" y2="10.1164" gradientUnits="userSpaceOnUse">
                        <stop offset="0.194" stopColor="#AFCED9" />
                        <stop offset="0.2378" stopColor="#BAD6E1" />
                        <stop offset="0.3456" stopColor="#D1E5F2" />
                        <stop offset="0.4483" stopColor="#DEEEFC" />
                        <stop offset="0.5393" stopColor="#E3F1FF" />
                        <stop offset="0.8221" stopColor="#FEFEFD" />
                    </linearGradient>
                    <linearGradient id="paint73_linear_196_10227" x1="1.39207" y1="11.8399" x2="6.1252" y2="11.8399" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CCE0FA" />
                        <stop offset="0.6681" stopColor="#C9DCF9" />
                        <stop offset="0.724" stopColor="#B9C9E9" />
                        <stop offset="0.8121" stopColor="#A6B0D5" />
                        <stop offset="0.8931" stopColor="#9AA1C9" />
                        <stop offset="0.9596" stopColor="#969CC5" />
                    </linearGradient>
                    <linearGradient id="paint74_linear_196_10227" x1="29.4764" y1="18.144" x2="32.2545" y2="15.8128" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#B1B9E2" />
                        <stop offset="0.0638" stopColor="#B6BFE5" />
                        <stop offset="0.1464" stopColor="#C4CFEE" />
                        <stop offset="0.2391" stopColor="#DCE9FD" />
                        <stop offset="0.2526" stopColor="#E0EEFF" />
                        <stop offset="0.4048" stopColor="#E4F0FF" />
                        <stop offset="0.4823" stopColor="#E9F3FF" />
                        <stop offset="0.5831" stopColor="#E6F0FD" />
                        <stop offset="0.6609" stopColor="#DCE7F8" />
                        <stop offset="0.7311" stopColor="#CCD9EE" />
                        <stop offset="0.7964" stopColor="#B5C4E0" />
                        <stop offset="0.8141" stopColor="#ADBDDC" />
                    </linearGradient>
                    <linearGradient id="paint75_linear_196_10227" x1="29.533" y1="16.4114" x2="31.8779" y2="14.8297" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E7F0FF" />
                        <stop offset="0.4617" stopColor="#D6E6FB" />
                        <stop offset="0.5034" stopColor="#C9DEF7" />
                        <stop offset="0.5404" stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint76_linear_196_10227" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFCED9" />
                        <stop offset="0.1268" stopColor="#BAD6E1" />
                        <stop offset="0.439" stopColor="#D1E5F2" />
                        <stop offset="0.7363" stopColor="#DEEEFC" />
                        <stop offset="1" stopColor="#E3F1FF" />
                    </linearGradient>
                    <linearGradient id="paint77_linear_196_10227" x1="28.9811" y1="18.534" x2="33.7152" y2="18.534" gradientUnits="userSpaceOnUse">
                        <stop offset="0.2198" stopColor="#7D85A4" />
                        <stop offset="0.4596" stopColor="#C4DBF6" />
                        <stop offset="0.4966" stopColor="#C9DEF7" />
                        <stop offset="0.5383" stopColor="#D6E6FB" />
                        <stop offset="0.5726" stopColor="#E7F0FF" />
                    </linearGradient>
                    <linearGradient id="paint78_linear_196_10227" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFCED9" />
                        <stop offset="0.1268" stopColor="#BAD6E1" />
                        <stop offset="0.439" stopColor="#D1E5F2" />
                        <stop offset="0.7363" stopColor="#DEEEFC" />
                        <stop offset="1" stopColor="#E3F1FF" />
                    </linearGradient>
                    <linearGradient id="paint79_linear_196_10227" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CCE0FA" />
                        <stop offset="0.6681" stopColor="#C9DCF9" />
                        <stop offset="0.724" stopColor="#B9C9E9" />
                        <stop offset="0.8121" stopColor="#A6B0D5" />
                        <stop offset="0.8931" stopColor="#9AA1C9" />
                        <stop offset="0.9596" stopColor="#969CC5" />
                    </linearGradient>
                    <linearGradient id="paint80_linear_196_10227" x1="6.1252" y1="7.79929" x2="10.8592" y2="7.79929" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0080863" stopColor="#D9E8F9" />
                        <stop offset="0.113" stopColor="#E3EEFB" />
                        <stop offset="0.3197" stopColor="#F0F6FE" />
                        <stop offset="0.5034" stopColor="#F5F9FF" />
                        <stop offset="0.7479" stopColor="#F2F8FF" />
                        <stop offset="0.9366" stopColor="#E8F3FF" />
                        <stop offset="1" stopColor="#E3F1FF" />
                    </linearGradient>
                    <linearGradient id="paint81_linear_196_10227" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                        <stop offset="0.2198" stopColor="#7D85A4" />
                        <stop offset="0.4596" stopColor="#C4DBF6" />
                        <stop offset="0.4966" stopColor="#C9DEF7" />
                        <stop offset="0.5383" stopColor="#D6E6FB" />
                        <stop offset="0.5726" stopColor="#E7F0FF" />
                    </linearGradient>
                    <linearGradient id="paint82_linear_196_10227" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CCE0FA" />
                        <stop offset="0.6681" stopColor="#C9DCF9" />
                        <stop offset="0.724" stopColor="#B9C9E9" />
                        <stop offset="0.8121" stopColor="#A6B0D5" />
                        <stop offset="0.8931" stopColor="#9AA1C9" />
                        <stop offset="0.9596" stopColor="#969CC5" />
                    </linearGradient>
                    <linearGradient id="paint83_linear_196_10227" x1="19.7778" y1="2.95104" x2="20.3705" y2="6.31258" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0745" stopColor="#C6E0FF" />
                        <stop offset="0.1248" stopColor="#B6CAEC" />
                        <stop offset="0.2357" stopColor="#969CC5" />
                        <stop offset="0.3834" stopColor="#B4C3E3" />
                        <stop offset="0.5303" stopColor="#CFE5FD" />
                        <stop offset="0.7108" stopColor="#DAEBFF" />
                        <stop offset="1" stopColor="#B9D8F4" />
                    </linearGradient>
                    <linearGradient id="paint84_linear_196_10227" x1="4.57346" y1="10.52" x2="7.67693" y2="8.42666" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E7F0FF" />
                        <stop offset="0.4617" stopColor="#D6E6FB" />
                        <stop offset="0.5034" stopColor="#C9DEF7" />
                        <stop offset="0.5404" stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint85_linear_196_10227" x1="15.0366" y1="4.98668" x2="18.4863" y2="3.30416" gradientUnits="userSpaceOnUse">
                        <stop offset="0.3389" stopColor="#F3FCFF" />
                        <stop offset="0.352" stopColor="#F0FAFE" />
                        <stop offset="0.4365" stopColor="#E3EEFB" />
                        <stop offset="0.5115" stopColor="#DEEAFA" />
                        <stop offset="0.7158" stopColor="#DAE6F8" />
                        <stop offset="0.8158" stopColor="#CAD3F2" />
                    </linearGradient>
                    <linearGradient id="paint86_linear_196_10227" x1="17.5536" y1="3.75904" x2="19.5143" y2="3.75904" gradientUnits="userSpaceOnUse">
                        <stop offset="0.4274" stopColor="#E7F0FF" />
                        <stop offset="0.4617" stopColor="#D6E6FB" />
                        <stop offset="0.5034" stopColor="#C9DEF7" />
                        <stop offset="0.5404" stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint87_linear_196_10227" x1="12.82" y1="3.75904" x2="17.5539" y2="3.75904" gradientUnits="userSpaceOnUse">
                        <stop offset="0.3389" stopColor="#F3FCFF" />
                        <stop offset="0.352" stopColor="#F0FAFE" />
                        <stop offset="0.4365" stopColor="#E3EEFB" />
                        <stop offset="0.5115" stopColor="#DEEAFA" />
                        <stop offset="0.7158" stopColor="#DAE6F8" />
                        <stop offset="0.8158" stopColor="#CAD3F2" />
                    </linearGradient>
                    <linearGradient id="paint88_linear_196_10227" x1="11.6069" y1="2.44053" x2="12.2738" y2="6.22239" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0745" stopColor="#C6E0FF" />
                        <stop offset="0.1248" stopColor="#B6CAEC" />
                        <stop offset="0.2357" stopColor="#969CC5" />
                        <stop offset="0.3834" stopColor="#B4C3E3" />
                        <stop offset="0.5303" stopColor="#CFE5FD" />
                        <stop offset="0.7108" stopColor="#DAEBFF" />
                        <stop offset="1" stopColor="#B9D8F4" />
                    </linearGradient>
                    <linearGradient id="paint89_linear_196_10227" x1="17.5536" y1="20.4108" x2="22.2869" y2="20.4108" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint90_linear_196_10227" x1="19.5741" y1="19.5741" x2="22.921" y2="16.2271" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint91_linear_196_10227" x1="20.4109" y1="17.5537" x2="20.4109" y2="12.8205" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint92_linear_196_10227" x1="19.574" y1="15.5333" x2="16.2268" y2="12.1861" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint93_linear_196_10227" x1="17.5539" y1="14.6965" x2="12.82" y2="14.6965" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint94_linear_196_10227" x1="15.5333" y1="15.5333" x2="12.1861" y2="18.8806" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint95_linear_196_10227" x1="14.6963" y1="17.5536" x2="14.6963" y2="22.2877" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint96_linear_196_10227" x1="15.5333" y1="19.5739" x2="18.8806" y2="22.9212" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#C4DBF6" />
                        <stop offset="0.7802" stopColor="#7D85A4" />
                    </linearGradient>
                    <linearGradient id="paint97_linear_196_10227" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CCE0FA" />
                        <stop offset="0.6681" stopColor="#C9DCF9" />
                        <stop offset="0.724" stopColor="#B9C9E9" />
                        <stop offset="0.8121" stopColor="#A6B0D5" />
                        <stop offset="0.8931" stopColor="#9AA1C9" />
                        <stop offset="0.9596" stopColor="#969CC5" />
                    </linearGradient>
                    <linearGradient id="paint98_linear_196_10227" x1="14.8959" y1="11.1373" x2="19.6364" y2="9.17365" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint99_linear_196_10227" x1="21.3122" y1="13.22" x2="24.6647" y2="11.8313" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint100_linear_196_10227" x1="10.1551" y1="13.1009" x2="14.8956" y2="11.1373" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint101_linear_196_10227" x1="15.4708" y1="25.9338" x2="20.2113" y2="23.9702" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint102_linear_196_10227" x1="8.47902" y1="18.536" x2="11.8313" y2="17.1474" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint103_linear_196_10227" x1="10.4428" y1="23.2763" x2="13.7946" y2="21.8879" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint104_linear_196_10227" x1="20.2113" y1="23.9707" x2="24.9521" y2="22.007" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint105_linear_196_10227" x1="23.2762" y1="17.9608" x2="26.628" y2="16.5724" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint106_linear_196_10227" x1="11.1397" y1="14.8972" x2="13.1027" y2="10.1582" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint107_linear_196_10227" x1="17.1474" y1="11.8335" x2="18.5351" y2="8.48339" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint108_linear_196_10227" x1="9.17724" y1="19.6353" x2="11.1398" y2="14.8972" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint109_linear_196_10227" x1="22.0047" y1="24.9491" x2="23.9677" y2="20.2101" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint110_linear_196_10227" x1="11.8337" y1="24.6616" x2="13.2218" y2="21.3107" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint111_linear_196_10227" x1="16.5726" y1="26.6242" x2="17.9604" y2="23.2738" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint112_linear_196_10227" x1="23.9676" y1="20.2101" x2="25.9302" y2="15.472" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint113_linear_196_10227" x1="21.8856" y1="13.7967" x2="23.2736" y2="10.4457" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint114_linear_196_10227" x1="6.61929" y1="17.5537" x2="6.61929" y2="9.47524" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint115_linear_196_10227" x1="13.5146" y1="9.47523" x2="13.5146" y2="3.76307" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint116_linear_196_10227" x1="6.61929" y1="25.6321" x2="6.61929" y2="17.5537" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint117_linear_196_10227" x1="28.4887" y1="25.6321" x2="28.4887" y2="17.5537" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint118_linear_196_10227" x1="13.5146" y1="31.3444" x2="13.5146" y2="25.6321" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint119_linear_196_10227" x1="21.5929" y1="31.3444" x2="21.5929" y2="25.6321" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint120_linear_196_10227" x1="28.4887" y1="17.5537" x2="28.4887" y2="9.47524" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint121_linear_196_10227" x1="21.5929" y1="9.47523" x2="21.5929" y2="3.76307" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#AFDFE5" />
                        <stop offset="0.7912" stopColor="#A4D5D8" />
                        <stop offset="1" stopColor="#A0D2D4" />
                    </linearGradient>
                    <linearGradient id="paint122_linear_196_10227" x1="9.47238" y1="24.6542" x2="14.2065" y2="24.6542" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#ADD3E5" />
                        <stop offset="0.0707" stopColor="#B6D9EE" />
                        <stop offset="0.1978" stopColor="#C2E1FB" />
                        <stop offset="0.4063" stopColor="#C6E3FB" />
                        <stop offset="0.6097" stopColor="#D2E9FC" />
                        <stop offset="0.8102" stopColor="#E5F2FD" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                </defs>
            </svg>
            )
        } else if (color === "red") {
            return (
                <svg width="28" height="28" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="#BCD8F4" />
                    <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="url(#paint0_linear_196_10503)" />
                    <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint1_linear_196_10503)" />
                    <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="url(#paint2_linear_196_10503)" />
                    <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="#C4DAF2" />
                    <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint3_linear_196_10503)" />
                    <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="url(#paint4_linear_196_10503)" />
                    <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="url(#paint5_linear_196_10503)" />
                    <path d="M17.5535 10.8596V6.12616H15.5921L14.2061 9.47216L17.5535 10.8596Z" fill="url(#paint6_linear_196_10503)" />
                    <path d="M14.2063 9.47215L10.8589 8.08685L9.47217 9.47215L12.8196 12.8203L14.2063 9.47215Z" fill="url(#paint7_linear_196_10503)" />
                    <path d="M20.9011 9.47216L19.5144 6.12616H17.5537V10.8596L20.9011 9.47216Z" fill="url(#paint8_linear_196_10503)" />
                    <path d="M24.2476 8.08686L22.2869 6.12616H19.5142L20.9009 9.47216L24.2476 8.08686Z" fill="url(#paint9_linear_196_10503)" />
                    <path d="M15.5923 6.12616H12.8196L10.8589 8.08686L14.2063 9.47216L15.5923 6.12616Z" fill="url(#paint10_linear_196_10503)" />
                    <path d="M10.8589 27.0205L12.8196 28.9812H15.5923L14.2063 25.6352L10.8589 27.0205Z" fill="url(#paint11_linear_196_10503)" />
                    <path d="M20.9009 25.6352L24.2476 27.0204L25.6343 25.6352L22.2869 22.2878L20.9009 25.6352Z" fill="url(#paint12_linear_196_10503)" />
                    <path d="M19.5142 28.9812H22.2869L24.2476 27.0205L20.9009 25.6352L19.5142 28.9812Z" fill="url(#paint13_linear_196_10503)" />
                    <path d="M6.12549 19.5144V22.2878L8.08549 24.2485L9.47219 20.9011L6.12549 19.5144Z" fill="url(#paint14_linear_196_10503)" />
                    <path d="M17.5537 24.2485V28.9812H19.5144L20.9011 25.6352L17.5537 24.2485Z" fill="url(#paint15_linear_196_10503)" />
                    <path d="M22.2871 22.2878L25.6345 25.6352L27.0212 24.2485L25.6345 20.9011L22.2871 22.2878Z" fill="url(#paint16_linear_196_10503)" />
                    <path d="M9.47215 20.9011L8.08545 24.2485L9.47215 25.6352L12.8195 22.2878L9.47215 20.9011Z" fill="url(#paint17_linear_196_10503)" />
                    <path d="M14.2061 25.6352L15.5921 28.9812H17.5535V24.2485L14.2061 25.6352Z" fill="url(#paint18_linear_196_10503)" />
                    <path d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint19_linear_196_10503)" />
                    <path d="M22.2869 12.8203L25.6343 9.47215L24.2476 8.08685L20.9009 9.47215L22.2869 12.8203Z" fill="url(#paint20_linear_196_10503)" />
                    <path d="M10.8589 17.5536L12.8196 22.2878L17.5537 17.5536H10.8589Z" fill="url(#paint21_linear_196_10503)" />
                    <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="#C5DDF8" />
                    <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="url(#paint22_linear_196_10503)" />
                    <path d="M25.6345 14.2063L22.2871 12.8203L24.2478 17.5537L25.6345 14.2063Z" fill="url(#paint23_linear_196_10503)" />
                    <path d="M22.2871 12.8203L17.5537 10.8596V17.5537L22.2871 12.8203Z" fill="url(#paint24_linear_196_10503)" />
                    <path d="M24.2478 17.5537L22.2871 12.8203L17.5537 17.5537H24.2478Z" fill="url(#paint25_linear_196_10503)" />
                    <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint26_linear_196_10503)" />
                    <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="url(#paint27_linear_196_10503)" />
                    <path d="M25.6345 14.2063L27.0212 10.8596L25.6345 9.47217L22.2871 12.8203L25.6345 14.2063Z" fill="url(#paint28_linear_196_10503)" />
                    <path opacity="0.9" d="M14.2065 25.6352L17.5539 24.2485L12.8198 22.2878L14.2065 25.6352Z" fill="url(#paint29_linear_196_10503)" />
                    <path d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint30_linear_196_10503)" />
                    <path opacity="0.5" d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="url(#paint31_linear_196_10503)" />
                    <path d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint32_linear_196_10503)" />
                    <path opacity="0.5" d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="url(#paint33_linear_196_10503)" />
                    <path d="M20.9011 25.6352L22.2871 22.2878L17.5537 24.2485L20.9011 25.6352Z" fill="url(#paint34_linear_196_10503)" />
                    <path d="M25.6343 20.9011L28.981 19.5144V17.5536H24.2476L25.6343 20.9011Z" fill="url(#paint35_linear_196_10503)" />
                    <path d="M24.2476 17.5537H28.981V15.593L25.6343 14.2063L24.2476 17.5537Z" fill="url(#paint36_linear_196_10503)" />
                    <path d="M22.2871 22.2878L25.6345 20.9011L24.2478 17.5536L22.2871 22.2878Z" fill="url(#paint37_linear_196_10503)" />
                    <path d="M28.981 15.593V12.8203L27.021 10.8596L25.6343 14.2063L28.981 15.593Z" fill="url(#paint38_linear_196_10503)" />
                    <path opacity="0.9" d="M17.5537 24.2485L22.2871 22.2878L17.5537 17.5536V24.2485Z" fill="url(#paint39_linear_196_10503)" />
                    <path d="M25.6343 20.9011L27.021 24.2485L28.981 22.2878V19.5144L25.6343 20.9011Z" fill="url(#paint40_linear_196_10503)" />
                    <path d="M12.8198 22.2878L17.5539 24.2485V17.5536L12.8198 22.2878Z" fill="url(#paint41_linear_196_10503)" />
                    <path opacity="0.9" d="M12.8196 12.8203L10.8589 17.5537H17.5537L12.8196 12.8203Z" fill="url(#paint42_linear_196_10503)" />
                    <path d="M17.5537 17.5536L22.2871 22.2878L24.2478 17.5536H17.5537Z" fill="url(#paint43_linear_196_10503)" />
                    <path d="M17.5539 10.8596L12.8198 12.8203L17.5539 17.5537V10.8596Z" fill="url(#paint44_linear_196_10503)" />
                    <path d="M6.12549 22.2878V28.9812L8.08549 24.2485L6.12549 22.2878Z" fill="url(#paint45_linear_196_10503)" />
                    <path d="M9.47219 25.6352L6.12549 28.9812L10.8589 27.0205L9.47219 25.6352Z" fill="url(#paint46_linear_196_10503)" />
                    <path d="M8.08549 24.2485L6.12549 28.9812L9.47219 25.6352L8.08549 24.2485Z" fill="url(#paint47_linear_196_10503)" />
                    <path d="M10.8589 27.0204L6.12549 28.9811H12.8196L10.8589 27.0204Z" fill="url(#paint48_linear_196_10503)" />
                    <path d="M6.12549 28.9812L17.5537 33.7153L12.8196 28.9812H6.12549Z" fill="url(#paint49_linear_196_10503)" />
                    <path d="M1.39209 17.5536L6.12549 28.9811V22.2878L1.39209 17.5536Z" fill="url(#paint50_linear_196_10503)" />
                    <path d="M12.8198 28.9812L17.5539 33.7153L15.5925 28.9812H12.8198Z" fill="url(#paint51_linear_196_10503)" />
                    <path d="M6.12549 15.593L1.39209 17.5537H6.12549V15.593Z" fill="url(#paint52_linear_196_10503)" />
                    <path d="M6.12549 17.5536H1.39209L6.12549 19.5144V17.5536Z" fill="url(#paint53_linear_196_10503)" />
                    <path d="M6.12549 19.5144L1.39209 17.5536L6.12549 22.2878V19.5144Z" fill="url(#paint54_linear_196_10503)" />
                    <path d="M19.5144 28.9812L17.5537 33.7153L22.2871 28.9812H19.5144Z" fill="url(#paint55_linear_196_10503)" />
                    <path d="M25.6343 25.6352L28.981 28.9812L27.021 24.2485L25.6343 25.6352Z" fill="url(#paint56_linear_196_10503)" />
                    <path d="M27.021 24.2485L28.981 28.9812V22.2878L27.021 24.2485Z" fill="url(#paint57_linear_196_10503)" />
                    <path d="M24.2476 27.0205L28.981 28.9812L25.6343 25.6352L24.2476 27.0205Z" fill="url(#paint58_linear_196_10503)" />
                    <path d="M28.981 22.2878V28.9811L33.7151 17.5536L28.981 22.2878Z" fill="url(#paint59_linear_196_10503)" />
                    <path d="M17.5537 33.7153L28.9812 28.9812H22.2871L17.5537 33.7153Z" fill="url(#paint60_linear_196_10503)" />
                    <path d="M22.2871 28.9811H28.9812L24.2478 27.0204L22.2871 28.9811Z" fill="url(#paint61_linear_196_10503)" />
                    <path opacity="0.9" d="M6.12549 12.8203L1.39209 17.5537L6.12549 15.593V12.8203Z" fill="url(#paint62_linear_196_10503)" />
                    <path d="M17.5537 28.9812V33.7153L19.5144 28.9812H17.5537Z" fill="url(#paint63_linear_196_10503)" />
                    <path d="M15.5923 28.9812L17.5537 33.7153V28.9812H15.5923Z" fill="url(#paint64_linear_196_10503)" />
                    <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="#ABC8E0" />
                    <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="url(#paint65_linear_196_10503)" />
                    <path d="M28.981 12.8203V6.12616L27.021 10.8596L28.981 12.8203Z" fill="url(#paint66_linear_196_10503)" />
                    <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint67_linear_196_10503)" />
                    <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="url(#paint68_linear_196_10503)" />
                    <path d="M27.021 10.8596L28.981 6.12616L25.6343 9.47216L27.021 10.8596Z" fill="url(#paint69_linear_196_10503)" />
                    <path opacity="0.9" d="M24.2478 8.08686L28.9812 6.12616H22.2871L24.2478 8.08686Z" fill="url(#paint70_linear_196_10503)" />
                    <path d="M28.981 22.2878L33.7151 17.5536L28.981 19.5144V22.2878Z" fill="url(#paint71_linear_196_10503)" />
                    <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint72_linear_196_10503)" />
                    <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="url(#paint73_linear_196_10503)" />
                    <path d="M28.981 17.5537H33.7151L28.981 15.593V17.5537Z" fill="url(#paint74_linear_196_10503)" />
                    <path d="M28.981 15.593L33.7151 17.5537L28.981 12.8203V15.593Z" fill="url(#paint75_linear_196_10503)" />
                    <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint76_linear_196_10503)" />
                    <path d="M28.981 19.5144L33.7151 17.5536H28.981V19.5144Z" fill="url(#paint77_linear_196_10503)" />
                    <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint78_linear_196_10503)" />
                    <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="url(#paint79_linear_196_10503)" />
                    <path d="M10.8589 8.08686L6.12549 6.12616L9.47219 9.47216L10.8589 8.08686Z" fill="url(#paint80_linear_196_10503)" />
                    <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint81_linear_196_10503)" />
                    <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="url(#paint82_linear_196_10503)" />
                    <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="#C4DAF2" />
                    <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="url(#paint83_linear_196_10503)" />
                    <path d="M8.08549 10.8596L6.12549 6.12616V12.8203L8.08549 10.8596Z" fill="url(#paint84_linear_196_10503)" />
                    <path d="M17.5537 6.12616V1.39206L15.5923 6.12616H17.5537Z" fill="url(#paint85_linear_196_10503)" />
                    <path d="M19.5144 6.12616L17.5537 1.39206V6.12616H19.5144Z" fill="url(#paint86_linear_196_10503)" />
                    <path d="M15.5925 6.12616L17.5539 1.39206L12.8198 6.12616H15.5925Z" fill="url(#paint87_linear_196_10503)" />
                    <path d="M17.5537 1.39206L6.12549 6.12616H12.8196L17.5537 1.39206Z" fill="url(#paint88_linear_196_10503)" />
                    <g opacity="0.5">
                        <path d="M17.5537 17.5536L19.9211 23.2678L22.2871 22.2878L17.5537 17.5536Z" fill="url(#paint89_linear_196_10503)" />
                        <path d="M17.5537 17.5536L23.2678 19.9211L24.2478 17.5536H17.5537Z" fill="url(#paint90_linear_196_10503)" />
                        <path d="M17.5537 17.5537L23.2678 15.187L22.2871 12.8203L17.5537 17.5537Z" fill="url(#paint91_linear_196_10503)" />
                        <path d="M17.5537 17.5537L19.9211 11.8396L17.5537 10.8596V17.5537Z" fill="url(#paint92_linear_196_10503)" />
                        <path d="M17.5539 17.5537L15.1872 11.8396L12.8198 12.8203L17.5539 17.5537Z" fill="url(#paint93_linear_196_10503)" />
                        <path d="M17.5537 17.5537L11.8396 15.187L10.8589 17.5537H17.5537Z" fill="url(#paint94_linear_196_10503)" />
                        <path d="M17.5535 17.5536L11.8394 19.9211L12.8194 22.2878L17.5535 17.5536Z" fill="url(#paint95_linear_196_10503)" />
                        <path d="M17.5537 17.5536L15.187 23.2678L17.5537 24.2485V17.5536Z" fill="url(#paint96_linear_196_10503)" />
                    </g>
                    <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="url(#paint97_linear_196_10503)" />
                    <g opacity="0.3">
                        <path d="M17.554 8.07285L14.7764 10.8497H20.3309L17.554 8.07285Z" fill="url(#paint98_linear_196_10503)" />
                        <path d="M24.2583 10.8497H20.3306L24.2583 14.7774V10.8497Z" fill="url(#paint99_linear_196_10503)" />
                        <path d="M10.8491 10.8497V14.7774L14.7761 10.8497H10.8491Z" fill="url(#paint100_linear_196_10503)" />
                        <path d="M17.554 27.0344L20.3309 24.2582H14.7764L17.554 27.0344Z" fill="url(#paint101_linear_196_10503)" />
                        <path d="M8.07227 17.5536L10.8492 20.3312V14.7774L8.07227 17.5536Z" fill="url(#paint102_linear_196_10503)" />
                        <path d="M10.8491 24.2582H14.7761L10.8491 20.3312V24.2582Z" fill="url(#paint103_linear_196_10503)" />
                        <path d="M24.2583 24.2582V20.3312L20.3306 24.2582H24.2583Z" fill="url(#paint104_linear_196_10503)" />
                        <path d="M24.2583 20.3312L27.0345 17.5536L24.2583 14.7774V20.3312Z" fill="url(#paint105_linear_196_10503)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M10.8525 10.8526V14.7782L14.7781 10.8526H10.8525Z" fill="url(#paint106_linear_196_10503)" />
                        <path d="M17.5538 8.07706L14.7783 10.8526H20.3293L17.5538 8.07706Z" fill="url(#paint107_linear_196_10503)" />
                        <path d="M8.07715 17.5537L10.8526 20.3292V14.7782L8.07715 17.5537Z" fill="url(#paint108_linear_196_10503)" />
                        <path d="M24.2554 24.2548V20.3292L20.3291 24.2548H24.2554Z" fill="url(#paint109_linear_196_10503)" />
                        <path d="M10.8525 24.2548H14.7781L10.8525 20.3292V24.2548Z" fill="url(#paint110_linear_196_10503)" />
                        <path d="M17.5538 27.0303L20.3293 24.2548H14.7783L17.5538 27.0303Z" fill="url(#paint111_linear_196_10503)" />
                        <path d="M27.0302 17.5537L24.2554 14.7782V20.3292L27.0302 17.5537Z" fill="url(#paint112_linear_196_10503)" />
                        <path d="M24.2554 14.7782V10.8526H20.3291L24.2554 14.7782Z" fill="url(#paint113_linear_196_10503)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M3.76318 11.8417L6.12918 17.5537L9.47518 9.47498L3.76318 11.8417Z" fill="url(#paint114_linear_196_10503)" />
                        <path d="M11.8418 3.76297L9.4751 9.47497L17.5538 6.12897L11.8418 3.76297Z" fill="url(#paint115_linear_196_10503)" />
                        <path d="M3.76318 23.2657L9.47518 25.6324L6.12918 17.5537L3.76318 23.2657Z" fill="url(#paint116_linear_196_10503)" />
                        <path d="M31.3448 23.2657L28.9781 17.5537L25.6328 25.6324L31.3448 23.2657Z" fill="url(#paint117_linear_196_10503)" />
                        <path d="M11.8418 31.3444L17.5538 28.9784L9.4751 25.6324L11.8418 31.3444Z" fill="url(#paint118_linear_196_10503)" />
                        <path d="M23.2664 31.3444L25.6324 25.6324L17.5537 28.9784L23.2664 31.3444Z" fill="url(#paint119_linear_196_10503)" />
                        <path d="M31.3448 11.8417L25.6328 9.47498L28.9781 17.5537L31.3448 11.8417Z" fill="url(#paint120_linear_196_10503)" />
                        <path d="M25.6324 9.47497L23.2664 3.76297L17.5537 6.12897L25.6324 9.47497Z" fill="url(#paint121_linear_196_10503)" />
                    </g>
                    <path opacity="0.3" d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="url(#paint122_linear_196_10503)" />
                    <path opacity="0.1" d="M33.674 17.4543L28.9812 28.7838L17.553 33.5179L6.12479 28.7838L1.43269 17.4557L1.39209 17.5537L6.12549 28.9812L17.5537 33.7153L28.9812 28.9812L33.7153 17.5537L33.674 17.4543Z" fill="white" />
                    <path opacity="0.5" d="M1.43339 17.6531L6.12619 6.32356L17.5544 1.58946L28.9826 6.32356L33.6747 17.6517L33.7153 17.5537L28.9819 6.12616L17.5537 1.39206L6.12619 6.12616L1.39209 17.5537L1.43339 17.6531Z" fill="#A6C8ED" />
                    <g style={{ mixBlendMode: "multiply" }}>
                        <path d="M28.9816 6.12659L17.5537 1.39206L6.12514 6.12659L1.39209 17.5537L6.12514 28.9816L17.5537 33.7154L28.9816 28.9816L33.7154 17.5537L28.9816 6.12659Z" fill="#850101" />
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_196_10503" x1="6.1252" y1="13.2262" x2="9.47241" y2="13.2262" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_196_10503" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_196_10503" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_196_10503" x1="6.27745" y1="19.873" x2="10.9939" y2="17.871" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_196_10503" x1="6.1252" y1="15.88" x2="10.8592" y2="15.88" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_196_10503" x1="6.1252" y1="19.2273" x2="10.8592" y2="19.2273" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint6_linear_196_10503" x1="14.7016" y1="10.0627" x2="18.3135" y2="7.032" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint7_linear_196_10503" x1="11.5729" y1="7.96082" x2="12.4416" y2="12.8872" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint8_linear_196_10503" x1="17.9886" y1="11.0262" x2="19.824" y2="6.24497" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint9_linear_196_10503" x1="19.5141" y1="7.79929" x2="24.2472" y2="7.79929" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint10_linear_196_10503" x1="11.161" y1="8.79771" x2="15.8767" y2="6.79603" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint11_linear_196_10503" x1="10.8592" y1="27.3082" x2="15.5923" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint12_linear_196_10503" x1="20.9008" y1="24.6542" x2="25.634" y2="24.6542" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint13_linear_196_10503" x1="19.2298" y1="28.3116" x2="23.9455" y2="26.3099" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint14_linear_196_10503" x1="6.1252" y1="21.8813" x2="9.47241" y2="21.8813" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint15_linear_196_10503" x1="16.7631" y1="27.8093" x2="20.615" y2="25.2111" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint16_linear_196_10503" x1="22.2869" y1="23.2679" x2="27.0209" y2="23.2679" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint17_linear_196_10503" x1="8.08557" y1="23.2679" x2="12.8197" y2="23.2679" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint18_linear_196_10503" x1="14.2063" y1="26.6148" x2="17.5534" y2="26.6148" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint19_linear_196_10503" x1="11.2499" y1="27.1708" x2="13.0853" y2="22.3896" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint20_linear_196_10503" x1="20.9008" y1="10.4536" x2="25.634" y2="10.4536" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint21_linear_196_10503" x1="11.6988" y1="19.9893" x2="17.3881" y2="17.2144" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint22_linear_196_10503" x1="21.2137" y1="9.42334" x2="19.7324" y2="12.1909" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint23_linear_196_10503" x1="22.2869" y1="15.1871" x2="25.6343" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint24_linear_196_10503" x1="17.5536" y1="14.2065" x2="22.2869" y2="14.2065" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint25_linear_196_10503" x1="17.5536" y1="15.1871" x2="24.2475" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint26_linear_196_10503" x1="14.5549" y1="13.4865" x2="15.8531" y2="10.1044" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint27_linear_196_10503" x1="15.6248" y1="9.65069" x2="15.0458" y2="12.5457" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint28_linear_196_10503" x1="23.9881" y1="13.5744" x2="25.5719" y2="9.44851" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint29_linear_196_10503" x1="12.82" y1="23.9613" x2="17.5539" y2="23.9613" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint30_linear_196_10503" x1="9.47238" y1="15.1871" x2="12.8197" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint31_linear_196_10503" x1="11.1499" y1="13.5571" x2="11.4714" y2="17.2228" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1898" stopColor="#E5EEFD" />
                            <stop offset="0.3903" stopColor="#D2E2FC" />
                            <stop offset="0.5937" stopColor="#C6DAFB" />
                            <stop offset="0.8022" stopColor="#C2D8FB" />
                            <stop offset="0.9293" stopColor="#B6D0EE" />
                            <stop offset="1" stopColor="#ADCBE5" />
                        </linearGradient>
                        <linearGradient id="paint32_linear_196_10503" x1="10.4464" y1="17.6264" x2="11.3151" y2="22.553" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint33_linear_196_10503" x1="12.0043" y1="19.8213" x2="9.52433" y2="21.3161" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1898" stopColor="#E5EEFD" />
                            <stop offset="0.3903" stopColor="#D2E2FC" />
                            <stop offset="0.5937" stopColor="#C6DAFB" />
                            <stop offset="0.8022" stopColor="#C2D8FB" />
                            <stop offset="0.9293" stopColor="#B6D0EE" />
                            <stop offset="1" stopColor="#ADCBE5" />
                        </linearGradient>
                        <linearGradient id="paint34_linear_196_10503" x1="18.0586" y1="25.4377" x2="22.7744" y2="23.436" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint35_linear_196_10503" x1="24.2472" y1="19.2273" x2="28.9811" y2="19.2273" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint36_linear_196_10503" x1="25.3801" y1="14.3471" x2="27.5861" y2="18.3269" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint37_linear_196_10503" x1="22.4939" y1="18.5256" x2="24.2392" y2="21.6742" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint38_linear_196_10503" x1="25.634" y1="13.2262" x2="28.9811" y2="13.2262" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint39_linear_196_10503" x1="17.5536" y1="20.9009" x2="22.2869" y2="20.9009" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint40_linear_196_10503" x1="26.3129" y1="22.5807" x2="29.6766" y2="21.1529" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint41_linear_196_10503" x1="12.82" y1="20.9009" x2="17.5539" y2="20.9009" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint42_linear_196_10503" x1="10.8592" y1="15.1871" x2="17.5536" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint43_linear_196_10503" x1="17.5536" y1="19.9207" x2="24.2475" y2="19.9207" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint44_linear_196_10503" x1="15.0906" y1="11.2936" x2="16.2354" y2="17.7861" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint45_linear_196_10503" x1="4.57363" y1="26.681" x2="8.11527" y2="24.2922" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint46_linear_196_10503" x1="6.1252" y1="27.3082" x2="10.8592" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint47_linear_196_10503" x1="6.1252" y1="26.6148" x2="9.47241" y2="26.6148" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint48_linear_196_10503" x1="6.63623" y1="30.1853" x2="12.3087" y2="27.7774" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint49_linear_196_10503" x1="6.1252" y1="31.3484" x2="17.5536" y2="31.3484" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint50_linear_196_10503" x1="1.39207" y1="23.2675" x2="6.1252" y2="23.2675" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1929" stopColor="#C9E0EB" />
                            <stop offset="0.5344" stopColor="#CADEEF" />
                            <stop offset="0.8146" stopColor="#D7E8FA" />
                            <stop offset="1" stopColor="#D7E8FA" />
                        </linearGradient>
                        <linearGradient id="paint51_linear_196_10503" x1="14.7108" y1="28.648" x2="15.6631" y2="34.0488" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint52_linear_196_10503" x1="1.84685" y1="18.4861" x2="6.44289" y2="16.2445" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint53_linear_196_10503" x1="2.58657" y1="19.3246" x2="5.83967" y2="17.1303" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint54_linear_196_10503" x1="2.78671" y1="16.7806" x2="5.90652" y2="22.4089" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint55_linear_196_10503" x1="16.8094" y1="32.1895" x2="22.4983" y2="29.4148" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint56_linear_196_10503" x1="27.0944" y1="27.3754" x2="28.591" y2="26.1196" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint57_linear_196_10503" x1="27.8628" y1="22.4848" x2="29.0075" y2="28.9767" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint58_linear_196_10503" x1="24.2472" y1="27.3082" x2="28.9811" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint59_linear_196_10503" x1="28.9811" y1="23.2675" x2="33.7152" y2="23.2675" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1929" stopColor="#C9E0EB" />
                            <stop offset="0.5344" stopColor="#CADEEF" />
                            <stop offset="0.8146" stopColor="#D7E8FA" />
                            <stop offset="1" stopColor="#D7E8FA" />
                        </linearGradient>
                        <linearGradient id="paint60_linear_196_10503" x1="18.5613" y1="34.9162" x2="27.5983" y2="27.3332" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint61_linear_196_10503" x1="22.2869" y1="28.0011" x2="28.9813" y2="28.0011" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint62_linear_196_10503" x1="1.39207" y1="15.1871" x2="6.1252" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint63_linear_196_10503" x1="17.5536" y1="31.3484" x2="19.5143" y2="31.3484" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint64_linear_196_10503" x1="15.9829" y1="31.8435" x2="18.314" y2="29.8875" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint65_linear_196_10503" x1="24.6535" y1="8.91961" x2="29.2501" y2="6.67769" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint66_linear_196_10503" x1="26.9912" y1="10.8155" x2="30.5328" y2="8.42666" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint67_linear_196_10503" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint68_linear_196_10503" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint69_linear_196_10503" x1="25.634" y1="8.49268" x2="28.9811" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint70_linear_196_10503" x1="22.2869" y1="7.1064" x2="28.9813" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint71_linear_196_10503" x1="28.2983" y1="21.4739" x2="33.4074" y2="17.1868" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint72_linear_196_10503" x1="-0.301693" y1="13.5634" x2="7.81896" y2="10.1164" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint73_linear_196_10503" x1="1.39207" y1="11.8399" x2="6.1252" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint74_linear_196_10503" x1="29.4764" y1="18.144" x2="32.2545" y2="15.8128" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint75_linear_196_10503" x1="29.533" y1="16.4114" x2="31.8779" y2="14.8297" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint76_linear_196_10503" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint77_linear_196_10503" x1="28.9811" y1="18.534" x2="33.7152" y2="18.534" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint78_linear_196_10503" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint79_linear_196_10503" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint80_linear_196_10503" x1="6.1252" y1="7.79929" x2="10.8592" y2="7.79929" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint81_linear_196_10503" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint82_linear_196_10503" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint83_linear_196_10503" x1="19.7778" y1="2.95104" x2="20.3705" y2="6.31258" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint84_linear_196_10503" x1="4.57346" y1="10.52" x2="7.67693" y2="8.42666" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint85_linear_196_10503" x1="15.0366" y1="4.98668" x2="18.4863" y2="3.30416" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint86_linear_196_10503" x1="17.5536" y1="3.75904" x2="19.5143" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint87_linear_196_10503" x1="12.82" y1="3.75904" x2="17.5539" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint88_linear_196_10503" x1="11.6069" y1="2.44053" x2="12.2738" y2="6.22239" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint89_linear_196_10503" x1="17.5536" y1="20.4108" x2="22.2869" y2="20.4108" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint90_linear_196_10503" x1="19.5741" y1="19.5741" x2="22.921" y2="16.2271" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint91_linear_196_10503" x1="20.4109" y1="17.5537" x2="20.4109" y2="12.8205" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint92_linear_196_10503" x1="19.574" y1="15.5333" x2="16.2268" y2="12.1861" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint93_linear_196_10503" x1="17.5539" y1="14.6965" x2="12.82" y2="14.6965" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint94_linear_196_10503" x1="15.5333" y1="15.5333" x2="12.1861" y2="18.8806" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint95_linear_196_10503" x1="14.6963" y1="17.5536" x2="14.6963" y2="22.2877" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint96_linear_196_10503" x1="15.5333" y1="19.5739" x2="18.8806" y2="22.9212" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint97_linear_196_10503" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint98_linear_196_10503" x1="14.8959" y1="11.1373" x2="19.6364" y2="9.17365" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint99_linear_196_10503" x1="21.3122" y1="13.22" x2="24.6647" y2="11.8313" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint100_linear_196_10503" x1="10.1551" y1="13.1009" x2="14.8956" y2="11.1373" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint101_linear_196_10503" x1="15.4708" y1="25.9338" x2="20.2113" y2="23.9702" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint102_linear_196_10503" x1="8.47902" y1="18.536" x2="11.8313" y2="17.1474" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint103_linear_196_10503" x1="10.4428" y1="23.2763" x2="13.7946" y2="21.8879" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint104_linear_196_10503" x1="20.2113" y1="23.9707" x2="24.9521" y2="22.007" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint105_linear_196_10503" x1="23.2762" y1="17.9608" x2="26.628" y2="16.5724" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint106_linear_196_10503" x1="11.1397" y1="14.8972" x2="13.1027" y2="10.1582" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint107_linear_196_10503" x1="17.1474" y1="11.8335" x2="18.5351" y2="8.48339" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint108_linear_196_10503" x1="9.17724" y1="19.6353" x2="11.1398" y2="14.8972" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint109_linear_196_10503" x1="22.0047" y1="24.9491" x2="23.9677" y2="20.2101" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint110_linear_196_10503" x1="11.8337" y1="24.6616" x2="13.2218" y2="21.3107" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint111_linear_196_10503" x1="16.5726" y1="26.6242" x2="17.9604" y2="23.2738" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint112_linear_196_10503" x1="23.9676" y1="20.2101" x2="25.9302" y2="15.472" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint113_linear_196_10503" x1="21.8856" y1="13.7967" x2="23.2736" y2="10.4457" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint114_linear_196_10503" x1="6.61929" y1="17.5537" x2="6.61929" y2="9.47524" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint115_linear_196_10503" x1="13.5146" y1="9.47523" x2="13.5146" y2="3.76307" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint116_linear_196_10503" x1="6.61929" y1="25.6321" x2="6.61929" y2="17.5537" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint117_linear_196_10503" x1="28.4887" y1="25.6321" x2="28.4887" y2="17.5537" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint118_linear_196_10503" x1="13.5146" y1="31.3444" x2="13.5146" y2="25.6321" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint119_linear_196_10503" x1="21.5929" y1="31.3444" x2="21.5929" y2="25.6321" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint120_linear_196_10503" x1="28.4887" y1="17.5537" x2="28.4887" y2="9.47524" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint121_linear_196_10503" x1="21.5929" y1="9.47523" x2="21.5929" y2="3.76307" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint122_linear_196_10503" x1="9.47238" y1="24.6542" x2="14.2065" y2="24.6542" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#ADD3E5" />
                            <stop offset="0.0707" stopColor="#B6D9EE" />
                            <stop offset="0.1978" stopColor="#C2E1FB" />
                            <stop offset="0.4063" stopColor="#C6E3FB" />
                            <stop offset="0.6097" stopColor="#D2E9FC" />
                            <stop offset="0.8102" stopColor="#E5F2FD" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                    </defs>
                </svg>
            )
        } else if (color === "champange") {
            return (
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.08418 11.5452L7.23828 13.3918V16.0028L10.3904 14.6966L9.08418 11.5452Z" fill="#0D2ED5" />
                    <path opacity="0.3" d="M9.08418 11.5452L7.23828 13.3918V16.0028L10.3904 14.6966L9.08418 11.5452Z" fill="url(#paint0_linear_302_2851)" />
                    <path d="M13.5423 13.3918L10.3902 10.239L9.08398 11.5452L10.3902 14.6966L13.5423 13.3918Z" fill="url(#paint1_linear_302_2851)" />
                    <path d="M13.5423 13.3918L10.3902 10.239L9.08398 11.5452L10.3902 14.6966L13.5423 13.3918Z" fill="url(#paint2_linear_302_2851)" />
                    <path d="M10.3904 14.6966L7.23828 16.0028V17.8487H11.6966L10.3904 14.6966Z" fill="#2D42CC" />
                    <path d="M11.6966 17.8487H7.23828V19.6953L10.3904 21.0008L11.6966 17.8487Z" fill="url(#paint3_linear_302_2851)" />
                    <path opacity="0.3" d="M10.3904 14.6966L7.23828 16.0028V17.8487H11.6966L10.3904 14.6966Z" fill="url(#paint4_linear_302_2851)" />
                    <path opacity="0.3" d="M11.6966 17.8487H7.23828V19.6953L10.3904 21.0008L11.6966 17.8487Z" fill="url(#paint5_linear_302_2851)" />
                    <path d="M17.9998 11.5452V7.08832H16.1532L14.8477 10.239L17.9998 11.5452Z" fill="url(#paint6_linear_302_2851)" />
                    <path d="M14.8477 10.239L11.6963 8.9342L10.3901 10.239L13.5422 13.3918L14.8477 10.239Z" fill="#2E40B6" />
                    <path d="M21.1521 10.239L19.8459 7.08832H18V11.5452L21.1521 10.239Z" fill="url(#paint7_linear_302_2851)" />
                    <path d="M24.3026 8.93422L22.4567 7.08832H19.8457L21.1519 10.239L24.3026 8.93422Z" fill="url(#paint8_linear_302_2851)" />
                    <path d="M16.1532 7.08832H13.5422L11.6963 8.93422L14.8477 10.239L16.1532 7.08832Z" fill="url(#paint9_linear_302_2851)" />
                    <path d="M11.6963 26.7632L13.5422 28.6098H16.1532L14.8477 25.4584L11.6963 26.7632Z" fill="url(#paint10_linear_302_2851)" />
                    <path d="M21.1519 25.4584L24.3026 26.7632L25.6088 25.4584L22.4567 22.3063L21.1519 25.4584Z" fill="#1F2C6D" />
                    <path d="M19.8457 28.6098H22.4567L24.3026 26.7632L21.1519 25.4584L19.8457 28.6098Z" fill="url(#paint11_linear_302_2851)" />
                    <path d="M7.23828 19.6953V22.3063L9.08418 24.1529L10.3904 21.0008L7.23828 19.6953Z" fill="url(#paint12_linear_302_2851)" />
                    <path d="M18 24.1529V28.6098H19.8459L21.1521 25.4584L18 24.1529Z" fill="#0419A9" />
                    <path d="M22.457 22.3063L25.6091 25.4584L26.9146 24.1529L25.6091 21.0008L22.457 22.3063Z" fill="url(#paint13_linear_302_2851)" />
                    <path d="M10.3902 21.0008L9.08398 24.1529L10.3902 25.4584L13.5423 22.3063L10.3902 21.0008Z" fill="#051791" />
                    <path d="M14.8477 25.4584L16.1532 28.6098H17.9998V24.1529L14.8477 25.4584Z" fill="url(#paint14_linear_302_2851)" />
                    <path d="M13.5422 22.3063L10.3901 25.4584L11.6963 26.7632L14.8477 25.4584L13.5422 22.3063Z" fill="url(#paint15_linear_302_2851)" />
                    <path d="M22.4567 13.3918L25.6088 10.239L24.3026 8.9342L21.1519 10.239L22.4567 13.3918Z" fill="url(#paint16_linear_302_2851)" />
                    <path d="M11.6963 17.8487L13.5422 22.3063L17.9998 17.8487H11.6963Z" fill="#18256A" />
                    <path d="M21.1521 10.239L18 11.5452L22.4569 13.3918L21.1521 10.239Z" fill="#2241E3" />
                    <path opacity="0.6" d="M21.1521 10.239L18 11.5452L22.4569 13.3918L21.1521 10.239Z" fill="url(#paint17_linear_302_2851)" />
                    <path d="M25.6091 14.6966L22.457 13.3918L24.3029 17.8487L25.6091 14.6966Z" fill="url(#paint18_linear_302_2851)" />
                    <path d="M22.4569 13.3918L18 11.5452V17.8487L22.4569 13.3918Z" fill="#101E67" />
                    <path d="M24.3028 17.8487L22.4569 13.3918L18 17.8487H24.3028Z" fill="url(#paint19_linear_302_2851)" />
                    <path d="M14.848 10.239L13.5425 13.3918L18.0001 11.5452L14.848 10.239Z" fill="url(#paint20_linear_302_2851)" />
                    <path opacity="0.5" d="M14.848 10.239L13.5425 13.3918L18.0001 11.5452L14.848 10.239Z" fill="url(#paint21_linear_302_2851)" />
                    <path d="M25.6091 14.6966L26.9146 11.5452L25.6091 10.239L22.457 13.3918L25.6091 14.6966Z" fill="url(#paint22_linear_302_2851)" />
                    <path d="M14.848 25.4584L18.0001 24.1529L13.5425 22.3063L14.848 25.4584Z" fill="#172B96" />
                    <path d="M10.3901 14.6966L11.6963 17.8487L13.5422 13.3918L10.3901 14.6966Z" fill="#304EE3" />
                    <path opacity="0.5" d="M10.3901 14.6966L11.6963 17.8487L13.5422 13.3918L10.3901 14.6966Z" fill="url(#paint23_linear_302_2851)" />
                    <path d="M10.3901 21.0008L13.5422 22.3063L11.6963 17.8487L10.3901 21.0008Z" fill="#2033B2" />
                    <path opacity="0.5" d="M10.3901 21.0008L13.5422 22.3063L11.6963 17.8487L10.3901 21.0008Z" fill="url(#paint24_linear_302_2851)" />
                    <path d="M21.1521 25.4584L22.4569 22.3063L18 24.1529L21.1521 25.4584Z" fill="url(#paint25_linear_302_2851)" />
                    <path d="M25.6089 21.0008L28.761 19.6953V17.8487H24.3027L25.6089 21.0008Z" fill="#304EE3" />
                    <path d="M24.3027 17.8487H28.761V16.0028L25.6089 14.6966L24.3027 17.8487Z" fill="#152EE1" />
                    <path d="M22.457 22.3063L25.6091 21.0008L24.3029 17.8487L22.457 22.3063Z" fill="#152EE1" />
                    <path d="M28.761 16.0028V13.3918L26.9144 11.5452L25.6089 14.6966L28.761 16.0028Z" fill="#0D21AB" />
                    <path d="M18 24.1529L22.4569 22.3063L18 17.8487V24.1529Z" fill="url(#paint26_linear_302_2851)" />
                    <path d="M25.6089 21.0008L26.9144 24.1529L28.761 22.3063V19.6953L25.6089 21.0008Z" fill="url(#paint27_linear_302_2851)" />
                    <path d="M13.5425 22.3063L18.0001 24.1529V17.8487L13.5425 22.3063Z" fill="url(#paint28_linear_302_2851)" />
                    <path d="M13.5422 13.3918L11.6963 17.8487H17.9998L13.5422 13.3918Z" fill="#172B96" />
                    <path d="M18 17.8487L22.4569 22.3063L24.3028 17.8487H18Z" fill="url(#paint29_linear_302_2851)" />
                    <path d="M18.0001 11.5452L13.5425 13.3918L18.0001 17.8487V11.5452Z" fill="#1E31B1" />
                    <path d="M7.23828 22.3063V28.6098L9.08418 24.1529L7.23828 22.3063Z" fill="#0419A9" />
                    <path d="M10.3904 25.4584L7.23828 28.6098L11.6966 26.7632L10.3904 25.4584Z" fill="url(#paint30_linear_302_2851)" />
                    <path d="M9.08418 24.1529L7.23828 28.6098L10.3904 25.4584L9.08418 24.1529Z" fill="url(#paint31_linear_302_2851)" />
                    <path d="M11.6966 26.7632L7.23828 28.6098H13.5425L11.6966 26.7632Z" fill="url(#paint32_linear_302_2851)" />
                    <path d="M7.23828 28.6098L18.0001 33.0674L13.5425 28.6098H7.23828Z" fill="url(#paint33_linear_302_2851)" />
                    <path d="M2.78125 17.8487L7.23815 28.6098V22.3063L2.78125 17.8487Z" fill="url(#paint34_linear_302_2851)" />
                    <path d="M13.5425 28.6098L18.0001 33.0674L16.1535 28.6098H13.5425Z" fill="#2033B2" />
                    <path d="M7.23815 16.0028L2.78125 17.8487H7.23815V16.0028Z" fill="#2336B2" />
                    <path d="M7.23815 17.8487H2.78125L7.23815 19.6953V17.8487Z" fill="#0B1E7E" />
                    <path d="M7.23815 19.6953L2.78125 17.8487L7.23815 22.3063V19.6953Z" fill="#2638B3" />
                    <path d="M19.8459 28.6098L18 33.0674L22.4569 28.6098H19.8459Z" fill="#0520DE" />
                    <path d="M25.6089 25.4584L28.761 28.6098L26.9144 24.1529L25.6089 25.4584Z" fill="url(#paint35_linear_302_2851)" />
                    <path d="M26.9146 24.1529L28.7612 28.6098V22.3063L26.9146 24.1529Z" fill="#2E40B6" />
                    <path d="M24.3027 26.7632L28.761 28.6098L25.6089 25.4584L24.3027 26.7632Z" fill="#206BCC" />
                    <path d="M28.7612 22.3063V28.6098L33.2188 17.8487L28.7612 22.3063Z" fill="#00199D" />
                    <path d="M18 33.0674L28.7611 28.6098H22.4569L18 33.0674Z" fill="url(#paint36_linear_302_2851)" />
                    <path d="M22.457 28.6098H28.7612L24.3029 26.7632L22.457 28.6098Z" fill="#1529AE" />
                    <path d="M7.23815 13.3918L2.78125 17.8487L7.23815 16.0028V13.3918Z" fill="#0C218F" />
                    <path d="M18 28.6098V33.0674L19.8459 28.6098H18Z" fill="#3E5CF3" />
                    <path d="M16.1533 28.6098L17.9999 33.0674V28.6098H16.1533Z" fill="url(#paint37_linear_302_2851)" />
                    <path d="M25.6089 10.239L28.761 7.08832L24.3027 8.93422L25.6089 10.239Z" fill="#041584" />
                    <path opacity="0.3" d="M25.6089 10.239L28.761 7.08832L24.3027 8.93422L25.6089 10.239Z" fill="url(#paint38_linear_302_2851)" />
                    <path d="M28.7612 13.3918V7.08832L26.9146 11.5452L28.7612 13.3918Z" fill="#0B1E7E" />
                    <path d="M28.7611 7.08831L18 2.63L22.4569 7.08831H28.7611Z" fill="#206BCC" />
                    <path opacity="0.3" d="M28.7611 7.08831L18 2.63L22.4569 7.08831H28.7611Z" fill="url(#paint39_linear_302_2851)" />
                    <path d="M26.9144 11.5452L28.761 7.08832L25.6089 10.239L26.9144 11.5452Z" fill="#182CAF" />
                    <path d="M24.3029 8.93422L28.7612 7.08832H22.457L24.3029 8.93422Z" fill="#172B96" />
                    <path d="M28.7612 22.3063L33.2188 17.8487L28.7612 19.6953V22.3063Z" fill="url(#paint40_linear_302_2851)" />
                    <path d="M7.23815 7.08832L2.78125 17.8487L7.23815 13.3918V7.08832Z" fill="url(#paint41_linear_302_2851)" />
                    <path opacity="0.3" d="M7.23815 7.08832L2.78125 17.8487L7.23815 13.3918V7.08832Z" fill="url(#paint42_linear_302_2851)" />
                    <path d="M28.7612 17.8487H33.2188L28.7612 16.0028V17.8487Z" fill="url(#paint43_linear_302_2851)" />
                    <path d="M28.7612 16.0028L33.2188 17.8487L28.7612 13.3918V16.0028Z" fill="#0B1E7E" />
                    <path d="M33.2188 17.8487L28.7612 7.08832V13.3918L33.2188 17.8487Z" fill="#182CAF" />
                    <path d="M28.7612 19.6953L33.2188 17.8487H28.7612V19.6953Z" fill="#23306F" />
                    <path d="M13.5425 7.08832H7.23828L11.6966 8.93422L13.5425 7.08832Z" fill="#304EE3" />
                    <path opacity="0.3" d="M13.5425 7.08832H7.23828L11.6966 8.93422L13.5425 7.08832Z" fill="url(#paint44_linear_302_2851)" />
                    <path d="M11.6966 8.93422L7.23828 7.08832L10.3904 10.239L11.6966 8.93422Z" fill="#152696" />
                    <path d="M10.3904 10.239L7.23828 7.08832L9.08418 11.5452L10.3904 10.239Z" fill="url(#paint45_linear_302_2851)" />
                    <path opacity="0.3" d="M10.3904 10.239L7.23828 7.08832L9.08418 11.5452L10.3904 10.239Z" fill="url(#paint46_linear_302_2851)" />
                    <path d="M22.4569 7.08831L18 2.63L19.8459 7.08831H22.4569Z" fill="#2D42CC" />
                    <path opacity="0.2" d="M22.4569 7.08831L18 2.63L19.8459 7.08831H22.4569Z" fill="url(#paint47_linear_302_2851)" />
                    <path d="M9.08418 11.5452L7.23828 7.08832V13.3918L9.08418 11.5452Z" fill="#0B1E7E" />
                    <path d="M17.9999 7.08831V2.63L16.1533 7.08831H17.9999Z" fill="#0520DE" />
                    <path d="M19.8459 7.08831L18 2.63V7.08831H19.8459Z" fill="url(#paint48_linear_302_2851)" />
                    <path d="M16.1535 7.08831L18.0001 2.63L13.5425 7.08831H16.1535Z" fill="#051791" />
                    <path d="M18.0001 2.63L7.23828 7.08831H13.5425L18.0001 2.63Z" fill="#2033B2" />
                    <path opacity="0.3" d="M18 17.8487L20.2288 23.2296L22.4569 22.3063L18 17.8487Z" fill="url(#paint49_linear_302_2851)" />
                    <path opacity="0.3" d="M18 17.8487L23.3809 20.0775L24.3028 17.8487H18Z" fill="url(#paint50_linear_302_2851)" />
                    <path opacity="0.3" d="M18 17.8487L23.3809 15.6199L22.4569 13.3918L18 17.8487Z" fill="url(#paint51_linear_302_2851)" />
                    <path opacity="0.3" d="M18 17.8487L20.2288 12.4678L18 11.5452V17.8487Z" fill="url(#paint52_linear_302_2851)" />
                    <path opacity="0.3" d="M18.0001 17.8487L15.7713 12.4678L13.5425 13.3918L18.0001 17.8487Z" fill="url(#paint53_linear_302_2851)" />
                    <path opacity="0.3" d="M17.9998 17.8487L12.6189 15.6199L11.6963 17.8487H17.9998Z" fill="url(#paint54_linear_302_2851)" />
                    <path opacity="0.3" d="M18 17.8487L12.6191 20.0775L13.5424 22.3063L18 17.8487Z" fill="url(#paint55_linear_302_2851)" />
                    <path opacity="0.3" d="M17.9998 17.8487L15.771 23.2296L17.9998 24.1529V17.8487Z" fill="url(#paint56_linear_302_2851)" />
                    <path opacity="0.3" d="M33.2188 17.8487L28.7612 7.08832V13.3918L33.2188 17.8487Z" fill="url(#paint57_linear_302_2851)" />
                    <g opacity="0.3">
                        <mask id="mask0_302_2851" style={{ "maskType": "luminance" }} maskUnits="userSpaceOnUse" x="2" y="2" width="32" height="32">
                            <path d="M28.7611 7.08831L18 2.63L7.23815 7.08831L2.78125 17.8487L7.23815 28.6098L18 33.0674L28.7611 28.6098L33.2187 17.8487L28.7611 7.08831Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_302_2851)">
                            <path d="M7.96631 24.1193L9.03941 26.738L11.6483 27.8314L11.663 24.134L7.96631 24.1193Z" fill="#0D2ED5" />
                            <path opacity="0.3" d="M7.96631 24.1193L9.03941 26.738L11.6483 27.8314L11.663 24.134L7.96631 24.1193Z" fill="url(#paint58_linear_302_2851)" />
                            <path d="M11.6794 20.4373L7.2085 22.2671L7.9666 24.1193L11.6633 24.134L11.6794 20.4373Z" fill="url(#paint59_linear_302_2851)" />
                            <path d="M11.6794 20.4373L7.2085 22.2671L7.9666 24.1193L11.6633 24.134L11.6794 20.4373Z" fill="url(#paint60_linear_302_2851)" />
                            <path d="M11.6631 24.134L11.6484 27.8314L13.4936 28.6042L15.3605 24.1494L11.6631 24.134Z" fill="#2D42CC" />
                            <path d="M15.3606 24.1494L13.4937 28.6042L15.3389 29.3777L17.9639 26.7744L15.3606 24.1494Z" fill="url(#paint61_linear_302_2851)" />
                            <path opacity="0.3" d="M11.6631 24.134L11.6484 27.8314L13.4936 28.6042L15.3605 24.1494L11.6631 24.134Z" fill="url(#paint62_linear_302_2851)" />
                            <path opacity="0.3" d="M15.3606 24.1494L13.4937 28.6042L15.3389 29.3777L17.9639 26.7744L15.3606 24.1494Z" fill="url(#paint63_linear_302_2851)" />
                            <path d="M11.7 15.209L7.24518 13.3421L6.47168 15.188L9.07498 17.8123L11.7 15.209Z" fill="url(#paint64_linear_302_2851)" />
                            <path d="M9.07498 17.8123L6.45068 20.4156L7.20808 22.2671L11.679 20.4373L9.07498 17.8123Z" fill="#2E40B6" />
                            <path d="M11.7151 11.5116L8.01911 11.4976L7.24561 13.3421L11.7004 15.209L11.7151 11.5116Z" fill="url(#paint65_linear_302_2851)" />
                            <path d="M11.7304 7.81628L9.11244 8.88798L8.01904 11.4976L11.715 11.5116L11.7304 7.81628Z" fill="url(#paint66_linear_302_2851)" />
                            <path d="M6.47209 15.188L5.37939 17.7976L6.45109 20.4156L9.07539 17.8123L6.47209 15.188Z" fill="url(#paint67_linear_302_2851)" />
                            <path d="M24.27 27.8825L26.888 26.8108L27.9814 24.2012L24.2854 24.1858L24.27 27.8825Z" fill="url(#paint68_linear_302_2851)" />
                            <path d="M26.9258 17.8851L29.5494 15.2825L28.792 13.431L24.3218 15.2615L26.9258 17.8851Z" fill="#1F2C6D" />
                            <path d="M29.5284 20.5101L30.6211 17.9012L29.5494 15.2825L26.9258 17.8851L29.5284 20.5101Z" fill="url(#paint69_linear_302_2851)" />
                            <path d="M15.3389 29.3777L17.9485 30.4711L20.5672 29.3994L17.9639 26.7744L15.3389 29.3777Z" fill="url(#paint70_linear_302_2851)" />
                            <path d="M24.3008 20.4884L28.7549 22.3553L29.5284 20.5101L26.9258 17.8851L24.3008 20.4884Z" fill="#0419A9" />
                            <path d="M24.3218 15.2615L28.792 13.431L28.0339 11.5788L24.3365 11.5641L24.3218 15.2615Z" fill="url(#paint71_linear_302_2851)" />
                            <path d="M17.9639 26.7744L20.5672 29.3994L22.4194 28.6413L20.5889 24.1711L17.9639 26.7744Z" fill="#051791" />
                            <path d="M24.2856 24.1858L27.9816 24.2012L28.7551 22.3553L24.301 20.4884L24.2856 24.1858Z" fill="url(#paint72_linear_302_2851)" />
                            <path d="M20.5889 24.1711L22.4194 28.6413L24.2702 27.8825L24.2856 24.1858L20.5889 24.1711Z" fill="url(#paint73_linear_302_2851)" />
                            <path d="M15.4122 11.5277L13.581 7.0575L11.7302 7.8163L11.7148 11.5116L15.4122 11.5277Z" fill="url(#paint74_linear_302_2851)" />
                            <path d="M15.3604 24.1494L20.5887 24.1711L18.0001 17.8487L15.3604 24.1494Z" fill="#18256A" />
                            <path d="M11.7149 11.5116L11.7002 15.209L15.4123 11.5277L11.7149 11.5116Z" fill="#2241E3" />
                            <path opacity="0.6" d="M11.7149 11.5116L11.7002 15.209L15.4123 11.5277L11.7149 11.5116Z" fill="url(#paint75_linear_302_2851)" />
                            <path d="M18.0364 8.92444L15.4121 11.5277L20.6397 11.5494L18.0364 8.92444Z" fill="url(#paint76_linear_302_2851)" />
                            <path d="M15.4123 11.5277L11.7002 15.209L18.0002 17.8487L15.4123 11.5277Z" fill="#101E67" />
                            <path d="M20.6397 11.5494L15.4121 11.5277L18 17.8487L20.6397 11.5494Z" fill="url(#paint77_linear_302_2851)" />
                            <path d="M9.0752 17.8123L11.6792 20.4373L11.7002 15.209L9.0752 17.8123Z" fill="url(#paint78_linear_302_2851)" />
                            <path opacity="0.5" d="M9.0752 17.8123L11.6792 20.4373L11.7002 15.209L9.0752 17.8123Z" fill="url(#paint79_linear_302_2851)" />
                            <path d="M18.0366 8.92444L15.4333 6.29944L13.5811 7.05754L15.4123 11.5277L18.0366 8.92444Z" fill="url(#paint80_linear_302_2851)" />
                            <path d="M24.2856 24.1858L24.301 20.4884L20.5889 24.1711L24.2856 24.1858Z" fill="#172B96" />
                            <path d="M11.6631 24.134L15.3605 24.1494L11.6792 20.4373L11.6631 24.134Z" fill="#304EE3" />
                            <path opacity="0.5" d="M11.6631 24.134L15.3605 24.1494L11.6792 20.4373L11.6631 24.134Z" fill="url(#paint81_linear_302_2851)" />
                            <path d="M17.9637 26.7744L20.5887 24.1711L15.3604 24.1494L17.9637 26.7744Z" fill="#2033B2" />
                            <path opacity="0.5" d="M17.9637 26.7744L20.5887 24.1711L15.3604 24.1494L17.9637 26.7744Z" fill="url(#paint82_linear_302_2851)" />
                            <path d="M26.9258 17.8851L24.3218 15.2615L24.3008 20.4884L26.9258 17.8851Z" fill="url(#paint83_linear_302_2851)" />
                            <path d="M24.3363 11.5641L24.3517 7.86743L22.5065 7.09393L20.6396 11.5494L24.3363 11.5641Z" fill="#304EE3" />
                            <path d="M20.6399 11.5494L22.5068 7.09391L20.6616 6.32111L18.0366 8.92441L20.6399 11.5494Z" fill="#152EE1" />
                            <path d="M24.3216 15.2615L24.3363 11.5641L20.6396 11.5494L24.3216 15.2615Z" fill="#152EE1" />
                            <path d="M20.6614 6.32112L18.0525 5.22772L15.4331 6.29942L18.0364 8.92442L20.6614 6.32112Z" fill="#0D21AB" />
                            <path d="M24.3007 20.4884L24.3217 15.2615L18 17.8487L24.3007 20.4884Z" fill="url(#paint84_linear_302_2851)" />
                            <path d="M24.3364 11.5641L28.0338 11.5788L26.9621 8.96083L24.3518 7.86743L24.3364 11.5641Z" fill="url(#paint85_linear_302_2851)" />
                            <path d="M20.5886 24.1711L24.3007 20.4884L18 17.8487L20.5886 24.1711Z" fill="url(#paint86_linear_302_2851)" />
                            <path d="M11.6792 20.4373L15.3605 24.1494L18.0002 17.8487L11.6792 20.4373Z" fill="#172B96" />
                            <path d="M18 17.8487L24.3217 15.2615L20.6397 11.5494L18 17.8487Z" fill="url(#paint87_linear_302_2851)" />
                            <path d="M11.7002 15.209L11.6792 20.4373L18.0002 17.8487L11.7002 15.209Z" fill="#1E31B1" />
                            <path d="M17.9482 30.4711L24.2482 33.1108L20.5669 29.3994L17.9482 30.4711Z" fill="#0419A9" />
                            <path d="M22.4194 28.6413L24.2485 33.1108L24.2702 27.8825L22.4194 28.6413Z" fill="url(#paint88_linear_302_2851)" />
                            <path d="M20.5669 29.3994L24.2482 33.1108L22.4191 28.6413L20.5669 29.3994Z" fill="url(#paint89_linear_302_2851)" />
                            <path d="M24.2702 27.8825L24.2485 33.1108L26.8882 26.8108L24.2702 27.8825Z" fill="url(#paint90_linear_302_2851)" />
                            <path d="M24.2485 33.1108L33.2106 24.2222L26.8882 26.8108L24.2485 33.1108Z" fill="url(#paint91_linear_302_2851)" />
                            <path d="M11.6274 33.059L24.2484 33.1108L17.9484 30.4711L11.6274 33.059Z" fill="url(#paint92_linear_302_2851)" />
                            <path d="M26.8882 26.8108L33.2106 24.2222L27.9816 24.2012L26.8882 26.8108Z" fill="#2033B2" />
                            <path d="M11.6484 27.8314L11.6274 33.059L13.4936 28.6042L11.6484 27.8314Z" fill="#2336B2" />
                            <path d="M13.4936 28.6042L11.6274 33.059L15.3388 29.3777L13.4936 28.6042Z" fill="#0B1E7E" />
                            <path d="M15.3388 29.3777L11.6274 33.059L17.9484 30.4711L15.3388 29.3777Z" fill="#2638B3" />
                            <path d="M29.5283 20.5101L33.2103 24.2222L30.621 17.9012L29.5283 20.5101Z" fill="#0520DE" />
                            <path d="M28.7923 13.431L33.2618 11.6005L28.0342 11.5788L28.7923 13.431Z" fill="url(#paint93_linear_302_2851)" />
                            <path d="M28.0341 11.5788L33.2617 11.6005L26.9624 8.96082L28.0341 11.5788Z" fill="#2E40B6" />
                            <path d="M29.5494 15.2825L33.2615 11.6005L28.792 13.431L29.5494 15.2825Z" fill="#206BCC" />
                            <path d="M26.9621 8.9608L33.2614 11.6005L24.3735 2.6391L26.9621 8.9608Z" fill="#00199D" />
                            <path d="M33.2104 24.2222L33.2615 11.6005L30.6211 17.9012L33.2104 24.2222Z" fill="url(#paint94_linear_302_2851)" />
                            <path d="M30.621 17.9012L33.2614 11.6005L29.5493 15.2825L30.621 17.9012Z" fill="#1529AE" />
                            <path d="M9.03955 26.738L11.6275 33.059L11.6485 27.8314L9.03955 26.738Z" fill="#0C218F" />
                            <path d="M28.7549 22.3553L33.2104 24.2222L29.5284 20.5101L28.7549 22.3553Z" fill="#3E5CF3" />
                            <path d="M27.9814 24.2012L33.2104 24.2222L28.7549 22.3553L27.9814 24.2012Z" fill="url(#paint95_linear_302_2851)" />
                            <path d="M13.5813 7.05751L11.7522 2.58801L11.7305 7.81631L13.5813 7.05751Z" fill="#041584" />
                            <path opacity="0.3" d="M13.5813 7.05751L11.7522 2.58801L11.7305 7.81631L13.5813 7.05751Z" fill="url(#paint96_linear_302_2851)" />
                            <path d="M18.0527 5.22771L11.752 2.58801L15.4333 6.29941L18.0527 5.22771Z" fill="#0B1E7E" />
                            <path d="M11.7519 2.58801L2.79053 11.4759L9.11223 8.88801L11.7519 2.58801Z" fill="#206BCC" />
                            <path opacity="0.3" d="M11.7519 2.58801L2.79053 11.4759L9.11223 8.88801L11.7519 2.58801Z" fill="url(#paint97_linear_302_2851)" />
                            <path d="M15.4333 6.29941L11.752 2.58801L13.5811 7.05751L15.4333 6.29941Z" fill="#182CAF" />
                            <path d="M11.7303 7.81631L11.752 2.58801L9.1123 8.88801L11.7303 7.81631Z" fill="#172B96" />
                            <path d="M26.9624 8.9608L24.3738 2.6391L24.3521 7.8674L26.9624 8.9608Z" fill="url(#paint98_linear_302_2851)" />
                            <path d="M2.73877 24.0976L11.6274 33.059L9.03947 26.738L2.73877 24.0976Z" fill="url(#paint99_linear_302_2851)" />
                            <path opacity="0.3" d="M2.73877 24.0976L11.6274 33.059L9.03947 26.738L2.73877 24.0976Z" fill="url(#paint100_linear_302_2851)" />
                            <path d="M22.5068 7.0939L24.3737 2.6391L20.6616 6.3211L22.5068 7.0939Z" fill="url(#paint101_linear_302_2851)" />
                            <path d="M20.6616 6.3211L24.3737 2.6391L18.0527 5.2277L20.6616 6.3211Z" fill="#0B1E7E" />
                            <path d="M24.3737 2.63911L11.752 2.58801L18.0527 5.22771L24.3737 2.63911Z" fill="#182CAF" />
                            <path d="M24.352 7.8674L24.3737 2.6391L22.5068 7.0939L24.352 7.8674Z" fill="#23306F" />
                            <path d="M5.37917 17.7976L2.73877 24.0976L6.45087 20.4156L5.37917 17.7976Z" fill="#304EE3" />
                            <path opacity="0.3" d="M5.37917 17.7976L2.73877 24.0976L6.45087 20.4156L5.37917 17.7976Z" fill="url(#paint102_linear_302_2851)" />
                            <path d="M6.45087 20.4156L2.73877 24.0976L7.20827 22.2671L6.45087 20.4156Z" fill="#152696" />
                            <path d="M7.20827 22.2671L2.73877 24.0976L7.96637 24.1193L7.20827 22.2671Z" fill="url(#paint103_linear_302_2851)" />
                            <path opacity="0.3" d="M7.20827 22.2671L2.73877 24.0976L7.96637 24.1193L7.20827 22.2671Z" fill="url(#paint104_linear_302_2851)" />
                            <path d="M9.11223 8.888L2.79053 11.4759L8.01883 11.4976L9.11223 8.888Z" fill="#2D42CC" />
                            <path opacity="0.2" d="M9.11223 8.888L2.79053 11.4759L8.01883 11.4976L9.11223 8.888Z" fill="url(#paint105_linear_302_2851)" />
                            <path d="M7.96637 24.1193L2.73877 24.0976L9.03947 26.738L7.96637 24.1193Z" fill="#0B1E7E" />
                            <path d="M7.24533 13.3421L2.79053 11.4759L6.47183 15.188L7.24533 13.3421Z" fill="#0520DE" />
                            <path d="M8.01883 11.4976L2.79053 11.4759L7.24533 13.3421L8.01883 11.4976Z" fill="url(#paint106_linear_302_2851)" />
                            <path d="M6.47183 15.188L2.79053 11.4759L5.37913 17.7976L6.47183 15.188Z" fill="#051791" />
                            <path d="M2.79057 11.4759L2.73877 24.0976L5.37917 17.7976L2.79057 11.4759Z" fill="#2033B2" />
                            <path opacity="0.3" d="M18 17.8487L24.3112 17.8746L24.3217 15.2615L18 17.8487Z" fill="url(#paint107_linear_302_2851)" />
                            <path opacity="0.3" d="M18 17.8487L22.4814 13.4044L20.6397 11.5494L18 17.8487Z" fill="url(#paint108_linear_302_2851)" />
                            <path opacity="0.3" d="M18 17.8487L18.0259 11.5375L15.4121 11.5277L18 17.8487Z" fill="url(#paint109_linear_302_2851)" />
                            <path opacity="0.3" d="M18.0002 17.8487L13.5559 13.3673L11.7002 15.209L18.0002 17.8487Z" fill="url(#paint110_linear_302_2851)" />
                            <path opacity="0.3" d="M18.0002 17.8487L11.689 17.8228L11.6792 20.4373L18.0002 17.8487Z" fill="url(#paint111_linear_302_2851)" />
                            <path opacity="0.3" d="M18.0002 17.8487L13.5195 22.293L15.3605 24.1494L18.0002 17.8487Z" fill="url(#paint112_linear_302_2851)" />
                            <path opacity="0.3" d="M18.0003 17.8487L17.9751 24.1599L20.5889 24.1711L18.0003 17.8487Z" fill="url(#paint113_linear_302_2851)" />
                            <path opacity="0.3" d="M18 17.8487L22.4443 22.3294L24.3007 20.4884L18 17.8487Z" fill="url(#paint114_linear_302_2851)" />
                            <path opacity="0.3" d="M24.3737 2.63911L11.752 2.58801L18.0527 5.22771L24.3737 2.63911Z" fill="url(#paint115_linear_302_2851)" />
                        </g>
                    </g>
                    <g opacity="0.3">
                        <path d="M18 8.92163L15.3848 11.5361H20.6145L18 8.92163Z" fill="url(#paint116_linear_302_2851)" />
                        <path d="M24.3131 11.5361H20.6143L24.3131 15.2342V11.5361Z" fill="url(#paint117_linear_302_2851)" />
                        <path d="M11.6865 11.5361V15.2342L15.3846 11.5361H11.6865Z" fill="url(#paint118_linear_302_2851)" />
                        <path d="M18 26.7765L20.6145 24.162H15.3848L18 26.7765Z" fill="url(#paint119_linear_302_2851)" />
                        <path d="M9.07129 17.8488L11.6865 20.464V15.2343L9.07129 17.8488Z" fill="url(#paint120_linear_302_2851)" />
                        <path d="M11.6865 24.162H15.3846L11.6865 20.4639V24.162Z" fill="url(#paint121_linear_302_2851)" />
                        <path d="M24.3131 24.162V20.4639L20.6143 24.162H24.3131Z" fill="url(#paint122_linear_302_2851)" />
                        <path d="M24.313 20.464L26.9275 17.8488L24.313 15.2343V20.464Z" fill="url(#paint123_linear_302_2851)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M11.6895 11.5382V15.2349L15.3862 11.5382H11.6895Z" fill="url(#paint124_linear_302_2851)" />
                        <path d="M17.9995 8.92511L15.3857 11.5382H20.6133L17.9995 8.92511Z" fill="url(#paint125_linear_302_2851)" />
                        <path d="M9.07617 17.8487L11.6893 20.4625V15.2349L9.07617 17.8487Z" fill="url(#paint126_linear_302_2851)" />
                        <path d="M24.3105 24.1592V20.4625L20.6138 24.1592H24.3105Z" fill="url(#paint127_linear_302_2851)" />
                        <path d="M11.6895 24.1592H15.3862L11.6895 20.4625V24.1592Z" fill="url(#paint128_linear_302_2851)" />
                        <path d="M17.9995 26.773L20.6133 24.1592H15.3857L17.9995 26.773Z" fill="url(#paint129_linear_302_2851)" />
                        <path d="M26.9232 17.8487L24.3101 15.2349V20.4625L26.9232 17.8487Z" fill="url(#paint130_linear_302_2851)" />
                        <path d="M24.3105 15.2349V11.5382H20.6138L24.3105 15.2349Z" fill="url(#paint131_linear_302_2851)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M5.01416 12.4699L7.24156 17.8487L10.393 10.2418L5.01416 12.4699Z" fill="url(#paint132_linear_302_2851)" />
                        <path d="M12.6212 4.86298L10.3931 10.2418L18 7.09108L12.6212 4.86298Z" fill="url(#paint133_linear_302_2851)" />
                        <path d="M5.01416 23.2275L10.393 25.4556L7.24156 17.8487L5.01416 23.2275Z" fill="url(#paint134_linear_302_2851)" />
                        <path d="M30.9857 23.2275L28.7569 17.8487L25.6069 25.4556L30.9857 23.2275Z" fill="url(#paint135_linear_302_2851)" />
                        <path d="M12.6212 30.8351L18 28.607L10.3931 25.4556L12.6212 30.8351Z" fill="url(#paint136_linear_302_2851)" />
                        <path d="M23.3788 30.8351L25.6069 25.4556L18 28.607L23.3788 30.8351Z" fill="url(#paint137_linear_302_2851)" />
                        <path d="M30.9857 12.4699L25.6069 10.2418L28.7569 17.8487L30.9857 12.4699Z" fill="url(#paint138_linear_302_2851)" />
                        <path d="M25.6069 10.2418L23.3788 4.86298L18 7.09108L25.6069 10.2418Z" fill="url(#paint139_linear_302_2851)" />
                    </g>
                    <path opacity="0.3" d="M13.5422 22.3063L10.3901 25.4584L11.6963 26.7632L14.8477 25.4584L13.5422 22.3063Z" fill="url(#paint140_linear_302_2851)" />
                    <path opacity="0.1" d="M33.1802 17.7556L28.7604 28.4236L17.9993 32.8812L7.23815 28.4236L2.81975 17.7563L2.78125 17.8487L7.23815 28.6098L18 33.0674L28.7611 28.6098L33.2187 17.8487L33.1802 17.7556Z" fill="white" />
                    <path opacity="0.5" d="M2.81975 17.9425L7.23955 7.2738L18.0007 2.8162L28.7618 7.2738L33.1802 17.9411L33.2187 17.8487L28.7618 7.0883L18 2.63L7.23955 7.0883L2.78125 17.8487L2.81975 17.9425Z" fill="#0C46B7" />
                    <g style={{ "mixBlendMode": "color" }}>
                        <path d="M28.7611 7.08831L18 2.63L7.23815 7.08831L2.78125 17.8487L7.23815 28.6098L18 33.0674L28.7611 28.6098L33.2187 17.8487L28.7611 7.08831Z" fill="url(#paint141_diamond_302_2851)" />
                    </g>
                    <path opacity="0.5" d="M28.7611 7.08831L18 2.63L7.23815 7.08831L2.78125 17.8487L7.23815 28.6098L18 33.0674L28.7611 28.6098L33.2187 17.8487L28.7611 7.08831Z" fill="#F7E7CE" />
                    <defs>
                        <linearGradient id="paint0_linear_302_2851" x1="7.23851" y1="13.7738" x2="10.3904" y2="13.7738" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_302_2851" x1="9.08432" y1="12.468" x2="13.5422" y2="12.468" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_302_2851" x1="9.08432" y1="12.468" x2="13.5422" y2="12.468" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_302_2851" x1="7.23851" y1="19.4247" x2="11.6963" y2="19.4247" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_302_2851" x1="7.23851" y1="16.2728" x2="11.6963" y2="16.2728" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.3047" stopColor="#145FF0" />
                            <stop offset="0.4091" stopColor="#2067F1" />
                            <stop offset="0.5124" stopColor="#3374F2" />
                            <stop offset="0.6152" stopColor="#4F87F4" />
                            <stop offset="0.7176" stopColor="#729FF6" />
                            <stop offset="0.8197" stopColor="#9EBDF9" />
                            <stop offset="0.9195" stopColor="#D1DFFC" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_302_2851" x1="7.23851" y1="19.4247" x2="11.6963" y2="19.4247" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.3047" stopColor="#145FF0" />
                            <stop offset="0.4091" stopColor="#2067F1" />
                            <stop offset="0.5124" stopColor="#3374F2" />
                            <stop offset="0.6152" stopColor="#4F87F4" />
                            <stop offset="0.7176" stopColor="#729FF6" />
                            <stop offset="0.8197" stopColor="#9EBDF9" />
                            <stop offset="0.9195" stopColor="#D1DFFC" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint6_linear_302_2851" x1="14.848" y1="9.31656" x2="17.9997" y2="9.31656" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint7_linear_302_2851" x1="17.9999" y1="9.31656" x2="21.1519" y2="9.31656" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint8_linear_302_2851" x1="19.846" y1="8.66363" x2="24.3029" y2="8.66363" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint9_linear_302_2851" x1="11.696" y1="8.66363" x2="16.1529" y2="8.66363" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint10_linear_302_2851" x1="11.696" y1="27.034" x2="16.1529" y2="27.034" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint11_linear_302_2851" x1="19.846" y1="27.034" x2="24.3029" y2="27.034" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint12_linear_302_2851" x1="7.23851" y1="21.9239" x2="10.3904" y2="21.9239" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint13_linear_302_2851" x1="22.4571" y1="23.2296" x2="26.9149" y2="23.2296" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint14_linear_302_2851" x1="14.848" y1="26.3811" x2="17.9997" y2="26.3811" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint15_linear_302_2851" x1="10.3901" y1="24.535" x2="14.848" y2="24.535" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint16_linear_302_2851" x1="21.1517" y1="11.163" x2="25.6088" y2="11.163" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2F4DE3" />
                            <stop offset="0.1725" stopColor="#3351E3" />
                            <stop offset="0.3607" stopColor="#3D5FE4" />
                            <stop offset="0.5558" stopColor="#4F74E5" />
                            <stop offset="0.5907" stopColor="#5379E5" />
                        </linearGradient>
                        <linearGradient id="paint17_linear_302_2851" x1="21.4464" y1="10.1929" x2="20.0516" y2="12.799" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint18_linear_302_2851" x1="22.4571" y1="15.6203" x2="25.6091" y2="15.6203" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint19_linear_302_2851" x1="17.9999" y1="15.6203" x2="24.3031" y2="15.6203" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint20_linear_302_2851" x1="13.5424" y1="11.8155" x2="18" y2="11.8155" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint21_linear_302_2851" x1="16.1835" y1="10.407" x2="15.6383" y2="13.1331" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint22_linear_302_2851" x1="22.4571" y1="12.468" x2="26.9149" y2="12.468" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint23_linear_302_2851" x1="11.9698" y1="14.0854" x2="12.2725" y2="17.5372" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint24_linear_302_2851" x1="12.7743" y1="19.9841" x2="10.4391" y2="21.3916" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint25_linear_302_2851" x1="17.9999" y1="23.8826" x2="22.457" y2="23.8826" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint26_linear_302_2851" x1="17.9999" y1="21.0007" x2="22.457" y2="21.0007" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4158F1" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint27_linear_302_2851" x1="25.6089" y1="21.9239" x2="28.7606" y2="21.9239" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint28_linear_302_2851" x1="13.5424" y1="21.0007" x2="18" y2="21.0007" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint29_linear_302_2851" x1="17.9999" y1="20.0776" x2="24.3031" y2="20.0776" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint30_linear_302_2851" x1="7.23851" y1="27.034" x2="11.6963" y2="27.034" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint31_linear_302_2851" x1="7.23851" y1="26.3811" x2="10.3904" y2="26.3811" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2F4DE3" />
                            <stop offset="0.1725" stopColor="#3351E3" />
                            <stop offset="0.3607" stopColor="#3D5FE4" />
                            <stop offset="0.5558" stopColor="#4F74E5" />
                            <stop offset="0.5907" stopColor="#5379E5" />
                        </linearGradient>
                        <linearGradient id="paint32_linear_302_2851" x1="7.23851" y1="27.6865" x2="13.5424" y2="27.6865" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint33_linear_302_2851" x1="7.23851" y1="30.8385" x2="18" y2="30.8385" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint34_linear_302_2851" x1="2.78145" y1="23.2292" x2="7.23837" y2="23.2292" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0D24BE" />
                            <stop offset="1" stopColor="#0E1D85" />
                        </linearGradient>
                        <linearGradient id="paint35_linear_302_2851" x1="25.6089" y1="26.3811" x2="28.7606" y2="26.3811" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint36_linear_302_2851" x1="17.9999" y1="30.8385" x2="28.7607" y2="30.8385" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint37_linear_302_2851" x1="16.153" y1="30.8385" x2="17.9999" y2="30.8385" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint38_linear_302_2851" x1="24.3031" y1="8.66363" x2="28.7607" y2="8.66363" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.2739" stopColor="#1D65F1" />
                            <stop offset="0.4155" stopColor="#407DF3" />
                            <stop offset="0.607" stopColor="#78A3F7" />
                            <stop offset="0.8385" stopColor="#C5D8FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint39_linear_302_2851" x1="17.9999" y1="4.85915" x2="28.7607" y2="4.85915" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint40_linear_302_2851" x1="28.7609" y1="20.0776" x2="33.2188" y2="20.0776" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint41_linear_302_2851" x1="2.78145" y1="12.4684" x2="7.23837" y2="12.4684" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint42_linear_302_2851" x1="2.78145" y1="12.4684" x2="7.23837" y2="12.4684" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint43_linear_302_2851" x1="28.7609" y1="16.9257" x2="33.2188" y2="16.9257" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint44_linear_302_2851" x1="7.23851" y1="8.01118" x2="13.5424" y2="8.01118" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint45_linear_302_2851" x1="7.23851" y1="9.31657" x2="10.3904" y2="9.31657" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint46_linear_302_2851" x1="7.23851" y1="9.31657" x2="10.3904" y2="9.31657" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint47_linear_302_2851" x1="20.1471" y1="4.12717" x2="20.4855" y2="7.17245" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint48_linear_302_2851" x1="17.9999" y1="4.85915" x2="19.8462" y2="4.85915" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint49_linear_302_2851" x1="17.9999" y1="20.5392" x2="22.457" y2="20.5392" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint50_linear_302_2851" x1="19.9024" y1="19.7513" x2="23.054" y2="16.5997" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint51_linear_302_2851" x1="20.6904" y1="17.8488" x2="20.6904" y2="13.3918" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint52_linear_302_2851" x1="19.9024" y1="15.9463" x2="16.7505" y2="12.7944" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint53_linear_302_2851" x1="18" y1="15.1584" x2="13.5424" y2="15.1584" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint54_linear_302_2851" x1="16.0973" y1="15.9464" x2="12.9454" y2="19.0983" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint55_linear_302_2851" x1="15.3096" y1="17.8488" x2="15.3096" y2="22.3065" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint56_linear_302_2851" x1="16.0973" y1="19.7512" x2="19.2493" y2="22.9031" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint57_linear_302_2851" x1="28.7609" y1="12.4684" x2="33.2188" y2="12.4684" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint58_linear_302_2851" x1="9.42083" y1="26.8977" x2="10.7409" y2="23.7477" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint59_linear_302_2851" x1="8.88901" y1="24.5059" x2="10.7561" y2="20.0506" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint60_linear_302_2851" x1="8.88901" y1="24.5059" x2="10.7561" y2="20.0506" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint61_linear_302_2851" x1="15.0685" y1="29.2642" x2="16.9354" y2="24.8091" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint62_linear_302_2851" x1="11.9185" y1="27.9442" x2="13.7855" y2="23.4891" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.3047" stopColor="#145FF0" />
                            <stop offset="0.4091" stopColor="#2067F1" />
                            <stop offset="0.5124" stopColor="#3374F2" />
                            <stop offset="0.6152" stopColor="#4F87F4" />
                            <stop offset="0.7176" stopColor="#729FF6" />
                            <stop offset="0.8197" stopColor="#9EBDF9" />
                            <stop offset="0.9195" stopColor="#D1DFFC" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint63_linear_302_2851" x1="15.0685" y1="29.2642" x2="16.9354" y2="24.8091" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.3047" stopColor="#145FF0" />
                            <stop offset="0.4091" stopColor="#2067F1" />
                            <stop offset="0.5124" stopColor="#3374F2" />
                            <stop offset="0.6152" stopColor="#4F87F4" />
                            <stop offset="0.7176" stopColor="#729FF6" />
                            <stop offset="0.8197" stopColor="#9EBDF9" />
                            <stop offset="0.9195" stopColor="#D1DFFC" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint64_linear_302_2851" x1="8.1526" y1="17.4256" x2="9.47268" y2="14.2755" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint65_linear_302_2851" x1="9.47302" y1="14.2757" x2="10.7932" y2="11.1254" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint66_linear_302_2851" x1="9.59347" y1="12.1571" x2="11.4602" y2="7.70266" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint67_linear_302_2851" x1="6.18042" y1="20.3024" x2="8.04705" y2="15.8481" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint68_linear_302_2851" x1="24.5403" y1="27.9957" x2="26.407" y2="23.5412" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint69_linear_302_2851" x1="27.9534" y1="19.8503" x2="29.82" y2="15.396" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint70_linear_302_2851" x1="17.5663" y1="30.3108" x2="18.8864" y2="27.1609" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint71_linear_302_2851" x1="25.2443" y1="15.6477" x2="27.1114" y2="11.1924" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint72_linear_302_2851" x1="25.2079" y1="24.572" x2="26.528" y2="21.4219" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint73_linear_302_2851" x1="21.4958" y1="28.2542" x2="23.3629" y2="23.7989" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint74_linear_302_2851" x1="12.6383" y1="11.8987" x2="14.5049" y2="7.44443" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2F4DE3" />
                            <stop offset="0.1725" stopColor="#3351E3" />
                            <stop offset="0.3607" stopColor="#3D5FE4" />
                            <stop offset="0.5558" stopColor="#4F74E5" />
                            <stop offset="0.5907" stopColor="#5379E5" />
                        </linearGradient>
                        <linearGradient id="paint75_linear_302_2851" x1="11.792" y1="11.1981" x2="13.8125" y2="13.6836" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint76_linear_302_2851" x1="17.6393" y1="12.4613" x2="18.9595" y2="9.31108" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint77_linear_302_2851" x1="15.773" y1="16.9154" x2="18.4128" y2="10.6161" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint78_linear_302_2851" x1="10.1037" y1="19.7773" x2="11.9706" y2="15.3222" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint79_linear_302_2851" x1="9.80204" y1="16.5478" x2="12.2983" y2="18.2345" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint80_linear_302_2851" x1="14.4889" y1="11.141" x2="16.3559" y2="6.68572" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint81_linear_302_2851" x1="11.7138" y1="22.2995" x2="15.2904" y2="23.4426" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint82_linear_302_2851" x1="17.9459" y1="23.9657" x2="18.3745" y2="26.8891" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint83_linear_302_2851" x1="24.0304" y1="20.3755" x2="25.897" y2="15.9213" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint84_linear_302_2851" x1="21.1503" y1="19.1689" x2="23.0169" y2="14.7144" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4158F1" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint85_linear_302_2851" x1="25.2593" y1="11.9508" x2="26.5793" y2="8.80066" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint86_linear_302_2851" x1="19.2833" y1="23.6239" x2="21.1503" y2="19.1686" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint87_linear_302_2851" x1="20.2275" y1="18.7822" x2="22.8673" y2="12.4828" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint88_linear_302_2851" x1="22.6738" y1="32.4508" x2="24.5407" y2="27.9958" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint89_linear_302_2851" x1="22.0209" y1="32.1773" x2="23.3409" y2="29.0275" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2F4DE3" />
                            <stop offset="0.1725" stopColor="#3351E3" />
                            <stop offset="0.3607" stopColor="#3D5FE4" />
                            <stop offset="0.5558" stopColor="#4F74E5" />
                            <stop offset="0.5907" stopColor="#5379E5" />
                        </linearGradient>
                        <linearGradient id="paint90_linear_302_2851" x1="23.3258" y1="32.724" x2="25.9659" y2="26.4239" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint91_linear_302_2851" x1="26.4757" y1="34.044" x2="30.9829" y2="23.2887" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint92_linear_302_2851" x1="17.0043" y1="35.3121" x2="18.8712" y2="30.8573" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0D24BE" />
                            <stop offset="1" stopColor="#0E1D85" />
                        </linearGradient>
                        <linearGradient id="paint93_linear_302_2851" x1="29.7144" y1="13.8174" x2="31.0345" y2="10.6672" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint94_linear_302_2851" x1="30.9823" y1="23.2886" x2="35.4891" y2="12.5341" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint95_linear_302_2851" x1="30.209" y1="25.1345" x2="30.9826" y2="23.2886" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint96_linear_302_2851" x1="11.46" y1="7.70261" x2="13.3269" y2="3.24763" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.2739" stopColor="#1D65F1" />
                            <stop offset="0.4155" stopColor="#407DF3" />
                            <stop offset="0.607" stopColor="#78A3F7" />
                            <stop offset="0.8385" stopColor="#C5D8FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint97_linear_302_2851" x1="5.01782" y1="12.4091" x2="9.5247" y2="1.65437" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint98_linear_302_2851" x1="24.7343" y1="8.02753" x2="26.6013" y2="3.57238" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint99_linear_302_2851" x1="6.24973" y1="30.8052" x2="8.11634" y2="26.351" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint100_linear_302_2851" x1="6.24973" y1="30.8052" x2="8.11634" y2="26.351" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint101_linear_302_2851" x1="21.5842" y1="6.70759" x2="23.4513" y2="2.25234" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint102_linear_302_2851" x1="3.6613" y1="24.4844" x2="6.30143" y2="18.1843" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint103_linear_302_2851" x1="4.96606" y1="25.0311" x2="6.28615" y2="21.881" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint104_linear_302_2851" x1="4.96606" y1="25.0311" x2="6.28615" y2="21.881" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint105_linear_302_2851" x1="5.18559" y1="9.95654" x2="8.37067" y2="10.8937" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint106_linear_302_2851" x1="5.01788" y1="12.4091" x2="5.79116" y2="10.5638" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint107_linear_302_2851" x1="20.6889" y1="18.9755" x2="22.5556" y2="14.5211" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint108_linear_302_2851" x1="20.6983" y1="16.7441" x2="18.8683" y2="12.2746" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint109_linear_302_2851" x1="19.127" y1="15.1599" x2="14.6725" y2="13.2932" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint110_linear_302_2851" x1="16.8957" y1="15.1506" x2="12.4256" y2="16.9809" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint111_linear_302_2851" x1="15.3115" y1="16.722" x2="13.4445" y2="21.1773" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint112_linear_302_2851" x1="15.3024" y1="18.9535" x2="17.1326" y2="23.4235" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint113_linear_302_2851" x1="16.8737" y1="20.5376" x2="21.3289" y2="22.4045" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint114_linear_302_2851" x1="19.1049" y1="20.5467" x2="23.5748" y2="18.7165" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint115_linear_302_2851" x1="17.1292" y1="4.84111" x2="18.9964" y2="0.385507" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint116_linear_302_2851" x1="15.497" y1="11.8069" x2="19.9609" y2="9.95782" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint117_linear_302_2851" x1="21.539" y1="13.7681" x2="24.6958" y2="12.4604" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint118_linear_302_2851" x1="11.033" y1="13.6559" x2="15.4968" y2="11.8069" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint119_linear_302_2851" x1="16.0384" y1="25.74" x2="20.5023" y2="23.8909" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint120_linear_302_2851" x1="9.4546" y1="18.7738" x2="12.6113" y2="17.4663" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint121_linear_302_2851" x1="11.3039" y1="23.2375" x2="14.4601" y2="21.9301" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint122_linear_302_2851" x1="20.5023" y1="23.8914" x2="24.9665" y2="22.0422" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint123_linear_302_2851" x1="23.3882" y1="18.2322" x2="26.5445" y2="16.9248" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint124_linear_302_2851" x1="11.9603" y1="15.3474" x2="13.8088" y2="10.8849" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint125_linear_302_2851" x1="17.6169" y1="12.4625" x2="18.9236" y2="9.30782" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint126_linear_302_2851" x1="10.1121" y1="19.8088" x2="11.9601" y2="15.3474" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint127_linear_302_2851" x1="22.1913" y1="24.8127" x2="24.0398" y2="20.3501" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint128_linear_302_2851" x1="12.6138" y1="24.542" x2="13.9209" y2="21.3864" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint129_linear_302_2851" x1="17.0756" y1="26.3899" x2="18.3824" y2="23.2351" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint130_linear_302_2851" x1="24.0393" y1="20.3501" x2="25.8873" y2="15.8886" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint131_linear_302_2851" x1="22.0792" y1="14.311" x2="23.3862" y2="11.1556" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint132_linear_302_2851" x1="7.70336" y1="17.8487" x2="7.70336" y2="10.2417" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint133_linear_302_2851" x1="14.1965" y1="10.2417" x2="14.1965" y2="4.86276" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint134_linear_302_2851" x1="7.70336" y1="25.4558" x2="7.70336" y2="17.8488" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint135_linear_302_2851" x1="28.2965" y1="25.4558" x2="28.2965" y2="17.8488" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint136_linear_302_2851" x1="14.1965" y1="30.8347" x2="14.1965" y2="25.4557" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint137_linear_302_2851" x1="21.8035" y1="30.8347" x2="21.8035" y2="25.4557" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint138_linear_302_2851" x1="28.2965" y1="17.8487" x2="28.2965" y2="10.2417" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint139_linear_302_2851" x1="21.8035" y1="10.2417" x2="21.8035" y2="4.86276" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint140_linear_302_2851" x1="10.3901" y1="24.535" x2="14.848" y2="24.535" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.3047" stopColor="#145FF0" />
                            <stop offset="0.4091" stopColor="#2067F1" />
                            <stop offset="0.5124" stopColor="#3374F2" />
                            <stop offset="0.6152" stopColor="#4F87F4" />
                            <stop offset="0.7176" stopColor="#729FF6" />
                            <stop offset="0.8197" stopColor="#9EBDF9" />
                            <stop offset="0.9195" stopColor="#D1DFFC" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <radialGradient id="paint141_diamond_302_2851" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18 17.8487) rotate(-133.409) scale(17.826 13.2402)">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="#040404" />
                        </radialGradient>
                    </defs>
                </svg>

            )
        } else if (color === "grey") {
            return (
                <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.58418 11.5452L6.73828 13.3918V16.0028L9.89038 14.6966L8.58418 11.5452Z" fill="#0D2ED5" />
                    <path opacity="0.3" d="M8.58418 11.5452L6.73828 13.3918V16.0028L9.89038 14.6966L8.58418 11.5452Z" fill="url(#paint0_linear_302_2603)" />
                    <path d="M13.0423 13.3918L9.89018 10.239L8.58398 11.5452L9.89018 14.6966L13.0423 13.3918Z" fill="url(#paint1_linear_302_2603)" />
                    <path d="M13.0423 13.3918L9.89018 10.239L8.58398 11.5452L9.89018 14.6966L13.0423 13.3918Z" fill="url(#paint2_linear_302_2603)" />
                    <path d="M9.89038 14.6966L6.73828 16.0028V17.8487H11.1966L9.89038 14.6966Z" fill="#2D42CC" />
                    <path d="M11.1966 17.8487H6.73828V19.6953L9.89038 21.0008L11.1966 17.8487Z" fill="url(#paint3_linear_302_2603)" />
                    <path opacity="0.3" d="M9.89038 14.6966L6.73828 16.0028V17.8487H11.1966L9.89038 14.6966Z" fill="url(#paint4_linear_302_2603)" />
                    <path opacity="0.3" d="M11.1966 17.8487H6.73828V19.6953L9.89038 21.0008L11.1966 17.8487Z" fill="url(#paint5_linear_302_2603)" />
                    <path d="M17.4998 11.5452V7.08832H15.6532L14.3477 10.239L17.4998 11.5452Z" fill="url(#paint6_linear_302_2603)" />
                    <path d="M14.3477 10.239L11.1963 8.9342L9.89014 10.239L13.0422 13.3918L14.3477 10.239Z" fill="#2E40B6" />
                    <path d="M20.6521 10.239L19.3459 7.08832H17.5V11.5452L20.6521 10.239Z" fill="url(#paint7_linear_302_2603)" />
                    <path d="M23.8026 8.93422L21.9567 7.08832H19.3457L20.6519 10.239L23.8026 8.93422Z" fill="url(#paint8_linear_302_2603)" />
                    <path d="M15.6532 7.08832H13.0422L11.1963 8.93422L14.3477 10.239L15.6532 7.08832Z" fill="url(#paint9_linear_302_2603)" />
                    <path d="M11.1963 26.7632L13.0422 28.6098H15.6532L14.3477 25.4584L11.1963 26.7632Z" fill="url(#paint10_linear_302_2603)" />
                    <path d="M20.6519 25.4584L23.8026 26.7632L25.1088 25.4584L21.9567 22.3063L20.6519 25.4584Z" fill="#1F2C6D" />
                    <path d="M19.3457 28.6098H21.9567L23.8026 26.7632L20.6519 25.4584L19.3457 28.6098Z" fill="url(#paint11_linear_302_2603)" />
                    <path d="M6.73828 19.6953V22.3063L8.58418 24.1529L9.89038 21.0008L6.73828 19.6953Z" fill="url(#paint12_linear_302_2603)" />
                    <path d="M17.5 24.1529V28.6098H19.3459L20.6521 25.4584L17.5 24.1529Z" fill="#0419A9" />
                    <path d="M21.957 22.3063L25.1091 25.4584L26.4146 24.1529L25.1091 21.0008L21.957 22.3063Z" fill="url(#paint13_linear_302_2603)" />
                    <path d="M9.89018 21.0008L8.58398 24.1529L9.89018 25.4584L13.0423 22.3063L9.89018 21.0008Z" fill="#051791" />
                    <path d="M14.3477 25.4584L15.6532 28.6098H17.4998V24.1529L14.3477 25.4584Z" fill="url(#paint14_linear_302_2603)" />
                    <path d="M13.0422 22.3063L9.89014 25.4584L11.1963 26.7632L14.3477 25.4584L13.0422 22.3063Z" fill="url(#paint15_linear_302_2603)" />
                    <path d="M21.9567 13.3918L25.1088 10.239L23.8026 8.9342L20.6519 10.239L21.9567 13.3918Z" fill="url(#paint16_linear_302_2603)" />
                    <path d="M11.1963 17.8487L13.0422 22.3063L17.4998 17.8487H11.1963Z" fill="#18256A" />
                    <path d="M20.6521 10.239L17.5 11.5452L21.9569 13.3918L20.6521 10.239Z" fill="#2241E3" />
                    <path opacity="0.6" d="M20.6521 10.239L17.5 11.5452L21.9569 13.3918L20.6521 10.239Z" fill="url(#paint17_linear_302_2603)" />
                    <path d="M25.1091 14.6966L21.957 13.3918L23.8029 17.8487L25.1091 14.6966Z" fill="url(#paint18_linear_302_2603)" />
                    <path d="M21.9569 13.3918L17.5 11.5452V17.8487L21.9569 13.3918Z" fill="#101E67" />
                    <path d="M23.8028 17.8487L21.9569 13.3918L17.5 17.8487H23.8028Z" fill="url(#paint19_linear_302_2603)" />
                    <path d="M14.348 10.239L13.0425 13.3918L17.5001 11.5452L14.348 10.239Z" fill="url(#paint20_linear_302_2603)" />
                    <path opacity="0.5" d="M14.348 10.239L13.0425 13.3918L17.5001 11.5452L14.348 10.239Z" fill="url(#paint21_linear_302_2603)" />
                    <path d="M25.1091 14.6966L26.4146 11.5452L25.1091 10.239L21.957 13.3918L25.1091 14.6966Z" fill="url(#paint22_linear_302_2603)" />
                    <path d="M14.348 25.4584L17.5001 24.1529L13.0425 22.3063L14.348 25.4584Z" fill="#172B96" />
                    <path d="M9.89014 14.6966L11.1963 17.8487L13.0422 13.3918L9.89014 14.6966Z" fill="#304EE3" />
                    <path opacity="0.5" d="M9.89014 14.6966L11.1963 17.8487L13.0422 13.3918L9.89014 14.6966Z" fill="url(#paint23_linear_302_2603)" />
                    <path d="M9.89014 21.0008L13.0422 22.3063L11.1963 17.8487L9.89014 21.0008Z" fill="#2033B2" />
                    <path opacity="0.5" d="M9.89014 21.0008L13.0422 22.3063L11.1963 17.8487L9.89014 21.0008Z" fill="url(#paint24_linear_302_2603)" />
                    <path d="M20.6521 25.4584L21.9569 22.3063L17.5 24.1529L20.6521 25.4584Z" fill="url(#paint25_linear_302_2603)" />
                    <path d="M25.1089 21.0008L28.261 19.6953V17.8487H23.8027L25.1089 21.0008Z" fill="#304EE3" />
                    <path d="M23.8027 17.8487H28.261V16.0028L25.1089 14.6966L23.8027 17.8487Z" fill="#152EE1" />
                    <path d="M21.957 22.3063L25.1091 21.0008L23.8029 17.8487L21.957 22.3063Z" fill="#152EE1" />
                    <path d="M28.261 16.0028V13.3918L26.4144 11.5452L25.1089 14.6966L28.261 16.0028Z" fill="#0D21AB" />
                    <path d="M17.5 24.1529L21.9569 22.3063L17.5 17.8487V24.1529Z" fill="url(#paint26_linear_302_2603)" />
                    <path d="M25.1089 21.0008L26.4144 24.1529L28.261 22.3063V19.6953L25.1089 21.0008Z" fill="url(#paint27_linear_302_2603)" />
                    <path d="M13.0425 22.3063L17.5001 24.1529V17.8487L13.0425 22.3063Z" fill="url(#paint28_linear_302_2603)" />
                    <path d="M13.0422 13.3918L11.1963 17.8487H17.4998L13.0422 13.3918Z" fill="#172B96" />
                    <path d="M17.5 17.8487L21.9569 22.3063L23.8028 17.8487H17.5Z" fill="url(#paint29_linear_302_2603)" />
                    <path d="M17.5001 11.5452L13.0425 13.3918L17.5001 17.8487V11.5452Z" fill="#1E31B1" />
                    <path d="M6.73828 22.3063V28.6098L8.58418 24.1529L6.73828 22.3063Z" fill="#0419A9" />
                    <path d="M9.89038 25.4584L6.73828 28.6098L11.1966 26.7632L9.89038 25.4584Z" fill="url(#paint30_linear_302_2603)" />
                    <path d="M8.58418 24.1529L6.73828 28.6098L9.89038 25.4584L8.58418 24.1529Z" fill="url(#paint31_linear_302_2603)" />
                    <path d="M11.1966 26.7632L6.73828 28.6098H13.0425L11.1966 26.7632Z" fill="url(#paint32_linear_302_2603)" />
                    <path d="M6.73828 28.6098L17.5001 33.0674L13.0425 28.6098H6.73828Z" fill="url(#paint33_linear_302_2603)" />
                    <path d="M2.28125 17.8487L6.73815 28.6098V22.3063L2.28125 17.8487Z" fill="url(#paint34_linear_302_2603)" />
                    <path d="M13.0425 28.6098L17.5001 33.0674L15.6535 28.6098H13.0425Z" fill="#2033B2" />
                    <path d="M6.73815 16.0028L2.28125 17.8487H6.73815V16.0028Z" fill="#2336B2" />
                    <path d="M6.73815 17.8487H2.28125L6.73815 19.6953V17.8487Z" fill="#0B1E7E" />
                    <path d="M6.73815 19.6953L2.28125 17.8487L6.73815 22.3063V19.6953Z" fill="#2638B3" />
                    <path d="M19.3459 28.6098L17.5 33.0674L21.9569 28.6098H19.3459Z" fill="#0520DE" />
                    <path d="M25.1089 25.4584L28.261 28.6098L26.4144 24.1529L25.1089 25.4584Z" fill="url(#paint35_linear_302_2603)" />
                    <path d="M26.4146 24.1529L28.2612 28.6098V22.3063L26.4146 24.1529Z" fill="#2E40B6" />
                    <path d="M23.8027 26.7632L28.261 28.6098L25.1089 25.4584L23.8027 26.7632Z" fill="#206BCC" />
                    <path d="M28.2612 22.3063V28.6098L32.7188 17.8487L28.2612 22.3063Z" fill="#00199D" />
                    <path d="M17.5 33.0674L28.2611 28.6098H21.9569L17.5 33.0674Z" fill="url(#paint36_linear_302_2603)" />
                    <path d="M21.957 28.6098H28.2612L23.8029 26.7632L21.957 28.6098Z" fill="#1529AE" />
                    <path d="M6.73815 13.3918L2.28125 17.8487L6.73815 16.0028V13.3918Z" fill="#0C218F" />
                    <path d="M17.5 28.6098V33.0674L19.3459 28.6098H17.5Z" fill="#3E5CF3" />
                    <path d="M15.6533 28.6098L17.4999 33.0674V28.6098H15.6533Z" fill="url(#paint37_linear_302_2603)" />
                    <path d="M25.1089 10.239L28.261 7.08832L23.8027 8.93422L25.1089 10.239Z" fill="#041584" />
                    <path opacity="0.3" d="M25.1089 10.239L28.261 7.08832L23.8027 8.93422L25.1089 10.239Z" fill="url(#paint38_linear_302_2603)" />
                    <path d="M28.2612 13.3918V7.08832L26.4146 11.5452L28.2612 13.3918Z" fill="#0B1E7E" />
                    <path d="M28.2611 7.08831L17.5 2.63L21.9569 7.08831H28.2611Z" fill="#206BCC" />
                    <path opacity="0.3" d="M28.2611 7.08831L17.5 2.63L21.9569 7.08831H28.2611Z" fill="url(#paint39_linear_302_2603)" />
                    <path d="M26.4144 11.5452L28.261 7.08832L25.1089 10.239L26.4144 11.5452Z" fill="#182CAF" />
                    <path d="M23.8029 8.93422L28.2612 7.08832H21.957L23.8029 8.93422Z" fill="#172B96" />
                    <path d="M28.2612 22.3063L32.7188 17.8487L28.2612 19.6953V22.3063Z" fill="url(#paint40_linear_302_2603)" />
                    <path d="M6.73815 7.08832L2.28125 17.8487L6.73815 13.3918V7.08832Z" fill="url(#paint41_linear_302_2603)" />
                    <path opacity="0.3" d="M6.73815 7.08832L2.28125 17.8487L6.73815 13.3918V7.08832Z" fill="url(#paint42_linear_302_2603)" />
                    <path d="M28.2612 17.8487H32.7188L28.2612 16.0028V17.8487Z" fill="url(#paint43_linear_302_2603)" />
                    <path d="M28.2612 16.0028L32.7188 17.8487L28.2612 13.3918V16.0028Z" fill="#0B1E7E" />
                    <path d="M32.7188 17.8487L28.2612 7.08832V13.3918L32.7188 17.8487Z" fill="#182CAF" />
                    <path d="M28.2612 19.6953L32.7188 17.8487H28.2612V19.6953Z" fill="#23306F" />
                    <path d="M13.0425 7.08832H6.73828L11.1966 8.93422L13.0425 7.08832Z" fill="#304EE3" />
                    <path opacity="0.3" d="M13.0425 7.08832H6.73828L11.1966 8.93422L13.0425 7.08832Z" fill="url(#paint44_linear_302_2603)" />
                    <path d="M11.1966 8.93422L6.73828 7.08832L9.89038 10.239L11.1966 8.93422Z" fill="#152696" />
                    <path d="M9.89038 10.239L6.73828 7.08832L8.58418 11.5452L9.89038 10.239Z" fill="url(#paint45_linear_302_2603)" />
                    <path opacity="0.3" d="M9.89038 10.239L6.73828 7.08832L8.58418 11.5452L9.89038 10.239Z" fill="url(#paint46_linear_302_2603)" />
                    <path d="M21.9569 7.08831L17.5 2.63L19.3459 7.08831H21.9569Z" fill="#2D42CC" />
                    <path opacity="0.2" d="M21.9569 7.08831L17.5 2.63L19.3459 7.08831H21.9569Z" fill="url(#paint47_linear_302_2603)" />
                    <path d="M8.58418 11.5452L6.73828 7.08832V13.3918L8.58418 11.5452Z" fill="#0B1E7E" />
                    <path d="M17.4999 7.08831V2.63L15.6533 7.08831H17.4999Z" fill="#0520DE" />
                    <path d="M19.3459 7.08831L17.5 2.63V7.08831H19.3459Z" fill="url(#paint48_linear_302_2603)" />
                    <path d="M15.6535 7.08831L17.5001 2.63L13.0425 7.08831H15.6535Z" fill="#051791" />
                    <path d="M17.5001 2.63L6.73828 7.08831H13.0425L17.5001 2.63Z" fill="#2033B2" />
                    <path opacity="0.3" d="M17.5 17.8487L19.7288 23.2296L21.9569 22.3063L17.5 17.8487Z" fill="url(#paint49_linear_302_2603)" />
                    <path opacity="0.3" d="M17.5 17.8487L22.8809 20.0775L23.8028 17.8487H17.5Z" fill="url(#paint50_linear_302_2603)" />
                    <path opacity="0.3" d="M17.5 17.8487L22.8809 15.6199L21.9569 13.3918L17.5 17.8487Z" fill="url(#paint51_linear_302_2603)" />
                    <path opacity="0.3" d="M17.5 17.8487L19.7288 12.4678L17.5 11.5452V17.8487Z" fill="url(#paint52_linear_302_2603)" />
                    <path opacity="0.3" d="M17.5001 17.8487L15.2713 12.4678L13.0425 13.3918L17.5001 17.8487Z" fill="url(#paint53_linear_302_2603)" />
                    <path opacity="0.3" d="M17.4998 17.8487L12.1189 15.6199L11.1963 17.8487H17.4998Z" fill="url(#paint54_linear_302_2603)" />
                    <path opacity="0.3" d="M17.5 17.8487L12.1191 20.0775L13.0424 22.3063L17.5 17.8487Z" fill="url(#paint55_linear_302_2603)" />
                    <path opacity="0.3" d="M17.4998 17.8487L15.271 23.2296L17.4998 24.1529V17.8487Z" fill="url(#paint56_linear_302_2603)" />
                    <path opacity="0.3" d="M32.7188 17.8487L28.2612 7.08832V13.3918L32.7188 17.8487Z" fill="url(#paint57_linear_302_2603)" />
                    <g opacity="0.3">
                        <mask id="mask0_302_2603" style={{ "maskType": "luminance" }} maskUnits="userSpaceOnUse" x="2" y="2" width="31" height="32">
                            <path d="M28.2611 7.08831L17.5 2.63L6.73815 7.08831L2.28125 17.8487L6.73815 28.6098L17.5 33.0674L28.2611 28.6098L32.7187 17.8487L28.2611 7.08831Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_302_2603)">
                            <path d="M7.46631 24.1193L8.53941 26.738L11.1483 27.8314L11.163 24.134L7.46631 24.1193Z" fill="#0D2ED5" />
                            <path opacity="0.3" d="M7.46631 24.1193L8.53941 26.738L11.1483 27.8314L11.163 24.134L7.46631 24.1193Z" fill="url(#paint58_linear_302_2603)" />
                            <path d="M11.1794 20.4373L6.7085 22.2671L7.4666 24.1193L11.1633 24.134L11.1794 20.4373Z" fill="url(#paint59_linear_302_2603)" />
                            <path d="M11.1794 20.4373L6.7085 22.2671L7.4666 24.1193L11.1633 24.134L11.1794 20.4373Z" fill="url(#paint60_linear_302_2603)" />
                            <path d="M11.1631 24.134L11.1484 27.8314L12.9936 28.6042L14.8605 24.1494L11.1631 24.134Z" fill="#2D42CC" />
                            <path d="M14.8606 24.1494L12.9937 28.6042L14.8389 29.3777L17.4639 26.7744L14.8606 24.1494Z" fill="url(#paint61_linear_302_2603)" />
                            <path opacity="0.3" d="M11.1631 24.134L11.1484 27.8314L12.9936 28.6042L14.8605 24.1494L11.1631 24.134Z" fill="url(#paint62_linear_302_2603)" />
                            <path opacity="0.3" d="M14.8606 24.1494L12.9937 28.6042L14.8389 29.3777L17.4639 26.7744L14.8606 24.1494Z" fill="url(#paint63_linear_302_2603)" />
                            <path d="M11.2 15.209L6.74518 13.3421L5.97168 15.188L8.57498 17.8123L11.2 15.209Z" fill="url(#paint64_linear_302_2603)" />
                            <path d="M8.57498 17.8123L5.95068 20.4156L6.70808 22.2671L11.179 20.4373L8.57498 17.8123Z" fill="#2E40B6" />
                            <path d="M11.2151 11.5116L7.51911 11.4976L6.74561 13.3421L11.2004 15.209L11.2151 11.5116Z" fill="url(#paint65_linear_302_2603)" />
                            <path d="M11.2304 7.81628L8.61244 8.88798L7.51904 11.4976L11.215 11.5116L11.2304 7.81628Z" fill="url(#paint66_linear_302_2603)" />
                            <path d="M5.97209 15.188L4.87939 17.7976L5.95109 20.4156L8.57539 17.8123L5.97209 15.188Z" fill="url(#paint67_linear_302_2603)" />
                            <path d="M23.77 27.8825L26.388 26.8108L27.4814 24.2012L23.7854 24.1858L23.77 27.8825Z" fill="url(#paint68_linear_302_2603)" />
                            <path d="M26.4258 17.8851L29.0494 15.2825L28.292 13.431L23.8218 15.2615L26.4258 17.8851Z" fill="#1F2C6D" />
                            <path d="M29.0284 20.5101L30.1211 17.9012L29.0494 15.2825L26.4258 17.8851L29.0284 20.5101Z" fill="url(#paint69_linear_302_2603)" />
                            <path d="M14.8389 29.3777L17.4485 30.4711L20.0672 29.3994L17.4639 26.7744L14.8389 29.3777Z" fill="url(#paint70_linear_302_2603)" />
                            <path d="M23.8008 20.4884L28.2549 22.3553L29.0284 20.5101L26.4258 17.8851L23.8008 20.4884Z" fill="#0419A9" />
                            <path d="M23.8218 15.2615L28.292 13.431L27.5339 11.5788L23.8365 11.5641L23.8218 15.2615Z" fill="url(#paint71_linear_302_2603)" />
                            <path d="M17.4639 26.7744L20.0672 29.3994L21.9194 28.6413L20.0889 24.1711L17.4639 26.7744Z" fill="#051791" />
                            <path d="M23.7856 24.1858L27.4816 24.2012L28.2551 22.3553L23.801 20.4884L23.7856 24.1858Z" fill="url(#paint72_linear_302_2603)" />
                            <path d="M20.0889 24.1711L21.9194 28.6413L23.7702 27.8825L23.7856 24.1858L20.0889 24.1711Z" fill="url(#paint73_linear_302_2603)" />
                            <path d="M14.9122 11.5277L13.081 7.0575L11.2302 7.8163L11.2148 11.5116L14.9122 11.5277Z" fill="url(#paint74_linear_302_2603)" />
                            <path d="M14.8604 24.1494L20.0887 24.1711L17.5001 17.8487L14.8604 24.1494Z" fill="#18256A" />
                            <path d="M11.2149 11.5116L11.2002 15.209L14.9123 11.5277L11.2149 11.5116Z" fill="#2241E3" />
                            <path opacity="0.6" d="M11.2149 11.5116L11.2002 15.209L14.9123 11.5277L11.2149 11.5116Z" fill="url(#paint75_linear_302_2603)" />
                            <path d="M17.5364 8.92444L14.9121 11.5277L20.1397 11.5494L17.5364 8.92444Z" fill="url(#paint76_linear_302_2603)" />
                            <path d="M14.9123 11.5277L11.2002 15.209L17.5002 17.8487L14.9123 11.5277Z" fill="#101E67" />
                            <path d="M20.1397 11.5494L14.9121 11.5277L17.5 17.8487L20.1397 11.5494Z" fill="url(#paint77_linear_302_2603)" />
                            <path d="M8.5752 17.8123L11.1792 20.4373L11.2002 15.209L8.5752 17.8123Z" fill="url(#paint78_linear_302_2603)" />
                            <path opacity="0.5" d="M8.5752 17.8123L11.1792 20.4373L11.2002 15.209L8.5752 17.8123Z" fill="url(#paint79_linear_302_2603)" />
                            <path d="M17.5366 8.92444L14.9333 6.29944L13.0811 7.05754L14.9123 11.5277L17.5366 8.92444Z" fill="url(#paint80_linear_302_2603)" />
                            <path d="M23.7856 24.1858L23.801 20.4884L20.0889 24.1711L23.7856 24.1858Z" fill="#172B96" />
                            <path d="M11.1631 24.134L14.8605 24.1494L11.1792 20.4373L11.1631 24.134Z" fill="#304EE3" />
                            <path opacity="0.5" d="M11.1631 24.134L14.8605 24.1494L11.1792 20.4373L11.1631 24.134Z" fill="url(#paint81_linear_302_2603)" />
                            <path d="M17.4637 26.7744L20.0887 24.1711L14.8604 24.1494L17.4637 26.7744Z" fill="#2033B2" />
                            <path opacity="0.5" d="M17.4637 26.7744L20.0887 24.1711L14.8604 24.1494L17.4637 26.7744Z" fill="url(#paint82_linear_302_2603)" />
                            <path d="M26.4258 17.8851L23.8218 15.2615L23.8008 20.4884L26.4258 17.8851Z" fill="url(#paint83_linear_302_2603)" />
                            <path d="M23.8363 11.5641L23.8517 7.86743L22.0065 7.09393L20.1396 11.5494L23.8363 11.5641Z" fill="#304EE3" />
                            <path d="M20.1399 11.5494L22.0068 7.09391L20.1616 6.32111L17.5366 8.92441L20.1399 11.5494Z" fill="#152EE1" />
                            <path d="M23.8216 15.2615L23.8363 11.5641L20.1396 11.5494L23.8216 15.2615Z" fill="#152EE1" />
                            <path d="M20.1614 6.32112L17.5525 5.22772L14.9331 6.29942L17.5364 8.92442L20.1614 6.32112Z" fill="#0D21AB" />
                            <path d="M23.8007 20.4884L23.8217 15.2615L17.5 17.8487L23.8007 20.4884Z" fill="url(#paint84_linear_302_2603)" />
                            <path d="M23.8364 11.5641L27.5338 11.5788L26.4621 8.96083L23.8518 7.86743L23.8364 11.5641Z" fill="url(#paint85_linear_302_2603)" />
                            <path d="M20.0886 24.1711L23.8007 20.4884L17.5 17.8487L20.0886 24.1711Z" fill="url(#paint86_linear_302_2603)" />
                            <path d="M11.1792 20.4373L14.8605 24.1494L17.5002 17.8487L11.1792 20.4373Z" fill="#172B96" />
                            <path d="M17.5 17.8487L23.8217 15.2615L20.1397 11.5494L17.5 17.8487Z" fill="url(#paint87_linear_302_2603)" />
                            <path d="M11.2002 15.209L11.1792 20.4373L17.5002 17.8487L11.2002 15.209Z" fill="#1E31B1" />
                            <path d="M17.4482 30.4711L23.7482 33.1108L20.0669 29.3994L17.4482 30.4711Z" fill="#0419A9" />
                            <path d="M21.9194 28.6413L23.7485 33.1108L23.7702 27.8825L21.9194 28.6413Z" fill="url(#paint88_linear_302_2603)" />
                            <path d="M20.0669 29.3994L23.7482 33.1108L21.9191 28.6413L20.0669 29.3994Z" fill="url(#paint89_linear_302_2603)" />
                            <path d="M23.7702 27.8825L23.7485 33.1108L26.3882 26.8108L23.7702 27.8825Z" fill="url(#paint90_linear_302_2603)" />
                            <path d="M23.7485 33.1108L32.7106 24.2222L26.3882 26.8108L23.7485 33.1108Z" fill="url(#paint91_linear_302_2603)" />
                            <path d="M11.1274 33.059L23.7484 33.1108L17.4484 30.4711L11.1274 33.059Z" fill="url(#paint92_linear_302_2603)" />
                            <path d="M26.3882 26.8108L32.7106 24.2222L27.4816 24.2012L26.3882 26.8108Z" fill="#2033B2" />
                            <path d="M11.1484 27.8314L11.1274 33.059L12.9936 28.6042L11.1484 27.8314Z" fill="#2336B2" />
                            <path d="M12.9936 28.6042L11.1274 33.059L14.8388 29.3777L12.9936 28.6042Z" fill="#0B1E7E" />
                            <path d="M14.8388 29.3777L11.1274 33.059L17.4484 30.4711L14.8388 29.3777Z" fill="#2638B3" />
                            <path d="M29.0283 20.5101L32.7103 24.2222L30.121 17.9012L29.0283 20.5101Z" fill="#0520DE" />
                            <path d="M28.2923 13.431L32.7618 11.6005L27.5342 11.5788L28.2923 13.431Z" fill="url(#paint93_linear_302_2603)" />
                            <path d="M27.5341 11.5788L32.7617 11.6005L26.4624 8.96082L27.5341 11.5788Z" fill="#2E40B6" />
                            <path d="M29.0494 15.2825L32.7615 11.6005L28.292 13.431L29.0494 15.2825Z" fill="#206BCC" />
                            <path d="M26.4621 8.9608L32.7614 11.6005L23.8735 2.6391L26.4621 8.9608Z" fill="#00199D" />
                            <path d="M32.7104 24.2222L32.7615 11.6005L30.1211 17.9012L32.7104 24.2222Z" fill="url(#paint94_linear_302_2603)" />
                            <path d="M30.121 17.9012L32.7614 11.6005L29.0493 15.2825L30.121 17.9012Z" fill="#1529AE" />
                            <path d="M8.53955 26.738L11.1275 33.059L11.1485 27.8314L8.53955 26.738Z" fill="#0C218F" />
                            <path d="M28.2549 22.3553L32.7104 24.2222L29.0284 20.5101L28.2549 22.3553Z" fill="#3E5CF3" />
                            <path d="M27.4814 24.2012L32.7104 24.2222L28.2549 22.3553L27.4814 24.2012Z" fill="url(#paint95_linear_302_2603)" />
                            <path d="M13.0813 7.05751L11.2522 2.58801L11.2305 7.81631L13.0813 7.05751Z" fill="#041584" />
                            <path opacity="0.3" d="M13.0813 7.05751L11.2522 2.58801L11.2305 7.81631L13.0813 7.05751Z" fill="url(#paint96_linear_302_2603)" />
                            <path d="M17.5527 5.22771L11.252 2.58801L14.9333 6.29941L17.5527 5.22771Z" fill="#0B1E7E" />
                            <path d="M11.2519 2.58801L2.29053 11.4759L8.61223 8.88801L11.2519 2.58801Z" fill="#206BCC" />
                            <path opacity="0.3" d="M11.2519 2.58801L2.29053 11.4759L8.61223 8.88801L11.2519 2.58801Z" fill="url(#paint97_linear_302_2603)" />
                            <path d="M14.9333 6.29941L11.252 2.58801L13.0811 7.05751L14.9333 6.29941Z" fill="#182CAF" />
                            <path d="M11.2303 7.81631L11.252 2.58801L8.6123 8.88801L11.2303 7.81631Z" fill="#172B96" />
                            <path d="M26.4624 8.9608L23.8738 2.6391L23.8521 7.8674L26.4624 8.9608Z" fill="url(#paint98_linear_302_2603)" />
                            <path d="M2.23877 24.0976L11.1274 33.059L8.53947 26.738L2.23877 24.0976Z" fill="url(#paint99_linear_302_2603)" />
                            <path opacity="0.3" d="M2.23877 24.0976L11.1274 33.059L8.53947 26.738L2.23877 24.0976Z" fill="url(#paint100_linear_302_2603)" />
                            <path d="M22.0068 7.0939L23.8737 2.6391L20.1616 6.3211L22.0068 7.0939Z" fill="url(#paint101_linear_302_2603)" />
                            <path d="M20.1616 6.3211L23.8737 2.6391L17.5527 5.2277L20.1616 6.3211Z" fill="#0B1E7E" />
                            <path d="M23.8737 2.63911L11.252 2.58801L17.5527 5.22771L23.8737 2.63911Z" fill="#182CAF" />
                            <path d="M23.852 7.8674L23.8737 2.6391L22.0068 7.0939L23.852 7.8674Z" fill="#23306F" />
                            <path d="M4.87917 17.7976L2.23877 24.0976L5.95087 20.4156L4.87917 17.7976Z" fill="#304EE3" />
                            <path opacity="0.3" d="M4.87917 17.7976L2.23877 24.0976L5.95087 20.4156L4.87917 17.7976Z" fill="url(#paint102_linear_302_2603)" />
                            <path d="M5.95087 20.4156L2.23877 24.0976L6.70827 22.2671L5.95087 20.4156Z" fill="#152696" />
                            <path d="M6.70827 22.2671L2.23877 24.0976L7.46637 24.1193L6.70827 22.2671Z" fill="url(#paint103_linear_302_2603)" />
                            <path opacity="0.3" d="M6.70827 22.2671L2.23877 24.0976L7.46637 24.1193L6.70827 22.2671Z" fill="url(#paint104_linear_302_2603)" />
                            <path d="M8.61223 8.888L2.29053 11.4759L7.51883 11.4976L8.61223 8.888Z" fill="#2D42CC" />
                            <path opacity="0.2" d="M8.61223 8.888L2.29053 11.4759L7.51883 11.4976L8.61223 8.888Z" fill="url(#paint105_linear_302_2603)" />
                            <path d="M7.46637 24.1193L2.23877 24.0976L8.53947 26.738L7.46637 24.1193Z" fill="#0B1E7E" />
                            <path d="M6.74533 13.3421L2.29053 11.4759L5.97183 15.188L6.74533 13.3421Z" fill="#0520DE" />
                            <path d="M7.51883 11.4976L2.29053 11.4759L6.74533 13.3421L7.51883 11.4976Z" fill="url(#paint106_linear_302_2603)" />
                            <path d="M5.97183 15.188L2.29053 11.4759L4.87913 17.7976L5.97183 15.188Z" fill="#051791" />
                            <path d="M2.29057 11.4759L2.23877 24.0976L4.87917 17.7976L2.29057 11.4759Z" fill="#2033B2" />
                            <path opacity="0.3" d="M17.5 17.8487L23.8112 17.8746L23.8217 15.2615L17.5 17.8487Z" fill="url(#paint107_linear_302_2603)" />
                            <path opacity="0.3" d="M17.5 17.8487L21.9814 13.4044L20.1397 11.5494L17.5 17.8487Z" fill="url(#paint108_linear_302_2603)" />
                            <path opacity="0.3" d="M17.5 17.8487L17.5259 11.5375L14.9121 11.5277L17.5 17.8487Z" fill="url(#paint109_linear_302_2603)" />
                            <path opacity="0.3" d="M17.5002 17.8487L13.0559 13.3673L11.2002 15.209L17.5002 17.8487Z" fill="url(#paint110_linear_302_2603)" />
                            <path opacity="0.3" d="M17.5002 17.8487L11.189 17.8228L11.1792 20.4373L17.5002 17.8487Z" fill="url(#paint111_linear_302_2603)" />
                            <path opacity="0.3" d="M17.5002 17.8487L13.0195 22.293L14.8605 24.1494L17.5002 17.8487Z" fill="url(#paint112_linear_302_2603)" />
                            <path opacity="0.3" d="M17.5003 17.8487L17.4751 24.1599L20.0889 24.1711L17.5003 17.8487Z" fill="url(#paint113_linear_302_2603)" />
                            <path opacity="0.3" d="M17.5 17.8487L21.9443 22.3294L23.8007 20.4884L17.5 17.8487Z" fill="url(#paint114_linear_302_2603)" />
                            <path opacity="0.3" d="M23.8737 2.63911L11.252 2.58801L17.5527 5.22771L23.8737 2.63911Z" fill="url(#paint115_linear_302_2603)" />
                        </g>
                    </g>
                    <g opacity="0.3">
                        <path d="M17.5 8.92163L14.8848 11.5361H20.1145L17.5 8.92163Z" fill="url(#paint116_linear_302_2603)" />
                        <path d="M23.8131 11.5361H20.1143L23.8131 15.2342V11.5361Z" fill="url(#paint117_linear_302_2603)" />
                        <path d="M11.1865 11.5361V15.2342L14.8846 11.5361H11.1865Z" fill="url(#paint118_linear_302_2603)" />
                        <path d="M17.5 26.7765L20.1145 24.162H14.8848L17.5 26.7765Z" fill="url(#paint119_linear_302_2603)" />
                        <path d="M8.57129 17.8488L11.1865 20.464V15.2343L8.57129 17.8488Z" fill="url(#paint120_linear_302_2603)" />
                        <path d="M11.1865 24.162H14.8846L11.1865 20.4639V24.162Z" fill="url(#paint121_linear_302_2603)" />
                        <path d="M23.8131 24.162V20.4639L20.1143 24.162H23.8131Z" fill="url(#paint122_linear_302_2603)" />
                        <path d="M23.813 20.464L26.4275 17.8488L23.813 15.2343V20.464Z" fill="url(#paint123_linear_302_2603)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M11.1895 11.5382V15.2349L14.8862 11.5382H11.1895Z" fill="url(#paint124_linear_302_2603)" />
                        <path d="M17.4995 8.92511L14.8857 11.5382H20.1133L17.4995 8.92511Z" fill="url(#paint125_linear_302_2603)" />
                        <path d="M8.57617 17.8487L11.1893 20.4625V15.2349L8.57617 17.8487Z" fill="url(#paint126_linear_302_2603)" />
                        <path d="M23.8105 24.1592V20.4625L20.1138 24.1592H23.8105Z" fill="url(#paint127_linear_302_2603)" />
                        <path d="M11.1895 24.1592H14.8862L11.1895 20.4625V24.1592Z" fill="url(#paint128_linear_302_2603)" />
                        <path d="M17.4995 26.773L20.1133 24.1592H14.8857L17.4995 26.773Z" fill="url(#paint129_linear_302_2603)" />
                        <path d="M26.4232 17.8487L23.8101 15.2349V20.4625L26.4232 17.8487Z" fill="url(#paint130_linear_302_2603)" />
                        <path d="M23.8105 15.2349V11.5382H20.1138L23.8105 15.2349Z" fill="url(#paint131_linear_302_2603)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M4.51416 12.4699L6.74156 17.8487L9.89296 10.2418L4.51416 12.4699Z" fill="url(#paint132_linear_302_2603)" />
                        <path d="M12.1212 4.86298L9.89307 10.2418L17.5 7.09108L12.1212 4.86298Z" fill="url(#paint133_linear_302_2603)" />
                        <path d="M4.51416 23.2275L9.89296 25.4556L6.74156 17.8487L4.51416 23.2275Z" fill="url(#paint134_linear_302_2603)" />
                        <path d="M30.4857 23.2275L28.2569 17.8487L25.1069 25.4556L30.4857 23.2275Z" fill="url(#paint135_linear_302_2603)" />
                        <path d="M12.1212 30.8351L17.5 28.607L9.89307 25.4556L12.1212 30.8351Z" fill="url(#paint136_linear_302_2603)" />
                        <path d="M22.8788 30.8351L25.1069 25.4556L17.5 28.607L22.8788 30.8351Z" fill="url(#paint137_linear_302_2603)" />
                        <path d="M30.4857 12.4699L25.1069 10.2418L28.2569 17.8487L30.4857 12.4699Z" fill="url(#paint138_linear_302_2603)" />
                        <path d="M25.1069 10.2418L22.8788 4.86298L17.5 7.09108L25.1069 10.2418Z" fill="url(#paint139_linear_302_2603)" />
                    </g>
                    <path opacity="0.3" d="M13.0422 22.3063L9.89014 25.4584L11.1963 26.7632L14.3477 25.4584L13.0422 22.3063Z" fill="url(#paint140_linear_302_2603)" />
                    <path opacity="0.1" d="M32.6802 17.7556L28.2604 28.4236L17.4993 32.8812L6.73815 28.4236L2.31975 17.7563L2.28125 17.8487L6.73815 28.6098L17.5 33.0674L28.2611 28.6098L32.7187 17.8487L32.6802 17.7556Z" fill="white" />
                    <path opacity="0.5" d="M2.31975 17.9425L6.73955 7.2738L17.5007 2.8162L28.2618 7.2738L32.6802 17.9411L32.7187 17.8487L28.2618 7.0883L17.5 2.63L6.73955 7.0883L2.28125 17.8487L2.31975 17.9425Z" fill="#0C46B7" />
                    <g style={{ "mixBlendMode": "color" }}>
                        <path d="M28.2611 7.08831L17.5 2.63L6.73815 7.08831L2.28125 17.8487L6.73815 28.6098L17.5 33.0674L28.2611 28.6098L32.7187 17.8487L28.2611 7.08831Z" fill="url(#paint141_diamond_302_2603)" />
                    </g>
                    <path opacity="0.5" d="M28.2611 7.08831L17.5 2.63L6.73815 7.08831L2.28125 17.8487L6.73815 28.6098L17.5 33.0674L28.2611 28.6098L32.7187 17.8487L28.2611 7.08831Z" fill="#808080" />
                    <defs>
                        <linearGradient id="paint0_linear_302_2603" x1="6.73851" y1="13.7738" x2="9.89039" y2="13.7738" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_302_2603" x1="8.58432" y1="12.468" x2="13.0422" y2="12.468" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_302_2603" x1="8.58432" y1="12.468" x2="13.0422" y2="12.468" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_302_2603" x1="6.73851" y1="19.4247" x2="11.1963" y2="19.4247" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_302_2603" x1="6.73851" y1="16.2728" x2="11.1963" y2="16.2728" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.3047" stopColor="#145FF0" />
                            <stop offset="0.4091" stopColor="#2067F1" />
                            <stop offset="0.5124" stopColor="#3374F2" />
                            <stop offset="0.6152" stopColor="#4F87F4" />
                            <stop offset="0.7176" stopColor="#729FF6" />
                            <stop offset="0.8197" stopColor="#9EBDF9" />
                            <stop offset="0.9195" stopColor="#D1DFFC" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_302_2603" x1="6.73851" y1="19.4247" x2="11.1963" y2="19.4247" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.3047" stopColor="#145FF0" />
                            <stop offset="0.4091" stopColor="#2067F1" />
                            <stop offset="0.5124" stopColor="#3374F2" />
                            <stop offset="0.6152" stopColor="#4F87F4" />
                            <stop offset="0.7176" stopColor="#729FF6" />
                            <stop offset="0.8197" stopColor="#9EBDF9" />
                            <stop offset="0.9195" stopColor="#D1DFFC" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint6_linear_302_2603" x1="14.348" y1="9.31656" x2="17.4997" y2="9.31656" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint7_linear_302_2603" x1="17.4999" y1="9.31656" x2="20.6519" y2="9.31656" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint8_linear_302_2603" x1="19.346" y1="8.66363" x2="23.8029" y2="8.66363" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint9_linear_302_2603" x1="11.196" y1="8.66363" x2="15.6529" y2="8.66363" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint10_linear_302_2603" x1="11.196" y1="27.034" x2="15.6529" y2="27.034" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint11_linear_302_2603" x1="19.346" y1="27.034" x2="23.8029" y2="27.034" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint12_linear_302_2603" x1="6.73851" y1="21.9239" x2="9.89039" y2="21.9239" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint13_linear_302_2603" x1="21.9571" y1="23.2296" x2="26.4149" y2="23.2296" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint14_linear_302_2603" x1="14.348" y1="26.3811" x2="17.4997" y2="26.3811" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint15_linear_302_2603" x1="9.89014" y1="24.535" x2="14.348" y2="24.535" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint16_linear_302_2603" x1="20.6517" y1="11.163" x2="25.1088" y2="11.163" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2F4DE3" />
                            <stop offset="0.1725" stopColor="#3351E3" />
                            <stop offset="0.3607" stopColor="#3D5FE4" />
                            <stop offset="0.5558" stopColor="#4F74E5" />
                            <stop offset="0.5907" stopColor="#5379E5" />
                        </linearGradient>
                        <linearGradient id="paint17_linear_302_2603" x1="20.9464" y1="10.1929" x2="19.5516" y2="12.799" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint18_linear_302_2603" x1="21.9571" y1="15.6203" x2="25.1091" y2="15.6203" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint19_linear_302_2603" x1="17.4999" y1="15.6203" x2="23.8031" y2="15.6203" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint20_linear_302_2603" x1="13.0424" y1="11.8155" x2="17.5" y2="11.8155" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint21_linear_302_2603" x1="15.6835" y1="10.407" x2="15.1383" y2="13.1331" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint22_linear_302_2603" x1="21.9571" y1="12.468" x2="26.4149" y2="12.468" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint23_linear_302_2603" x1="11.4698" y1="14.0854" x2="11.7725" y2="17.5372" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint24_linear_302_2603" x1="12.2743" y1="19.9841" x2="9.93906" y2="21.3916" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint25_linear_302_2603" x1="17.4999" y1="23.8826" x2="21.957" y2="23.8826" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint26_linear_302_2603" x1="17.4999" y1="21.0007" x2="21.957" y2="21.0007" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4158F1" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint27_linear_302_2603" x1="25.1089" y1="21.9239" x2="28.2606" y2="21.9239" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint28_linear_302_2603" x1="13.0424" y1="21.0007" x2="17.5" y2="21.0007" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint29_linear_302_2603" x1="17.4999" y1="20.0776" x2="23.8031" y2="20.0776" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint30_linear_302_2603" x1="6.73851" y1="27.034" x2="11.1963" y2="27.034" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint31_linear_302_2603" x1="6.73851" y1="26.3811" x2="9.89039" y2="26.3811" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2F4DE3" />
                            <stop offset="0.1725" stopColor="#3351E3" />
                            <stop offset="0.3607" stopColor="#3D5FE4" />
                            <stop offset="0.5558" stopColor="#4F74E5" />
                            <stop offset="0.5907" stopColor="#5379E5" />
                        </linearGradient>
                        <linearGradient id="paint32_linear_302_2603" x1="6.73851" y1="27.6865" x2="13.0424" y2="27.6865" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint33_linear_302_2603" x1="6.73851" y1="30.8385" x2="17.5" y2="30.8385" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint34_linear_302_2603" x1="2.28145" y1="23.2292" x2="6.73837" y2="23.2292" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0D24BE" />
                            <stop offset="1" stopColor="#0E1D85" />
                        </linearGradient>
                        <linearGradient id="paint35_linear_302_2603" x1="25.1089" y1="26.3811" x2="28.2606" y2="26.3811" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint36_linear_302_2603" x1="17.4999" y1="30.8385" x2="28.2607" y2="30.8385" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint37_linear_302_2603" x1="15.653" y1="30.8385" x2="17.4999" y2="30.8385" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint38_linear_302_2603" x1="23.8031" y1="8.66363" x2="28.2607" y2="8.66363" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.2739" stopColor="#1D65F1" />
                            <stop offset="0.4155" stopColor="#407DF3" />
                            <stop offset="0.607" stopColor="#78A3F7" />
                            <stop offset="0.8385" stopColor="#C5D8FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint39_linear_302_2603" x1="17.4999" y1="4.85915" x2="28.2607" y2="4.85915" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint40_linear_302_2603" x1="28.2609" y1="20.0776" x2="32.7188" y2="20.0776" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint41_linear_302_2603" x1="2.28145" y1="12.4684" x2="6.73837" y2="12.4684" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint42_linear_302_2603" x1="2.28145" y1="12.4684" x2="6.73837" y2="12.4684" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint43_linear_302_2603" x1="28.2609" y1="16.9257" x2="32.7188" y2="16.9257" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint44_linear_302_2603" x1="6.73851" y1="8.01118" x2="13.0424" y2="8.01118" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint45_linear_302_2603" x1="6.73851" y1="9.31657" x2="9.89039" y2="9.31657" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint46_linear_302_2603" x1="6.73851" y1="9.31657" x2="9.89039" y2="9.31657" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint47_linear_302_2603" x1="19.6471" y1="4.12717" x2="19.9855" y2="7.17245" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint48_linear_302_2603" x1="17.4999" y1="4.85915" x2="19.3462" y2="4.85915" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint49_linear_302_2603" x1="17.4999" y1="20.5392" x2="21.957" y2="20.5392" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint50_linear_302_2603" x1="19.4024" y1="19.7513" x2="22.554" y2="16.5997" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint51_linear_302_2603" x1="20.1904" y1="17.8488" x2="20.1904" y2="13.3918" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint52_linear_302_2603" x1="19.4024" y1="15.9463" x2="16.2505" y2="12.7944" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint53_linear_302_2603" x1="17.5" y1="15.1584" x2="13.0424" y2="15.1584" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint54_linear_302_2603" x1="15.5973" y1="15.9464" x2="12.4454" y2="19.0983" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint55_linear_302_2603" x1="14.8096" y1="17.8488" x2="14.8096" y2="22.3065" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint56_linear_302_2603" x1="15.5973" y1="19.7512" x2="18.7493" y2="22.9031" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint57_linear_302_2603" x1="28.2609" y1="12.4684" x2="32.7188" y2="12.4684" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint58_linear_302_2603" x1="8.92083" y1="26.8977" x2="10.2409" y2="23.7477" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint59_linear_302_2603" x1="8.38901" y1="24.5059" x2="10.2561" y2="20.0506" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint60_linear_302_2603" x1="8.38901" y1="24.5059" x2="10.2561" y2="20.0506" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint61_linear_302_2603" x1="14.5685" y1="29.2642" x2="16.4354" y2="24.8091" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint62_linear_302_2603" x1="11.4185" y1="27.9442" x2="13.2855" y2="23.4891" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.3047" stopColor="#145FF0" />
                            <stop offset="0.4091" stopColor="#2067F1" />
                            <stop offset="0.5124" stopColor="#3374F2" />
                            <stop offset="0.6152" stopColor="#4F87F4" />
                            <stop offset="0.7176" stopColor="#729FF6" />
                            <stop offset="0.8197" stopColor="#9EBDF9" />
                            <stop offset="0.9195" stopColor="#D1DFFC" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint63_linear_302_2603" x1="14.5685" y1="29.2642" x2="16.4354" y2="24.8091" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.3047" stopColor="#145FF0" />
                            <stop offset="0.4091" stopColor="#2067F1" />
                            <stop offset="0.5124" stopColor="#3374F2" />
                            <stop offset="0.6152" stopColor="#4F87F4" />
                            <stop offset="0.7176" stopColor="#729FF6" />
                            <stop offset="0.8197" stopColor="#9EBDF9" />
                            <stop offset="0.9195" stopColor="#D1DFFC" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint64_linear_302_2603" x1="7.6526" y1="17.4256" x2="8.97268" y2="14.2755" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint65_linear_302_2603" x1="8.97302" y1="14.2757" x2="10.2932" y2="11.1254" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint66_linear_302_2603" x1="9.09347" y1="12.1571" x2="10.9602" y2="7.70266" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint67_linear_302_2603" x1="5.68042" y1="20.3024" x2="7.54705" y2="15.8481" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint68_linear_302_2603" x1="24.0403" y1="27.9957" x2="25.907" y2="23.5412" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint69_linear_302_2603" x1="27.4534" y1="19.8503" x2="29.32" y2="15.396" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint70_linear_302_2603" x1="17.0663" y1="30.3108" x2="18.3864" y2="27.1609" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint71_linear_302_2603" x1="24.7443" y1="15.6477" x2="26.6114" y2="11.1924" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint72_linear_302_2603" x1="24.7079" y1="24.572" x2="26.028" y2="21.4219" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint73_linear_302_2603" x1="20.9958" y1="28.2542" x2="22.8629" y2="23.7989" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint74_linear_302_2603" x1="12.1383" y1="11.8987" x2="14.0049" y2="7.44443" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2F4DE3" />
                            <stop offset="0.1725" stopColor="#3351E3" />
                            <stop offset="0.3607" stopColor="#3D5FE4" />
                            <stop offset="0.5558" stopColor="#4F74E5" />
                            <stop offset="0.5907" stopColor="#5379E5" />
                        </linearGradient>
                        <linearGradient id="paint75_linear_302_2603" x1="11.292" y1="11.1981" x2="13.3125" y2="13.6836" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint76_linear_302_2603" x1="17.1393" y1="12.4613" x2="18.4595" y2="9.31108" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint77_linear_302_2603" x1="15.273" y1="16.9154" x2="17.9128" y2="10.6161" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint78_linear_302_2603" x1="9.60366" y1="19.7773" x2="11.4706" y2="15.3222" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint79_linear_302_2603" x1="9.30204" y1="16.5478" x2="11.7983" y2="18.2345" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint80_linear_302_2603" x1="13.9889" y1="11.141" x2="15.8559" y2="6.68572" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint81_linear_302_2603" x1="11.2138" y1="22.2995" x2="14.7904" y2="23.4426" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint82_linear_302_2603" x1="17.4459" y1="23.9657" x2="17.8745" y2="26.8891" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint83_linear_302_2603" x1="23.5304" y1="20.3755" x2="25.397" y2="15.9213" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint84_linear_302_2603" x1="20.6503" y1="19.1689" x2="22.5169" y2="14.7144" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4158F1" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint85_linear_302_2603" x1="24.7593" y1="11.9508" x2="26.0793" y2="8.80066" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint86_linear_302_2603" x1="18.7833" y1="23.6239" x2="20.6503" y2="19.1686" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint87_linear_302_2603" x1="19.7275" y1="18.7822" x2="22.3673" y2="12.4828" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint88_linear_302_2603" x1="22.1738" y1="32.4508" x2="24.0407" y2="27.9958" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint89_linear_302_2603" x1="21.5209" y1="32.1773" x2="22.8409" y2="29.0275" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2F4DE3" />
                            <stop offset="0.1725" stopColor="#3351E3" />
                            <stop offset="0.3607" stopColor="#3D5FE4" />
                            <stop offset="0.5558" stopColor="#4F74E5" />
                            <stop offset="0.5907" stopColor="#5379E5" />
                        </linearGradient>
                        <linearGradient id="paint90_linear_302_2603" x1="22.8258" y1="32.724" x2="25.4659" y2="26.4239" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint91_linear_302_2603" x1="25.9757" y1="34.044" x2="30.4829" y2="23.2887" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint92_linear_302_2603" x1="16.5043" y1="35.3121" x2="18.3712" y2="30.8573" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0D24BE" />
                            <stop offset="1" stopColor="#0E1D85" />
                        </linearGradient>
                        <linearGradient id="paint93_linear_302_2603" x1="29.2144" y1="13.8174" x2="30.5345" y2="10.6672" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint94_linear_302_2603" x1="30.4823" y1="23.2886" x2="34.9891" y2="12.5341" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint95_linear_302_2603" x1="29.709" y1="25.1345" x2="30.4826" y2="23.2886" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint96_linear_302_2603" x1="10.96" y1="7.70261" x2="12.8269" y2="3.24763" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.2739" stopColor="#1D65F1" />
                            <stop offset="0.4155" stopColor="#407DF3" />
                            <stop offset="0.607" stopColor="#78A3F7" />
                            <stop offset="0.8385" stopColor="#C5D8FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint97_linear_302_2603" x1="4.51782" y1="12.4091" x2="9.0247" y2="1.65437" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint98_linear_302_2603" x1="24.2343" y1="8.02753" x2="26.1013" y2="3.57238" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint99_linear_302_2603" x1="5.74973" y1="30.8052" x2="7.61634" y2="26.351" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint100_linear_302_2603" x1="5.74973" y1="30.8052" x2="7.61634" y2="26.351" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint101_linear_302_2603" x1="21.0842" y1="6.70759" x2="22.9513" y2="2.25234" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint102_linear_302_2603" x1="3.1613" y1="24.4844" x2="5.80143" y2="18.1843" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint103_linear_302_2603" x1="4.46606" y1="25.0311" x2="5.78615" y2="21.881" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint104_linear_302_2603" x1="4.46606" y1="25.0311" x2="5.78615" y2="21.881" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint105_linear_302_2603" x1="4.68559" y1="9.95654" x2="7.87067" y2="10.8937" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint106_linear_302_2603" x1="4.51788" y1="12.4091" x2="5.29116" y2="10.5638" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint107_linear_302_2603" x1="20.1889" y1="18.9755" x2="22.0556" y2="14.5211" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint108_linear_302_2603" x1="20.1983" y1="16.7441" x2="18.3683" y2="12.2746" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint109_linear_302_2603" x1="18.627" y1="15.1599" x2="14.1725" y2="13.2932" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint110_linear_302_2603" x1="16.3957" y1="15.1506" x2="11.9256" y2="16.9809" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint111_linear_302_2603" x1="14.8115" y1="16.722" x2="12.9445" y2="21.1773" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint112_linear_302_2603" x1="14.8024" y1="18.9535" x2="16.6326" y2="23.4235" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint113_linear_302_2603" x1="16.3737" y1="20.5376" x2="20.8289" y2="22.4045" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint114_linear_302_2603" x1="18.6049" y1="20.5467" x2="23.0748" y2="18.7165" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint115_linear_302_2603" x1="16.6292" y1="4.84111" x2="18.4964" y2="0.385507" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint116_linear_302_2603" x1="14.997" y1="11.8069" x2="19.4609" y2="9.95782" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint117_linear_302_2603" x1="21.039" y1="13.7681" x2="24.1958" y2="12.4604" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint118_linear_302_2603" x1="10.533" y1="13.6559" x2="14.9968" y2="11.8069" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint119_linear_302_2603" x1="15.5384" y1="25.74" x2="20.0023" y2="23.8909" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint120_linear_302_2603" x1="8.9546" y1="18.7738" x2="12.1113" y2="17.4663" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint121_linear_302_2603" x1="10.8039" y1="23.2375" x2="13.9601" y2="21.9301" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint122_linear_302_2603" x1="20.0023" y1="23.8914" x2="24.4665" y2="22.0422" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint123_linear_302_2603" x1="22.8882" y1="18.2322" x2="26.0445" y2="16.9248" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint124_linear_302_2603" x1="11.4603" y1="15.3474" x2="13.3088" y2="10.8849" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint125_linear_302_2603" x1="17.1169" y1="12.4625" x2="18.4236" y2="9.30782" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint126_linear_302_2603" x1="9.6121" y1="19.8088" x2="11.4601" y2="15.3474" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint127_linear_302_2603" x1="21.6913" y1="24.8127" x2="23.5398" y2="20.3501" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint128_linear_302_2603" x1="12.1138" y1="24.542" x2="13.4209" y2="21.3864" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint129_linear_302_2603" x1="16.5756" y1="26.3899" x2="17.8824" y2="23.2351" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint130_linear_302_2603" x1="23.5393" y1="20.3501" x2="25.3873" y2="15.8886" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint131_linear_302_2603" x1="21.5792" y1="14.311" x2="22.8862" y2="11.1556" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint132_linear_302_2603" x1="7.20336" y1="17.8487" x2="7.20336" y2="10.2417" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint133_linear_302_2603" x1="13.6965" y1="10.2417" x2="13.6965" y2="4.86276" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint134_linear_302_2603" x1="7.20336" y1="25.4558" x2="7.20336" y2="17.8488" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint135_linear_302_2603" x1="27.7965" y1="25.4558" x2="27.7965" y2="17.8488" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint136_linear_302_2603" x1="13.6965" y1="30.8347" x2="13.6965" y2="25.4557" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint137_linear_302_2603" x1="21.3035" y1="30.8347" x2="21.3035" y2="25.4557" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint138_linear_302_2603" x1="27.7965" y1="17.8487" x2="27.7965" y2="10.2417" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint139_linear_302_2603" x1="21.3035" y1="10.2417" x2="21.3035" y2="4.86276" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint140_linear_302_2603" x1="9.89014" y1="24.535" x2="14.348" y2="24.535" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.3047" stopColor="#145FF0" />
                            <stop offset="0.4091" stopColor="#2067F1" />
                            <stop offset="0.5124" stopColor="#3374F2" />
                            <stop offset="0.6152" stopColor="#4F87F4" />
                            <stop offset="0.7176" stopColor="#729FF6" />
                            <stop offset="0.8197" stopColor="#9EBDF9" />
                            <stop offset="0.9195" stopColor="#D1DFFC" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <radialGradient id="paint141_diamond_302_2603" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.5 17.8487) rotate(-133.409) scale(17.826 13.2402)">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="#040404" />
                        </radialGradient>
                    </defs>
                </svg>

            )
        } else if (color === "violet") {
            return (
                <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.58418 11.5452L6.73828 13.3918V16.0028L9.89038 14.6966L8.58418 11.5452Z" fill="#0D2ED5" />
                    <path opacity="0.3" d="M8.58418 11.5452L6.73828 13.3918V16.0028L9.89038 14.6966L8.58418 11.5452Z" fill="url(#paint0_linear_302_2356)" />
                    <path d="M13.0423 13.3918L9.89018 10.239L8.58398 11.5452L9.89018 14.6966L13.0423 13.3918Z" fill="url(#paint1_linear_302_2356)" />
                    <path d="M13.0423 13.3918L9.89018 10.239L8.58398 11.5452L9.89018 14.6966L13.0423 13.3918Z" fill="url(#paint2_linear_302_2356)" />
                    <path d="M9.89038 14.6966L6.73828 16.0028V17.8487H11.1966L9.89038 14.6966Z" fill="#2D42CC" />
                    <path d="M11.1966 17.8487H6.73828V19.6953L9.89038 21.0008L11.1966 17.8487Z" fill="url(#paint3_linear_302_2356)" />
                    <path opacity="0.3" d="M9.89038 14.6966L6.73828 16.0028V17.8487H11.1966L9.89038 14.6966Z" fill="url(#paint4_linear_302_2356)" />
                    <path opacity="0.3" d="M11.1966 17.8487H6.73828V19.6953L9.89038 21.0008L11.1966 17.8487Z" fill="url(#paint5_linear_302_2356)" />
                    <path d="M17.4998 11.5452V7.08832H15.6532L14.3477 10.239L17.4998 11.5452Z" fill="url(#paint6_linear_302_2356)" />
                    <path d="M14.3477 10.239L11.1963 8.9342L9.89014 10.239L13.0422 13.3918L14.3477 10.239Z" fill="#2E40B6" />
                    <path d="M20.6521 10.239L19.3459 7.08832H17.5V11.5452L20.6521 10.239Z" fill="url(#paint7_linear_302_2356)" />
                    <path d="M23.8026 8.93422L21.9567 7.08832H19.3457L20.6519 10.239L23.8026 8.93422Z" fill="url(#paint8_linear_302_2356)" />
                    <path d="M15.6532 7.08832H13.0422L11.1963 8.93422L14.3477 10.239L15.6532 7.08832Z" fill="url(#paint9_linear_302_2356)" />
                    <path d="M11.1963 26.7632L13.0422 28.6098H15.6532L14.3477 25.4584L11.1963 26.7632Z" fill="url(#paint10_linear_302_2356)" />
                    <path d="M20.6519 25.4584L23.8026 26.7632L25.1088 25.4584L21.9567 22.3063L20.6519 25.4584Z" fill="#1F2C6D" />
                    <path d="M19.3457 28.6098H21.9567L23.8026 26.7632L20.6519 25.4584L19.3457 28.6098Z" fill="url(#paint11_linear_302_2356)" />
                    <path d="M6.73828 19.6953V22.3063L8.58418 24.1529L9.89038 21.0008L6.73828 19.6953Z" fill="url(#paint12_linear_302_2356)" />
                    <path d="M17.5 24.1529V28.6098H19.3459L20.6521 25.4584L17.5 24.1529Z" fill="#0419A9" />
                    <path d="M21.957 22.3063L25.1091 25.4584L26.4146 24.1529L25.1091 21.0008L21.957 22.3063Z" fill="url(#paint13_linear_302_2356)" />
                    <path d="M9.89018 21.0008L8.58398 24.1529L9.89018 25.4584L13.0423 22.3063L9.89018 21.0008Z" fill="#051791" />
                    <path d="M14.3477 25.4584L15.6532 28.6098H17.4998V24.1529L14.3477 25.4584Z" fill="url(#paint14_linear_302_2356)" />
                    <path d="M13.0422 22.3063L9.89014 25.4584L11.1963 26.7632L14.3477 25.4584L13.0422 22.3063Z" fill="url(#paint15_linear_302_2356)" />
                    <path d="M21.9567 13.3918L25.1088 10.239L23.8026 8.9342L20.6519 10.239L21.9567 13.3918Z" fill="url(#paint16_linear_302_2356)" />
                    <path d="M11.1963 17.8487L13.0422 22.3063L17.4998 17.8487H11.1963Z" fill="#18256A" />
                    <path d="M20.6521 10.239L17.5 11.5452L21.9569 13.3918L20.6521 10.239Z" fill="#2241E3" />
                    <path opacity="0.6" d="M20.6521 10.239L17.5 11.5452L21.9569 13.3918L20.6521 10.239Z" fill="url(#paint17_linear_302_2356)" />
                    <path d="M25.1091 14.6966L21.957 13.3918L23.8029 17.8487L25.1091 14.6966Z" fill="url(#paint18_linear_302_2356)" />
                    <path d="M21.9569 13.3918L17.5 11.5452V17.8487L21.9569 13.3918Z" fill="#101E67" />
                    <path d="M23.8028 17.8487L21.9569 13.3918L17.5 17.8487H23.8028Z" fill="url(#paint19_linear_302_2356)" />
                    <path d="M14.348 10.239L13.0425 13.3918L17.5001 11.5452L14.348 10.239Z" fill="url(#paint20_linear_302_2356)" />
                    <path opacity="0.5" d="M14.348 10.239L13.0425 13.3918L17.5001 11.5452L14.348 10.239Z" fill="url(#paint21_linear_302_2356)" />
                    <path d="M25.1091 14.6966L26.4146 11.5452L25.1091 10.239L21.957 13.3918L25.1091 14.6966Z" fill="url(#paint22_linear_302_2356)" />
                    <path d="M14.348 25.4584L17.5001 24.1529L13.0425 22.3063L14.348 25.4584Z" fill="#172B96" />
                    <path d="M9.89014 14.6966L11.1963 17.8487L13.0422 13.3918L9.89014 14.6966Z" fill="#304EE3" />
                    <path opacity="0.5" d="M9.89014 14.6966L11.1963 17.8487L13.0422 13.3918L9.89014 14.6966Z" fill="url(#paint23_linear_302_2356)" />
                    <path d="M9.89014 21.0008L13.0422 22.3063L11.1963 17.8487L9.89014 21.0008Z" fill="#2033B2" />
                    <path opacity="0.5" d="M9.89014 21.0008L13.0422 22.3063L11.1963 17.8487L9.89014 21.0008Z" fill="url(#paint24_linear_302_2356)" />
                    <path d="M20.6521 25.4584L21.9569 22.3063L17.5 24.1529L20.6521 25.4584Z" fill="url(#paint25_linear_302_2356)" />
                    <path d="M25.1089 21.0008L28.261 19.6953V17.8487H23.8027L25.1089 21.0008Z" fill="#304EE3" />
                    <path d="M23.8027 17.8487H28.261V16.0028L25.1089 14.6966L23.8027 17.8487Z" fill="#152EE1" />
                    <path d="M21.957 22.3063L25.1091 21.0008L23.8029 17.8487L21.957 22.3063Z" fill="#152EE1" />
                    <path d="M28.261 16.0028V13.3918L26.4144 11.5452L25.1089 14.6966L28.261 16.0028Z" fill="#0D21AB" />
                    <path d="M17.5 24.1529L21.9569 22.3063L17.5 17.8487V24.1529Z" fill="url(#paint26_linear_302_2356)" />
                    <path d="M25.1089 21.0008L26.4144 24.1529L28.261 22.3063V19.6953L25.1089 21.0008Z" fill="url(#paint27_linear_302_2356)" />
                    <path d="M13.0425 22.3063L17.5001 24.1529V17.8487L13.0425 22.3063Z" fill="url(#paint28_linear_302_2356)" />
                    <path d="M13.0422 13.3918L11.1963 17.8487H17.4998L13.0422 13.3918Z" fill="#172B96" />
                    <path d="M17.5 17.8487L21.9569 22.3063L23.8028 17.8487H17.5Z" fill="url(#paint29_linear_302_2356)" />
                    <path d="M17.5001 11.5452L13.0425 13.3918L17.5001 17.8487V11.5452Z" fill="#1E31B1" />
                    <path d="M6.73828 22.3063V28.6098L8.58418 24.1529L6.73828 22.3063Z" fill="#0419A9" />
                    <path d="M9.89038 25.4584L6.73828 28.6098L11.1966 26.7632L9.89038 25.4584Z" fill="url(#paint30_linear_302_2356)" />
                    <path d="M8.58418 24.1529L6.73828 28.6098L9.89038 25.4584L8.58418 24.1529Z" fill="url(#paint31_linear_302_2356)" />
                    <path d="M11.1966 26.7632L6.73828 28.6098H13.0425L11.1966 26.7632Z" fill="url(#paint32_linear_302_2356)" />
                    <path d="M6.73828 28.6098L17.5001 33.0674L13.0425 28.6098H6.73828Z" fill="url(#paint33_linear_302_2356)" />
                    <path d="M2.28125 17.8487L6.73815 28.6098V22.3063L2.28125 17.8487Z" fill="url(#paint34_linear_302_2356)" />
                    <path d="M13.0425 28.6098L17.5001 33.0674L15.6535 28.6098H13.0425Z" fill="#2033B2" />
                    <path d="M6.73815 16.0028L2.28125 17.8487H6.73815V16.0028Z" fill="#2336B2" />
                    <path d="M6.73815 17.8487H2.28125L6.73815 19.6953V17.8487Z" fill="#0B1E7E" />
                    <path d="M6.73815 19.6953L2.28125 17.8487L6.73815 22.3063V19.6953Z" fill="#2638B3" />
                    <path d="M19.3459 28.6098L17.5 33.0674L21.9569 28.6098H19.3459Z" fill="#0520DE" />
                    <path d="M25.1089 25.4584L28.261 28.6098L26.4144 24.1529L25.1089 25.4584Z" fill="url(#paint35_linear_302_2356)" />
                    <path d="M26.4146 24.1529L28.2612 28.6098V22.3063L26.4146 24.1529Z" fill="#2E40B6" />
                    <path d="M23.8027 26.7632L28.261 28.6098L25.1089 25.4584L23.8027 26.7632Z" fill="#206BCC" />
                    <path d="M28.2612 22.3063V28.6098L32.7188 17.8487L28.2612 22.3063Z" fill="#00199D" />
                    <path d="M17.5 33.0674L28.2611 28.6098H21.9569L17.5 33.0674Z" fill="url(#paint36_linear_302_2356)" />
                    <path d="M21.957 28.6098H28.2612L23.8029 26.7632L21.957 28.6098Z" fill="#1529AE" />
                    <path d="M6.73815 13.3918L2.28125 17.8487L6.73815 16.0028V13.3918Z" fill="#0C218F" />
                    <path d="M17.5 28.6098V33.0674L19.3459 28.6098H17.5Z" fill="#3E5CF3" />
                    <path d="M15.6533 28.6098L17.4999 33.0674V28.6098H15.6533Z" fill="url(#paint37_linear_302_2356)" />
                    <path d="M25.1089 10.239L28.261 7.08832L23.8027 8.93422L25.1089 10.239Z" fill="#041584" />
                    <path opacity="0.3" d="M25.1089 10.239L28.261 7.08832L23.8027 8.93422L25.1089 10.239Z" fill="url(#paint38_linear_302_2356)" />
                    <path d="M28.2612 13.3918V7.08832L26.4146 11.5452L28.2612 13.3918Z" fill="#0B1E7E" />
                    <path d="M28.2611 7.08831L17.5 2.63L21.9569 7.08831H28.2611Z" fill="#206BCC" />
                    <path opacity="0.3" d="M28.2611 7.08831L17.5 2.63L21.9569 7.08831H28.2611Z" fill="url(#paint39_linear_302_2356)" />
                    <path d="M26.4144 11.5452L28.261 7.08832L25.1089 10.239L26.4144 11.5452Z" fill="#182CAF" />
                    <path d="M23.8029 8.93422L28.2612 7.08832H21.957L23.8029 8.93422Z" fill="#172B96" />
                    <path d="M28.2612 22.3063L32.7188 17.8487L28.2612 19.6953V22.3063Z" fill="url(#paint40_linear_302_2356)" />
                    <path d="M6.73815 7.08832L2.28125 17.8487L6.73815 13.3918V7.08832Z" fill="url(#paint41_linear_302_2356)" />
                    <path opacity="0.3" d="M6.73815 7.08832L2.28125 17.8487L6.73815 13.3918V7.08832Z" fill="url(#paint42_linear_302_2356)" />
                    <path d="M28.2612 17.8487H32.7188L28.2612 16.0028V17.8487Z" fill="url(#paint43_linear_302_2356)" />
                    <path d="M28.2612 16.0028L32.7188 17.8487L28.2612 13.3918V16.0028Z" fill="#0B1E7E" />
                    <path d="M32.7188 17.8487L28.2612 7.08832V13.3918L32.7188 17.8487Z" fill="#182CAF" />
                    <path d="M28.2612 19.6953L32.7188 17.8487H28.2612V19.6953Z" fill="#23306F" />
                    <path d="M13.0425 7.08832H6.73828L11.1966 8.93422L13.0425 7.08832Z" fill="#304EE3" />
                    <path opacity="0.3" d="M13.0425 7.08832H6.73828L11.1966 8.93422L13.0425 7.08832Z" fill="url(#paint44_linear_302_2356)" />
                    <path d="M11.1966 8.93422L6.73828 7.08832L9.89038 10.239L11.1966 8.93422Z" fill="#152696" />
                    <path d="M9.89038 10.239L6.73828 7.08832L8.58418 11.5452L9.89038 10.239Z" fill="url(#paint45_linear_302_2356)" />
                    <path opacity="0.3" d="M9.89038 10.239L6.73828 7.08832L8.58418 11.5452L9.89038 10.239Z" fill="url(#paint46_linear_302_2356)" />
                    <path d="M21.9569 7.08831L17.5 2.63L19.3459 7.08831H21.9569Z" fill="#2D42CC" />
                    <path opacity="0.2" d="M21.9569 7.08831L17.5 2.63L19.3459 7.08831H21.9569Z" fill="url(#paint47_linear_302_2356)" />
                    <path d="M8.58418 11.5452L6.73828 7.08832V13.3918L8.58418 11.5452Z" fill="#0B1E7E" />
                    <path d="M17.4999 7.08831V2.63L15.6533 7.08831H17.4999Z" fill="#0520DE" />
                    <path d="M19.3459 7.08831L17.5 2.63V7.08831H19.3459Z" fill="url(#paint48_linear_302_2356)" />
                    <path d="M15.6535 7.08831L17.5001 2.63L13.0425 7.08831H15.6535Z" fill="#051791" />
                    <path d="M17.5001 2.63L6.73828 7.08831H13.0425L17.5001 2.63Z" fill="#2033B2" />
                    <path opacity="0.3" d="M17.5 17.8487L19.7288 23.2296L21.9569 22.3063L17.5 17.8487Z" fill="url(#paint49_linear_302_2356)" />
                    <path opacity="0.3" d="M17.5 17.8487L22.8809 20.0775L23.8028 17.8487H17.5Z" fill="url(#paint50_linear_302_2356)" />
                    <path opacity="0.3" d="M17.5 17.8487L22.8809 15.6199L21.9569 13.3918L17.5 17.8487Z" fill="url(#paint51_linear_302_2356)" />
                    <path opacity="0.3" d="M17.5 17.8487L19.7288 12.4678L17.5 11.5452V17.8487Z" fill="url(#paint52_linear_302_2356)" />
                    <path opacity="0.3" d="M17.5001 17.8487L15.2713 12.4678L13.0425 13.3918L17.5001 17.8487Z" fill="url(#paint53_linear_302_2356)" />
                    <path opacity="0.3" d="M17.4998 17.8487L12.1189 15.6199L11.1963 17.8487H17.4998Z" fill="url(#paint54_linear_302_2356)" />
                    <path opacity="0.3" d="M17.5 17.8487L12.1191 20.0775L13.0424 22.3063L17.5 17.8487Z" fill="url(#paint55_linear_302_2356)" />
                    <path opacity="0.3" d="M17.4998 17.8487L15.271 23.2296L17.4998 24.1529V17.8487Z" fill="url(#paint56_linear_302_2356)" />
                    <path opacity="0.3" d="M32.7188 17.8487L28.2612 7.08832V13.3918L32.7188 17.8487Z" fill="url(#paint57_linear_302_2356)" />
                    <g opacity="0.3">
                        <mask id="mask0_302_2356" style={{ "maskType": "luminance" }} maskUnits="userSpaceOnUse" x="2" y="2" width="31" height="32">
                            <path d="M28.2611 7.08831L17.5 2.63L6.73815 7.08831L2.28125 17.8487L6.73815 28.6098L17.5 33.0674L28.2611 28.6098L32.7187 17.8487L28.2611 7.08831Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_302_2356)">
                            <path d="M7.46631 24.1193L8.53941 26.738L11.1483 27.8314L11.163 24.134L7.46631 24.1193Z" fill="#0D2ED5" />
                            <path opacity="0.3" d="M7.46631 24.1193L8.53941 26.738L11.1483 27.8314L11.163 24.134L7.46631 24.1193Z" fill="url(#paint58_linear_302_2356)" />
                            <path d="M11.1794 20.4373L6.7085 22.2671L7.4666 24.1193L11.1633 24.134L11.1794 20.4373Z" fill="url(#paint59_linear_302_2356)" />
                            <path d="M11.1794 20.4373L6.7085 22.2671L7.4666 24.1193L11.1633 24.134L11.1794 20.4373Z" fill="url(#paint60_linear_302_2356)" />
                            <path d="M11.1631 24.134L11.1484 27.8314L12.9936 28.6042L14.8605 24.1494L11.1631 24.134Z" fill="#2D42CC" />
                            <path d="M14.8606 24.1494L12.9937 28.6042L14.8389 29.3777L17.4639 26.7744L14.8606 24.1494Z" fill="url(#paint61_linear_302_2356)" />
                            <path opacity="0.3" d="M11.1631 24.134L11.1484 27.8314L12.9936 28.6042L14.8605 24.1494L11.1631 24.134Z" fill="url(#paint62_linear_302_2356)" />
                            <path opacity="0.3" d="M14.8606 24.1494L12.9937 28.6042L14.8389 29.3777L17.4639 26.7744L14.8606 24.1494Z" fill="url(#paint63_linear_302_2356)" />
                            <path d="M11.2 15.209L6.74518 13.3421L5.97168 15.188L8.57498 17.8123L11.2 15.209Z" fill="url(#paint64_linear_302_2356)" />
                            <path d="M8.57498 17.8123L5.95068 20.4156L6.70808 22.2671L11.179 20.4373L8.57498 17.8123Z" fill="#2E40B6" />
                            <path d="M11.2151 11.5116L7.51911 11.4976L6.74561 13.3421L11.2004 15.209L11.2151 11.5116Z" fill="url(#paint65_linear_302_2356)" />
                            <path d="M11.2304 7.81628L8.61244 8.88798L7.51904 11.4976L11.215 11.5116L11.2304 7.81628Z" fill="url(#paint66_linear_302_2356)" />
                            <path d="M5.97209 15.188L4.87939 17.7976L5.95109 20.4156L8.57539 17.8123L5.97209 15.188Z" fill="url(#paint67_linear_302_2356)" />
                            <path d="M23.77 27.8825L26.388 26.8108L27.4814 24.2012L23.7854 24.1858L23.77 27.8825Z" fill="url(#paint68_linear_302_2356)" />
                            <path d="M26.4258 17.8851L29.0494 15.2825L28.292 13.431L23.8218 15.2615L26.4258 17.8851Z" fill="#1F2C6D" />
                            <path d="M29.0284 20.5101L30.1211 17.9012L29.0494 15.2825L26.4258 17.8851L29.0284 20.5101Z" fill="url(#paint69_linear_302_2356)" />
                            <path d="M14.8389 29.3777L17.4485 30.4711L20.0672 29.3994L17.4639 26.7744L14.8389 29.3777Z" fill="url(#paint70_linear_302_2356)" />
                            <path d="M23.8008 20.4884L28.2549 22.3553L29.0284 20.5101L26.4258 17.8851L23.8008 20.4884Z" fill="#0419A9" />
                            <path d="M23.8218 15.2615L28.292 13.431L27.5339 11.5788L23.8365 11.5641L23.8218 15.2615Z" fill="url(#paint71_linear_302_2356)" />
                            <path d="M17.4639 26.7744L20.0672 29.3994L21.9194 28.6413L20.0889 24.1711L17.4639 26.7744Z" fill="#051791" />
                            <path d="M23.7856 24.1858L27.4816 24.2012L28.2551 22.3553L23.801 20.4884L23.7856 24.1858Z" fill="url(#paint72_linear_302_2356)" />
                            <path d="M20.0889 24.1711L21.9194 28.6413L23.7702 27.8825L23.7856 24.1858L20.0889 24.1711Z" fill="url(#paint73_linear_302_2356)" />
                            <path d="M14.9122 11.5277L13.081 7.0575L11.2302 7.8163L11.2148 11.5116L14.9122 11.5277Z" fill="url(#paint74_linear_302_2356)" />
                            <path d="M14.8604 24.1494L20.0887 24.1711L17.5001 17.8487L14.8604 24.1494Z" fill="#18256A" />
                            <path d="M11.2149 11.5116L11.2002 15.209L14.9123 11.5277L11.2149 11.5116Z" fill="#2241E3" />
                            <path opacity="0.6" d="M11.2149 11.5116L11.2002 15.209L14.9123 11.5277L11.2149 11.5116Z" fill="url(#paint75_linear_302_2356)" />
                            <path d="M17.5364 8.92444L14.9121 11.5277L20.1397 11.5494L17.5364 8.92444Z" fill="url(#paint76_linear_302_2356)" />
                            <path d="M14.9123 11.5277L11.2002 15.209L17.5002 17.8487L14.9123 11.5277Z" fill="#101E67" />
                            <path d="M20.1397 11.5494L14.9121 11.5277L17.5 17.8487L20.1397 11.5494Z" fill="url(#paint77_linear_302_2356)" />
                            <path d="M8.5752 17.8123L11.1792 20.4373L11.2002 15.209L8.5752 17.8123Z" fill="url(#paint78_linear_302_2356)" />
                            <path opacity="0.5" d="M8.5752 17.8123L11.1792 20.4373L11.2002 15.209L8.5752 17.8123Z" fill="url(#paint79_linear_302_2356)" />
                            <path d="M17.5366 8.92444L14.9333 6.29944L13.0811 7.05754L14.9123 11.5277L17.5366 8.92444Z" fill="url(#paint80_linear_302_2356)" />
                            <path d="M23.7856 24.1858L23.801 20.4884L20.0889 24.1711L23.7856 24.1858Z" fill="#172B96" />
                            <path d="M11.1631 24.134L14.8605 24.1494L11.1792 20.4373L11.1631 24.134Z" fill="#304EE3" />
                            <path opacity="0.5" d="M11.1631 24.134L14.8605 24.1494L11.1792 20.4373L11.1631 24.134Z" fill="url(#paint81_linear_302_2356)" />
                            <path d="M17.4637 26.7744L20.0887 24.1711L14.8604 24.1494L17.4637 26.7744Z" fill="#2033B2" />
                            <path opacity="0.5" d="M17.4637 26.7744L20.0887 24.1711L14.8604 24.1494L17.4637 26.7744Z" fill="url(#paint82_linear_302_2356)" />
                            <path d="M26.4258 17.8851L23.8218 15.2615L23.8008 20.4884L26.4258 17.8851Z" fill="url(#paint83_linear_302_2356)" />
                            <path d="M23.8363 11.5641L23.8517 7.86743L22.0065 7.09393L20.1396 11.5494L23.8363 11.5641Z" fill="#304EE3" />
                            <path d="M20.1399 11.5494L22.0068 7.09391L20.1616 6.32111L17.5366 8.92441L20.1399 11.5494Z" fill="#152EE1" />
                            <path d="M23.8216 15.2615L23.8363 11.5641L20.1396 11.5494L23.8216 15.2615Z" fill="#152EE1" />
                            <path d="M20.1614 6.32112L17.5525 5.22772L14.9331 6.29942L17.5364 8.92442L20.1614 6.32112Z" fill="#0D21AB" />
                            <path d="M23.8007 20.4884L23.8217 15.2615L17.5 17.8487L23.8007 20.4884Z" fill="url(#paint84_linear_302_2356)" />
                            <path d="M23.8364 11.5641L27.5338 11.5788L26.4621 8.96083L23.8518 7.86743L23.8364 11.5641Z" fill="url(#paint85_linear_302_2356)" />
                            <path d="M20.0886 24.1711L23.8007 20.4884L17.5 17.8487L20.0886 24.1711Z" fill="url(#paint86_linear_302_2356)" />
                            <path d="M11.1792 20.4373L14.8605 24.1494L17.5002 17.8487L11.1792 20.4373Z" fill="#172B96" />
                            <path d="M17.5 17.8487L23.8217 15.2615L20.1397 11.5494L17.5 17.8487Z" fill="url(#paint87_linear_302_2356)" />
                            <path d="M11.2002 15.209L11.1792 20.4373L17.5002 17.8487L11.2002 15.209Z" fill="#1E31B1" />
                            <path d="M17.4482 30.4711L23.7482 33.1108L20.0669 29.3994L17.4482 30.4711Z" fill="#0419A9" />
                            <path d="M21.9194 28.6413L23.7485 33.1108L23.7702 27.8825L21.9194 28.6413Z" fill="url(#paint88_linear_302_2356)" />
                            <path d="M20.0669 29.3994L23.7482 33.1108L21.9191 28.6413L20.0669 29.3994Z" fill="url(#paint89_linear_302_2356)" />
                            <path d="M23.7702 27.8825L23.7485 33.1108L26.3882 26.8108L23.7702 27.8825Z" fill="url(#paint90_linear_302_2356)" />
                            <path d="M23.7485 33.1108L32.7106 24.2222L26.3882 26.8108L23.7485 33.1108Z" fill="url(#paint91_linear_302_2356)" />
                            <path d="M11.1274 33.059L23.7484 33.1108L17.4484 30.4711L11.1274 33.059Z" fill="url(#paint92_linear_302_2356)" />
                            <path d="M26.3882 26.8108L32.7106 24.2222L27.4816 24.2012L26.3882 26.8108Z" fill="#2033B2" />
                            <path d="M11.1484 27.8314L11.1274 33.059L12.9936 28.6042L11.1484 27.8314Z" fill="#2336B2" />
                            <path d="M12.9936 28.6042L11.1274 33.059L14.8388 29.3777L12.9936 28.6042Z" fill="#0B1E7E" />
                            <path d="M14.8388 29.3777L11.1274 33.059L17.4484 30.4711L14.8388 29.3777Z" fill="#2638B3" />
                            <path d="M29.0283 20.5101L32.7103 24.2222L30.121 17.9012L29.0283 20.5101Z" fill="#0520DE" />
                            <path d="M28.2923 13.431L32.7618 11.6005L27.5342 11.5788L28.2923 13.431Z" fill="url(#paint93_linear_302_2356)" />
                            <path d="M27.5341 11.5788L32.7617 11.6005L26.4624 8.96082L27.5341 11.5788Z" fill="#2E40B6" />
                            <path d="M29.0494 15.2825L32.7615 11.6005L28.292 13.431L29.0494 15.2825Z" fill="#206BCC" />
                            <path d="M26.4621 8.9608L32.7614 11.6005L23.8735 2.6391L26.4621 8.9608Z" fill="#00199D" />
                            <path d="M32.7104 24.2222L32.7615 11.6005L30.1211 17.9012L32.7104 24.2222Z" fill="url(#paint94_linear_302_2356)" />
                            <path d="M30.121 17.9012L32.7614 11.6005L29.0493 15.2825L30.121 17.9012Z" fill="#1529AE" />
                            <path d="M8.53955 26.738L11.1275 33.059L11.1485 27.8314L8.53955 26.738Z" fill="#0C218F" />
                            <path d="M28.2549 22.3553L32.7104 24.2222L29.0284 20.5101L28.2549 22.3553Z" fill="#3E5CF3" />
                            <path d="M27.4814 24.2012L32.7104 24.2222L28.2549 22.3553L27.4814 24.2012Z" fill="url(#paint95_linear_302_2356)" />
                            <path d="M13.0813 7.05751L11.2522 2.58801L11.2305 7.81631L13.0813 7.05751Z" fill="#041584" />
                            <path opacity="0.3" d="M13.0813 7.05751L11.2522 2.58801L11.2305 7.81631L13.0813 7.05751Z" fill="url(#paint96_linear_302_2356)" />
                            <path d="M17.5527 5.22771L11.252 2.58801L14.9333 6.29941L17.5527 5.22771Z" fill="#0B1E7E" />
                            <path d="M11.2519 2.58801L2.29053 11.4759L8.61223 8.88801L11.2519 2.58801Z" fill="#206BCC" />
                            <path opacity="0.3" d="M11.2519 2.58801L2.29053 11.4759L8.61223 8.88801L11.2519 2.58801Z" fill="url(#paint97_linear_302_2356)" />
                            <path d="M14.9333 6.29941L11.252 2.58801L13.0811 7.05751L14.9333 6.29941Z" fill="#182CAF" />
                            <path d="M11.2303 7.81631L11.252 2.58801L8.6123 8.88801L11.2303 7.81631Z" fill="#172B96" />
                            <path d="M26.4624 8.9608L23.8738 2.6391L23.8521 7.8674L26.4624 8.9608Z" fill="url(#paint98_linear_302_2356)" />
                            <path d="M2.23877 24.0976L11.1274 33.059L8.53947 26.738L2.23877 24.0976Z" fill="url(#paint99_linear_302_2356)" />
                            <path opacity="0.3" d="M2.23877 24.0976L11.1274 33.059L8.53947 26.738L2.23877 24.0976Z" fill="url(#paint100_linear_302_2356)" />
                            <path d="M22.0068 7.0939L23.8737 2.6391L20.1616 6.3211L22.0068 7.0939Z" fill="url(#paint101_linear_302_2356)" />
                            <path d="M20.1616 6.3211L23.8737 2.6391L17.5527 5.2277L20.1616 6.3211Z" fill="#0B1E7E" />
                            <path d="M23.8737 2.63911L11.252 2.58801L17.5527 5.22771L23.8737 2.63911Z" fill="#182CAF" />
                            <path d="M23.852 7.8674L23.8737 2.6391L22.0068 7.0939L23.852 7.8674Z" fill="#23306F" />
                            <path d="M4.87917 17.7976L2.23877 24.0976L5.95087 20.4156L4.87917 17.7976Z" fill="#304EE3" />
                            <path opacity="0.3" d="M4.87917 17.7976L2.23877 24.0976L5.95087 20.4156L4.87917 17.7976Z" fill="url(#paint102_linear_302_2356)" />
                            <path d="M5.95087 20.4156L2.23877 24.0976L6.70827 22.2671L5.95087 20.4156Z" fill="#152696" />
                            <path d="M6.70827 22.2671L2.23877 24.0976L7.46637 24.1193L6.70827 22.2671Z" fill="url(#paint103_linear_302_2356)" />
                            <path opacity="0.3" d="M6.70827 22.2671L2.23877 24.0976L7.46637 24.1193L6.70827 22.2671Z" fill="url(#paint104_linear_302_2356)" />
                            <path d="M8.61223 8.888L2.29053 11.4759L7.51883 11.4976L8.61223 8.888Z" fill="#2D42CC" />
                            <path opacity="0.2" d="M8.61223 8.888L2.29053 11.4759L7.51883 11.4976L8.61223 8.888Z" fill="url(#paint105_linear_302_2356)" />
                            <path d="M7.46637 24.1193L2.23877 24.0976L8.53947 26.738L7.46637 24.1193Z" fill="#0B1E7E" />
                            <path d="M6.74533 13.3421L2.29053 11.4759L5.97183 15.188L6.74533 13.3421Z" fill="#0520DE" />
                            <path d="M7.51883 11.4976L2.29053 11.4759L6.74533 13.3421L7.51883 11.4976Z" fill="url(#paint106_linear_302_2356)" />
                            <path d="M5.97183 15.188L2.29053 11.4759L4.87913 17.7976L5.97183 15.188Z" fill="#051791" />
                            <path d="M2.29057 11.4759L2.23877 24.0976L4.87917 17.7976L2.29057 11.4759Z" fill="#2033B2" />
                            <path opacity="0.3" d="M17.5 17.8487L23.8112 17.8746L23.8217 15.2615L17.5 17.8487Z" fill="url(#paint107_linear_302_2356)" />
                            <path opacity="0.3" d="M17.5 17.8487L21.9814 13.4044L20.1397 11.5494L17.5 17.8487Z" fill="url(#paint108_linear_302_2356)" />
                            <path opacity="0.3" d="M17.5 17.8487L17.5259 11.5375L14.9121 11.5277L17.5 17.8487Z" fill="url(#paint109_linear_302_2356)" />
                            <path opacity="0.3" d="M17.5002 17.8487L13.0559 13.3673L11.2002 15.209L17.5002 17.8487Z" fill="url(#paint110_linear_302_2356)" />
                            <path opacity="0.3" d="M17.5002 17.8487L11.189 17.8228L11.1792 20.4373L17.5002 17.8487Z" fill="url(#paint111_linear_302_2356)" />
                            <path opacity="0.3" d="M17.5002 17.8487L13.0195 22.293L14.8605 24.1494L17.5002 17.8487Z" fill="url(#paint112_linear_302_2356)" />
                            <path opacity="0.3" d="M17.5003 17.8487L17.4751 24.1599L20.0889 24.1711L17.5003 17.8487Z" fill="url(#paint113_linear_302_2356)" />
                            <path opacity="0.3" d="M17.5 17.8487L21.9443 22.3294L23.8007 20.4884L17.5 17.8487Z" fill="url(#paint114_linear_302_2356)" />
                            <path opacity="0.3" d="M23.8737 2.63911L11.252 2.58801L17.5527 5.22771L23.8737 2.63911Z" fill="url(#paint115_linear_302_2356)" />
                        </g>
                    </g>
                    <g opacity="0.3">
                        <path d="M17.5 8.92163L14.8848 11.5361H20.1145L17.5 8.92163Z" fill="url(#paint116_linear_302_2356)" />
                        <path d="M23.8131 11.5361H20.1143L23.8131 15.2342V11.5361Z" fill="url(#paint117_linear_302_2356)" />
                        <path d="M11.1865 11.5361V15.2342L14.8846 11.5361H11.1865Z" fill="url(#paint118_linear_302_2356)" />
                        <path d="M17.5 26.7765L20.1145 24.162H14.8848L17.5 26.7765Z" fill="url(#paint119_linear_302_2356)" />
                        <path d="M8.57129 17.8488L11.1865 20.464V15.2343L8.57129 17.8488Z" fill="url(#paint120_linear_302_2356)" />
                        <path d="M11.1865 24.162H14.8846L11.1865 20.4639V24.162Z" fill="url(#paint121_linear_302_2356)" />
                        <path d="M23.8131 24.162V20.4639L20.1143 24.162H23.8131Z" fill="url(#paint122_linear_302_2356)" />
                        <path d="M23.813 20.464L26.4275 17.8488L23.813 15.2343V20.464Z" fill="url(#paint123_linear_302_2356)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M11.1895 11.5382V15.2349L14.8862 11.5382H11.1895Z" fill="url(#paint124_linear_302_2356)" />
                        <path d="M17.4995 8.92511L14.8857 11.5382H20.1133L17.4995 8.92511Z" fill="url(#paint125_linear_302_2356)" />
                        <path d="M8.57617 17.8487L11.1893 20.4625V15.2349L8.57617 17.8487Z" fill="url(#paint126_linear_302_2356)" />
                        <path d="M23.8105 24.1592V20.4625L20.1138 24.1592H23.8105Z" fill="url(#paint127_linear_302_2356)" />
                        <path d="M11.1895 24.1592H14.8862L11.1895 20.4625V24.1592Z" fill="url(#paint128_linear_302_2356)" />
                        <path d="M17.4995 26.773L20.1133 24.1592H14.8857L17.4995 26.773Z" fill="url(#paint129_linear_302_2356)" />
                        <path d="M26.4232 17.8487L23.8101 15.2349V20.4625L26.4232 17.8487Z" fill="url(#paint130_linear_302_2356)" />
                        <path d="M23.8105 15.2349V11.5382H20.1138L23.8105 15.2349Z" fill="url(#paint131_linear_302_2356)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M4.51416 12.4699L6.74156 17.8487L9.89296 10.2418L4.51416 12.4699Z" fill="url(#paint132_linear_302_2356)" />
                        <path d="M12.1212 4.86298L9.89307 10.2418L17.5 7.09108L12.1212 4.86298Z" fill="url(#paint133_linear_302_2356)" />
                        <path d="M4.51416 23.2275L9.89296 25.4556L6.74156 17.8487L4.51416 23.2275Z" fill="url(#paint134_linear_302_2356)" />
                        <path d="M30.4857 23.2275L28.2569 17.8487L25.1069 25.4556L30.4857 23.2275Z" fill="url(#paint135_linear_302_2356)" />
                        <path d="M12.1212 30.8351L17.5 28.607L9.89307 25.4556L12.1212 30.8351Z" fill="url(#paint136_linear_302_2356)" />
                        <path d="M22.8788 30.8351L25.1069 25.4556L17.5 28.607L22.8788 30.8351Z" fill="url(#paint137_linear_302_2356)" />
                        <path d="M30.4857 12.4699L25.1069 10.2418L28.2569 17.8487L30.4857 12.4699Z" fill="url(#paint138_linear_302_2356)" />
                        <path d="M25.1069 10.2418L22.8788 4.86298L17.5 7.09108L25.1069 10.2418Z" fill="url(#paint139_linear_302_2356)" />
                    </g>
                    <path opacity="0.3" d="M13.0422 22.3063L9.89014 25.4584L11.1963 26.7632L14.3477 25.4584L13.0422 22.3063Z" fill="url(#paint140_linear_302_2356)" />
                    <path opacity="0.1" d="M32.6802 17.7556L28.2604 28.4236L17.4993 32.8812L6.73815 28.4236L2.31975 17.7563L2.28125 17.8487L6.73815 28.6098L17.5 33.0674L28.2611 28.6098L32.7187 17.8487L32.6802 17.7556Z" fill="white" />
                    <path opacity="0.5" d="M2.31975 17.9425L6.73955 7.2738L17.5007 2.8162L28.2618 7.2738L32.6802 17.9411L32.7187 17.8487L28.2618 7.0883L17.5 2.63L6.73955 7.0883L2.28125 17.8487L2.31975 17.9425Z" fill="#0C46B7" />
                    <g style={{ "mixBlendMode": "color" }}>
                        <path d="M28.2611 7.08831L17.5 2.63L6.73815 7.08831L2.28125 17.8487L6.73815 28.6098L17.5 33.0674L28.2611 28.6098L32.7187 17.8487L28.2611 7.08831Z" fill="url(#paint141_diamond_302_2356)" />
                    </g>
                    <path opacity="0.5" d="M28.2611 7.08831L17.5 2.63L6.73815 7.08831L2.28125 17.8487L6.73815 28.6098L17.5 33.0674L28.2611 28.6098L32.7187 17.8487L28.2611 7.08831Z" fill="#EE82EE" />
                    <defs>
                        <linearGradient id="paint0_linear_302_2356" x1="6.73851" y1="13.7738" x2="9.89039" y2="13.7738" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_302_2356" x1="8.58432" y1="12.468" x2="13.0422" y2="12.468" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_302_2356" x1="8.58432" y1="12.468" x2="13.0422" y2="12.468" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_302_2356" x1="6.73851" y1="19.4247" x2="11.1963" y2="19.4247" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_302_2356" x1="6.73851" y1="16.2728" x2="11.1963" y2="16.2728" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.3047" stopColor="#145FF0" />
                            <stop offset="0.4091" stopColor="#2067F1" />
                            <stop offset="0.5124" stopColor="#3374F2" />
                            <stop offset="0.6152" stopColor="#4F87F4" />
                            <stop offset="0.7176" stopColor="#729FF6" />
                            <stop offset="0.8197" stopColor="#9EBDF9" />
                            <stop offset="0.9195" stopColor="#D1DFFC" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_302_2356" x1="6.73851" y1="19.4247" x2="11.1963" y2="19.4247" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.3047" stopColor="#145FF0" />
                            <stop offset="0.4091" stopColor="#2067F1" />
                            <stop offset="0.5124" stopColor="#3374F2" />
                            <stop offset="0.6152" stopColor="#4F87F4" />
                            <stop offset="0.7176" stopColor="#729FF6" />
                            <stop offset="0.8197" stopColor="#9EBDF9" />
                            <stop offset="0.9195" stopColor="#D1DFFC" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint6_linear_302_2356" x1="14.348" y1="9.31656" x2="17.4997" y2="9.31656" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint7_linear_302_2356" x1="17.4999" y1="9.31656" x2="20.6519" y2="9.31656" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint8_linear_302_2356" x1="19.346" y1="8.66363" x2="23.8029" y2="8.66363" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint9_linear_302_2356" x1="11.196" y1="8.66363" x2="15.6529" y2="8.66363" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint10_linear_302_2356" x1="11.196" y1="27.034" x2="15.6529" y2="27.034" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint11_linear_302_2356" x1="19.346" y1="27.034" x2="23.8029" y2="27.034" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint12_linear_302_2356" x1="6.73851" y1="21.9239" x2="9.89039" y2="21.9239" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint13_linear_302_2356" x1="21.9571" y1="23.2296" x2="26.4149" y2="23.2296" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint14_linear_302_2356" x1="14.348" y1="26.3811" x2="17.4997" y2="26.3811" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint15_linear_302_2356" x1="9.89014" y1="24.535" x2="14.348" y2="24.535" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint16_linear_302_2356" x1="20.6517" y1="11.163" x2="25.1088" y2="11.163" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2F4DE3" />
                            <stop offset="0.1725" stopColor="#3351E3" />
                            <stop offset="0.3607" stopColor="#3D5FE4" />
                            <stop offset="0.5558" stopColor="#4F74E5" />
                            <stop offset="0.5907" stopColor="#5379E5" />
                        </linearGradient>
                        <linearGradient id="paint17_linear_302_2356" x1="20.9464" y1="10.1929" x2="19.5516" y2="12.799" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint18_linear_302_2356" x1="21.9571" y1="15.6203" x2="25.1091" y2="15.6203" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint19_linear_302_2356" x1="17.4999" y1="15.6203" x2="23.8031" y2="15.6203" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint20_linear_302_2356" x1="13.0424" y1="11.8155" x2="17.5" y2="11.8155" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint21_linear_302_2356" x1="15.6835" y1="10.407" x2="15.1383" y2="13.1331" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint22_linear_302_2356" x1="21.9571" y1="12.468" x2="26.4149" y2="12.468" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint23_linear_302_2356" x1="11.4698" y1="14.0854" x2="11.7725" y2="17.5372" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint24_linear_302_2356" x1="12.2743" y1="19.9841" x2="9.93906" y2="21.3916" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint25_linear_302_2356" x1="17.4999" y1="23.8826" x2="21.957" y2="23.8826" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint26_linear_302_2356" x1="17.4999" y1="21.0007" x2="21.957" y2="21.0007" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4158F1" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint27_linear_302_2356" x1="25.1089" y1="21.9239" x2="28.2606" y2="21.9239" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint28_linear_302_2356" x1="13.0424" y1="21.0007" x2="17.5" y2="21.0007" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint29_linear_302_2356" x1="17.4999" y1="20.0776" x2="23.8031" y2="20.0776" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint30_linear_302_2356" x1="6.73851" y1="27.034" x2="11.1963" y2="27.034" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint31_linear_302_2356" x1="6.73851" y1="26.3811" x2="9.89039" y2="26.3811" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2F4DE3" />
                            <stop offset="0.1725" stopColor="#3351E3" />
                            <stop offset="0.3607" stopColor="#3D5FE4" />
                            <stop offset="0.5558" stopColor="#4F74E5" />
                            <stop offset="0.5907" stopColor="#5379E5" />
                        </linearGradient>
                        <linearGradient id="paint32_linear_302_2356" x1="6.73851" y1="27.6865" x2="13.0424" y2="27.6865" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint33_linear_302_2356" x1="6.73851" y1="30.8385" x2="17.5" y2="30.8385" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint34_linear_302_2356" x1="2.28145" y1="23.2292" x2="6.73837" y2="23.2292" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0D24BE" />
                            <stop offset="1" stopColor="#0E1D85" />
                        </linearGradient>
                        <linearGradient id="paint35_linear_302_2356" x1="25.1089" y1="26.3811" x2="28.2606" y2="26.3811" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint36_linear_302_2356" x1="17.4999" y1="30.8385" x2="28.2607" y2="30.8385" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint37_linear_302_2356" x1="15.653" y1="30.8385" x2="17.4999" y2="30.8385" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint38_linear_302_2356" x1="23.8031" y1="8.66363" x2="28.2607" y2="8.66363" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.2739" stopColor="#1D65F1" />
                            <stop offset="0.4155" stopColor="#407DF3" />
                            <stop offset="0.607" stopColor="#78A3F7" />
                            <stop offset="0.8385" stopColor="#C5D8FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint39_linear_302_2356" x1="17.4999" y1="4.85915" x2="28.2607" y2="4.85915" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint40_linear_302_2356" x1="28.2609" y1="20.0776" x2="32.7188" y2="20.0776" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint41_linear_302_2356" x1="2.28145" y1="12.4684" x2="6.73837" y2="12.4684" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint42_linear_302_2356" x1="2.28145" y1="12.4684" x2="6.73837" y2="12.4684" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint43_linear_302_2356" x1="28.2609" y1="16.9257" x2="32.7188" y2="16.9257" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint44_linear_302_2356" x1="6.73851" y1="8.01118" x2="13.0424" y2="8.01118" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint45_linear_302_2356" x1="6.73851" y1="9.31657" x2="9.89039" y2="9.31657" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint46_linear_302_2356" x1="6.73851" y1="9.31657" x2="9.89039" y2="9.31657" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint47_linear_302_2356" x1="19.6471" y1="4.12717" x2="19.9855" y2="7.17245" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint48_linear_302_2356" x1="17.4999" y1="4.85915" x2="19.3462" y2="4.85915" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint49_linear_302_2356" x1="17.4999" y1="20.5392" x2="21.957" y2="20.5392" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint50_linear_302_2356" x1="19.4024" y1="19.7513" x2="22.554" y2="16.5997" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint51_linear_302_2356" x1="20.1904" y1="17.8488" x2="20.1904" y2="13.3918" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint52_linear_302_2356" x1="19.4024" y1="15.9463" x2="16.2505" y2="12.7944" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint53_linear_302_2356" x1="17.5" y1="15.1584" x2="13.0424" y2="15.1584" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint54_linear_302_2356" x1="15.5973" y1="15.9464" x2="12.4454" y2="19.0983" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint55_linear_302_2356" x1="14.8096" y1="17.8488" x2="14.8096" y2="22.3065" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint56_linear_302_2356" x1="15.5973" y1="19.7512" x2="18.7493" y2="22.9031" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint57_linear_302_2356" x1="28.2609" y1="12.4684" x2="32.7188" y2="12.4684" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint58_linear_302_2356" x1="8.92083" y1="26.8977" x2="10.2409" y2="23.7477" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint59_linear_302_2356" x1="8.38901" y1="24.5059" x2="10.2561" y2="20.0506" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint60_linear_302_2356" x1="8.38901" y1="24.5059" x2="10.2561" y2="20.0506" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint61_linear_302_2356" x1="14.5685" y1="29.2642" x2="16.4354" y2="24.8091" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint62_linear_302_2356" x1="11.4185" y1="27.9442" x2="13.2855" y2="23.4891" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.3047" stopColor="#145FF0" />
                            <stop offset="0.4091" stopColor="#2067F1" />
                            <stop offset="0.5124" stopColor="#3374F2" />
                            <stop offset="0.6152" stopColor="#4F87F4" />
                            <stop offset="0.7176" stopColor="#729FF6" />
                            <stop offset="0.8197" stopColor="#9EBDF9" />
                            <stop offset="0.9195" stopColor="#D1DFFC" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint63_linear_302_2356" x1="14.5685" y1="29.2642" x2="16.4354" y2="24.8091" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.3047" stopColor="#145FF0" />
                            <stop offset="0.4091" stopColor="#2067F1" />
                            <stop offset="0.5124" stopColor="#3374F2" />
                            <stop offset="0.6152" stopColor="#4F87F4" />
                            <stop offset="0.7176" stopColor="#729FF6" />
                            <stop offset="0.8197" stopColor="#9EBDF9" />
                            <stop offset="0.9195" stopColor="#D1DFFC" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint64_linear_302_2356" x1="7.6526" y1="17.4256" x2="8.97268" y2="14.2755" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint65_linear_302_2356" x1="8.97302" y1="14.2757" x2="10.2932" y2="11.1254" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint66_linear_302_2356" x1="9.09347" y1="12.1571" x2="10.9602" y2="7.70266" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint67_linear_302_2356" x1="5.68042" y1="20.3024" x2="7.54705" y2="15.8481" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint68_linear_302_2356" x1="24.0403" y1="27.9957" x2="25.907" y2="23.5412" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint69_linear_302_2356" x1="27.4534" y1="19.8503" x2="29.32" y2="15.396" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint70_linear_302_2356" x1="17.0663" y1="30.3108" x2="18.3864" y2="27.1609" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint71_linear_302_2356" x1="24.7443" y1="15.6477" x2="26.6114" y2="11.1924" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint72_linear_302_2356" x1="24.7079" y1="24.572" x2="26.028" y2="21.4219" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint73_linear_302_2356" x1="20.9958" y1="28.2542" x2="22.8629" y2="23.7989" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint74_linear_302_2356" x1="12.1383" y1="11.8987" x2="14.0049" y2="7.44443" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2F4DE3" />
                            <stop offset="0.1725" stopColor="#3351E3" />
                            <stop offset="0.3607" stopColor="#3D5FE4" />
                            <stop offset="0.5558" stopColor="#4F74E5" />
                            <stop offset="0.5907" stopColor="#5379E5" />
                        </linearGradient>
                        <linearGradient id="paint75_linear_302_2356" x1="11.292" y1="11.1981" x2="13.3125" y2="13.6836" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint76_linear_302_2356" x1="17.1393" y1="12.4613" x2="18.4595" y2="9.31108" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint77_linear_302_2356" x1="15.273" y1="16.9154" x2="17.9128" y2="10.6161" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint78_linear_302_2356" x1="9.60366" y1="19.7773" x2="11.4706" y2="15.3222" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint79_linear_302_2356" x1="9.30204" y1="16.5478" x2="11.7983" y2="18.2345" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint80_linear_302_2356" x1="13.9889" y1="11.141" x2="15.8559" y2="6.68572" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5379E5" />
                            <stop offset="1" stopColor="#152EE1" />
                        </linearGradient>
                        <linearGradient id="paint81_linear_302_2356" x1="11.2138" y1="22.2995" x2="14.7904" y2="23.4426" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint82_linear_302_2356" x1="17.4459" y1="23.9657" x2="17.8745" y2="26.8891" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint83_linear_302_2356" x1="23.5304" y1="20.3755" x2="25.397" y2="15.9213" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint84_linear_302_2356" x1="20.6503" y1="19.1689" x2="22.5169" y2="14.7144" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4158F1" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint85_linear_302_2356" x1="24.7593" y1="11.9508" x2="26.0793" y2="8.80066" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint86_linear_302_2356" x1="18.7833" y1="23.6239" x2="20.6503" y2="19.1686" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1125B4" />
                            <stop offset="1" stopColor="#4971E5" />
                        </linearGradient>
                        <linearGradient id="paint87_linear_302_2356" x1="19.7275" y1="18.7822" x2="22.3673" y2="12.4828" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint88_linear_302_2356" x1="22.1738" y1="32.4508" x2="24.0407" y2="27.9958" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint89_linear_302_2356" x1="21.5209" y1="32.1773" x2="22.8409" y2="29.0275" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2F4DE3" />
                            <stop offset="0.1725" stopColor="#3351E3" />
                            <stop offset="0.3607" stopColor="#3D5FE4" />
                            <stop offset="0.5558" stopColor="#4F74E5" />
                            <stop offset="0.5907" stopColor="#5379E5" />
                        </linearGradient>
                        <linearGradient id="paint90_linear_302_2356" x1="22.8258" y1="32.724" x2="25.4659" y2="26.4239" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint91_linear_302_2356" x1="25.9757" y1="34.044" x2="30.4829" y2="23.2887" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1F88" />
                            <stop offset="1" stopColor="#0D24C1" />
                        </linearGradient>
                        <linearGradient id="paint92_linear_302_2356" x1="16.5043" y1="35.3121" x2="18.3712" y2="30.8573" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0D24BE" />
                            <stop offset="1" stopColor="#0E1D85" />
                        </linearGradient>
                        <linearGradient id="paint93_linear_302_2356" x1="29.2144" y1="13.8174" x2="30.5345" y2="10.6672" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint94_linear_302_2356" x1="30.4823" y1="23.2886" x2="34.9891" y2="12.5341" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint95_linear_302_2356" x1="29.709" y1="25.1345" x2="30.4826" y2="23.2886" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint96_linear_302_2356" x1="10.96" y1="7.70261" x2="12.8269" y2="3.24763" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.2739" stopColor="#1D65F1" />
                            <stop offset="0.4155" stopColor="#407DF3" />
                            <stop offset="0.607" stopColor="#78A3F7" />
                            <stop offset="0.8385" stopColor="#C5D8FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint97_linear_302_2356" x1="4.51782" y1="12.4091" x2="9.0247" y2="1.65437" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint98_linear_302_2356" x1="24.2343" y1="8.02753" x2="26.1013" y2="3.57238" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint99_linear_302_2356" x1="5.74973" y1="30.8052" x2="7.61634" y2="26.351" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#002DAE" />
                            <stop offset="1" stopColor="#3044D1" />
                        </linearGradient>
                        <linearGradient id="paint100_linear_302_2356" x1="5.74973" y1="30.8052" x2="7.61634" y2="26.351" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint101_linear_302_2356" x1="21.0842" y1="6.70759" x2="22.9513" y2="2.25234" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4971E5" />
                            <stop offset="1" stopColor="#233DC3" />
                        </linearGradient>
                        <linearGradient id="paint102_linear_302_2356" x1="3.1613" y1="24.4844" x2="5.80143" y2="18.1843" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint103_linear_302_2356" x1="4.46606" y1="25.0311" x2="5.78615" y2="21.881" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint104_linear_302_2356" x1="4.46606" y1="25.0311" x2="5.78615" y2="21.881" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint105_linear_302_2356" x1="4.68559" y1="9.95654" x2="7.87067" y2="10.8937" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0805" stopColor="#D1DFFC" />
                            <stop offset="0.1803" stopColor="#9EBDF9" />
                            <stop offset="0.2824" stopColor="#729FF6" />
                            <stop offset="0.3848" stopColor="#4F87F4" />
                            <stop offset="0.4876" stopColor="#3374F2" />
                            <stop offset="0.5909" stopColor="#2067F1" />
                            <stop offset="0.6953" stopColor="#145FF0" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint106_linear_302_2356" x1="4.51788" y1="12.4091" x2="5.29116" y2="10.5638" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C20A8" />
                            <stop offset="1" stopColor="#0B196F" />
                        </linearGradient>
                        <linearGradient id="paint107_linear_302_2356" x1="20.1889" y1="18.9755" x2="22.0556" y2="14.5211" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint108_linear_302_2356" x1="20.1983" y1="16.7441" x2="18.3683" y2="12.2746" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint109_linear_302_2356" x1="18.627" y1="15.1599" x2="14.1725" y2="13.2932" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint110_linear_302_2356" x1="16.3957" y1="15.1506" x2="11.9256" y2="16.9809" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint111_linear_302_2356" x1="14.8115" y1="16.722" x2="12.9445" y2="21.1773" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint112_linear_302_2356" x1="14.8024" y1="18.9535" x2="16.6326" y2="23.4235" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint113_linear_302_2356" x1="16.3737" y1="20.5376" x2="20.8289" y2="22.4045" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint114_linear_302_2356" x1="18.6049" y1="20.5467" x2="23.0748" y2="18.7165" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2032A8" />
                            <stop offset="1" stopColor="#213BE2" />
                        </linearGradient>
                        <linearGradient id="paint115_linear_302_2356" x1="16.6292" y1="4.84111" x2="18.4964" y2="0.385507" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1615" stopColor="#C5D8FB" />
                            <stop offset="0.393" stopColor="#78A3F7" />
                            <stop offset="0.5845" stopColor="#407DF3" />
                            <stop offset="0.7261" stopColor="#1D65F1" />
                            <stop offset="0.8022" stopColor="#105CF0" />
                            <stop offset="0.8442" stopColor="#0E59E3" />
                            <stop offset="0.9215" stopColor="#0752C1" />
                            <stop offset="1" stopColor="#004A99" />
                        </linearGradient>
                        <linearGradient id="paint116_linear_302_2356" x1="14.997" y1="11.8069" x2="19.4609" y2="9.95782" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint117_linear_302_2356" x1="21.039" y1="13.7681" x2="24.1958" y2="12.4604" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint118_linear_302_2356" x1="10.533" y1="13.6559" x2="14.9968" y2="11.8069" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint119_linear_302_2356" x1="15.5384" y1="25.74" x2="20.0023" y2="23.8909" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint120_linear_302_2356" x1="8.9546" y1="18.7738" x2="12.1113" y2="17.4663" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint121_linear_302_2356" x1="10.8039" y1="23.2375" x2="13.9601" y2="21.9301" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint122_linear_302_2356" x1="20.0023" y1="23.8914" x2="24.4665" y2="22.0422" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint123_linear_302_2356" x1="22.8882" y1="18.2322" x2="26.0445" y2="16.9248" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint124_linear_302_2356" x1="11.4603" y1="15.3474" x2="13.3088" y2="10.8849" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint125_linear_302_2356" x1="17.1169" y1="12.4625" x2="18.4236" y2="9.30782" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint126_linear_302_2356" x1="9.6121" y1="19.8088" x2="11.4601" y2="15.3474" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint127_linear_302_2356" x1="21.6913" y1="24.8127" x2="23.5398" y2="20.3501" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint128_linear_302_2356" x1="12.1138" y1="24.542" x2="13.4209" y2="21.3864" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint129_linear_302_2356" x1="16.5756" y1="26.3899" x2="17.8824" y2="23.2351" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint130_linear_302_2356" x1="23.5393" y1="20.3501" x2="25.3873" y2="15.8886" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint131_linear_302_2356" x1="21.5792" y1="14.311" x2="22.8862" y2="11.1556" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint132_linear_302_2356" x1="7.20336" y1="17.8487" x2="7.20336" y2="10.2417" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint133_linear_302_2356" x1="13.6965" y1="10.2417" x2="13.6965" y2="4.86276" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint134_linear_302_2356" x1="7.20336" y1="25.4558" x2="7.20336" y2="17.8488" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint135_linear_302_2356" x1="27.7965" y1="25.4558" x2="27.7965" y2="17.8488" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint136_linear_302_2356" x1="13.6965" y1="30.8347" x2="13.6965" y2="25.4557" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint137_linear_302_2356" x1="21.3035" y1="30.8347" x2="21.3035" y2="25.4557" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint138_linear_302_2356" x1="27.7965" y1="17.8487" x2="27.7965" y2="10.2417" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint139_linear_302_2356" x1="21.3035" y1="10.2417" x2="21.3035" y2="4.86276" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#076999" />
                            <stop offset="0.25" stopColor="#06618C" />
                            <stop offset="0.7086" stopColor="#024E6B" />
                            <stop offset="1" stopColor="#003F52" />
                        </linearGradient>
                        <linearGradient id="paint140_linear_302_2356" x1="9.89014" y1="24.535" x2="14.348" y2="24.535" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#004A99" />
                            <stop offset="0.0785" stopColor="#0752C1" />
                            <stop offset="0.1558" stopColor="#0E59E3" />
                            <stop offset="0.1978" stopColor="#105CF0" />
                            <stop offset="0.3047" stopColor="#145FF0" />
                            <stop offset="0.4091" stopColor="#2067F1" />
                            <stop offset="0.5124" stopColor="#3374F2" />
                            <stop offset="0.6152" stopColor="#4F87F4" />
                            <stop offset="0.7176" stopColor="#729FF6" />
                            <stop offset="0.8197" stopColor="#9EBDF9" />
                            <stop offset="0.9195" stopColor="#D1DFFC" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <radialGradient id="paint141_diamond_302_2356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.5 17.8487) rotate(-133.409) scale(17.826 13.2402)">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="#040404" />
                        </radialGradient>
                    </defs>
                </svg>

            )
        } else {
            return (
                <svg width="28" height="28" viewBox="0 0 32 35" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="none" />
                    <path d="M8.08549 10.8596L6.12549 12.8203V15.593L9.47219 14.2063L8.08549 10.8596Z" fill="none" />
                    <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="none" />
                    <path d="M12.8195 12.8203L9.47215 9.47217L8.08545 10.8596L9.47215 14.2063L12.8195 12.8203Z" fill="none" />
                    <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="none" />
                    <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="none" />
                    <path d="M9.47219 14.2063L6.12549 15.593V17.5537H10.8589L9.47219 14.2063Z" fill="none" />
                    <path d="M10.8589 17.5536H6.12549V19.5144L9.47219 20.9011L10.8589 17.5536Z" fill="none" />
                    <path d="M17.5535 10.8596V6.12616H15.5921L14.2061 9.47216L17.5535 10.8596Z" fill="none" />
                    <path d="M14.2063 9.47215L10.8589 8.08685L9.47217 9.47215L12.8196 12.8203L14.2063 9.47215Z" fill="none" />
                    <path d="M20.9011 9.47216L19.5144 6.12616H17.5537V10.8596L20.9011 9.47216Z" fill="none" />
                    <path d="M24.2476 8.08686L22.2869 6.12616H19.5142L20.9009 9.47216L24.2476 8.08686Z" fill="none" />
                    <path d="M15.5923 6.12616H12.8196L10.8589 8.08686L14.2063 9.47216L15.5923 6.12616Z" fill="none" />
                    <path d="M10.8589 27.0205L12.8196 28.9812H15.5923L14.2063 25.6352L10.8589 27.0205Z" fill="none" />
                    <path d="M20.9009 25.6352L24.2476 27.0204L25.6343 25.6352L22.2869 22.2878L20.9009 25.6352Z" fill="none" />
                    <path d="M19.5142 28.9812H22.2869L24.2476 27.0205L20.9009 25.6352L19.5142 28.9812Z" fill="none" />
                    <path d="M6.12549 19.5144V22.2878L8.08549 24.2485L9.47219 20.9011L6.12549 19.5144Z" fill="none" />
                    <path d="M17.5537 24.2485V28.9812H19.5144L20.9011 25.6352L17.5537 24.2485Z" fill="none" />
                    <path d="M22.2871 22.2878L25.6345 25.6352L27.0212 24.2485L25.6345 20.9011L22.2871 22.2878Z" fill="none" />
                    <path d="M9.47215 20.9011L8.08545 24.2485L9.47215 25.6352L12.8195 22.2878L9.47215 20.9011Z" fill="none" />
                    <path d="M14.2061 25.6352L15.5921 28.9812H17.5535V24.2485L14.2061 25.6352Z" fill="none" />
                    <path d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="none" />
                    <path d="M22.2869 12.8203L25.6343 9.47215L24.2476 8.08685L20.9009 9.47215L22.2869 12.8203Z" fill="none" />
                    <path d="M10.8589 17.5536L12.8196 22.2878L17.5537 17.5536H10.8589Z" fill="none" />
                    <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="none" />
                    <path d="M20.9011 9.47217L17.5537 10.8596L22.2871 12.8203L20.9011 9.47217Z" fill="none" />
                    <path d="M25.6345 14.2063L22.2871 12.8203L24.2478 17.5537L25.6345 14.2063Z" fill="none" />
                    <path d="M22.2871 12.8203L17.5537 10.8596V17.5537L22.2871 12.8203Z" fill="none" />
                    <path d="M24.2478 17.5537L22.2871 12.8203L17.5537 17.5537H24.2478Z" fill="none" />
                    <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="none" />
                    <path d="M14.2065 9.47217L12.8198 12.8203L17.5539 10.8596L14.2065 9.47217Z" fill="none" />
                    <path d="M25.6345 14.2063L27.0212 10.8596L25.6345 9.47217L22.2871 12.8203L25.6345 14.2063Z" fill="none" />
                    <path opacity="0.9" d="M14.2065 25.6352L17.5539 24.2485L12.8198 22.2878L14.2065 25.6352Z" fill="none" />
                    <path d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="none" />
                    <path opacity="0.5" d="M9.47217 14.2063L10.8589 17.5537L12.8196 12.8203L9.47217 14.2063Z" fill="none" />
                    <path d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="none" />
                    <path opacity="0.5" d="M9.47217 20.9011L12.8196 22.2878L10.8589 17.5536L9.47217 20.9011Z" fill="none" />
                    <path d="M20.9011 25.6352L22.2871 22.2878L17.5537 24.2485L20.9011 25.6352Z" fill="none" />
                    <path d="M25.6343 20.9011L28.981 19.5144V17.5536H24.2476L25.6343 20.9011Z" fill="none" />
                    <path d="M24.2476 17.5537H28.981V15.593L25.6343 14.2063L24.2476 17.5537Z" fill="none" />
                    <path d="M22.2871 22.2878L25.6345 20.9011L24.2478 17.5536L22.2871 22.2878Z" fill="none" />
                    <path d="M28.981 15.593V12.8203L27.021 10.8596L25.6343 14.2063L28.981 15.593Z" fill="none" />
                    <path opacity="0.9" d="M17.5537 24.2485L22.2871 22.2878L17.5537 17.5536V24.2485Z" fill="none" />
                    <path d="M25.6343 20.9011L27.021 24.2485L28.981 22.2878V19.5144L25.6343 20.9011Z" fill="none" />
                    <path d="M12.8198 22.2878L17.5539 24.2485V17.5536L12.8198 22.2878Z" fill="none" />
                    <path opacity="0.9" d="M12.8196 12.8203L10.8589 17.5537H17.5537L12.8196 12.8203Z" fill="none" />
                    <path d="M17.5537 17.5536L22.2871 22.2878L24.2478 17.5536H17.5537Z" fill="none" />
                    <path d="M17.5539 10.8596L12.8198 12.8203L17.5539 17.5537V10.8596Z" fill="none" />
                    <path d="M6.12549 22.2878V28.9812L8.08549 24.2485L6.12549 22.2878Z" fill="none" />
                    <path d="M9.47219 25.6352L6.12549 28.9812L10.8589 27.0205L9.47219 25.6352Z" fill="none" />
                    <path d="M8.08549 24.2485L6.12549 28.9812L9.47219 25.6352L8.08549 24.2485Z" fill="none" />
                    <path d="M10.8589 27.0204L6.12549 28.9811H12.8196L10.8589 27.0204Z" fill="none" />
                    <path d="M6.12549 28.9812L17.5537 33.7153L12.8196 28.9812H6.12549Z" fill="none" />
                    <path d="M1.39209 17.5536L6.12549 28.9811V22.2878L1.39209 17.5536Z" fill="none" />
                    <path d="M12.8198 28.9812L17.5539 33.7153L15.5925 28.9812H12.8198Z" fill="none" />
                    <path d="M6.12549 15.593L1.39209 17.5537H6.12549V15.593Z" fill="none" />
                    <path d="M6.12549 17.5536H1.39209L6.12549 19.5144V17.5536Z" fill="none" />
                    <path d="M6.12549 19.5144L1.39209 17.5536L6.12549 22.2878V19.5144Z" fill="none" />
                    <path d="M19.5144 28.9812L17.5537 33.7153L22.2871 28.9812H19.5144Z" fill="none" />
                    <path d="M25.6343 25.6352L28.981 28.9812L27.021 24.2485L25.6343 25.6352Z" fill="none" />
                    <path d="M27.021 24.2485L28.981 28.9812V22.2878L27.021 24.2485Z" fill="none" />
                    <path d="M24.2476 27.0205L28.981 28.9812L25.6343 25.6352L24.2476 27.0205Z" fill="none" />
                    <path d="M28.981 22.2878V28.9811L33.7151 17.5536L28.981 22.2878Z" fill="none" />
                    <path d="M17.5537 33.7153L28.9812 28.9812H22.2871L17.5537 33.7153Z" fill="none" />
                    <path d="M22.2871 28.9811H28.9812L24.2478 27.0204L22.2871 28.9811Z" fill="none" />
                    <path opacity="0.9" d="M6.12549 12.8203L1.39209 17.5537L6.12549 15.593V12.8203Z" fill="none" />
                    <path d="M17.5537 28.9812V33.7153L19.5144 28.9812H17.5537Z" fill="none" />
                    <path d="M15.5923 28.9812L17.5537 33.7153V28.9812H15.5923Z" fill="none" />
                    <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="none" />
                    <path d="M25.6343 9.47216L28.981 6.12616L24.2476 8.08686L25.6343 9.47216Z" fill="none" />
                    <path d="M28.981 12.8203V6.12616L27.021 10.8596L28.981 12.8203Z" fill="none" />
                    <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="none" />
                    <path d="M28.9812 6.12616L17.5537 1.39206L22.2871 6.12616H28.9812Z" fill="none" />
                    <path d="M27.021 10.8596L28.981 6.12616L25.6343 9.47216L27.021 10.8596Z" fill="none" />
                    <path opacity="0.9" d="M24.2478 8.08686L28.9812 6.12616H22.2871L24.2478 8.08686Z" fill="none" />
                    <path d="M28.981 22.2878L33.7151 17.5536L28.981 19.5144V22.2878Z" fill="none" />
                    <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="none" />
                    <path d="M6.12549 6.12616L1.39209 17.5537L6.12549 12.8203V6.12616Z" fill="none" />
                    <path d="M28.981 17.5537H33.7151L28.981 15.593V17.5537Z" fill="none" />
                    <path d="M28.981 15.593L33.7151 17.5537L28.981 12.8203V15.593Z" fill="none" />
                    <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="none" />
                    <path d="M28.981 19.5144L33.7151 17.5536H28.981V19.5144Z" fill="none" />
                    <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="none" />
                    <path d="M12.8196 6.12616H6.12549L10.8589 8.08686L12.8196 6.12616Z" fill="none" />
                    <path d="M10.8589 8.08686L6.12549 6.12616L9.47219 9.47216L10.8589 8.08686Z" fill="none" />
                    <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="none" />
                    <path d="M9.47219 9.47216L6.12549 6.12616L8.08549 10.8596L9.47219 9.47216Z" fill="none" />
                    <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="none" />
                    <path d="M22.2871 6.12616L17.5537 1.39206L19.5144 6.12616H22.2871Z" fill="none" />
                    <path d="M8.08549 10.8596L6.12549 6.12616V12.8203L8.08549 10.8596Z" fill="none" />
                    <path d="M17.5537 6.12616V1.39206L15.5923 6.12616H17.5537Z" fill="none" />
                    <path d="M19.5144 6.12616L17.5537 1.39206V6.12616H19.5144Z" fill="none" />
                    <path d="M15.5925 6.12616L17.5539 1.39206L12.8198 6.12616H15.5925Z" fill="none" />
                    <path d="M17.5537 1.39206L6.12549 6.12616H12.8196L17.5537 1.39206Z" fill="none" />
                    <g opacity="0.5">
                        <path d="M17.5537 17.5536L19.9211 23.2678L22.2871 22.2878L17.5537 17.5536Z" fill="none" />
                        <path d="M17.5537 17.5536L23.2678 19.9211L24.2478 17.5536H17.5537Z" fill="none" />
                        <path d="M17.5537 17.5537L23.2678 15.187L22.2871 12.8203L17.5537 17.5537Z" fill="none" />
                        <path d="M17.5537 17.5537L19.9211 11.8396L17.5537 10.8596V17.5537Z" fill="none" />
                        <path d="M17.5539 17.5537L15.1872 11.8396L12.8198 12.8203L17.5539 17.5537Z" fill="none" />
                        <path d="M17.5537 17.5537L11.8396 15.187L10.8589 17.5537H17.5537Z" fill="none" />
                        <path d="M17.5535 17.5536L11.8394 19.9211L12.8194 22.2878L17.5535 17.5536Z" fill="none" />
                        <path d="M17.5537 17.5536L15.187 23.2678L17.5537 24.2485V17.5536Z" fill="none" />
                    </g>
                    <path d="M33.7151 17.5537L28.981 6.12616V12.8203L33.7151 17.5537Z" fill="none" />
                    <g opacity="0.3">
                        <path d="M17.554 8.07285L14.7764 10.8497H20.3309L17.554 8.07285Z" fill="none" />
                        <path d="M24.2583 10.8497H20.3306L24.2583 14.7774V10.8497Z" fill="none" />
                        <path d="M10.8491 10.8497V14.7774L14.7761 10.8497H10.8491Z" fill="none" />
                        <path d="M17.554 27.0344L20.3309 24.2582H14.7764L17.554 27.0344Z" fill="none" />
                        <path d="M8.07227 17.5536L10.8492 20.3312V14.7774L8.07227 17.5536Z" fill="none" />
                        <path d="M10.8491 24.2582H14.7761L10.8491 20.3312V24.2582Z" fill="none" />
                        <path d="M24.2583 24.2582V20.3312L20.3306 24.2582H24.2583Z" fill="none" />
                        <path d="M24.2583 20.3312L27.0345 17.5536L24.2583 14.7774V20.3312Z" fill="none" />
                    </g>
                    <g opacity="0.3">
                        <path d="M10.8525 10.8526V14.7782L14.7781 10.8526H10.8525Z" fill="none" />
                        <path d="M17.5538 8.07706L14.7783 10.8526H20.3293L17.5538 8.07706Z" fill="none" />
                        <path d="M8.07715 17.5537L10.8526 20.3292V14.7782L8.07715 17.5537Z" fill="none" />
                        <path d="M24.2554 24.2548V20.3292L20.3291 24.2548H24.2554Z" fill="none" />
                        <path d="M10.8525 24.2548H14.7781L10.8525 20.3292V24.2548Z" fill="none" />
                        <path d="M17.5538 27.0303L20.3293 24.2548H14.7783L17.5538 27.0303Z" fill="none" />
                        <path d="M27.0302 17.5537L24.2554 14.7782V20.3292L27.0302 17.5537Z" fill="none" />
                        <path d="M24.2554 14.7782V10.8526H20.3291L24.2554 14.7782Z" fill="none" />
                    </g>
                    <g opacity="0.3">
                        <path d="M3.76318 11.8417L6.12918 17.5537L9.47518 9.47498L3.76318 11.8417Z" fill="none" />
                        <path d="M11.8418 3.76297L9.4751 9.47497L17.5538 6.12897L11.8418 3.76297Z" fill="none" />
                        <path d="M3.76318 23.2657L9.47518 25.6324L6.12918 17.5537L3.76318 23.2657Z" fill="none" />
                        <path d="M31.3448 23.2657L28.9781 17.5537L25.6328 25.6324L31.3448 23.2657Z" fill="none" />
                        <path d="M11.8418 31.3444L17.5538 28.9784L9.4751 25.6324L11.8418 31.3444Z" fill="none" />
                        <path d="M23.2664 31.3444L25.6324 25.6324L17.5537 28.9784L23.2664 31.3444Z" fill="none" />
                        <path d="M31.3448 11.8417L25.6328 9.47498L28.9781 17.5537L31.3448 11.8417Z" fill="none" />
                        <path d="M25.6324 9.47497L23.2664 3.76297L17.5537 6.12897L25.6324 9.47497Z" fill="none" />
                    </g>
                    <path opacity="0.3" d="M12.8196 22.2878L9.47217 25.6352L10.8589 27.0204L14.2063 25.6352L12.8196 22.2878Z" fill="none" />
                    <path opacity="0.1" d="M33.674 17.4543L28.9812 28.7838L17.553 33.5179L6.12479 28.7838L1.43269 17.4557L1.39209 17.5537L6.12549 28.9812L17.5537 33.7153L28.9812 28.9812L33.7153 17.5537L33.674 17.4543Z" fill="none" />
                    <path opacity="0.5" d="M1.43339 17.6531L6.12619 6.32356L17.5544 1.58946L28.9826 6.32356L33.6747 17.6517L33.7153 17.5537L28.9819 6.12616L17.5537 1.39206L6.12619 6.12616L1.39209 17.5537L1.43339 17.6531Z" fill="none" />
                    <g style={{ mixBlendMode: "multiply" }}>
                        <path d="M28.9816 6.12659L17.5537 1.39206L6.12514 6.12659L1.39209 17.5537L6.12514 28.9816L17.5537 33.7154L28.9816 28.9816L33.7154 17.5537L28.9816 6.12659Z" fill="none" style={{ stroke: "black" }} />
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_196_10503" x1="6.1252" y1="13.2262" x2="9.47241" y2="13.2262" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_196_10503" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_196_10503" x1="8.08557" y1="11.8394" x2="12.8197" y2="11.8394" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_196_10503" x1="6.27745" y1="19.873" x2="10.9939" y2="17.871" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_196_10503" x1="6.1252" y1="15.88" x2="10.8592" y2="15.88" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_196_10503" x1="6.1252" y1="19.2273" x2="10.8592" y2="19.2273" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint6_linear_196_10503" x1="14.7016" y1="10.0627" x2="18.3135" y2="7.032" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint7_linear_196_10503" x1="11.5729" y1="7.96082" x2="12.4416" y2="12.8872" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint8_linear_196_10503" x1="17.9886" y1="11.0262" x2="19.824" y2="6.24497" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint9_linear_196_10503" x1="19.5141" y1="7.79929" x2="24.2472" y2="7.79929" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint10_linear_196_10503" x1="11.161" y1="8.79771" x2="15.8767" y2="6.79603" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint11_linear_196_10503" x1="10.8592" y1="27.3082" x2="15.5923" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint12_linear_196_10503" x1="20.9008" y1="24.6542" x2="25.634" y2="24.6542" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint13_linear_196_10503" x1="19.2298" y1="28.3116" x2="23.9455" y2="26.3099" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint14_linear_196_10503" x1="6.1252" y1="21.8813" x2="9.47241" y2="21.8813" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint15_linear_196_10503" x1="16.7631" y1="27.8093" x2="20.615" y2="25.2111" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint16_linear_196_10503" x1="22.2869" y1="23.2679" x2="27.0209" y2="23.2679" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint17_linear_196_10503" x1="8.08557" y1="23.2679" x2="12.8197" y2="23.2679" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint18_linear_196_10503" x1="14.2063" y1="26.6148" x2="17.5534" y2="26.6148" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint19_linear_196_10503" x1="11.2499" y1="27.1708" x2="13.0853" y2="22.3896" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint20_linear_196_10503" x1="20.9008" y1="10.4536" x2="25.634" y2="10.4536" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint21_linear_196_10503" x1="11.6988" y1="19.9893" x2="17.3881" y2="17.2144" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint22_linear_196_10503" x1="21.2137" y1="9.42334" x2="19.7324" y2="12.1909" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint23_linear_196_10503" x1="22.2869" y1="15.1871" x2="25.6343" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint24_linear_196_10503" x1="17.5536" y1="14.2065" x2="22.2869" y2="14.2065" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint25_linear_196_10503" x1="17.5536" y1="15.1871" x2="24.2475" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint26_linear_196_10503" x1="14.5549" y1="13.4865" x2="15.8531" y2="10.1044" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint27_linear_196_10503" x1="15.6248" y1="9.65069" x2="15.0458" y2="12.5457" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint28_linear_196_10503" x1="23.9881" y1="13.5744" x2="25.5719" y2="9.44851" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#DDE7FE" />
                            <stop offset="0.4345" stopColor="#D4DEF7" />
                            <stop offset="0.463" stopColor="#B6BFDD" />
                            <stop offset="0.4908" stopColor="#A0A8CB" />
                            <stop offset="0.5171" stopColor="#939BC0" />
                            <stop offset="0.5404" stopColor="#8E96BC" />
                        </linearGradient>
                        <linearGradient id="paint29_linear_196_10503" x1="12.82" y1="23.9613" x2="17.5539" y2="23.9613" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint30_linear_196_10503" x1="9.47238" y1="15.1871" x2="12.8197" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint31_linear_196_10503" x1="11.1499" y1="13.5571" x2="11.4714" y2="17.2228" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1898" stopColor="#E5EEFD" />
                            <stop offset="0.3903" stopColor="#D2E2FC" />
                            <stop offset="0.5937" stopColor="#C6DAFB" />
                            <stop offset="0.8022" stopColor="#C2D8FB" />
                            <stop offset="0.9293" stopColor="#B6D0EE" />
                            <stop offset="1" stopColor="#ADCBE5" />
                        </linearGradient>
                        <linearGradient id="paint32_linear_196_10503" x1="10.4464" y1="17.6264" x2="11.3151" y2="22.553" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint33_linear_196_10503" x1="12.0043" y1="19.8213" x2="9.52433" y2="21.3161" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.1898" stopColor="#E5EEFD" />
                            <stop offset="0.3903" stopColor="#D2E2FC" />
                            <stop offset="0.5937" stopColor="#C6DAFB" />
                            <stop offset="0.8022" stopColor="#C2D8FB" />
                            <stop offset="0.9293" stopColor="#B6D0EE" />
                            <stop offset="1" stopColor="#ADCBE5" />
                        </linearGradient>
                        <linearGradient id="paint34_linear_196_10503" x1="18.0586" y1="25.4377" x2="22.7744" y2="23.436" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint35_linear_196_10503" x1="24.2472" y1="19.2273" x2="28.9811" y2="19.2273" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint36_linear_196_10503" x1="25.3801" y1="14.3471" x2="27.5861" y2="18.3269" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint37_linear_196_10503" x1="22.4939" y1="18.5256" x2="24.2392" y2="21.6742" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint38_linear_196_10503" x1="25.634" y1="13.2262" x2="28.9811" y2="13.2262" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint39_linear_196_10503" x1="17.5536" y1="20.9009" x2="22.2869" y2="20.9009" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint40_linear_196_10503" x1="26.3129" y1="22.5807" x2="29.6766" y2="21.1529" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint41_linear_196_10503" x1="12.82" y1="20.9009" x2="17.5539" y2="20.9009" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint42_linear_196_10503" x1="10.8592" y1="15.1871" x2="17.5536" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint43_linear_196_10503" x1="17.5536" y1="19.9207" x2="24.2475" y2="19.9207" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint44_linear_196_10503" x1="15.0906" y1="11.2936" x2="16.2354" y2="17.7861" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint45_linear_196_10503" x1="4.57363" y1="26.681" x2="8.11527" y2="24.2922" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint46_linear_196_10503" x1="6.1252" y1="27.3082" x2="10.8592" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint47_linear_196_10503" x1="6.1252" y1="26.6148" x2="9.47241" y2="26.6148" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint48_linear_196_10503" x1="6.63623" y1="30.1853" x2="12.3087" y2="27.7774" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint49_linear_196_10503" x1="6.1252" y1="31.3484" x2="17.5536" y2="31.3484" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E0EBFF" />
                            <stop offset="0.4828" stopColor="#EBF2FF" />
                            <stop offset="0.5393" stopColor="#F0F5FF" />
                        </linearGradient>
                        <linearGradient id="paint50_linear_196_10503" x1="1.39207" y1="23.2675" x2="6.1252" y2="23.2675" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1929" stopColor="#C9E0EB" />
                            <stop offset="0.5344" stopColor="#CADEEF" />
                            <stop offset="0.8146" stopColor="#D7E8FA" />
                            <stop offset="1" stopColor="#D7E8FA" />
                        </linearGradient>
                        <linearGradient id="paint51_linear_196_10503" x1="14.7108" y1="28.648" x2="15.6631" y2="34.0488" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint52_linear_196_10503" x1="1.84685" y1="18.4861" x2="6.44289" y2="16.2445" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint53_linear_196_10503" x1="2.58657" y1="19.3246" x2="5.83967" y2="17.1303" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint54_linear_196_10503" x1="2.78671" y1="16.7806" x2="5.90652" y2="22.4089" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.3016" stopColor="#BBD6E2" />
                            <stop offset="0.4956" stopColor="#DBECF9" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="1" stopColor="#C3DAF4" />
                        </linearGradient>
                        <linearGradient id="paint55_linear_196_10503" x1="16.8094" y1="32.1895" x2="22.4983" y2="29.4148" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint56_linear_196_10503" x1="27.0944" y1="27.3754" x2="28.591" y2="26.1196" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint57_linear_196_10503" x1="27.8628" y1="22.4848" x2="29.0075" y2="28.9767" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint58_linear_196_10503" x1="24.2472" y1="27.3082" x2="28.9811" y2="27.3082" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint59_linear_196_10503" x1="28.9811" y1="23.2675" x2="33.7152" y2="23.2675" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1929" stopColor="#C9E0EB" />
                            <stop offset="0.5344" stopColor="#CADEEF" />
                            <stop offset="0.8146" stopColor="#D7E8FA" />
                            <stop offset="1" stopColor="#D7E8FA" />
                        </linearGradient>
                        <linearGradient id="paint60_linear_196_10503" x1="18.5613" y1="34.9162" x2="27.5983" y2="27.3332" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint61_linear_196_10503" x1="22.2869" y1="28.0011" x2="28.9813" y2="28.0011" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint62_linear_196_10503" x1="1.39207" y1="15.1871" x2="6.1252" y2="15.1871" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint63_linear_196_10503" x1="17.5536" y1="31.3484" x2="19.5143" y2="31.3484" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint64_linear_196_10503" x1="15.9829" y1="31.8435" x2="18.314" y2="29.8875" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint65_linear_196_10503" x1="24.6535" y1="8.91961" x2="29.2501" y2="6.67769" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint66_linear_196_10503" x1="26.9912" y1="10.8155" x2="30.5328" y2="8.42666" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint67_linear_196_10503" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint68_linear_196_10503" x1="17.5536" y1="3.75904" x2="28.9813" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint69_linear_196_10503" x1="25.634" y1="8.49268" x2="28.9811" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint70_linear_196_10503" x1="22.2869" y1="7.1064" x2="28.9813" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDE7F8" />
                            <stop offset="0.4833" stopColor="#E3F2FB" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                        <linearGradient id="paint71_linear_196_10503" x1="28.2983" y1="21.4739" x2="33.4074" y2="17.1868" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint72_linear_196_10503" x1="-0.301693" y1="13.5634" x2="7.81896" y2="10.1164" gradientUnits="userSpaceOnUse">
                            <stop offset="0.194" stopColor="#AFCED9" />
                            <stop offset="0.2378" stopColor="#BAD6E1" />
                            <stop offset="0.3456" stopColor="#D1E5F2" />
                            <stop offset="0.4483" stopColor="#DEEEFC" />
                            <stop offset="0.5393" stopColor="#E3F1FF" />
                            <stop offset="0.8221" stopColor="#FEFEFD" />
                        </linearGradient>
                        <linearGradient id="paint73_linear_196_10503" x1="1.39207" y1="11.8399" x2="6.1252" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint74_linear_196_10503" x1="29.4764" y1="18.144" x2="32.2545" y2="15.8128" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#B1B9E2" />
                            <stop offset="0.0638" stopColor="#B6BFE5" />
                            <stop offset="0.1464" stopColor="#C4CFEE" />
                            <stop offset="0.2391" stopColor="#DCE9FD" />
                            <stop offset="0.2526" stopColor="#E0EEFF" />
                            <stop offset="0.4048" stopColor="#E4F0FF" />
                            <stop offset="0.4823" stopColor="#E9F3FF" />
                            <stop offset="0.5831" stopColor="#E6F0FD" />
                            <stop offset="0.6609" stopColor="#DCE7F8" />
                            <stop offset="0.7311" stopColor="#CCD9EE" />
                            <stop offset="0.7964" stopColor="#B5C4E0" />
                            <stop offset="0.8141" stopColor="#ADBDDC" />
                        </linearGradient>
                        <linearGradient id="paint75_linear_196_10503" x1="29.533" y1="16.4114" x2="31.8779" y2="14.8297" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint76_linear_196_10503" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint77_linear_196_10503" x1="28.9811" y1="18.534" x2="33.7152" y2="18.534" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint78_linear_196_10503" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFCED9" />
                            <stop offset="0.1268" stopColor="#BAD6E1" />
                            <stop offset="0.439" stopColor="#D1E5F2" />
                            <stop offset="0.7363" stopColor="#DEEEFC" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint79_linear_196_10503" x1="6.1252" y1="7.1064" x2="12.8198" y2="7.1064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint80_linear_196_10503" x1="6.1252" y1="7.79929" x2="10.8592" y2="7.79929" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0080863" stopColor="#D9E8F9" />
                            <stop offset="0.113" stopColor="#E3EEFB" />
                            <stop offset="0.3197" stopColor="#F0F6FE" />
                            <stop offset="0.5034" stopColor="#F5F9FF" />
                            <stop offset="0.7479" stopColor="#F2F8FF" />
                            <stop offset="0.9366" stopColor="#E8F3FF" />
                            <stop offset="1" stopColor="#E3F1FF" />
                        </linearGradient>
                        <linearGradient id="paint81_linear_196_10503" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop offset="0.2198" stopColor="#7D85A4" />
                            <stop offset="0.4596" stopColor="#C4DBF6" />
                            <stop offset="0.4966" stopColor="#C9DEF7" />
                            <stop offset="0.5383" stopColor="#D6E6FB" />
                            <stop offset="0.5726" stopColor="#E7F0FF" />
                        </linearGradient>
                        <linearGradient id="paint82_linear_196_10503" x1="6.1252" y1="8.49268" x2="9.47241" y2="8.49268" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint83_linear_196_10503" x1="19.7778" y1="2.95104" x2="20.3705" y2="6.31258" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint84_linear_196_10503" x1="4.57346" y1="10.52" x2="7.67693" y2="8.42666" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint85_linear_196_10503" x1="15.0366" y1="4.98668" x2="18.4863" y2="3.30416" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint86_linear_196_10503" x1="17.5536" y1="3.75904" x2="19.5143" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.4274" stopColor="#E7F0FF" />
                            <stop offset="0.4617" stopColor="#D6E6FB" />
                            <stop offset="0.5034" stopColor="#C9DEF7" />
                            <stop offset="0.5404" stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint87_linear_196_10503" x1="12.82" y1="3.75904" x2="17.5539" y2="3.75904" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3389" stopColor="#F3FCFF" />
                            <stop offset="0.352" stopColor="#F0FAFE" />
                            <stop offset="0.4365" stopColor="#E3EEFB" />
                            <stop offset="0.5115" stopColor="#DEEAFA" />
                            <stop offset="0.7158" stopColor="#DAE6F8" />
                            <stop offset="0.8158" stopColor="#CAD3F2" />
                        </linearGradient>
                        <linearGradient id="paint88_linear_196_10503" x1="11.6069" y1="2.44053" x2="12.2738" y2="6.22239" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0745" stopColor="#C6E0FF" />
                            <stop offset="0.1248" stopColor="#B6CAEC" />
                            <stop offset="0.2357" stopColor="#969CC5" />
                            <stop offset="0.3834" stopColor="#B4C3E3" />
                            <stop offset="0.5303" stopColor="#CFE5FD" />
                            <stop offset="0.7108" stopColor="#DAEBFF" />
                            <stop offset="1" stopColor="#B9D8F4" />
                        </linearGradient>
                        <linearGradient id="paint89_linear_196_10503" x1="17.5536" y1="20.4108" x2="22.2869" y2="20.4108" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint90_linear_196_10503" x1="19.5741" y1="19.5741" x2="22.921" y2="16.2271" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint91_linear_196_10503" x1="20.4109" y1="17.5537" x2="20.4109" y2="12.8205" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint92_linear_196_10503" x1="19.574" y1="15.5333" x2="16.2268" y2="12.1861" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint93_linear_196_10503" x1="17.5539" y1="14.6965" x2="12.82" y2="14.6965" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint94_linear_196_10503" x1="15.5333" y1="15.5333" x2="12.1861" y2="18.8806" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint95_linear_196_10503" x1="14.6963" y1="17.5536" x2="14.6963" y2="22.2877" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint96_linear_196_10503" x1="15.5333" y1="19.5739" x2="18.8806" y2="22.9212" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4DBF6" />
                            <stop offset="0.7802" stopColor="#7D85A4" />
                        </linearGradient>
                        <linearGradient id="paint97_linear_196_10503" x1="28.9811" y1="11.8399" x2="33.7152" y2="11.8399" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCE0FA" />
                            <stop offset="0.6681" stopColor="#C9DCF9" />
                            <stop offset="0.724" stopColor="#B9C9E9" />
                            <stop offset="0.8121" stopColor="#A6B0D5" />
                            <stop offset="0.8931" stopColor="#9AA1C9" />
                            <stop offset="0.9596" stopColor="#969CC5" />
                        </linearGradient>
                        <linearGradient id="paint98_linear_196_10503" x1="14.8959" y1="11.1373" x2="19.6364" y2="9.17365" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint99_linear_196_10503" x1="21.3122" y1="13.22" x2="24.6647" y2="11.8313" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint100_linear_196_10503" x1="10.1551" y1="13.1009" x2="14.8956" y2="11.1373" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint101_linear_196_10503" x1="15.4708" y1="25.9338" x2="20.2113" y2="23.9702" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint102_linear_196_10503" x1="8.47902" y1="18.536" x2="11.8313" y2="17.1474" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint103_linear_196_10503" x1="10.4428" y1="23.2763" x2="13.7946" y2="21.8879" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint104_linear_196_10503" x1="20.2113" y1="23.9707" x2="24.9521" y2="22.007" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint105_linear_196_10503" x1="23.2762" y1="17.9608" x2="26.628" y2="16.5724" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint106_linear_196_10503" x1="11.1397" y1="14.8972" x2="13.1027" y2="10.1582" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint107_linear_196_10503" x1="17.1474" y1="11.8335" x2="18.5351" y2="8.48339" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint108_linear_196_10503" x1="9.17724" y1="19.6353" x2="11.1398" y2="14.8972" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint109_linear_196_10503" x1="22.0047" y1="24.9491" x2="23.9677" y2="20.2101" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint110_linear_196_10503" x1="11.8337" y1="24.6616" x2="13.2218" y2="21.3107" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint111_linear_196_10503" x1="16.5726" y1="26.6242" x2="17.9604" y2="23.2738" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint112_linear_196_10503" x1="23.9676" y1="20.2101" x2="25.9302" y2="15.472" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint113_linear_196_10503" x1="21.8856" y1="13.7967" x2="23.2736" y2="10.4457" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint114_linear_196_10503" x1="6.61929" y1="17.5537" x2="6.61929" y2="9.47524" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint115_linear_196_10503" x1="13.5146" y1="9.47523" x2="13.5146" y2="3.76307" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint116_linear_196_10503" x1="6.61929" y1="25.6321" x2="6.61929" y2="17.5537" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint117_linear_196_10503" x1="28.4887" y1="25.6321" x2="28.4887" y2="17.5537" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint118_linear_196_10503" x1="13.5146" y1="31.3444" x2="13.5146" y2="25.6321" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint119_linear_196_10503" x1="21.5929" y1="31.3444" x2="21.5929" y2="25.6321" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint120_linear_196_10503" x1="28.4887" y1="17.5537" x2="28.4887" y2="9.47524" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint121_linear_196_10503" x1="21.5929" y1="9.47523" x2="21.5929" y2="3.76307" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#AFDFE5" />
                            <stop offset="0.7912" stopColor="#A4D5D8" />
                            <stop offset="1" stopColor="#A0D2D4" />
                        </linearGradient>
                        <linearGradient id="paint122_linear_196_10503" x1="9.47238" y1="24.6542" x2="14.2065" y2="24.6542" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#ADD3E5" />
                            <stop offset="0.0707" stopColor="#B6D9EE" />
                            <stop offset="0.1978" stopColor="#C2E1FB" />
                            <stop offset="0.4063" stopColor="#C6E3FB" />
                            <stop offset="0.6097" stopColor="#D2E9FC" />
                            <stop offset="0.8102" stopColor="#E5F2FD" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                    </defs>
                </svg>
            )
        }
    }

    useEffect(() => {
        if (isReset) {
            setSelected([])
            let filter = JSON.parse(JSON.stringify(productFilter))
            filter['fancy_color'] = arr.join(',');
            dispatch(setProductFilter(filter))
            dispatch(setProductArrEmpty())
            dispatch(getProduct(filter))
        }
    }, [isReset])

    const handleClick = (e, fancy) => {
        let array = [...selected];
        setInitialLoading(false)

        array.indexOf(fancy) === -1 ? array.push(fancy) : array.splice(array.indexOf(fancy), 1)
        setSelected(array)
        if (isReset) {
            setResetFalse()
        }

        let filter = JSON.parse(JSON.stringify(productFilter))
        filter['fancy_color'] = array.length > 0 ? array.join(',') : arr.join(',')
        delete filter.color
        dispatch(setProductFilter(filter))
        dispatch(setProductArrEmpty())
        dispatch(getProduct(filter))
    }

    useEffect(() => {
        if (productFilter['fancy_color'] !== undefined) {
            setSelected(productFilter['fancy_color'])
        }
    }, [])

    return (
        <div className='tdp-flex max-tablet:tdp-w-[85%] lg-md-mobile:tdp-w-[84%] max-mobile:tdp-w-[74%] laptop-s-lg-tab:tdp-w-[80%] large-laptop-sm:tdp-w-[88%] laptop-s-lg-tab:tdp-overflow-x-scrolll'>
            {arr.map((fancy, i) => (
                fancy !== "champange" && fancy !== "other" &&
                <div key={i} className={`${fancy} tdp-flex tdp-flex-col-reverse tdp-border-2 tdp-border-transparent`} style={{ width: `${Number(parseFloat(width).toFixed(2))}%` }}>
                    <p className={`tdp-m-0 tdp-p-0 tdp-truncate laptop-s-lg-tab:tdp-text-sm`}>{fancy}</p>
                    <div className="fancy_color_svg laptop-s-lg-tab:tdp-small-svg tdp-select-none" onClick={(e) => handleClick(e, fancy)} style={selected.includes(fancy) ? { border: `1px solid ${color}` } : { border: '1px solid white' }}>
                        {colorComp(fancy.toLowerCase())}
                    </div>

                </div>
            ))}
        </div>
    )
}

export default FancyColor