import React from "react";
import Filter from "../components/Filter";

import '../App.css';
import '../assets/sass/antd.scss';
import '../assets/sass/app.scss';

export default function App() {
    
    return (
        <>
            <Filter />           
        </>
    );
}
