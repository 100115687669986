import { createSlice } from "@reduxjs/toolkit";


export const configSlice = createSlice({
    name: "Config",
    initialState: {
        loading: false,
        config: "",
        error: "",

        productFilter:{},

        countryName : ""
        
    },
    reducers: {
        getConfigLoading: (state) => {
            state.loading = true
        },
        getConfigSuccess: (state, action) => {
            state.loading = false
            state.config = action.payload
        },
        getConfigError: (state) => {
            state.loading = false
            state.error = "Can't find any product"
        },

        setProductFilter : (state,action)=>{
            state.productFilter = action.payload
        },

        setCountryName: (state, action) => {
            state.countryName = action.payload
        }
    }
});

export const {
    getConfigLoading, getConfigSuccess, getConfigError, setProductFilter, setCountryName
} = configSlice.actions;

export default configSlice.reducer