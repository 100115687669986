import React, { useState, useEffect, } from "react";

import SliderText from "./SliderText";
import SliderStep from "./SliderStep";
import { useDispatch, useSelector } from 'react-redux'
import { getConfig, getCurrentCountry } from '../middleware-redux/Config';
import Shape from "./Shape/Shape";
import { useParams } from "react-router-dom";
import { setProductFilter } from "../slice/config.slice";
import Product from "./Product";
import { getProduct } from "../middleware-redux/Product";
import { setProductArrEmpty } from "../slice/product.slice";
import '../assets/sass/style.scss'
import FancyColor from "./FancyColor";
import '../App.css'
import { Checkbox } from "antd";

export default function Filter() {
  const params = useParams();
  const dispatch = useDispatch();
  const timer = React.useRef();

  const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
  const [isFancy, setIsFancy] = useState(false);
  const { loading, config, productFilter } = useSelector((state) => state.config);
  const { product, productObj } = useSelector((state) => state.product);
  const [diamond_type, setDiamond_type] = useState(null);
  const [list_type, setList_type] = useState('Grid');
  const [sorting, setSorting] = useState(0);
  const [isReset, setIsReset] = useState(false);
  const [sliderMax, setSliderMax] = useState(0);
  const [configLab, setConfigLab] = useState([])

  useEffect(() => {
    dispatch(getCurrentCountry())
    let filter = JSON.parse(JSON.stringify(productFilter))
    filter['token'] = params.token
    dispatch(getConfig(filter))
  }, [])

  const getFilter = () => {
    let filter = JSON.parse(JSON.stringify(productFilter))
    if (Object.keys(config).length > 0) {
      filter['token'] = params.token
      if (config?.diamond_type.includes("both")) {
        filter['diamond_type'] = "natural"
      } else {
        filter['diamond_type'] = config?.diamond_type[0]
      }
      filter['carat'] = config?.carat?.join('-');
      filter['clarity'] = config?.clarity?.join(',')
      filter['color'] = config?.color?.join(',')
      filter['cut'] = config?.cut?.join(',')
      filter['fluorescence'] = config?.fluorescence?.join(',')
      filter['lab'] = config?.lab?.join(',')
      filter['polish'] = config?.polish?.join(',')
      if (config?.display_price === 1) {
        filter['price'] = config?.price?.join('-')
      }
      filter['shape'] = (config?.shape || filter?.shape)?.join(',')
      filter['symmetry'] = config?.symmetry?.join(',')
    }
    setConfigLab(config?.lab)
    return filter
  }

  function generateNumbersWithSteps(start, end, step, fix) {
    const numbers = [];
    let current = start;
    while (current <= end) {
      numbers.push(Number(parseFloat(current).toFixed(fix)));
      current += Number(parseFloat(step).toFixed(fix))
    }
    return numbers;
  }

  useEffect(() => {
    if (!loading && Object.keys(config).length > 0) {
      let filter = getFilter()
      dispatch(setProductFilter(filter))

      dispatch(getProduct(filter))
      setDiamond_type(config?.diamond_type?.includes("both") ? 'NATURAL' : config?.diamond_type && config?.diamond_type[0]?.toUpperCase())
      if (config.carat) {
        function generateNumberRanges() {
          const ranges = [
            { start: config.carat[0], end: 4.99, step: 0.01, fixed: 2 },
            { start: 5, end: 9.9, step: 0.1, fixed: 1 },
            { start: 10, end: 15, step: 0.5, fixed: 1 },
            { start: 16, end: config.carat[1], step: 1, fixed: 0 },
          ];

          const result = [];

          ranges.forEach((range) => {
            const numbersInRange = generateNumbersWithSteps(range.start, range.end, range.step, range.fixed);
            result.push(...numbersInRange);
          });

          return result;
        }

        let sum = generateNumberRanges()
        setSliderMax(sum)

      }

    }
  }, [loading, config])

  const changeD_Type = (type) => {
    let filter = JSON.parse(JSON.stringify(productFilter))
    filter['diamond_type'] = type;
    setDiamond_type(type)
    dispatch(setProductFilter(filter))
    dispatch(setProductArrEmpty())
    dispatch(getProduct(filter))
  }

  const listType = (list) => {
    setList_type(list)
  }

  const handleSortChange = (e) => {
    let filter = JSON.parse(JSON.stringify(productFilter))
    const sort0 = { sort_order: "asc", sort_field: "price" }
    const sort1 = { sort_order: "desc", sort_field: "price" }
    const sort2 = { sort_order: "asc", sort_field: "carat" }
    const sort3 = { sort_order: "desc", sort_field: "carat" }

    let sortFilter = {}

    if (Number(e.target.value) === 0) {
      sortFilter = { ...filter, ...sort0 }
    } else if (Number(e.target.value) === 1) {
      sortFilter = { ...filter, ...sort1 }
    } else if (Number(e.target.value) === 2) {
      sortFilter = { ...filter, ...sort2 }
    } else if (Number(e.target.value) === 3) {
      sortFilter = { ...filter, ...sort3 }
    }

    if (Object.keys(sortFilter).length !== 0) {
      dispatch(setProductFilter(sortFilter))

      dispatch(setProductArrEmpty())
      dispatch(getProduct(sortFilter))
    }

    setSorting(e.target.value)
  }

  const changeColorSetting = () => {
    setIsFancy(!isFancy)
    let filter = JSON.parse(JSON.stringify(productFilter))
    if (!isFancy) {
      delete filter['color'];
      filter['fancy_color'] = productFilter?.fancy_color?.join(',') || config?.fancy_color?.join(',')
    } else {
      filter['color'] = config?.color?.join(',')
      delete filter['fancy_color']
      dispatch(setProductFilter(filter))
    }
    dispatch(setProductArrEmpty())
    dispatch(getProduct(filter))
  }  

  const setReset = () => {
    setIsReset(true)
    setSorting("0")
    dispatch(setProductFilter(getFilter()))
    dispatch(setProductArrEmpty())
    dispatch(getProduct(getFilter()))
  }
  const onChange = (e) => {
    setConfigLab(e)
    let filter = JSON.parse(JSON.stringify(productFilter))
    filter['lab'] = e.length > 0 ? e.join(',') : config.lab.join(',')
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      dispatch(setProductFilter(filter))
      dispatch(setProductArrEmpty())
      dispatch(getProduct(filter))
    }, 800)
  }

  return (
    <>
      <>
        {Object.keys(productObj).length === 0 &&
          <div className="tdp-w-full tdp-flex tdp-justify-center tdp-flex-col tdp-items-center tdp-mt-10">
            {Object.keys(config).length === 0
              ? <span className="loading">Loading ...</span>
              : <>
                <div className="laptop-s-lg-tab:tdp-w-[94%] laptop-s-lg:tdp-w-[65%] laptop-md-sm:tdp-w-[85%]  laptop-md-sm:tdp-w-[83%] tablet-lg2-lg3:tdp-w-[82%] tablet-mobile-sm:tdp-my-4 tablet-mobile-sm:tdp-w-[82%]">
                  <div className='tdp-flex tdp-justify-between tdp-flex-row-reverse lg-sm-mobile:tdp-flex-col-reverse'>
                    {config?.diamond_type && <div className='tdp-flex tdp-flex-row-reverse lg-sm-mobile+1:tdp-justify-center tdp-items-center tdp-w-1/5 small-tablet-lg:tdp-w-1/3 tablet-lg-lg2:tdp-w-1/2 tablet-lg2-lg2_5:tdp-w-7/12 tablet-lg2-lg3:tdp-w-4/6 tablet-lg3-lg3_9:tdp-w-5/6 lg-sm-mobile:tdp-w-[97%]'>
                      {(config?.diamond_type.includes("both") || config?.diamond_type.includes("labgrown")) && <div onClick={() => changeD_Type("LABGROWN")} className={`tdp-select-none md-sm-mobile:tdp-text-base tablet-lg3-lg4:tdp-text-sm tdp-shadow-[1px_1px_5px_0px_black] tdp-w-5/12 tdp-text-center tdp-h-[35px] tdp-py-2 tdp-mx-[10px] tdp-rounded-full tdp-font-normal tdp-flex tdp-items-center tdp-justify-center tdp-text-lg tdp-font-medium large-laptop-sm:tdp-px-1 large-laptop-sm:tdp-text-sm laptop-s-lg-tab:tdp-text-sm`} style={diamond_type === "LABGROWN" ? { background: config.colorcode, color: config?.lightColor } : { color: config.colorcode, background: 'rgb(209 213 219)' }}>
                        Lab
                      </div>}
                      {(config?.diamond_type?.includes("both") || config?.diamond_type?.includes("natural")) && <div onClick={() => changeD_Type("NATURAL")} className={`tdp-select-none md-sm-mobile:tdp-text-base tablet-lg3-lg4:tdp-text-sm tdp-shadow-[1px_1px_5px_0px_black] tdp-w-5/12 tdp-text-center tdp-h-[35px] tdp-py-2 tdp-mx-[10px] tdp-rounded-full tdp-font-normal tdp-flex tdp-items-center tdp-justify-center tdp-text-lg tdp-font-medium large-laptop-sm:tdp-px-1 large-laptop-sm:tdp-text-sm laptop-s-lg-tab:tdp-text-sm`} style={diamond_type === "NATURAL" ? { background: config.colorcode, color: config?.lightColor } : { color: config.colorcode, background: 'rgb(209 213 219)' }}>
                        Natural
                      </div>}

                    </div>}
                    {config?.logo !== "" && <img src={config.logo} className='tdp-w-32 tdp-mt-[30px] lg-sm-mobile:tdp-w-full' />}
                  </div>
                </div>
                <div className="laptop-s-lg-tab:tdp-w-[99%] tablet-mobile-sm:tdp-w-[88%] laptop-md-sm:tdp-w-[88%] laptop-s-lg:tdp-w-[68%]">
                  <div className="tdp-w-full">
                    <div className="tdp-shape tdp-flex tdp-items-center tdp-w-[100%] tdp-h-full tdp-mt-3 tdp-pl-6 tdp-pr-10 lg-sm-mobile+1:tdp-px-0">
                      <div className='tdp-h-full tdp-flex tdp-items-center large-laptop-sm:tdp-w-[7%] laptop-s-lg-tab-1:tdp-w-[9%] tablet-lg-lg2:tdp-w-[21%] tablet-lg2-lg3:tdp-w-[13%] lg-md-mobile:tdp-w-[18%]'>
                        <div style={{ color: config?.colorcode, fontSize: '13px' }} className='tdp-ml-2 tdp-truncate tdp-my-0' >Shape</div>
                      </div>
                      <div className='tdp-h-[95px] tdp-shapes tdp-overflow-x-scroll tdp-overflow-y-hidden tablet-mobile-sm:tdp-flex-nowrap tdp-flex tdp-flex-row large-tablet-lg:tdp-w-[95.5%] tablet-mobile-sm:tdp-w-full'>
                        <Shape shapes={config?.shape} colorCode={config.colorcode} isReset={isReset} setResetFalse={() => setIsReset(false)} />
                      </div>
                    </div>
                  </div>

                  <div className='tdp-flex tdp-flex-wrap tdp-w-full tdp-diamond-filter'>
                    <div id="tdp-carat" className="tdp-carat tdp-pl-6 tdp-pr-10 lg-sm-mobile+1:tdp-px-0 tdp-items-start tdp-flex large-tablet-lg:tdp-w-[49.5%] tablet-mobile-sm:tdp-w-full max-mobile:tdp-w-full tdp-h-full tdp-py-1">
                      {config?.carat !== undefined && sliderMax !== 0 &&
                        <SliderText sliderMax={sliderMax} setResetFalse={() => setIsReset(false)} type="Carat" min={config?.carat[0]} max={config?.carat[1]} isPrice={false} keys="carat" isPercentage={false} colorCode={config.colorcode} isReset={isReset} />
                      }
                    </div>
                    <div className="tdp-price tdp-pl-6 tdp-pr-10 lg-sm-mobile+1:tdp-px-0 tdp-items-start tdp-flex large-tablet-lg:tdp-w-[49.5%] tablet-mobile-sm:tdp-w-full max-mobile:tdp-w-full tdp-h-full tdp-py-1">
                      {config?.price !== undefined && config?.display_price !== 0 &&
                        <SliderText setResetFalse={() => setIsReset(false)} type="Price" min={config?.price[0]} max={config?.price[1]} isPrice={true} keys="price" isPercentage={false} colorCode={config.colorcode} isReset={isReset} />
                      }
                    </div>
                    <div className={`tdp-price tdp-pl-6 tdp-pr-10 lg-sm-mobile+1:tdp-px-0 tdp-items-start tdp-flex large-tablet-lg:tdp-w-[49.5%] tablet-mobile-sm:tdp-w-full max-mobile:tdp-w-full tdp-h-full tdp-py-1`}>
                      <div className='tdp-mt-2 tdp-h-full tdp-flex tdp-flex-col tdp-items-start laptop-s-lg-tab:tdp-w-[23%] large-laptop-sm:tdp-w-[17%] max-tablet:tdp-w-[16%] lg-md-mobile:tdp-w-[18%] max-mobile:tdp-w-[26%]'>
                        <p style={{ color: config?.colorcode, fontSize: '13px' }} className='tdp-my-0 tdp-mx-2 tdp-truncate tdp-select-none hover:tdp-overflow-visible hover:tdp-bg-white hover:tdp-z-30 tdp-transition-all tdp-duration-300 tdp-transform hover:tdp-scale-100 tdp-px-[2px]'>Color</p>
                        <div className="lg-sm-mobile:tdp-border-transparent lg-sm-mobile:tdp-border tdp-px-1 tdp-flex tdp-items-center">
                          <svg onClick={() => changeColorSetting()} xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill={config?.colorcode} className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                          </svg>

                          <p className="tdp-text-base tdp-m-0 tdp-p-0 lg-sm-mobile:tdp-text-xs tdp-mx-1 lg-sm-mobile:tdp-m-0">{isFancy ? "Fancy" : "Color"}</p>
                          <svg onClick={() => changeColorSetting()} xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill={config?.colorcode} className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </div>
                      </div>

                      {isFancy
                        ? <>
                          {config?.fancy_color !== undefined && <FancyColor arr={config?.fancy_color} isReset={isReset} color={config.colorcode} />}
                        </>
                        : config?.color !== undefined &&
                        <SliderStep setResetFalse={() => setIsReset(false)} type="Color" keys="color" filterValue={config?.color} colorCode={config.colorcode} isReset={isReset} />
                      }
                    </div>
                    <div className="tdp-cut tdp-pl-6 tdp-pr-10 lg-sm-mobile+1:tdp-px-0 tdp-items-start tdp-flex large-tablet-lg:tdp-w-[49.5%] tablet-mobile-sm:tdp-w-full max-mobile:tdp-w-full tdp-h-full tdp-py-1">
                      {config?.cut !== undefined &&
                        <SliderStep setResetFalse={() => setIsReset(false)} type="Cut" keys="cut" filterValue={config?.cut} colorCode={config.colorcode} isReset={isReset} />
                      }
                    </div>
                    <div className="tdp-clarity tdp-pl-6 tdp-pr-10 lg-sm-mobile+1:tdp-px-0 tdp-items-start tdp-flex large-tablet-lg:tdp-w-[49.5%] tablet-mobile-sm:tdp-w-full max-mobile:tdp-w-full tdp-h-full tdp-py-1">
                      {config?.clarity !== undefined &&
                        <SliderStep setResetFalse={() => setIsReset(false)} type="Clarity" keys="clarity" filterValue={config?.clarity} colorCode={config.colorcode} isReset={isReset} />
                      }
                    </div>
                    <div className="tdp-lab tdp-pl-6 tdp-my-2 tdp-pr-10 lg-sm-mobile+1:tdp-px-0 tdp-items-start tdp-flex large-tablet-lg:tdp-w-[49.5%] tablet-mobile-sm:tdp-w-full max-mobile:tdp-w-full tdp-h-full tdp-py-1">
                      {config?.lab !== undefined &&
                        <>
                          <div className='tdp-h-full tdp-flex tdp-items-center laptop-s-lg-tab:tdp-w-[20%] large-laptop-sm:tdp-w-[17%] max-tablet:tdp-w-[16%] lg-md-mobile:tdp-w-[16%] max-mobile:tdp-w-[26%]'>
                            <div style={{ color: config?.colorcode, fontSize: '13px' }} className='tdp-mx-2 tdp-my-0 tdp-select-none hover:tdp-overflow-visible hover:tdp-bg-white hover:tdp-z-30 tdp-transition-all tdp-duration-300 tdp-transform hover:tdp-scale-100 tdp-px-[2px]'>Lab</div>
                          </div>
                          <div className="tdp-lab-checkbox laptop-s-lg-tab:tdp-w-[80%] large-laptop-sm:tdp-w-[88%] max-tablet:tdp-w-[85%] lg-md-mobile:tdp-w-[84%] max-mobile:tdp-w-[74%]">
                            {/* {config.lab?.map((lab, i) => (
                          <Checkbox onChange={onChange} >{lab}</Checkbox>
                        ))} */}
                            <Checkbox.Group options={config?.lab} defaultValue={config?.lab} value={configLab} onChange={onChange} />
                          </div>
                        </>
                      }
                    </div>
                    {
                      isAdvanceFilterOpen && <>
                        <div className="tdp-polish tdp-pl-6 tdp-pr-10 lg-sm-mobile+1:tdp-px-0 tdp-items-start tdp-flex large-tablet-lg:tdp-w-[49.5%] tablet-mobile-sm:tdp-w-full max-mobile:tdp-w-full tdp-h-full tdp-py-1">
                          {config?.polish !== undefined &&
                            <SliderStep setResetFalse={() => setIsReset(false)} type="Polish" keys="polish" filterValue={config?.polish} colorCode={config.colorcode} isReset={isReset} />
                          }
                        </div>
                        <div className="tdp-ptable tdp-pl-6 tdp-pr-10 lg-sm-mobile+1:tdp-px-0 tdp-items-start tdp-flex large-tablet-lg:tdp-w-[49.5%] tablet-mobile-sm:tdp-w-full max-mobile:tdp-w-full tdp-h-full tdp-py-1">
                          <SliderText setResetFalse={() => setIsReset(false)} type="Table" min={0} max={100} isPrice={false} keys="table" isPercentage={true} colorCode={config.colorcode} isReset={isReset} />
                        </div>
                        <div className="tdp-symmetry tdp-pl-6 tdp-pr-10 lg-sm-mobile+1:tdp-px-0 tdp-items-start tdp-flex large-tablet-lg:tdp-w-[49.5%] tablet-mobile-sm:tdp-w-full max-mobile:tdp-w-full tdp-h-full tdp-py-1">
                          {config?.symmetry !== undefined &&
                            <SliderStep setResetFalse={() => setIsReset(false)} type="Symmetry" keys="symmetry" filterValue={config?.symmetry} colorCode={config.colorcode} isReset={isReset} />
                          }
                        </div>
                        <div className="tdp-depth tdp-pl-6 tdp-pr-10 lg-sm-mobile+1:tdp-px-0 tdp-items-start tdp-flex large-tablet-lg:tdp-w-[49.5%] tablet-mobile-sm:tdp-w-full max-mobile:tdp-w-full tdp-h-full tdp-py-1">
                          <SliderText setResetFalse={() => setIsReset(false)} type="Depth" min={0} max={100} isPrice={false} keys="depth" isPercentage={true} colorCode={config.colorcode} isReset={isReset} />
                        </div>
                        <div className="tdp-florescence tdp-pl-6 tdp-pr-10 lg-sm-mobile+1:tdp-px-0 tdp-items-start tdp-flex large-tablet-lg:tdp-w-[49.5%] tablet-mobile-sm:tdp-w-full max-mobile:tdp-w-full tdp-h-full tdp-py-1">
                          {config?.fluorescence !== undefined &&
                            <SliderStep setResetFalse={() => setIsReset(false)} type="Fluorescence" keys="fluorescence" filterValue={config?.fluorescence} colorCode={config.colorcode} isReset={isReset} />
                          }
                        </div>
                      </>
                    }
                  </div>
                </div>
                <div className="laptop-s-lg-tab:tdp-w-[94%] laptop-s-lg:tdp-w-[65%] laptop-md-sm:tdp-w-[85%]  laptop-md-sm:tdp-w-[83%] tablet-lg2-lg3:tdp-w-[82%] tablet-mobile-sm:tdp-w-[97%] tablet-lg3-lg4:tdp-w-[88%]">
                  <div className="tdp-flex tdp-justify-between tdp-w-full tdp-my-10 tablet-mobile-sm:tdp-flex-col">
                    <div className="tablet-mobile-sm:tdp-my-2 tdp-flex tdp-flex-row tablet-mobile-sm:tdp-w-full max-mobile:tdp-w-full">
                      <div className={`tablet-mobile-sm:tdp-w-1/2 tdp-py-2 tdp-mx-2 tdp-px-5 laptop-s-lg-tab:tdp-text-sm laptop-s-lg-tab:tdp-px-2 laptop-s-lg-tab:tdp-mx-1 tdp-text-base border-2 tdp-cursor-pointer tdp-flex tdp-items-center tdp-justify-center`} onClick={() => setIsAdvanceFilterOpen(!isAdvanceFilterOpen)} style={{ border: `1px solid ${config?.colorcode}`, color: config?.colorcode, }}>
                        Advance Filter <span className="tdp-mx-2 tdp-flex tdp-items-center tdp-justify-center">
                          {!isAdvanceFilterOpen
                            ? <>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={config?.colorcode} className="bi bi-chevron-down" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                              </svg>
                            </>
                            : <>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={config?.colorcode} className="bi bi-chevron-up" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                              </svg>
                            </>}
                        </span>
                      </div>
                      <div className={`tablet-mobile-sm:tdp-w-1/2 tdp-border tdp-border-gray-600 laptop-s-lg-tab:tdp-text-sm tdp-border-solid tdp-py-2 tdp-mx-2 tdp-px-5 laptop-s-lg-tab:tdp-px-2 laptop-s-lg-tab:tdp-mx-1 tdp-text-base tdp-text-gray-600 tdp-bg-gray-300 border-2 tdp-cursor-pointer tdp-flex tdp-items-center tdp-justify-center`}>
                        Result ({product.total})
                      </div>

                    </div>
                    <div className="tdp-flex tdp-justify-between tablet-lg3-lg4:tdp-flex-col large-tablet-lg-1:tdp-hidden lg-sm-mobile:tdp-hidden">
                      <div className={`tablet-lg-lg2:tdp-w-[45%] tablet-lg2-lg3:tdp-w-[97.2%] laptop-s-lg-tab:tdp-text-sm tablet-lg3-lg4:tdp-w-[97.2%] tablet-mobile-sm:tdp-my-2 laptop-s-lg-tab:tdp-px-2 laptop-s-lg-tab:tdp-mx-1  tablet-mobile-sm:tdp-mx-2 max-mobile:tdp-my-5 tdp-border tdp-py-2 tdp-px-5 tdp-text-base tdp-flex tdp-items-center tdp-justify-center tdp-select-none tdp-cursor-pointer`} style={{ color: config.colorcode, borderColor: config?.colorcode }} onClick={() => setReset()}>
                        Reset Filter
                      </div>
                      <div className="tdp-flex tdp-flex-row tablet-mobile-sm:tdp-w-full max-mobile:tdp-w-full max-tablet:tdp-justify-end max-mobile:tdp-justify-center tablet-mobile-sm:tdp-my-2">
                        <div onClick={() => listType('Grid')} className={`laptop-s-lg-tab:tdp-mx-1 tdp-mx-2 tdp-py-2 tdp-px-2 tdp-text-base border-2 tdp-cursor-pointer tdp-flex tdp-items-center tdp-justify-center`} style={list_type === 'Table' ? { border: `1px solid ${config?.colorcode}`, color: config?.colorcode } : { border: `1px solid ${config?.colorcode}`, color: 'white', background: config?.colorcode }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-grid-3x3-gap-fill" viewBox="0 0 16 16">
                            <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z" />
                          </svg>
                        </div>

                        <div onClick={() => listType('Table')} className={`laptop-s-lg-tab:tdp-mx-1 tdp-mx-2 tdp-py-2 tdp-px-2 tdp-text-base border-2 tdp-cursor-pointer tdp-flex tdp-items-center tdp-justify-center`} style={list_type === 'Grid' ? { border: `1px solid ${config?.colorcode}`, color: config?.colorcode } : { border: `1px solid ${config?.colorcode}`, color: 'white', background: config?.colorcode }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-list-task" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z" />
                            <path d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z" />
                            <path fillRule="evenodd" d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z" />
                          </svg>
                        </div>
                        <select className="tdp-mx-2 lg-sm-mobile:tdp-w-[69%] tdp-text-base" style={{ border: `1px solid ${config?.colorcode}`, color: config?.colorcode }} value={sorting} onChange={handleSortChange}>
                          <option value={0}>Sort By | Price - Low to High</option>
                          <option value={1}>Sort By | Price - High to Low</option>
                          <option value={2}>Sort By | Carat - Low to High</option>
                          <option value={3}>Sort By | Carat - High to Low</option>
                        </select>
                      </div>
                    </div>
                    <div className={`max-tablet-1:tdp-hidden tablet-mobile-sm:tdp-my-2 laptop-s-lg-tab:tdp-text-sm laptop-s-lg-tab:tdp-px-2 laptop-s-lg-tab:tdp-mx-1 tablet-mobile-sm:tdp-mx-2 max-mobile:tdp-my-5 tdp-border tdp-py-2 tdp-px-5 tdp-text-lg tdp-flex tdp-items-center tdp-justify-center tdp-select-none tdp-cursor-pointer`} style={{ color: config.colorcode, borderColor: config?.colorcode }} onClick={() => setReset()}>
                      Reset Filter
                    </div>
                    <div className="max-tablet-1:tdp-hidden tdp-flex tdp-flex-row tablet-mobile-sm:tdp-w-full max-mobile:tdp-w-full max-tablet:tdp-justify-end max-mobile:tdp-justify-center tablet-mobile-sm:tdp-my-2">
                      <div onClick={() => listType('Grid')} className={`laptop-s-lg-tab:tdp-mx-1 tdp-mx-2 tdp-py-2 tdp-px-2 tdp-text-lg border-2 tdp-cursor-pointer tdp-flex tdp-items-center tdp-justify-center`} style={list_type === 'Table' ? { border: `1px solid ${config?.colorcode}`, color: config?.colorcode } : { border: `1px solid ${config?.colorcode}`, color: 'white', background: config?.colorcode }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-grid-3x3-gap-fill" viewBox="0 0 16 16">
                          <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z" />
                        </svg>
                      </div>

                      <div onClick={() => listType('Table')} className={`laptop-s-lg-tab:tdp-mx-1 tdp-mx-2 tdp-py-2 tdp-px-2 tdp-text-lg border-2 tdp-cursor-pointer tdp-flex tdp-items-center tdp-justify-center`} style={list_type === 'Grid' ? { border: `1px solid ${config?.colorcode}`, color: config?.colorcode } : { border: `1px solid ${config?.colorcode}`, color: 'white', background: config?.colorcode }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-list-task" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z" />
                          <path d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z" />
                          <path fillRule="evenodd" d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z" />
                        </svg>
                      </div>
                      <select className="tdp-mx-2 lg-sm-mobile:tdp-w-[69%] laptop-s-lg-tab:tdp-text-sm" style={{ border: `1px solid ${config?.colorcode}`, color: config?.colorcode, background: 'transparent' }} value={sorting} onChange={handleSortChange}>
                        <option value={0}>Sort By | Price - Low to High</option>
                        <option value={1}>Sort By | Price - High to Low</option>
                        <option value={2}>Sort By | Carat - Low to High</option>
                        <option value={3}>Sort By | Carat - High to Low</option>
                      </select>
                    </div>
                  </div>
                </div>
              </>
            }

          </div>
        }

        {Object.keys(config).length !== 0 && <Product listType={list_type} />}
      </>
      {/*:  <>
          <DiamondView data={productObj} />
        </> */}
    </>
  );
}

/**
 * May please refer to your email dated 17.10.2018
 */
