import React, { useEffect, useState } from 'react'
import { Checkbox, Form, InputNumber, Slider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setProductFilter } from '../slice/config.slice';
import { getConfig } from '../middleware-redux/Config';
import { getProduct } from '../middleware-redux/Product';
import { setProductArrEmpty } from '../slice/product.slice';

const SliderStep = ({ type, keys, isReset, colorCode, filterValue, setResetFalse }) => {
    const timer = React.useRef();
    const dispatch = useDispatch();
    const { productFilter } = useSelector((state) => state.config);

    const [filterEntityValue, setFilterEntityValue] = useState([0, 100]);

    const step = (100 / filterValue.length).toFixed(2)

    const Options = {}
    let min, max

    filterValue.forEach((st, i) => {
        if (i === 0) {
            min = (i * step).toFixed(2)
        } 
        if (i === filterValue.length - 1) {
            max = (i * step).toFixed(2)
        }
        let key = (i * step) > 100 ? 100 : (i * step).toFixed(2)
        Options[key] = st
    })

    useEffect(() => {
        setFilterEntityValue([0, 100])
        if (isReset) {
            setResetFalse()
        }
    }, [isReset])

    useEffect(() => {

        if (productFilter[keys] !== undefined) {
            let startVal = productFilter[keys]?.split(',')[0]
            let endVal = productFilter[keys]?.split(',')[productFilter[keys]?.split(',').length - 1]

            let arr = []

            Object.keys(Options).map((opt, i) => {
                if (startVal === endVal) {
                    if (Options[opt] === startVal) {
                        arr[0] = Number(parseFloat(opt))
                        arr[1] = i === Object.keys(Options).length - 1 ? 100 : Number(parseFloat(opt)) + Number(parseFloat(step))
                    }
                } else {
                    if (Options[opt] === startVal) {
                        arr[0] = Number(parseFloat(opt))
                    } else if (Options[opt] === endVal) {
                        arr[1] = Options[opt] === filterValue[filterValue.length - 1] ? 100 : parseFloat(Number(parseFloat(opt)) + Number(parseFloat(step))).toFixed(2)
                    }
                }
            })
            setFilterEntityValue(arr)
        }
    }, [])



    const handleChange = (e) => {
        let arr = []
        let start = filterValue.indexOf(Options[e[0].toFixed(2)])

        let index = parseFloat(e[1] === 100 ? max : (e[1] === 0 ? 0 : e[1].toFixed(2) - step))
        let end = arr[1] = filterValue.indexOf(Options[index.toFixed(2)])

        arr = filterValue.slice(start, end + 1)

        setFilterEntityValue(e)

        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            let filter = JSON.parse(JSON.stringify(productFilter))
            filter[keys] = e[0] === e[1] ? "" : arr.length === 0 ? e?.join(',') : arr.join(',');
            dispatch(setProductFilter(filter))
            dispatch(setProductArrEmpty())
            dispatch(getProduct(filter))
        }, 800);
    }

    useEffect(() => {
        let div = Array.from(document.querySelectorAll(`.${type} .ant-slider-mark-text`))
        if (div.length > 1) {
            let width = Number(parseFloat(div[1].style.left)) / 2
            div.forEach((d) => {
                let left = parseFloat(d.style.left)
                let posLeft = `${left + (width)}%`
                d.style.left = posLeft
            })
        } else if (div.length === 1) {
            div.forEach((d) => {
                d.style.left = "50%"
            })
        }
    }, [])



    return (
        <>
            {type !== "Color" && <div className='large-tablet-lg-1:tdp-mt-2 tdp-h-full tdp-flex tdp-items-center laptop-s-lg-tab:tdp-w-[22%] large-laptop-sm:tdp-w-[17%] max-tablet:tdp-w-[16%] lg-md-mobile:tdp-w-[19%] max-mobile:tdp-w-[26%]'>
                <div className='tdp-my-0 tdp-mx-2 tdp-truncate tdp-select-none hover:tdp-overflow-visible hover:tdp-bg-white hover:tdp-z-30 tdp-transition-all tdp-duration-300 tdp-transform hover:tdp-scale-100 tdp-px-[2px]' style={{fontSize: '13px',color: colorCode}}>{type}</div>
            </div>}
            <Form.Item className={`tdp-m-0 ${type} tdp-sliders ${'max-tablet:tdp-w-[85%] lg-md-mobile:tdp-w-[84%] max-mobile:tdp-w-[74%] laptop-s-lg-tab:tdp-w-[80%] large-laptop-sm:tdp-w-[88%]'}`}>
                <Slider tooltip={{ formatter: null }} className={`tdp-w-full ${type}`} range marks={Options} value={filterEntityValue} onChange={handleChange} step={step} />
            </Form.Item>
        </>
    )
}

export default SliderStep