import axios from "axios";
import { getProductLoading, getProductSuccess, getProductError, getMoreProductLoading, GetInquiryLoading, GetInquirySuccess, GetInquiryError, GetChooseDiamondLoading, GetChooseDiamondSuccess, GetChooseDiamondError } from '../slice/product.slice'

let abortC = null;
export function getProduct(payload, currentpage = undefined) {
    return (dispatch) => {
        abortC?.abort();
        abortC = new AbortController();
        currentpage === undefined ? dispatch(getProductLoading()) : dispatch(getMoreProductLoading())
        axios.post(`https://thediamondport.com/api/wh_search_diamond?page=${currentpage === undefined ? '1' : currentpage + 1}`, { ...payload }, {
            signal: abortC.signal
        })
            .then((resp) => {
                const { data, success } = resp.data
                if (success) {
                    data.data?.forEach((res, i) => {
                        let main = [], para = [], sidePara = [], secMain = []
                        Object.keys(res).forEach((key) => {
                            if (key === "carat" && res[key]?.toString().length > 0) {
                            } else if (key === "shape" && res[key]?.length > 0) {
                                let changeShape = res[key].slice(0, 1) + res[key].slice(1).toLowerCase()
                                secMain.push(changeShape)
                            } else if (key === "color" && res[key]?.toString().length > 0) {
                                let color = res[key]
                                let abs = `${color}`
                                main.push(abs)
                            } else if (key === "clarity" && res[key]?.toString().length > 0) {
                                main.push(res[key])
                            } else if (key === "cut" && res[key]?.toString().length > 0) {
                                let value
                                switch (res[key]) {
                                    case "EX":
                                        value = "Excellent"
                                        break;
                                    case "VG":
                                        value = "Very Good"
                                        break;
                                    case "GD":
                                        value = "Good"
                                        break;
                                    case "ID":
                                        value = "Ideal"
                                        break;
                                    case "FR":
                                        value = "Fair"
                                        break;
                                }
                                secMain.push(`${value} Cut`)
                                res['CUT_Full'] = `${value} Cut`
                            } /* else if (key === "fluorescence" && res[key]?.toString().length > 0){
                                sidePara.push(res[key])
                            } */ else if (key === "lab" && res[key]?.toString().length > 0) {
                                sidePara.push(res[key])
                            } else if (key === "diamond_type" && res[key]?.toString().length > 0 && res['diamond_type'] === "W") {
                                res['d_type'] = "Natural"
                            } else if (key === "diamond_type" && res[key]?.toString().length > 0 && res['diamond_type'] === "L") {
                                res['d_type'] = "Lab Grown"
                            } 
                        })
                        res['itemHead'] = main
                        res['itemsSubHead'] = para
                        res['flourLab'] = sidePara
                        res['secondItemHead'] = secMain
                    })
                    dispatch(getProductSuccess(data))
                }
                else {
                    dispatch(getProductError())
                }
            })
            .catch(() => dispatch(getProductError()))
    }
}

export function inquiryForm(payload) {
    return (dispatch) => {
        abortC?.abort();
        abortC = new AbortController();
        dispatch(GetInquiryLoading())
        axios.post("https://thediamondport.com/api/wh_inquiry_diamond", payload, { signal: abortC.signal })
            .then((res) => {
                const { data } = res
                if (data.success) {
                    dispatch(GetInquirySuccess(res))
                } else {
                    dispatch(GetInquiryError(res))
                }
            })
            .catch((err) => dispatch(GetInquiryError(err.data)))
    }
}
export function chooseDiamond(payload) {
    return (dispatch) => {
        abortC?.abort();
        abortC = new AbortController();
        dispatch(GetChooseDiamondLoading())
        axios.post("https://thediamondport.com/api/wh_add_diamond", payload, { signal: abortC.signal })
            .then((res) => {
                const { data } = res
                if (data.success) {
                    dispatch(GetChooseDiamondSuccess(res))
                } else {
                    dispatch(GetChooseDiamondError(res))
                }
            })
            .catch((err) => console.log(err))
    }
}