import React, { useState } from 'react'
import { Form, Slider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setProductFilter } from '../slice/config.slice';
import { getProduct } from '../middleware-redux/Product';
import { setProductArrEmpty } from '../slice/product.slice';




const SliderText = ({ setResetFalse, sliderMax = undefined, type, min, max, keys, isPercentage, colorCode, isPrice, isReset }) => {
    const { productFilter } = useSelector((state) => state.config);
    const dispatch = useDispatch();

    const [minVal, setMinVal] = useState(isPercentage ? 0 : Number(parseFloat(min).toFixed(2)))
    const [maxVal, setMaxVal] = useState(isPercentage ? 100 : Number(parseFloat(max).toFixed(2)))
    const [filterEntityValue, setFilterEntityValue] = useState(type === "Carat" ? [min, sliderMax.length - 1] : [min, max]);
    const [initialLoading, setInitialLoading] = useState(true)
    const [minCaratStep, setMinCaratStep] = useState(0.01)
    const [maxCaratStep, setMaxCaratStep] = useState(1)
    const [initialChange, setInitialChange] = useState(false)
    const [background, setBackground] = useState('')



    const countDigitsAfterDecimal = (value) => {
        const decimalIndex = JSON.stringify(value).indexOf('.');
        let count = 0;
        if (decimalIndex !== -1) {
            let arr = JSON.stringify(value).split('.')
            count = arr[1].length
        }
        return count;
    };

    useEffect(() => {
        let arr = [min, max]
        setFilterEntityValue(type === "Carat" ? [min, sliderMax.length - 1] : [min, max])
        setMinVal(isPercentage ? 0 : Number(parseFloat(min).toFixed(2)))
        setMaxVal(isPercentage ? 100 : Number(parseFloat(max).toFixed(2)))
        let filter = JSON.parse(JSON.stringify(productFilter))
        filter[keys] = arr.join('-');
        if (!initialLoading) {
            dispatch(setProductFilter(filter))
            dispatch(setProductArrEmpty())
        }

        if (isReset) {
            setResetFalse()
            setInitialLoading(true)
        }
    }, [isReset])




    const changeSlider = (e) => {
        let arr = [...e]
        if (type === "Carat") {
            e[0] = Number(parseFloat(e[0]).toFixed(0))
            e[1] = Number(parseFloat(e[1]).toFixed(0))
            let diff
            if (e[1] >= sliderMax.indexOf(15) && e[1] <= sliderMax.length - 1) {
                diff = Number(parseFloat(max - (sliderMax.indexOf(max) - e[1])).toFixed(0))

            } else if (e[1] >= sliderMax.indexOf(10) && e[1] < sliderMax.indexOf(15)) {
                diff = Number(parseFloat(15 - ((sliderMax.indexOf(15) - e[1]) * 0.5)).toFixed(1))

            } else if (e[1] >= sliderMax.indexOf(5) && e[1] < sliderMax.indexOf(10)) {
                diff = Number(parseFloat(10 - ((sliderMax.indexOf(10) - e[1]) * 0.1)).toFixed(1))

            } else if (e[1] >= min && e[1] < sliderMax.indexOf(5)) {
                diff = Number(parseFloat(5 - ((sliderMax.indexOf(5) - e[1]) * 0.01)).toFixed(2))

            }
            arr[1] = diff

            let differ
            if (e[0] >= sliderMax.indexOf(15) && e[0] <= sliderMax.length - 1) {
                differ = Number(parseFloat(max - (sliderMax.indexOf(max) - e[0])).toFixed(0))

            } else if (e[0] >= sliderMax.indexOf(10) && e[0] < sliderMax.indexOf(15)) {
                differ = Number(parseFloat(15 - ((sliderMax.indexOf(15) - e[0]) * 0.5)).toFixed(1))

            } else if (e[0] >= sliderMax.indexOf(5) && e[0] < sliderMax.indexOf(10)) {
                differ = Number(parseFloat(10 - ((sliderMax.indexOf(10) - e[0]) * 0.1)).toFixed(1))

            } else if (e[0] >= min && e[0] < sliderMax.indexOf(5)) {
                differ = Number(parseFloat(5 - ((sliderMax.indexOf(5) - e[0]) * 0.01)).toFixed(2))

            }
            arr[0] = differ
        }
        setMinVal(Number(parseFloat(arr[0]).toFixed(2)))
        setMaxVal(Number(parseFloat(arr[1]).toFixed(2)))
        setFilterEntityValue(e)
    }

    const putMinValue = ({ target }) => {
        let arr = [...filterEntityValue]
        let value = (Number(target.value) < min || target.value === "") ? min : Number(target.value) > maxVal ? maxVal : Number(target.value)

        let input = countDigitsAfterDecimal(value) > 4 ? parseFloat(value).toFixed(3) : value

        if (type === "Carat") {
            let diff;
            if (input >= 15 && input <= max) {
                diff = sliderMax.indexOf(max) - ((max - input) / 1)

            } else if (input >= 10 && input < 15) {
                diff = sliderMax.indexOf(15) - ((15 - input) / 0.5)

            } else if (input >= 5 && input < 10) {
                diff = sliderMax.indexOf(10) - ((10 - input) / 0.1)

            } else if (input >= min && input < 5) {
                diff = sliderMax.indexOf(5) - ((5 - input) / 0.01)

            }
            arr[0] = Number(parseFloat(diff).toFixed(0))
        } else {
            arr[0] = input

        }
        setMinVal(JSON.stringify(Number(input)))

        setFilterEntityValue(arr)
        let filter = JSON.parse(JSON.stringify(productFilter))
        filter[keys] = [input, maxVal].join('-');
        dispatch(setProductFilter(filter))
        dispatch(setProductArrEmpty())
        dispatch(getProduct(filter))

    }

    const putMaxValue = ({ target }) => {
        let arr = [...filterEntityValue]

        let value = (Number(target.value) > max || target.value === "") ? max : Number(target.value) < minVal ? minVal : Number(target.value)

        let input = countDigitsAfterDecimal(value) > 4 ? parseFloat(value).toFixed(3) : value

        if (type === "Carat") {
            let diff;
            if (input >= 15 && input <= max) {
                diff = sliderMax.indexOf(max) - ((max - input) / 1)
            } else if (input >= 10 && input < 15) {
                diff = sliderMax.indexOf(15) - ((15 - input) / 0.5)

            } else if (input >= 5 && input < 10) {
                diff = sliderMax.indexOf(10) - ((10 - input) / 0.1)

            } else if (input >= min && input < 5) {
                diff = sliderMax.indexOf(5) - ((5 - input) / 0.01)

            }
            arr[1] = Number(parseFloat(diff).toFixed(0))
        } else {
            arr[1] = input
        }

        // arr[1] = input
        setMaxVal(JSON.stringify(Number(input)))

        setFilterEntityValue(arr)
        let filter = JSON.parse(JSON.stringify(productFilter))
        filter[keys] = [minVal, input].join('-');
        dispatch(setProductFilter(filter))
        dispatch(setProductArrEmpty())
        dispatch(getProduct(filter))

    }

    useEffect(() => {
        if (!initialLoading) {
            let filter = JSON.parse(JSON.stringify(productFilter))
            filter[keys] = filterEntityValue.join('-');
            dispatch(setProductFilter(filter))
            dispatch(setProductArrEmpty())
            dispatch(getProduct(filter))
        }
    }, [initialLoading])

    function rgbToHex(rgb) {
        var parts = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
        if (parts) {
            var hex = '#';
            for (var i = 1; i <= 3; i++) {
                var channel = parseInt(parts[i], 10);
                hex += ('0' + channel.toString(16)).slice(-2);
            }
            return hex;
        }
        return rgb; // Return the original value if it's not in RGB format
    }

    useEffect(() => {
        let div = document.querySelector(`.${type} .ant-slider-track`)
        if (div?.style?.background) {
            div.style.background = colorCode
        }
        if (productFilter[keys] !== undefined) {
            let arr = productFilter[keys].split('-')
            let filterVal = [...arr]
            if (type === "Carat") {

                if (arr[1] >= 15 && arr[1] <= max) {
                    filterVal[1] = sliderMax.indexOf(max) - ((max - arr[1]) / 1)
                } else if (arr[1] >= 10 && arr[1] < 15) {
                    filterVal[1] = sliderMax.indexOf(15) - ((15 - arr[1]) / 0.5)
    
                } else if (arr[1] >= 5 && arr[1] < 10) {
                    filterVal[1] = sliderMax.indexOf(10) - ((10 - arr[1]) / 0.1)
    
                } else if (arr[1] >= min && arr[1] < 5) {
                    filterVal[1] = sliderMax.indexOf(5) - ((5 - arr[1]) / 0.01)
    
                }

                if (arr[0] >= 15 && arr[0] <= max) {
                    filterVal[0] = sliderMax.indexOf(max) - ((max - arr[0]) / 1)
    
                } else if (arr[0] >= 10 && arr[0] < 15) {
                    filterVal[0] = sliderMax.indexOf(15) - ((15 - arr[0]) / 0.5)
    
                } else if (arr[0] >= 5 && arr[0] < 10) {
                    filterVal[0] = sliderMax.indexOf(10) - ((10 - arr[0]) / 0.1)
    
                } else if (arr[0] >= min && arr[0] < 5) {
                    filterVal[0] = sliderMax.indexOf(5) - ((5 - arr[0]) / 0.01)
    
                }

            }
            // filterVal = type === "Carat" ? [min, sliderMax.length-1] : arr
            setFilterEntityValue(filterVal)
            setMinVal(arr[0])
            setMaxVal(arr[1])
        }
        
        let element,bg
        element = document.querySelector(`body`);
        bg = window.getComputedStyle(element).backgroundColor;

        setBackground((bg==='rgba(0, 0, 0, 0)'||bg==='transparent'||bg==='#ffffff00') ? 'white' : bg)
    }, [])

    const callApiAfterChange = (e) => {
        let arr = [...e]

        if (type === "Carat") {
            let diff
            if (e[1] >= sliderMax.indexOf(15) && e[1] <= sliderMax.length - 1) {
                diff = max - (sliderMax.indexOf(max) - e[1])

            } else if (e[1] >= sliderMax.indexOf(10) && e[1] < sliderMax.indexOf(15)) {
                diff = 15 - ((sliderMax.indexOf(15) - e[1]) * 0.5)

            } else if (e[1] >= sliderMax.indexOf(5) && e[1] < sliderMax.indexOf(10)) {
                diff = 10 - ((sliderMax.indexOf(10) - e[1]) * 0.1)

            } else if (e[1] >= min && e[1] < sliderMax.indexOf(5)) {
                diff = 5 - ((sliderMax.indexOf(5) - e[1]) * 0.01)

            }
            arr[1] = Number(parseFloat(diff).toFixed(2))

            let differ
            if (e[0] >= sliderMax.indexOf(15) && e[0] <= sliderMax.length - 1) {
                differ = max - (sliderMax.indexOf(max) - e[0])

            } else if (e[0] >= sliderMax.indexOf(10) && e[0] < sliderMax.indexOf(15)) {
                differ = 15 - ((sliderMax.indexOf(15) - e[0]) * 0.5)

            } else if (e[0] >= sliderMax.indexOf(5) && e[0] < sliderMax.indexOf(10)) {
                differ = 10 - ((sliderMax.indexOf(10) - e[0]) * 0.1)

            } else if (e[0] >= min && e[0] < sliderMax.indexOf(5)) {
                differ = 5 - ((sliderMax.indexOf(5) - e[0]) * 0.01)

            }
            arr[0] = Number(parseFloat(differ).toFixed(2))
        }


        let filter = JSON.parse(JSON.stringify(productFilter))
        filter[keys] = arr.join('-');
        dispatch(setProductFilter(filter))
        dispatch(setProductArrEmpty())
        dispatch(getProduct(filter))
        initialChange === false && setInitialChange(type === "Carat")

    }

    const changeMinValue = (val) => {
        setMinVal(val)

        if (type === "Carat") {

            let caratMin = Number(parseInt(val))

            if (caratMin >= min && caratMin < 5) {
                setMinCaratStep(0.01)
            }
            else if (caratMin >= 5 && caratMin < 10) {
                setMinCaratStep(0.1)
            }
            else if (caratMin >= 10 && caratMin < 15) {
                setMinCaratStep(0.5)
            }
            else if (caratMin >= 15 && caratMin <= max) {
                setMinCaratStep(1)
            }
        }
    }

    const changeMaxValue = (val) => {
        setMaxVal(val)

        if (type === "Carat") {

            let caratMax = Number(parseInt(val))

            if (caratMax >= min && caratMax < 5) {
                setMaxCaratStep(0.01)
            }
            else if (caratMax >= 5 && caratMax < 10) {
                setMaxCaratStep(0.1)
            }
            else if (caratMax >= 10 && caratMax < 15) {
                setMaxCaratStep(0.5)
            }
            else if (caratMax >= 15 && caratMax < max) {
                setMaxCaratStep(1)
            }
        }
    }

    return (
        <>
            <div className='tdp-mt-2 tdp-h-full tdp-flex tdp-items-center laptop-s-lg-tab:tdp-w-[22%] large-laptop-sm:tdp-w-[17%] max-tablet:tdp-w-[19%] lg-md-mobile:tdp-w-[19%] max-mobile:tdp-w-[26%]'>
                <div style={{ color: colorCode, fontSize: '13px' }} className='tdp-mx-2 tdp-my-0 tdp-select-none tdp-px-[2px] hover:tdp-overflow-visible hover:tdp-bg-white hover:tdp-z-30 tdp-transition-all tdp-duration-300 tdp-transform hover:tdp-scale-100'>{type}</div>
            </div>
            <div className='tdp-flex tdp-flex-col laptop-s-lg-tab:tdp-w-[80%] large-laptop-sm:tdp-w-[88%] max-tablet:tdp-w-[85%] lg-md-mobile:tdp-w-[84%] max-mobile:tdp-w-[74%]'>
                <Form.Item className={`tdp-m-0 ${type} tdp-sliders`}>
                    <Slider range tooltip={{ formatter: null }} onAfterChange={callApiAfterChange} onChange={changeSlider} min={min} max={type === "Carat" ? sliderMax.length - 1 : max} value={filterEntityValue} step={1}
                        trackStyle={{ background: colorCode, zIndex: 100, height: 2 }} handleStyle={{ opacity: 1, background: colorCode, borderColor: colorCode }}
                    />
                </Form.Item>
                <div className='tdp-flex tdp-justify-between tdp-px-3'>
                    <div className="min tdp-w-1/5 laptop-sm2-sm:tdp-w-[30%] lg-sm-mobile:tdp-[45%] laptop-s-lg-tab:tdp-w-2/5 lg-sm-mobile:tdp-w-2/5 tdp-rounded-lg tdp-border tdp-relative tdp-h-[38px]" style={{ borderColor: colorCode }}>
                        {/* {!!background && <span className={`tdp-px-2 md-sm-mobile:tdp-px-1 tdp-absolute tdp-top-[-9px] tdp-left-3 lg-sm-mobile:tdp-text-xs md-sm-mobile:tdp-left-1`} style={{ color: colorCode, background }}>min</span>} */}
                        <div className="tdp-w-full tdp-flex tdp-items-center tdp-h-[36px]">
                            {isPrice && <span className="prefix tdp-z-50 tdp-h5 tdp-h-full tdp-items-center tdp-m-0 tdp-p-0 tdp-text-black tdp-w-1/5 tdp-flex tdp-justify-center tdp-tracking-normal">$</span>}
                            <input className={`tdp-slider-text tdp-border-0 tdp-bg-transparent tdp-h-full ${isPrice ? "tdp-w-4/5" : "tdp-w-full tdp-plr-3"}`} type="number" placeholder="0" onChange={(e) => {changeMinValue(e.target.value) }} onBlur={(e) => putMinValue(e)} value={minVal} min={minVal <= min ? min : 0} max={max} step={type === "Carat" ? minCaratStep : 1} />
                        </div>
                        {/* {isPrice && <span className="prefix tdp-absolute tdp-top-[25%] tdp-left-2.5 tdp-z-50 h5 tdp-text-black">$</span>}
                        <input className={`tdp-bg-transparent tdp-border-0 tdp-w-full tdp-absolute tdp-bottom-1 tdp-text-sm tdp-left-0 tdp-px-2 ${isPrice && "tdp-pl-5"}`} type="number" placeholder="0" onChange={(e) => {changeMinValue(e.target.value) }} onBlur={(e) => putMinValue(e)} value={minVal} min={minVal <= min ? min : 0} max={max} step={type === "Carat" ? minCaratStep : 1} /> */}
                    </div>
                    <div className="max tdp-w-1/5 laptop-sm2-sm:tdp-w-[30%] lg-sm-mobile:tdp-[45%] laptop-s-lg-tab:tdp-w-2/5 lg-sm-mobile:tdp-w-2/5 tdp-rounded-lg tdp-border tdp-relative tdp-h-[38px]" style={{ borderColor: colorCode }}>
                       {/* {!!background &&  <span className={`tdp-px-2 md-sm-mobile:tdp-px-1 tdp-bg-[${background}] tdp-absolute tdp-top-[-9px] tdp-left-3 lg-sm-mobile:tdp-text-xs md-sm-mobile:tdp-left-1`} style={{ color: colorCode, background }}>max</span>} */}
                       <div className="tdp-w-full tdp-flex tdp-items-center tdp-h-[36px]">
                            {isPrice && <span className="prefix tdp-z-50 tdp-h5 tdp-h-full tdp-items-center tdp-m-0 tdp-p-0 tdp-text-black tdp-w-1/5 tdp-flex tdp-justify-center tdp-tracking-normal">$</span>}
                            <input className={`tdp-slider-text tdp-border-0 tdp-bg-transparent tdp-h-full ${isPrice ? "tdp-w-4/5" : "tdp-w-full tdp-plr-3"}`} type="number" placeholder="0" onChange={(e) => changeMaxValue(e.target.value)} onBlur={(e) => putMaxValue(e)} value={maxVal} max={max} min={maxVal <= min ? min : 0} step={type === "Carat" ? maxCaratStep : 1} />
                        </div>
                        {/* {isPrice && <span className="prefix tdp-absolute tdp-top-[25%] tdp-left-2.5 tdp-z-50 h5 tdp-text-black">$</span>}
                        <input className={`tdp-bg-transparent tdp-border-0 tdp-w-full tdp-absolute tdp-bottom-1 tdp-text-sm tdp-left-0 tdp-px-2 ${isPrice && "tdp-pl-5"}`} type="number" placeholder="0" onChange={(e) => changeMaxValue(e.target.value)} onBlur={(e) => putMaxValue(e)} value={maxVal} max={max} min={maxVal <= min ? min : 0} step={type === "Carat" ? maxCaratStep : 1} /> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SliderText